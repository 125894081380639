import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    display: 'flex',
    height: 'inherit',
    flexDirection: 'column',
  },
  tableContainer: {
    display: 'flex',
    height: 'inherit',
    padding: 15,
    flexDirection: 'column',
  },
  row_flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  icons: {
    height: 23,
    width: 23,
    cursor: 'pointer',
  },
  align_right: {
    justifyContent: 'flex-end',
  },
  padding_right: {
    paddingRight: 15,
  },
  table: {
    display: 'flex',
    height: 'inherit',
    overflow: 'auto',
  },
  pagination: {
    display: 'flex',
    marginBottom: 120,
    padding: 20,
    justifyContent: 'center',
    flex: 1,
  },
  menuBar_container: {
    marginBottom: 15,
  },
  flex_view: {
    width: '100%',
    display: 'flex',
    marginTop: 60,
    justifyContent: 'center',
  },
  tab_container: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  tabs: {
    '& .MuiTab-root': {
      color: '#716D73',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#39b077',
      height: 3,
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#39b077',
    },
  },
  tab: {
    padding: '2px 2px',
    minWidth: '0px',
    marginRight: 30,
    fontFamily: 'Lato, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px !important',
  },
}));
