import axios from 'axios';
import { UPDATE_FEE_STATUS } from '../../constants';

export const changeFeeStatus =
  (feeId, data, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(updateFeeStatus());

    axios
      .put(`/fee/updateFeeStatus?feeId=${feeId}`, data, config)
      .then((res) => {
        dispatch(setUpdateFeeStatus(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorUpdateFeeStatus(err.response));
        onError(err.response);
      });
  };

export const updateFeeStatus = () => ({
  type: UPDATE_FEE_STATUS.LOAD,
});

export const setUpdateFeeStatus = (data) => ({
  type: UPDATE_FEE_STATUS.SUCCESS,
  payload: data.data,
});

export const errorUpdateFeeStatus = (error) => ({
  type: UPDATE_FEE_STATUS.FAIL,
  payload: error,
});
