import React from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Button, RadioGroup, TextField, Typography } from '@mui/material';
import { Strings } from '../../common/strings';
import { Form, FormikProvider, useFormik } from 'formik';
import { Colors } from '../../common/colors';
import { TotalCalculationSchema } from '../../utils/validationSchemas';
import CustomRadioButton from '../CustomRadioButtonGroup';
import Loader from '../Loader';
import { connect } from 'react-redux';
import { calculateAmount } from '../../redux/actions/deliveryActions/calculateAmount';
import { getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';

const rateTypeArray = [
  { label: 'Designer Rate', value: 'designerRate' },
  { label: 'Customer Rate', value: 'customerRate' },
  { label: 'Surrounding Rate', value: 'surroundingRate' },
];

const DeliveryCalculationDialog = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      noOfMens: props?.data?.noOfMens || 1,
      travelTime: props?.data?.travelTime || 0,
      deliveryTime: props?.data?.deliveryTime || 0,
      fuelsCharge: props?.data?.fuelsCharge || 0,
      piecesOfTrash: props?.data?.piecesOfTrash || 0,
      miscFees: props?.data?.miscFees || 0,
      rateType: {
        designerRate: props?.data?.rateType === 'Designer Rate' ? true : false,
        customerRate: props?.data?.rateType === 'Customer Rate' ? true : false,
        surroundingRate:
          props?.data?.rateType === 'Surrounding Rate' ? true : false,
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: TotalCalculationSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue, setErrors } =
    formik;

  const handleDataSubmit = (values) => {
    const rateTypeOption = Object.keys(values?.rateType)?.filter((key) => {
      return values?.rateType[key];
    })[0];
    const newRateType = rateTypeArray?.filter(
      (el) => el?.value === rateTypeOption
    )[0];
    const data = {
      ...values,
      rateType: newRateType?.label,
      noOfItem: props?.noOfItem || 0,
      clientPickUp: props?.clientPickUp,
    };
    props.onSave(data);
    props.calculateAmount(data, onError, (data) => {
      props.setTotalValue(data?.feeData);
      props.onClose();
    });
  };

  const onChange = (key, value) => {
    setErrors({});
    setFieldValue(key, Number(value) || 0);
  };

  return (
    <div className={classes.dialog_mask_container}>
      <div className={classes.dialog_box_container}>
        <div className={classes.dialog_header_container}>
          <Typography variant='h6_700' color={Colors.BLACK}>
            {Strings.DELIVERY_FEE?.toUpperCase()}
          </Typography>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => props?.onClose()}
          />
        </div>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className={classes.dialog_content_container}>
              <TextField
                id='filled-required'
                label={Strings.NUM_OF_MEN}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.noOfMens}
                onChange={(e) => onChange('noOfMens', e.target.value)}
                error={Boolean(touched.noOfMens && errors.noOfMens)}
                helperText={touched.noOfMens && errors.noOfMens}
              />
              <TextField
                id='filled-required'
                label={Strings.TRAVEL_TIME}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.travelTime}
                onChange={(e) => onChange('travelTime', e.target.value)}
                error={Boolean(touched.travelTime && errors.travelTime)}
                helperText={touched.travelTime && errors.travelTime}
              />
              <TextField
                id='filled-required'
                label={Strings.DELIVERY_TIME}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.deliveryTime}
                onChange={(e) => onChange('deliveryTime', e.target.value)}
                error={Boolean(touched.deliveryTime && errors.deliveryTime)}
                helperText={touched.deliveryTime && errors.deliveryTime}
              />
              <TextField
                id='filled-required'
                label={Strings.FUELS_CHARGE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.fuelsCharge}
                onChange={(e) => onChange('fuelsCharge', e.target.value)}
                error={Boolean(touched.fuelsCharge && errors.fuelsCharge)}
                helperText={touched.fuelsCharge && errors.fuelsCharge}
              />
              <TextField
                id='filled-required'
                label={Strings.PIECES_OF_TRASH}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.piecesOfTrash}
                onChange={(e) => onChange('piecesOfTrash', e.target.value)}
                error={Boolean(touched.piecesOfTrash && errors.piecesOfTrash)}
                helperText={touched.piecesOfTrash && errors.piecesOfTrash}
              />
              <TextField
                id='filled-required'
                label={Strings.MISC_FEES}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.miscFees}
                onChange={(e) => onChange('miscFees', e.target.value)}
                error={Boolean(touched.miscFees && errors.miscFees)}
                helperText={touched.miscFees && errors.miscFees}
              />
            </div>
            <div className={classes.radio_button_container}>
              <RadioGroup
                name=''
                onChange={(e) => {
                  let rateType;
                  Object.entries(values.rateType).forEach(([key, value]) => {
                    rateType = {
                      ...rateType,
                      [key]: key !== '_id' ? key === e.target.value : value,
                    };
                  });
                  setFieldValue('rateType', rateType);
                }}
                value={
                  values?.rateType &&
                  Object.entries(values.rateType).find(
                    ([key, value]) => value === true
                  )
                    ? Object.entries(values.rateType).find(
                        ([key, value]) => value === true
                      )[0]
                    : '0'
                }
                className={classes.flex_row}
              >
                {rateTypeArray?.map((el, index) => (
                  <div style={{ flex: 1 }} key={index}>
                    <CustomRadioButton
                      label={el.label}
                      value={el.value}
                      disabled={props?.isShowDetails}
                    />
                  </div>
                ))}
              </RadioGroup>
              {(touched?.rateType?.designerRate ||
                touched?.rateType?.customerRate ||
                touched?.rateType?.surroundingRate) && (
                <label className={classes.optionErrorText}>
                  {errors?.rateType?.designerRate ||
                    errors?.rateType?.customerRate ||
                    errors?.rateType?.surroundingRate}
                </label>
              )}
            </div>
            <div className={classes.dialog_footer_container}>
              <Button
                type='submit'
                variant='contained'
                className={classes.save_button}
                onClick={() => {}}
                disabled={props.calculateAmountLoading}
              >
                {props.calculateAmountLoading ? <Loader /> : Strings.SAVE}
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  calculateAmountLoading: state.calculateAmountData.loading,
});

export default connect(mapStateToProps, {
  calculateAmount,
})(DeliveryCalculationDialog);
