import axios from 'axios';
import { UPDATE_VENDOR } from '../../constants';

export const updateVendor = (uID, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateVendor());

  axios
    .put(`/vendor/updateVendor?vendorId=${uID}`, data, config)
    .then((res) => {
      dispatch(setUpdateVendor(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateVendor(err.response));
      onError(err.response);
    });
};

export const loadUpdateVendor = () => ({
  type: UPDATE_VENDOR.LOAD,
});

export const setUpdateVendor = (data) => ({
  type: UPDATE_VENDOR.SUCCESS,
  payload: data.data,
});

export const errorUpdateVendor = (error) => ({
  type: UPDATE_VENDOR.FAIL,
  payload: error,
});
