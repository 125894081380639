import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  tab_container: {
    height: 274,
    width: '100%',
    display: 'flex',
    padding: 10,
    backgroundColor: Colors.BRIGHT_GRAY,
    borderRadius: 8,
    marginTop: 10,
    flexDirection: 'column',
  },
  tab_data_icons: {
    height: 20,
    width: 20,
    marginTop: 2,
  },
  business_info: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  top_5: {
    marginTop: 5,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  sub_heading: {
    marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
}));
