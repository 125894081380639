import axios from 'axios';
import { INVOICE_LIST } from './../../constants';

export const fetchInvoiceList = (search, designer, pageSize, pageNumber, startDate, endDate, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
      dispatch(loadInvoiceList());

    axios
      .get(
        `/invoice/listInvoices?searchString=${search}&designer=${designer}&page_size=${pageSize}&page_number=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        config
      )
      .then((res) =>  dispatch(setInvoiceList(res)))
      .catch((err) => {
        dispatch(errorInvoiceList(err.response));
        onError(err.response);
      });
}

export const loadInvoiceList = () => ({
    type: INVOICE_LIST.LOAD,
  });
  
  export const setInvoiceList = (data) => ({
    type: INVOICE_LIST.SUCCESS,
    payload: data.data.data,
  });
  
  export const errorInvoiceList = (error) => ({
    type: INVOICE_LIST.FAIL,
    payload: error,
  });
