import { Button, TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { icons } from '../../assets';
import { Strings } from '../../common/strings';
import CustomCheckbox from '../CustomCheckbox';
import CustomeDatePicker from '../CustomDatePicker';
import Selection from '../Selection';
import useStyles from './styles';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { getErrorMessage } from '../../utils/appUtils';
import { createRateSheet } from '../../redux/actions/rateSheetActions/addRateSheet';
import { AddRateSheetSchema } from '../../utils/validationSchemas';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { fetchClientList } from '../../redux/actions/rateSheetActions/clientList';
import { getRateSheet } from '../../redux/actions/rateSheetActions/getRateSheet';
import { updateRateSheet } from '../../redux/actions/rateSheetActions/updateRateSheet';
import moment from 'moment';
import Loader from '../Loader';
import { Colors } from '../../common/colors';
import ToggleButton from '../ToggleButton';

const CommonHeaderText = (props) => {
  const classes = useStyles();
  return (
    <Typography
      variant='p_400'
      className={classNames(
        classes.sub_heading,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.titleText}
    </Typography>
  );
};

const FeesInformationContainer = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.fees_content_item}>
      <div className={classes.flex_2} style={{ display: 'flex' }}>
        <Typography variant='p_600' style={{ paddingLeft: 16 }}>
          {props.label}
        </Typography>
      </div>
      <TextField
        // required
        name='receivingFee'
        id='filled-required'
        type='number'
        placeholder={Strings.TYPE_HERE}
        variant='standard'
        InputLabelProps={{
          shrink: true,
        }}
        className={classNames(
          classes.flex_3,
          classes.button_input,
          props?.disabledFields && classes.disabled_input
        )}
        value={props?.receivingFeeValue}
        onChange={(e) => props?.receivingFeeOnChange(e)}
        disabled={props?.disabledFields}
        //   error={Boolean(touched.name && errors.name)}
        //   helperText={touched.name && errors.name}
      />
      <CustomCheckbox
        name='receivingFeeDesc'
        container={classes.flex_1}
        checked={props?.receivingFeeDesc}
        onChange={(e) => props?.receivingFeeDescOnChecked(e)}
        style={{ margin: 0, padding: 0 }}
        disabled={props?.disabledFields}
      />
      <TextField
        // required
        name='storageFee'
        id='filled-required'
        type='number'
        placeholder={Strings.TYPE_HERE}
        variant='standard'
        InputLabelProps={{
          shrink: true,
        }}
        className={classNames(
          classes.flex_3,
          classes.button_input,
          props?.disabledFields && classes.disabled_input
        )}
        value={props?.storageFeeValue}
        onChange={(e) => props?.storageFeeOnChange(e)}
        disabled={props?.disabledFields}
      />
      <CustomCheckbox
        name='storageFeeDesc'
        container={classes.flex_1}
        checked={props?.storageFeeDesc}
        onChange={(e) => props?.storageFeeDescOnChecked(e)}
        style={{ margin: 0, padding: 0 }}
        disabled={props?.disabledFields}
      />
    </div>
  );
};

const AddRateSheetModal = (props) => {
  const classes = useStyles();
  const [feesInfoArray, setFeesInfoArray] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [designerList, setDesignerList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [designerId, setDesignerId] = useState('');
  const [checkAllReceivingDesc, setCheckAllReceivingDesc] = useState(false);
  const [checkAllStorageDesc, setCheckAllStorageDesc] = useState(false);
  const tempFeesInfoArray = [
    {
      name: 'xs',
      label: 'XS',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'sm',
      label: 'SM',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'md',
      label: 'MD',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'lg',
      label: 'LG',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'xl',
      label: 'XL',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'vault',
      label: 'Vault',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'carton',
      label: 'Carton',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
    {
      name: 'cost',
      label: 'Cost',
      receivingFee: '',
      receivingFeeDesc: false,
      storageFee: '',
      storageFeeDesc: false,
    },
  ];

  useEffect(() => {
    props.fetchDesignerList((err) => onError(err));
    if ((props?.edit || props.isShowDetails) && props?.selectRateSheetId) {
      props.getRateSheet(props?.selectRateSheetId, (err) => onError(err));
    }
    setFeesInfoArray(tempFeesInfoArray);
  }, []);

  useEffect(() => {
    if (designerId && designerId !== '0') {
      props.fetchClientList(designerId, (err) => onError(err));
    }
  }, [designerId]);

  useEffect(() => {
    const designerList = props.designerListData.map((e) => ({
      label: e.name,
      value: e._id,
    }));
    setDesignerList(designerList);
    const clientList = props.clientListData.map((e) => ({
      label: e.client,
      value: e._id,
    }));
    setClientList(clientList);
  }, [props.designerListData, props.clientListData]);

  const checkAllCheckedSelect = (array) => {
    if (array?.length > 0) {
      const isAllReceivingChecked = array?.every(
        ({ receivingFeeDesc }) => receivingFeeDesc
      );
      const isAllStorageChecked = array?.every(
        ({ storageFeeDesc }) => storageFeeDesc
      );
      isAllReceivingChecked
        ? setCheckAllReceivingDesc(true)
        : setCheckAllReceivingDesc(false);
      isAllStorageChecked
        ? setCheckAllStorageDesc(true)
        : setCheckAllStorageDesc(false);
    }
  };

  const selectHandleChange = (e, index, type) => {
    let newInfoArray = [...feesInfoArray];
    if (type === 'number') {
      newInfoArray[index][e.target.name] = e.target.value;
    } else if (type === 'checkbox') {
      newInfoArray[index][e.target.name] = e.target.checked;
    }
    checkAllCheckedSelect(newInfoArray);
    setFeesInfoArray(newInfoArray);
  };

  useEffect(() => {
    const changeField = feesInfoArray?.map((e) => ({
      size: e.label,
      receivingFee: Number(e.receivingFee),
      storageFee: Number(e.storageFee),
      receivingFeeDesc: e.receivingFeeDesc,
      storageFeeDesc: e.storageFeeDesc,
    }));
    const filterData = changeField?.filter(
      (e) => e.receivingFee !== 0 || e.storageFee !== 0
    );
    setFieldValue('ratesheet', filterData);
  }, [feesInfoArray]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };
  useEffect(() => {
    if (
      (props?.edit || props?.isShowDetails) &&
      props?.getRateSheetData?.data
    ) {
      const getRateSheetData = props?.getRateSheetData.data;
      setFieldValue('designer', getRateSheetData.designer || '');
      setDesignerId(getRateSheetData?.designer || '');
      setFieldValue('client', getRateSheetData.client || '');
      setFieldValue('effectiveOn', getRateSheetData.effectiveOn || '');
      setFieldValue('forAll', getRateSheetData.forAll || false);
      setFieldValue('forAllClient', getRateSheetData.forAllClient || false);
      setFieldValue('ultraService', getRateSheetData.ultraService || false);
      setFieldValue('retail', getRateSheetData.retail || false);
      if (getRateSheetData?.ratesheet.length === 0) {
        setFeesInfoArray(tempFeesInfoArray);
      } else {
        let newFeeArray = tempFeesInfoArray?.map((el) => {
          let isExist = getRateSheetData?.ratesheet?.some(
            (item) => item.size === el.label
          );
          if (isExist) {
            let data = getRateSheetData?.ratesheet?.filter((val) => {
              if (val?.size === el.label) {
                delete val?._id;
                val.name = val?.size.toLowerCase();
                val.label = val?.size;
                val.receivingFee =
                  val?.receivingFee === 0 ? '' : Number(val?.receivingFee);
                val.storageFee =
                  val?.storageFee === 0 ? '' : Number(val?.storageFee);
                val.receivingFeeDesc = val?.receivingFeeDesc;
                val.storageFeeDesc = val?.storageFeeDesc;
                return val;
              }
            });
            return (el = data[0]);
          } else {
            return el;
          }
        });
        checkAllCheckedSelect(newFeeArray);
        setFeesInfoArray(newFeeArray);
      }
    }
  }, [props.getRateSheetData, props?.edit, props?.isShowDetails]);

  const handleDataSubmit = (values) => {
    try {
      const data = {
        ...values,
      };
      if (data?.forAll) {
        delete data?.designer;
        delete data?.client;
      }
      if (data?.forAllClient) delete data?.client;
      if (props.edit) {
        props.updateRateSheet(
          props.selectRateSheetId,
          data,
          () => props.onClose(),
          (err) => onError(err)
        );
      } else {
        props.createRateSheet(
          data,
          () => {
            props.onClose();
          },
          (err) => onError(err)
        );
      }
    } catch (e) {
      console.log('eee', e, values);
    }
  };

  const selectAllDesc = (type, value) => {
    if (type === 'receivingDesc') {
      setCheckAllReceivingDesc(value);
      let newData = [...feesInfoArray];
      newData = newData?.map((el) => {
        el.receivingFeeDesc = value;
        return el;
      });
      setFeesInfoArray(newData);
    } else if (type === 'storageDesc') {
      setCheckAllStorageDesc(value);
      let newData = [...feesInfoArray];
      newData = newData?.map((el) => {
        el.storageFeeDesc = value;
        return el;
      });
      setFeesInfoArray(newData);
    }
  };

  const formik = useFormik({
    initialValues: {
      designer: '',
      client: '',
      effectiveOn: '',
      ratesheet: [],
      forAll: false,
      forAllClient: false,
      ultraService: false,
      retail: false,
    },
    validationSchema: AddRateSheetSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            <Typography variant='h5_700'>
              {props?.isShowDetails
                ? Strings.RATE_SHEET
                : props?.edit
                ? Strings.EDIT_RATE_SHEET
                : Strings?.NEW_RATE_SHEET}
            </Typography>
            {props?.getRateListLoading || props?.designerListLoading ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <>
                <Selection
                  isSearchable
                  containerClassName={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.selection_input
                  }
                  label={Strings.DESIGNER}
                  placeHolder={'Select'}
                  handleChange={(e, value) => {
                    if (value) {
                      setFieldValue('designer', value || '');
                      setDesignerId(value);
                      setFieldValue('client', '');
                    }
                  }}
                  value={values.designer}
                  title={Strings.SELECT}
                  error={Boolean(touched.designer && errors.designer)}
                  options={designerList}
                  helperText={touched.designer && errors.designer}
                  disabled={props?.isShowDetails || values.forAll}
                  isShowDetails={props.isShowDetails}
                />
                <Selection
                  isSearchable
                  containerClassName={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.selection_input
                  }
                  label={Strings.CLIENT}
                  placeHolder={'Select'}
                  handleChange={(e, value) => {
                    value && setFieldValue('client', value);
                  }}
                  disabled={
                    props?.clientListLoading ||
                    !designerId ||
                    props?.isShowDetails ||
                    values.forAll ||
                    values.forAllClient
                  }
                  error={Boolean(touched.client && errors.client)}
                  value={values.client}
                  title={Strings.SELECT}
                  options={clientList}
                  helperText={touched.client && errors.client}
                  isShowDetails={props.isShowDetails}
                />
                <CustomeDatePicker
                  className={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.selection_input
                  }
                  label={Strings.EFFECTIVE_ON}
                  value={values.effectiveOn}
                  onChange={(value) => {
                    setFieldValue('effectiveOn', moment(value).format());
                  }}
                  error={Boolean(touched.effectiveOn && errors.effectiveOn)}
                  helperText={touched.effectiveOn && errors.effectiveOn}
                  placeholder={Strings.SELECT}
                  disabled={props?.isShowDetails}
                  // disablePast
                />
                <div
                  className={classNames(
                    classes.flex_row,
                    classes.selection_input
                  )}
                >
                  <Typography variant='p_600' color={Colors.DAVYS_GREY}>
                    {Strings.RETAIL}
                  </Typography>
                  <ToggleButton
                    className={classes.toggle_btn}
                    checked={values.retail}
                    onChange={(e) => {
                      setFieldValue('retail', e.target.checked);
                    }}
                    disabled={props?.isShowDetails}
                  />
                </div>
                <div
                  className={classNames(
                    classes.flex_row,
                    classes.selection_input
                  )}
                >
                  <Typography variant='p_600' color={Colors.DAVYS_GREY}>
                    {Strings.ULTRA_SERVICE}
                  </Typography>
                  <ToggleButton
                    className={classes.toggle_btn}
                    checked={values.ultraService}
                    onChange={(e) => {
                      setFieldValue('ultraService', e.target.checked);
                    }}
                    disabled={props?.isShowDetails}
                  />
                </div>
                <div
                  className={classNames(
                    classes.flex_row,
                    classes.selection_input
                  )}
                >
                  <Typography variant='p_600' color={Colors.DAVYS_GREY}>
                    {Strings.APPLY_ALL_DESIGNER_CLIENT}
                  </Typography>
                  <ToggleButton
                    className={classes.toggle_btn}
                    checked={values.forAll}
                    onChange={(e) => {
                      if (values?.forAllClient)
                        setFieldValue('forAllClient', false);
                      setFieldValue('forAll', e.target.checked);
                    }}
                    disabled={props?.isShowDetails}
                  />
                </div>
                {values?.designer && (
                  <div
                    className={classNames(
                      classes.flex_row,
                      classes.selection_input
                    )}
                  >
                    <Typography variant='p_600' color={Colors.DAVYS_GREY}>
                      {Strings.APPLY_ALL_CLIENT}
                    </Typography>
                    <ToggleButton
                      className={classes.toggle_btn}
                      checked={values.forAllClient}
                      onChange={(e) => {
                        if (values?.forAll) setFieldValue('forAll', false);
                        setFieldValue('forAllClient', e.target.checked);
                      }}
                      disabled={props?.isShowDetails}
                    />
                  </div>
                )}

                <div className={classes.fees_container}>
                  <div className={classes.fees_header_container}>
                    <CommonHeaderText
                      containerClassName={classes.flex_2}
                      titleText=' '
                    />
                    <CommonHeaderText
                      containerClassName={classes.flex_3}
                      titleText={Strings.RECEIVING_FEE}
                    />
                    <div className={classes.desc_container}>
                      <CommonHeaderText
                        containerClassName={classes.flex_1}
                        titleText={Strings.DISC}
                      />
                      {/* <CustomCheckbox
                        checked={checkAllReceivingDesc}
                        onChange={(e) =>
                          selectAllDesc('receivingDesc', !checkAllReceivingDesc)
                        }
                      /> */}
                    </div>
                    <CommonHeaderText
                      containerClassName={classes.flex_3}
                      titleText={Strings.STORAGE_FEE}
                    />
                    <div className={classes.desc_container}>
                      <CommonHeaderText
                        containerClassName={classes.flex_1}
                        titleText={Strings.DISC}
                      />
                      {/* <CustomCheckbox
                        checked={checkAllStorageDesc}
                        onChange={(e) =>
                          selectAllDesc('storageDesc', !checkAllStorageDesc)
                        }
                      /> */}
                    </div>
                  </div>
                  <div className={classes.fees_content_container}>
                    {feesInfoArray?.map((el, index) => {
                      return (
                        <FeesInformationContainer
                          key={index}
                          label={el?.label === 'Cost' ? 'Crate' : el?.label}
                          receivingFeeOnChange={(e) =>
                            selectHandleChange(e, index, 'number')
                          }
                          storageFeeOnChange={(e) =>
                            selectHandleChange(e, index, 'number')
                          }
                          receivingFeeDescOnChecked={(e) =>
                            selectHandleChange(e, index, 'checkbox')
                          }
                          storageFeeDescOnChecked={(e) =>
                            selectHandleChange(e, index, 'checkbox')
                          }
                          receivingFeeValue={el?.receivingFee}
                          storageFeeValue={el?.storageFee}
                          receivingFeeDesc={el?.receivingFeeDesc}
                          storageFeeDesc={el?.storageFeeDesc}
                          disabledFields={props?.isShowDetails}
                        />
                      );
                    })}
                  </div>
                </div>
                {!props.isShowDetails && (
                  <Button
                    type='submit'
                    className={classes.add_user_button}
                    variant='contained-radius'
                    disabled={
                      props.edit
                        ? props.updateRateSheetDataLoading
                        : props.addRateSheetLoading
                    }
                  >
                    {(!props.edit && props.addRateSheetLoading) ||
                    (props.edit && props.updateRateSheetDataLoading) ? (
                      <Loader />
                    ) : props.edit ? (
                      Strings.EDIT.toUpperCase()
                    ) : (
                      Strings.ADD.toUpperCase()
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addRateSheetData: state.addRateSheetData.data,
  addRateSheetLoading: state.addRateSheetData.loading,
  designerListData: state.designerListData.data,
  designerListLoading: state.designerListData.loading,
  clientListData: state.clientListData.data,
  clientListLoading: state.clientListData.loading,
  getRateSheetData: state.getRateSheetData.data,
  getRateListLoading: state.getRateSheetData.loading,
  updateRateSheetData: state.updateRateSheetListData.data,
  updateRateSheetDataLoading: state.updateRateSheetListData.loading,
});

export default connect(mapStateToProps, {
  createRateSheet,
  fetchDesignerList,
  fetchClientList,
  getRateSheet,
  updateRateSheet,
})(AddRateSheetModal);
