import React, { useState, useEffect } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createFee } from '../../redux/actions/feeActions/addFee';
import { updateFeeList } from '../../redux/actions/feeActions/updateFee';
import { getErrorMessage, removeEmptyProperties } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';
import { Form, FormikProvider, useFormik } from 'formik';
import { AddFeeSchema } from '../../utils/validationSchemas';

function FeesModal(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const emptyArray = Array(8)
    .fill(1)
    .map((a, i) => `0${a + i}`);
  const [laborRate, setLaborRate] = useState(Array(8).fill(null));

  const handleChange = (e, index) => {
    const newInfoArray = laborRate.map((lr, ind) => {
      if (ind === index) {
        if (lr) {
          return {
            ...lr,
            [e.target.name]:
              isNaN(e.target.value) || e.target.value?.length === 0
                ? null
                : Number(e.target.value),
          };
        } else {
          return {
            [e.target.name]:
              isNaN(e.target.value) || e.target.value?.length === 0
                ? null
                : Number(e.target.value),
          };
        }
      } else {
        if (lr) {
          return {
            ...lr,
          };
        } else {
          return null;
        }
      }
    });
    setLaborRate(newInfoArray);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onSuccessFee = () => {
    resetForm();
    props.onClose();
    enqueueSnackbar(`Fee ${props.isEdit ? 'updated' : 'added'} successfully.`, {
      variant: 'success',
    });
  };

  const onChange = (key, value) => {
    setErrors({});
    setFieldValue(key, value || '');
  };

  const handleDataSubmit = (value) => {
    const data = {
      dailyRate: Number(value?.dailyRate) || '',
      transitRate: Number(value?.transitRate) || '',
      pullFee: Number(value?.pullFee) || '',
      pickupFee: Number(value?.pickupFee) || '',
      trashFee: Number(value?.trashFee) || '',
      barketFee: Number(value?.barketFee) || '',
      laborRates:
        laborRate?.filter((rate) => {
          if (rate) return removeEmptyProperties(rate);
        }) || [],
    };
    if (props.isEdit) {
      props.updateFeeList(
        props?.selectedFee?._id,
        data,
        () => {
          onSuccessFee();
          resetForm();
          setLaborRate(Array(8).fill(null));
        },
        onError
      );
    } else {
      props.createFee(
        data,
        () => {
          onSuccessFee();
          resetForm();
          setLaborRate(Array(8).fill(null));
        },
        onError
      );
    }
  };

  useEffect(() => {
    if (props?.selectedFee && Object.keys(props?.selectedFee).length > 0) {
      if (props?.selectedFee?.barketFee)
        setFieldValue('barketFee', props?.selectedFee?.barketFee);
      if (props?.selectedFee?.dailyRate)
        setFieldValue('dailyRate', props?.selectedFee?.dailyRate);
      if (props?.selectedFee?.transitRate)
        setFieldValue('transitRate', props?.selectedFee?.transitRate);
      if (props?.selectedFee?.pullFee)
        setFieldValue('pullFee', props?.selectedFee?.pullFee);
      if (props?.selectedFee?.pickupFee)
        setFieldValue('pickupFee', props?.selectedFee?.pickupFee);
      if (props?.selectedFee?.trashFee)
        setFieldValue('trashFee', props?.selectedFee?.trashFee);
      if (
        props?.selectedFee?.laborRates &&
        props?.selectedFee?.laborRates.length > 0
      ) {
        let updatedLaborRate = props?.selectedFee?.laborRates;
        let newLaborRate = [];
        for (let [ind, value] of laborRate.entries()) {
          for (let [ind1, value1] of updatedLaborRate.entries()) {
            if (ind === ind1) {
              value = value1;
            } else {
              value = value;
            }
          }
          newLaborRate.push(value);
        }
        setLaborRate(newLaborRate);
      }
    }
  }, [props?.selectedFee]);

  const formik = useFormik({
    initialValues: {
      dailyRate: '',
      transitRate: '',
      pullFee: '',
      pickupFee: '',
      trashFee: '',
      barketFee: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: AddFeeSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
  } = formik;

  return (
    <div className={classes.main_container}>
      <div className={classes.modal_container}>
        <div className={classes.close_icon_container}>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => props.onClose()}
          />
        </div>
        <div className={classNames(classes.flex_row, classes.align_center)}>
          <Typography variant='h5_700'>
            {props.isEdit ? Strings.EDIT_FEE : Strings.FEES}
          </Typography>
          {/* <img src={icons().edit} className={classes.icons} /> */}
        </div>

        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div
              className={classNames(
                classes.flex_row,
                classes.margin_top_15,
                classes.gap_30
              )}
            >
              <TextField
                id='filled-required'
                label={Strings.DVP_DAILY_RATE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.dailyRate}
                onChange={(e) => onChange('dailyRate', e.target.value)}
                className={classes.input}
                error={Boolean(touched.dailyRate && errors.dailyRate)}
                helperText={touched.dailyRate && errors.dailyRate}
              />
              <TextField
                id='filled-required'
                label={Strings.DVP_TRANSIT_RATE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.transitRate}
                onChange={(e) => onChange('transitRate', e.target.value)}
                className={classes.input}
                error={Boolean(touched.transitRate && errors.transitRate)}
                helperText={touched.transitRate && errors.transitRate}
              />
              <TextField
                id='filled-required'
                label={Strings.PULL_LOAD_FEE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.pullFee}
                onChange={(e) => onChange('pullFee', e.target.value)}
                className={classes.input}
                error={Boolean(touched.pullFee && errors.pullFee)}
                helperText={touched.pullFee && errors.pullFee}
              />
            </div>

            <div
              className={classNames(
                classes.flex_row,
                classes.margin_top_15,
                classes.gap_30
              )}
            >
              <TextField
                id='filled-required'
                label={Strings.PICKUP_FEE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.pickupFee}
                onChange={(e) => onChange('pickupFee', e.target.value)}
                className={classes.input}
                error={Boolean(touched.pickupFee && errors.pickupFee)}
                helperText={touched.pickupFee && errors.pickupFee}
              />
              <TextField
                id='filled-required'
                label={Strings.TRASH_FEE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.trashFee}
                onChange={(e) => onChange('trashFee', e.target.value)}
                className={classes.input}
                error={Boolean(touched.trashFee && errors.trashFee)}
                helperText={touched.trashFee && errors.trashFee}
              />
              <TextField
                id='filled-required'
                label={Strings.BLANKET_FEE}
                placeholder={Strings.TYPE_HERE}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                type='number'
                value={values.barketFee}
                onChange={(e) => onChange('barketFee', e.target.value)}
                className={classes.input}
                error={Boolean(touched.barketFee && errors.barketFee)}
                helperText={touched.barketFee && errors.barketFee}
              />
            </div>

            <div className={classes.sub_heading}>
              <Typography variant='h6_700'>{Strings.LABOR_RATES}</Typography>
            </div>

            <div
              className={classNames(
                classes.flex_row,
                classes.margin_top_15,
                classes.gap_30
              )}
            >
              <div className={classes.null_div} />
              <div className={classes.header}>
                <Typography variant='p_600'>{Strings.DESIGNER_RATE}</Typography>
              </div>
              <div className={classes.header}>
                <Typography variant='p_600'>{Strings.CUSTOMER_RATE}</Typography>
              </div>
              <div className={classes.header}>
                <Typography variant='p_600'>
                  {Strings.SURROUNDINGS_RATE}
                </Typography>
              </div>
            </div>

            {emptyArray.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    classes.flex_row,
                    classes.margin_top_10,
                    classes.gap_30,
                    classes.labour_row_bg
                  )}
                >
                  <div className={classes.null_div}>
                    <Typography variant='p_600'>{item}</Typography>
                  </div>
                  <div className={classes.header}>
                    <TextField
                      // required
                      name='designerRate'
                      id='filled-required'
                      placeholder={'1000'}
                      variant='standard'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        value: laborRate[index]?.designerRate || '',
                      }}
                      value={laborRate[index]?.designerRate}
                      onChange={(e) => {
                        handleChange(e, index);
                      }}
                      className={classes.input}
                    />
                  </div>
                  <div className={classes.header}>
                    <TextField
                      name='customerRate'
                      id='filled-required'
                      placeholder={'1000'}
                      variant='standard'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        value: laborRate[index]?.customerRate || '',
                      }}
                      value={laborRate[index]?.customerRate}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.input}
                    />
                  </div>
                  <div className={classes.header}>
                    <TextField
                      name='surroundingsRate'
                      id='filled-required'
                      placeholder={'1000'}
                      variant='standard'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        value: laborRate[index]?.surroundingsRate || '',
                      }}
                      value={laborRate[index]?.surroundingsRate}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.input}
                    />
                  </div>
                </div>
              );
            })}
            <Button
              type='submit'
              className={classes.add_fee_button}
              variant='contained-radius'
              disabled={props.addFeeLoading}
            >
              {props?.addFeeLoading || props.updateFeeLoading ? (
                <Loader />
              ) : props.isEdit ? (
                Strings.UPDATE_FEE.toUpperCase()
              ) : (
                Strings.ADD_FEE.toUpperCase()
              )}
            </Button>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  addFeeData: state.addFeeData.data,
  addFeeLoading: state.addFeeData.loading,
  updateFeeLoading: state.updateFeeData.loading,
});

export default connect(mapStateToProps, {
  createFee,
  updateFeeList,
})(FeesModal);
