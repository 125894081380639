import axios from 'axios';
import { RUN_REPORT } from '../../constants';

export const runReport = (data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  let param = '';
  for (let i in data) {
    param += `${i}=${data[i]}&`;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadRunReport());

  axios
    .post(`/admin/runReport?${param}`, {}, config)
    .then((res) => {
      dispatch(setRunReport(res));
      onSuccess?.();
    })
    .catch((err) => {
      dispatch(errorRunReport(err.response));
      onError(err.response);
    });
};

export const loadRunReport = () => ({
  type: RUN_REPORT.LOAD,
});

export const setRunReport = (data) => ({
  type: RUN_REPORT.SUCCESS,
  payload: data.data,
});

export const errorRunReport = (error) => ({
  type: RUN_REPORT.FAIL,
  payload: error,
});
