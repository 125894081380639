import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  header_container: {
    gap: 15,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    background: Colors.BRIGHT_GRAY,
    marginLeft: 15,
  },
  search_input: {
    width: 300,
  },
  selection_input: {
    width: '300px !important',
  },
  
}));
