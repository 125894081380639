import axios from 'axios';
import { GET_INVOICE } from '../../constants';

export const getInvoice = (ID, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetInvoice());
  axios
    .get(`/invoice/getInvoice/${ID}`, config)
    .then((res) => {
      dispatch(setGetInvoice(res?.data?.data));
    })
    .catch((err) => {
      dispatch(errorGetInvoice(err.response));
      onError(err.response);
    });
};

export const loadGetInvoice = () => ({
  type: GET_INVOICE.LOAD,
});

export const setGetInvoice = (data) => ({
  type: GET_INVOICE.SUCCESS,
  payload: data,
});

export const errorGetInvoice = (error) => ({
  type: GET_INVOICE.FAIL,
  payload: error,
});
