import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from './pages/Login';
import MainLayout from './layouts/MainLayout';
import Settings from './pages/Settings';
import Users from './pages/Users';
import setAuthToken from './utils/setAuthToken';
import store from './redux/store';
import { logout, setLogin } from './redux/actions/authActions/login';
import jwtDecode from 'jwt-decode';
import Vendors from './pages/Vendors';
import Incidents from './pages/Incidents';
import Clients from './pages/Clients';
import Inventory from './pages/Inventory';
import Location from './pages/Location';
import RateSheet from './pages/RateSheet';
import Invoice from './pages/Invoice';
import Deliveries from './pages/Deliveries';
import Fees from './pages/Fees';
import Report from './pages/Report';
import ForgotPassword from './pages/ForgotPassword';
import { isDesigner, isVendor } from './utils/appUtils';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwtDecode(token);
  store.dispatch(setLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = '/login';
  }
}

export default function Router() {
  return useRoutes([
    localStorage.jwtToken
      ? isVendor() || isDesigner()
        ? {
            path: '/',
            element: <MainLayout />,
            children: [
              { path: '/', element: <Navigate to='/app/incidents' /> },
              { path: '/app', element: <Navigate to='/app/incidents' /> },
              { path: '/app/incidents', element: <Incidents /> },
              { path: '/app/inventory', element: <Inventory /> },
              { path: '/app/settings', element: <Settings /> },
            ],
          }
        : {
            path: '/',
            element: <MainLayout />,
            children: [
              { path: '/', element: <Navigate to='/app/users' /> },
              { path: '/app', element: <Navigate to='/app/users' /> },
              { path: '/app/users', element: <Users /> },
              { path: '/app/vendors', element: <Vendors /> },
              { path: '/app/incidents', element: <Incidents /> },
              { path: '/app/vendors', element: <Settings /> },
              { path: '/app/rate-sheet', element: <RateSheet /> },
              { path: '/app/fees', element: <Fees /> },
              { path: '/app/clients', element: <Clients /> },
              { path: '/app/inventory', element: <Inventory /> },
              { path: '/app/location', element: <Location /> },
              { path: '/app/deliveries', element: <Deliveries /> },
              { path: '/app/invoice', element: <Invoice /> },
              { path: '/app/report', element: <Report /> },
              { path: '/app/settings', element: <Settings /> },
            ],
          }
      : {
          path: '/',
          children: [
            { path: '/', element: <Navigate to='/login' /> },
            { path: '/login', element: <Login /> },
            {
              path: '/forgotPassword',
              element: <ForgotPassword />,
            },
          ],
        },
    { path: '*', element: <Navigate to='/' replace /> },
  ]);
}
