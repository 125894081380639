import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../../utils/appUtils';
import { connect } from 'react-redux';
import { getInvoice } from '../../redux/actions/invoiceActions/getInvoice';
import { icons } from '../../assets';
import { Strings } from '../../common/strings';
import { Colors } from '../../common/colors';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import Loader from '../Loader';
import ToggleButton from '../ToggleButton';
import moment from 'moment';

const InvoiceDetailsModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [invoiceData, setInvoiceData] = useState(null);
  const [designer, setDesigner] = useState('');
  const [client, setClient] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props?.selectedInvoiceData) {
      setDesigner(props?.selectedInvoiceData?.designer);
      setClient(props?.selectedInvoiceData?.client);
      props.getInvoice(props?.selectedInvoiceData?._id, onError);
    }
  }, [props?.selectedInvoiceData]);

  useEffect(() => {
    if (props?.selectedInvoiceData && props?.getInvoiceData) {
      setInvoiceData(props?.getInvoiceData);
    }
  }, [props?.getInvoiceData]);

  return (
    <div className={classes.main_container}>
      <div className={classes.modal_container}>
        <div className={classes.close_icon_container}>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => {
              setInvoiceData(null);
              props.onClose();
            }}
          />
        </div>
        <Typography variant='h5_700'>{Strings.INVOICE}</Typography>
        {props?.getInvoiceLoading ? (
          <div className={classes.display_flex}>
            <Loader color={Colors.RAISIN_BLACK} size={40} />
          </div>
        ) : (
          <>
            <div className={classes.text_view_container}>
              <Typography className={classes.label_text}>
                {Strings.DESIGNER}
              </Typography>
              <label className={classes.text_view}>{designer || '-'}</label>
            </div>
            <div className={classes.text_view_container}>
              <Typography className={classes.label_text}>
                {Strings.CLIENT}
              </Typography>
              <label className={classes.text_view}>{client || '-'}</label>
            </div>
            <div className={classes.text_view_container}>
              <Typography className={classes.label_text}>
                {Strings.INVOICE_DATE}
              </Typography>
              <label className={classes.text_view}>
                {console.log(invoiceData?.invoiceDate)}
                {invoiceData?.invoiceDate
                  ? moment(invoiceData?.invoiceDate).format('MM/DD/YYYY')
                  : '-'}
              </label>
            </div>
            <div className={classes.text_view_container}>
              <Typography className={classes.label_text}>
                {Strings.DELIVERY_FEE}
              </Typography>
              <label className={classes.text_view}>
                {invoiceData?.deliveryFee || '-'}
              </label>
            </div>
            <div className={classes.text_view_container}>
              <Typography className={classes.label_text}>
                {Strings.DVP_FEE}
              </Typography>
              <label className={classes.text_view}>
                {invoiceData?.DVP_Fee || '-'}
              </label>
            </div>
            <div
              className={classNames(classes.flex_row, classes.selection_input)}
            >
              <Typography variant='p_600' color={Colors.DAVYS_GREY}>
                {Strings.PICKUP}
              </Typography>
              <ToggleButton
                className={classes.toggle_btn}
                checked={invoiceData?.pickUp || false}
                disabled
              />
            </div>
            <div className={classes.text_view_container}>
              <Typography className={classes.label_text}>
                {Strings.METHOD}
              </Typography>
              <label className={classes.text_view}>
                {invoiceData?.method || '-'}
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getInvoiceData: state.getInvoiceData.data,
  getInvoiceLoading: state.getInvoiceData.loading,
});

export default connect(mapStateToProps, {
  getInvoice,
})(InvoiceDetailsModal);
