import React, { useState } from 'react';
import useStyles from './styles';
import { Typography } from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';

export default function UserDetailsTabHeader(props) {
  const classes = useStyles();
  //   const [anchorEl, setAnchorEl] = useState(null);
  const TablTitle = [
    Strings.SHIPPING,
    Strings.BILLING,
    Strings.NOTES,
    Strings.CLIENTS,
  ];
  return (
    <div className={classes.tab_header}>
      {TablTitle.map((item, index) => {
        return (
          <Typography
            key={index}
            variant='h6_700'
            style={{
              paddingBottom: 2,
              color: props.selectedTab === index && Colors.SEA_GREEN,
              borderBottom:
                props.selectedTab === index && `1px solid ${Colors.SEA_GREEN}`,
              cursor: 'pointer',
            }}
            onClick={() => props.onClickHeader(index)}
          >
            {item}
          </Typography>
        );
      })}
    </div>
  );
}
