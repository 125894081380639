import axios from 'axios';
import { VENDOR_LIST } from '../../constants';

export const fetchVendorList =
  (search, pageSize, pageNumber, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadVendorList());
    }
    axios
      .get(
        `/vendor/listVendors?searchString=${search}&page_number=${pageNumber}&page_size=${pageSize}`,
        config
      )
      .then((res) => dispatch(setVendorList(res)))
      .catch((err) => {
        dispatch(errorVendorList(err.response));
        onError(err.response);
      });
  };

export const loadVendorList = () => ({
  type: VENDOR_LIST.LOAD,
});

export const setVendorList = (userList) => ({
  type: VENDOR_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorVendorList = (error) => ({
  type: VENDOR_LIST.FAIL,
  payload: error,
});
