import axios from 'axios';
import { UPDATE_RATE_SHEET_STATUS } from '../../constants';

export const updateRateSheetStatus =
  (id, data, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadUpdateRateSheetStatus());
    }

    axios
      .put(`/ratesheet/updateRatesheetStatus?ratesheetId=${id}`, data, config)
      .then((res) => dispatch(setUpdateRateSheetStatus(res)))
      .catch((err) => {
        dispatch(errorRateSheetStatus(err.response));
        onError(err.response);
      });
  };

export const loadUpdateRateSheetStatus = () => ({
  type: UPDATE_RATE_SHEET_STATUS.LOAD,
});

export const setUpdateRateSheetStatus = (rateList) => ({
  type: UPDATE_RATE_SHEET_STATUS.SUCCESS,
  payload: rateList.data.data,
});

export const errorRateSheetStatus = (error) => ({
  type: UPDATE_RATE_SHEET_STATUS.FAIL,
  payload: error,
});
