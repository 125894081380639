import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  mainContainer: {
    height: 60,
    width: '100%',
    backgroundColor: 'transparent', // 'transparent'
    padding: 16,
    marginTop: 16,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    // width: '100% !important', 
    marginRight: 'auto !important',
  },
  addNewBtn: {
    minWidth: '143px !important',
    height: '48px !important',
    marginRight: '16px !important',
    maxWidth: 'fit-content',
  }
}));
