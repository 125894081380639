import { ADD_LOCATION } from '../../constants';
import axios from 'axios';

export const addLocation =
  (data, onSuccess, onError, onDialogClose) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadAddLocation());
    axios
      .post(`/location/createLocation`, data, config)
      .then((res) => {
        dispatch(setAddLocation(res.data));
        onSuccess('Location has been added successfully.');
        onDialogClose();
      })
      .catch((err) => {
        dispatch(failAddLocation(err.response));
        onError(err.response);
      });
  };

export const loadAddLocation = () => ({
  type: ADD_LOCATION.LOAD,
});

export const setAddLocation = (data) => ({
  type: ADD_LOCATION.SUCCESS,
  payload: data,
});

export const failAddLocation = (err) => ({
  type: ADD_LOCATION.FAIL,
  payload: err,
});
