import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles(() => ({
  outer_container: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Colors.MODAL_TRANSPARENT,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  box_container: {
    backgroundColor: Colors.WHITE,
    minWidth: 650,
    borderRadius: 10,
    minHeight: '60%',
    maxHeight: '95%',
    overflow: 'hidden',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  save_btn: {
    maxWidth: 150,
  },
  item_list_container: {
    border: `1px solid ${Colors.CHINESE_SILVER}`,
    borderRadius: 5,
    height: '100%',
    overflow: 'auto',
  },
  item_box: {
    padding: '5px 10px',
    borderRadius: 5,
    boxShadow: `0 0 5px rgba(0,0,0,0.15)`,
    backgroundColor: Colors.WHITE,
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
  },
  edit_icon: {
    cursor: 'pointer',
    height: 21,
    marginLeft: 'auto',
  },
  cardText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 210,
  },
}));
