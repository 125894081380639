import axios from 'axios';
import { EXPORT_INVOICE } from './../../constants';
import { DownloadCSVorPDF } from  '../../../utils/appUtils'

export const exportInvoice = (data, onError, fileName) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob'
    };
      
    dispatch(loadExportInvoice());
    axios
      .put(
        `/invoice/exportInvoices`,data,
        config
      )
      .then((res) => {
        DownloadCSVorPDF(res.data, `${fileName}.xlsx`)
        dispatch(setExportInvoice(res.data))
      })
      .catch((err) => {
        dispatch(errorExportInvoice(err.response));
        onError(err.response);
      });
}

export const loadExportInvoice = () => ({
    type: EXPORT_INVOICE.LOAD,
  });
  
  export const setExportInvoice = (data) => ({
    type: EXPORT_INVOICE.SUCCESS,
    payload: data,
  });
  
  export const errorExportInvoice = (error) => ({
    type: EXPORT_INVOICE.FAIL,
    payload: error,
  });
  