import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { emailRegex } from '../../utils/appUtils';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const TagsInputField = ({ ...props }) => {
  const classes = useStyles();

  const handleAddChip = (chip) => {
    props.selectedTags(chip);
  };

  return (
    <div
      className={classNames(
        classes.input_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classes.input_label}
          htmlFor={props.label}
          style={{ opacity: props?.disabled && !props?.isShowDetails && 0.5 }}
        >
          {props.label}
        </label>
      )}
      <TagsInput
        value={props.tags}
        onChange={handleAddChip}
        onlyUnique
        validate={(chip) => emailRegex.test(chip)}
        disabled={props.disabled}
        className={classNames(
          classes.input_field,
          props.className && props.className
        )}
        inputProps={{ placeholder: props.placeholder }}
        renderTag={props.renderTag}
        renderLayout={(tagElements, inputElement) => (
          <>
            <div className={classes.tagsElementContainer}>{tagElements}</div>
            {inputElement}
          </>
        )}
        addKeys={[9, 13, 32]}
      />
      {props.error && (
        <p
          className={classNames(
            classes.input_error,
            props.errorClassName && props.errorClassName
          )}
        >
          {props.helperText}
        </p>
      )}
    </div>
  );
};

export default TagsInputField;
