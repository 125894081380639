import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  input: {
    // marginTop: '15px !important',
    // width: '100% !important',
  },
  search_icon: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginLeft: 12,
  },
  selection_root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontFamily: `Lato, sans-serif`,
      fontWeight: 400,
    },
  },
}));
