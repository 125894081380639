import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'flex-end',
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 559,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  text_view_container: {
    width: '100%',
    display: 'flex',
    flexDirection:'column',
    marginTop: 15,
    gap: 5,
  },
  text_view: {
    width: '100%',
    padding: '7px 7px 7px 10px',
    color: Colors.DAVYS_GREY,
    fontWeight: 400,
    fontSize: 16,
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
  },
  label_text: {
    color: `${Colors.DAVYS_GREY} !important`,
    fontWeight: `400 !important`,
    fontSize: `13px !important`,
  },
  selection_input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  sub_heading: {
    fontSize: '14px !important',
    color: Colors.DAVYS_GREY,
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  flex_row: {
    display: 'flex',
    alignItems: 'center',
  },
  toggle_btn: {
    marginLeft: 'auto',
    '& .MuiFormControlLabel-root': {
      margin: '0px !important',
      '& .MuiSwitch-root': {
        margin: '0px !important',
      },
    },
  },
}));
