import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  tab_container: {
    height: 274,
    width: '100%',
    display: 'flex',
    padding: 15,
    backgroundColor: Colors.BRIGHT_GRAY,
    borderRadius: 8,
    marginTop: 10,
    flexDirection: 'column',
    overflow: 'auto',
  },
  clients_item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${Colors.CHINESE_SILVER}`,
    gap: 10,
    cursor: 'pointer',
    // justifyContent: 'space-evenly',
  },
  ref_number: {
    // width: '20%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 15,
    flex: 1,
  },
  company_name: {
    // width: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 15,
    flex: 1,
  },
  company_addr: {
    // width: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 15,
    flex: 1,
  },
  icon_container: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  edit_icon: {
    height: 28,
    width: 28,
    marginRight: 15,
  },
}));
