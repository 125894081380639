import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  tab_header: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 25,
    marginBottom: 10,
    gap: 35,
  },
}));
