import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import { connect } from 'react-redux';
import { getErrorMessage, PAGE_SIZE } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import AddInventoryModal from '../../components/AddInventoryModal';
import Dialog from '../../components/Dialog';
import BarcodeModal from '../../components/BarcodeModal';
import AddLocationModal from '../../components/AddLocationModal';
import { fetchLocationList } from '../../redux/actions/locationActions/locationList';
import { deleteLocation } from '../../redux/actions/locationActions/deleteLocation';
import debounce from 'lodash/debounce';

const tableHeaders = [
  { label: 'Location Number', width: '80%' },
  { label: '', width: '20%' },
];

const Location = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showEditLocationDialog, setShowEditLocationDialog] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedBarcode, setSelectedBarcode] = useState(null);
  const [showBarcodeDialog, setShowBarcodeDialog] = useState(false);
  const totalRecords = props.locationListData?.totalRecords || 0;

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => props?.fetchLocationList(q, PAGE_SIZE, page, onError), 600),
    []
  );

  useEffect(() => {
    const data = props.locationListData?.locations;
    setLocations(data);
  }, [props.locationListData?.locations]);

  useEffect(() => {
    props.fetchLocationList(search, PAGE_SIZE, page, onError);
  }, [
    page,
    // search,
    props.addLocationData,
    props.editLocationData,
    props.deleteLocationData,
  ]);

  const handleChangePagination = async (e, p) => {
    // await jump(p);
    await setPage(p);
  };

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedLocation(item);
    await setShowEditLocationDialog(true);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedLocation(item);
    await setShowDeleteDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteLocation(
      selectedLocation?._id,
      onError,
      setShowDeleteDialog
    );
  };

  const onClickPrint = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedBarcode({ barcode: item?.location });
    await setShowBarcodeDialog(true);
  };

  const onClickView = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onClickTableRow = (data) => {
    // setIsShowDetails(true);
    setSelectedLocation(data);
    setShowEditLocationDialog(true);
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        searchPlaceholder={Strings.SEARCH_FOR_LOCATION_NUMBER}
        searchValue={search}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      />
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          {props.locationListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead headCells={tableHeaders} />
                    <TableBody>
                      {locations?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => onClickTableRow(row)}
                          >
                            <TableCell component='th' width='80%'>
                              <Typography variant='p_700'>
                                {row?.location}
                              </Typography>
                            </TableCell>

                            <TableCell width='20%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().eye_feldgrau}
                                    className={classes.icons}
                                    onClick={(e) => {
                                      setIsShowDetails(true);
                                      onClickTableRow(row);
                                    }}
                                  />
                                  <img
                                    src={icons().printer_icon}
                                    className={classes.icons}
                                    onClick={(e) => onClickPrint(e, row)}
                                  />
                                  <img
                                    src={icons().edit}
                                    className={classes.icons}
                                    onClick={(e) => onClickEdit(e, row)}
                                  />
                                  <img
                                    src={icons().delete}
                                    className={classes.icons}
                                    onClick={(e) => onClickDelete(e, row)}
                                  />
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
        {showEditLocationDialog && (
          <AddLocationModal
            onClose={() => {
              setShowEditLocationDialog(false);
              setIsShowDetails(false);
            }}
            isEdit
            selectedData={selectedLocation}
            isShowDetails={isShowDetails}
            setSelectedInventory={setSelectedInventory}
          />
        )}
        {selectedInventory !== '' && (
          <AddInventoryModal
            onClose={() => {
              setSelectedInventory('');
            }}
            isEdit
            selectedID={selectedInventory}
            isShowDetails={true}
          />
        )}
        {showDeleteDialog && (
          <Dialog
            title={Strings.DELETE_LOCATION}
            message={Strings.ARE_YOU_SURE_DELETE_LOCATION}
            onClose={() => setShowDeleteDialog(false)}
            loading={props.deleteLocationLoading}
            onClickYesButton={onClickYesButton}
          />
        )}
        {showBarcodeDialog && (
          <BarcodeModal
            title={Strings.BARCODE_VIEWER}
            // message={Strings.ARE_YOU_SURE_DELETE_NOTE}
            selectedBarcode={selectedBarcode}
            onClose={() => {
              setShowBarcodeDialog(false);
              setSelectedBarcode(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locationListData: state.locationListData.data,
  locationListLoading: state.locationListData.loading,

  addLocationData: state.addLocationData.data,
  editLocationData: state.editLocationData.data,

  deleteLocationData: state.deleteLocationData.data,
  deleteLocationLoading: state.deleteLocationData.loading,
});

export default connect(mapStateToProps, {
  fetchLocationList,
  deleteLocation,
})(Location);
