import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  tab_container: {
    height: 274,
    width: '100%',
    display: 'flex',
    backgroundColor: Colors.TRANSPARENT,
    borderRadius: 8,
    marginTop: 10,
    flexDirection: 'column',
  },
  notes_list: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));
