import React, { useRef, useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import classNames from 'classnames';
import Loader from '../Loader';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import { TextField } from '@mui/material';

export default function IncidentNotesInput(props) {
  const classes = useStyles();
  const inputFile = useRef(null);
  //   const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={classNames(classes.container, props.className)}>
      <textarea
        className={classNames(
          classes.textbox,
          props.inputClass && props.inputClass,
        )}
        rows={'3'}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
      />
      {props.total && (
        <div className={classes.totalContainer}>
          <TextField
            // required
            id='filled-required'
            label={Strings.TOTAL}
            placeholder={props.totalPlaceholder}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            type='number'
            onChange={props.totalOnChange}
            value={props.totalValue}
            disabled={props.disabled}
          />
        </div>
      )}
      {props.noteImage && (
        <div className={classes.align_center}>
          <div className={classes.remove_img}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().wrong_icon_white}
                className={classes.close_icon}
                style={{ cursor: 'pointer' }}
                onClick={() => props.removeSelectedFile()}
              />
            </div>
            <img src={props?.noteImage} alt='' className={classes.note_image} />
          </div>
        </div>
      )}
      <div className={classes.align_center}>
        {props?.fileLoader ? (
          <div className={classes.send_icon}>
            <Loader color={Colors.SEA_GREEN} size={22} />
          </div>
        ) : (
          <>
            <img
              src={icons().attach}
              onClick={() => inputFile.current.click()}
              className={classes.attach_icon}
            />

            <input
              style={{ display: 'none' }}
              ref={inputFile}
              onChange={(event) => props?.handleFileUpload(event)}
              type='file'
              accept='image/*'
              disabled={props.disabled || props?.fileLoader}
            />
          </>
        )}
      </div>

      <div className={classes.align_center}>
        {props?.loader ? (
          <div className={classes.send_icon}>
            <Loader color={Colors.SEA_GREEN} size={22} />
          </div>
        ) : (
          <img
            src={icons().send_bg}
            className={classes.send_icon}
            onClick={() =>
              !props.disabled && !props.fileLoader && props.onClickSend()
            }
          />
        )}
      </div>
    </div>
  );
}
