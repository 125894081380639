import axios from 'axios';
import { DownloadCSVorPDF } from '../../../utils/appUtils';
import { GENERATE_REPORT } from '../../constants';

export const generateReport = (data, onError, fileName) => (dispatch) => {
  const token = localStorage.jwtToken;
  let param = '';
  for (let i in data) {
    param += `${i}=${data[i]}&`;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGenerateReport());
  axios
    .post(`/admin/generateReport?${param}`, {}, config)
    .then((res) => {
      DownloadCSVorPDF(res.data, `${fileName}.csv`);
      dispatch(setGenerateReport(res));
    })
    .catch((err) => {
      dispatch(errorGenerateReport(err.response));
      onError(err.response);
    });
};

export const loadGenerateReport = () => ({
  type: GENERATE_REPORT.LOAD,
});

export const setGenerateReport = (data) => ({
  type: GENERATE_REPORT.SUCCESS,
  payload: data,
});

export const errorGenerateReport = (error) => ({
  type: GENERATE_REPORT.FAIL,
  payload: error,
});
