import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  checkbox_container: {
    marginRight: '0px !important',
  },
  label_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  sort_icon: {
    height: 16,
    width: 16,
  },
  sort_down: {
    transform: 'rotate(180deg)',
  },
}));
