import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Colors } from '../../common/colors';
import { Typography } from '@mui/material';
import useStyles from './styles';
import classNames from 'classnames';

export default function CustomCheckbox(props) {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: props.container }}
      control={
        <Checkbox
          className={classes.root}
          name={props?.name}
          TouchRippleProps={{ classes: { child: 'my-class' } }}
          checked={props.checked}
          disableRipple
          indeterminate={props.indeterminate}
          onChange={props.onChange}
          onClick={props.onClick}
          icon={<span className={classes.icon} />}
          checkedIcon={
            <span className={classNames(classes.icon, classes.checked_icon)} />
          }
          indeterminateIcon={
            props.indeterminate && (
              <span
                className={classNames(
                  classes.icon,
                  classes.checked_indeterminate,
                )}
              />
            )
          }
          disabled={props.disabled}
        />
      }
      label={
        <Typography
          variant='p_400'
          color={Colors.FELDGRAU}
          style={{ marginLeft: props.label ? 10 : 0 }}>
          {props.label}
        </Typography>
      }
    />
  );
}
