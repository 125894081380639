import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  dropdown_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  dropdown_label: {
    color: Colors.FELDGRAU,
    fontWeight: 400,
    fontSize: 15,
    fontFamily: "Lato, sans-serif",
  },
  dropdown_error: {
    color: Colors.FUZZY_WUZZY,
    fontSize: 12,
    fontFamily: "Lato, sans-serif",
    fontWeight: 600,
    margin: 0,
  },
  tag_container: {
    maxHeight: 90,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    alignItems: 'center',
  },
  checkbox_container: {
    marginLeft: 'auto !important',
  },
}));
