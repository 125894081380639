import axios from 'axios';
import { EMPLOYEE_LIST } from '../../constants';

export const fetchEmployeeList =
  (onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadEmployeeList());
    }
    axios
      .get(
        `admin/listEmployees`,
        config
      )
      .then((res) => dispatch(setEmployeeList(res)))
      .catch((err) => {
        dispatch(errorEmployeeList(err.response));
        onError(err.response);
      });
  };

export const loadEmployeeList = () => ({
  type: EMPLOYEE_LIST.LOAD,
});

export const setEmployeeList = (incidentList) => ({
  type: EMPLOYEE_LIST.SUCCESS,
  payload: incidentList.data.data,
});

export const errorEmployeeList = (error) => ({
  type: EMPLOYEE_LIST.FAIL,
  payload: error,
});
