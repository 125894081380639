import axios from 'axios';
import { GET_CLIENT } from '../../constants';

export const getClientData = (uID, onError, isClientsRequired) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  
  dispatch(loadGetClient());
  axios
    .get(`/client/getClient/${uID}`, config)
    .then((res) => {
      const data = res?.data?.data;
      dispatch(setGetClient(data));
    })
    .catch((err) => {
      dispatch(errorGetClient(err.response));
      onError(err.response);
    });
};

export const loadGetClient = () => ({
  type: GET_CLIENT.LOAD,
});

export const setGetClient = (data) => ({
  type: GET_CLIENT.SUCCESS,
  payload: data,
});

export const errorGetClient = (error) => ({
  type:  GET_CLIENT.FAIL,
  payload: error,
});
