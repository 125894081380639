import axios from 'axios';
import { ADD_FEE } from '../../constants';

export const createFee = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addFee());

  axios
    .post(`/fee/createFee`, data, config)
    .then((res) => {
      dispatch(setAddFee(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorAddFee(err.response));
      onError(err.response);
    });
};

export const addFee = () => ({
  type: ADD_FEE.LOAD,
});

export const setAddFee = (data) => ({
  type: ADD_FEE.SUCCESS,
  payload: data.data,
});

export const errorAddFee = (error) => ({
  type: ADD_FEE.FAIL,
  payload: error,
});
