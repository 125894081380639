export const Colors = {
  SEA_GREEN: '#2B8351',
  MEDIUM_SEA_GREEN: '#39B077',
  POWDER_BLUE: '#ADE7E0',
  FELDGRAU: '#4A575F',
  CHINESE_SILVER: '#C7CFD2',
  BRIGHT_GRAY: '#EBEFF1',
  RAISIN_BLACK: '#222222',
  QUICK_SILVER: '#9DA5A8',
  WHITE: '#FFFFFF',
  DAVYS_GREY: '#58585A',
  BLACK: '#000000',
  AMERICAN_SILVER: '#C6D0D3',
  FUZZY_WUZZY: '#CE6262',
  MENU_SHADOW: '0px 4px 20px rgba(0, 0, 0, 0.25)',
  LUST: '#E31A1A',
  PALE_SPRING_BUD: '#E5F5B5',
  PALE_LAVENDER: '#DED1FB',
  MODAL_TRANSPARENT: 'rgba(0, 0, 0, 0.71)',
  NEW_CAR: '#2A50D9',
  TRANSPARENT: 'transparent',
  LUST: '#E31A1A',
  LIGHT_GRAY: 'rgba(235, 239, 241, 0.5)',
  ORANGE:'#FF5C00',
  DARK_YELLOW: '#F6CE7F',
  CHOCOLATE_BROWN: '#936100',
  IMAGE_PLACEHOLDER: '#e1e9f7',
  EDGE_WATER: '#d3e8e5',
};
