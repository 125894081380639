import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { icons } from '../../assets';
import useStyles from './styles';
import { dialogTitleClasses } from '@mui/material';
import { Colors } from '../../common/colors';

export default function CommonMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Options [{ title: 'Edit' }, { title: 'Delete', color: Colors.LUST }]
  return (
    <div>
      <img
        className={classes.menu_icon}
        src={icons().menu}
        alt='menu'
        onClick={handleClick}
      />
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.menu_paper }}
      >
        {props.options?.map((option) => (
          <MenuItem
            key={option.title}
            classes={{ root: classes.menu_item }}
            style={{
              color: `${option.color || Colors.RAISIN_BLACK}`,
            }}
            onClick={() => {
              // for select option
              if (props.handleMenuClick) props.handleMenuClick(option.title);
              //
              handleClose();
            }}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
