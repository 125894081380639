import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Stack,
  Tabs,
  Tab,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import { connect } from 'react-redux';
import { getErrorMessage, PAGE_SIZE, userTypeList } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import { fetchClientList } from '../../redux/actions/clientActions/clientList';
import { getClientData } from '../../redux/actions/clientActions/getClientData';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { deleteClient } from '../../redux/actions/clientActions/deleteClient';

import Loader from '../../components/Loader';
import Dialog from '../../components/Dialog';
import AddClientModal from '../../components/AddClientModal';
import debounce from 'lodash/debounce';

const tableHeaders = [
  // { label: 'ID', width: '5%' },
  { label: 'Client Code', width: '10%' },
  { label: 'Client', width: '15%' },
  { label: 'Designer', width: '15%' },
  { label: 'Primary Address', width: '20%' },
  { label: 'Phone Number', width: '10%' },
  { label: 'City', width: '10%' },
  { label: 'State', width: '10%' },
  { label: 'Zipcode', width: '10%' },
  // { label: 'Secondary Address', width: '20%' },
  { label: '', width: '8%' },
];

const positionBgColor = {
  Designer: Colors.POWDER_BLUE,
  Employee: Colors.PALE_SPRING_BUD,
  Admin: Colors.PALE_LAVENDER,
};

const Clients = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const totalRecords = props.clientList?.totalRecords || 0;
  const [selectedItem, setSelectedItem] = useState('all');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showDeleteClientDialog, setShowDeleteClientDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedTab, setSelectedTab] = useState('active');

  const handleChangePagination = async (e, p) => {
    await setPage(p);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => fetchClients(q, selectedTab), 600),
    [selectedTab]
  );

  useEffect(() => {
    setClients(props.clientList?.clients);
  }, [props.clientList?.clients]);

  const fetchClients = (searchStr, activeTab) => {
    const data = {
      searchString: searchStr,
      type: selectedItem === 'all' ? '' : selectedItem,
      page_size: PAGE_SIZE,
      page_number: page,
      status: activeTab || selectedTab,
    };
    props.fetchClientList(data, (err) => onError(err));
  };

  useEffect(() => {
    fetchClients(search);
  }, [
    selectedItem,
    page,
    selectedTab,
    props.deleteClientData,
    props.addClientData,
    props.updateClientData,
  ]);

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedClient(item);
    await setShowEditClientModal(true);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedClient(item);
    await setShowDeleteClientDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteClient(
      selectedClient._id,
      setShowDeleteClientDialog,
      onError
    );
  };

  const onClickTableRow = (item) => {
    // setIsShowDetails(true);
    setSelectedClient(item);
    setShowEditClientModal(true);
  };

  // const updateClientDataById = (values) => {
  //   const clientIndex = clients.findIndex((obj) => obj._id === selectedClient?._id);
  //   let newClient = [...clients];
  //   let tempClient = { ...clients[clientIndex] };
  //   if (values.client !== clients[clientIndex].client) tempClient.client = values.client;
  //   if (values.email !== clients[clientIndex].email) tempClient.email = values.email;
  //   if (values.designer !== clients[clientIndex].designer) tempClient.designer = values.designer;
  //   newClient[clientIndex] = tempClient;
  //   setClients(newClient);
  // };

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
    setPage(1);
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        searchValue={search}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
        searchPlaceholder={Strings.SEARCH_FOR_NAME_ID}
      />
      <div className={classes.tableContainer}>
        <Stack width='100%' my={1.25}>
          <Tabs
            value={selectedTab}
            style={{ minHeight: 38, height: 38 }}
            onChange={handleTabChange}
            className={classes.tabs}
            scrollButtons={false}
            allowScrollButtonsMobile
            aria-label='scrollable force tabs example'
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={Strings.ACTIVE}
              value='active'
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={Strings.INACTIVE}
              value='inActive'
            />
          </Tabs>
        </Stack>
        <div className={classes.table}>
          {props.clientListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords && totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead headCells={tableHeaders} />
                    <TableBody>
                      {clients?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onClickTableRow(row)}
                          >
                            {/* <TableCell component='th' width='5%'>
                              <Typography variant='p_700'>
                                {row?.ID || `-`}
                              </Typography>
                            </TableCell> */}
                            <TableCell component='th' width='10%'>
                              <Typography variant='p_700'>
                                {row?.clientCode || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='15%'>
                              <Typography variant='p_700'>
                                {row?.client}
                              </Typography>
                            </TableCell>
                            <TableCell width='15%'>
                              <Typography variant='p_700'>
                                {row?.designer?.name || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='20%'>
                              <Typography variant='p_400'>
                                {row?.primaryAddress?.address || `-`}
                              </Typography>
                            </TableCell>
                            {/* <TableCell width='20%'>
                              <Typography variant='p_400'>
                                {row?.secondaryAddress?.address || `-`}
                              </Typography>
                            </TableCell> */}
                            <TableCell width='10%'>
                              <Typography variant='p_400'>
                                {row?.primaryAddress?.cel || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='10%'>
                              <Typography variant='p_400'>
                                {row?.primaryAddress?.city || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='10%'>
                              <Typography variant='p_400'>
                                {row?.primaryAddress?.state || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='10%'>
                              <Typography variant='p_400'>
                                {row?.primaryAddress?.zipcode || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='8%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().eye_feldgrau}
                                    className={classes.icons}
                                    onClick={(e) => {
                                      onClickEdit(e, row);
                                      setIsShowDetails(true);
                                    }}
                                  />
                                  <img
                                    src={icons().edit}
                                    className={classes.icons}
                                    onClick={(e) => onClickEdit(e, row)}
                                  />
                                  <img
                                    src={icons().delete}
                                    className={classes.icons}
                                    onClick={(e) => onClickDelete(e, row)}
                                  />
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>

      {showEditClientModal && (
        <AddClientModal
          onClose={() => {
            setShowEditClientModal(false);
            setIsShowDetails(false);
          }}
          edit
          selectedClient={selectedClient?._id}
          getClientData={props?.getClientData}
          clientData={props?.getClient}
          loader={props.getClientDataLoading}
          isShowDetails={isShowDetails}
          // updateClientDataById={updateClientDataById}
        />
      )}

      {showDeleteClientDialog && (
        <Dialog
          title={Strings.DELETE_CLIENT}
          message={Strings.ARE_YOU_SURE_DELETE_CLIENT}
          onClose={() => setShowDeleteClientDialog(false)}
          loading={props.deleteClientDataLoading}
          onClickYesButton={onClickYesButton}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientList: state.listClientData.data,
  clientListLoading: state.listClientData.loading,
  getClient: state.getClientData && state.getClientData.data,
  getClientDataLoading: state.getClientData && state.getClientData.loading,
  designerListData: state.designerListData.data,
  deleteClientDataLoading: state.deleteClientData.loading,
  deleteClientData: state.deleteClientData.data,
  addClientData: state.addClientData.data,
  updateClientData: state.updateClientData.data,
});

export default connect(mapStateToProps, {
  fetchClientList,
  getClientData,
  fetchDesignerList,
  deleteClient,
})(Clients);
