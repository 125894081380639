import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  input: {
    // marginTop: '15px !important',
    // width: '100% !important',
    // background: 'yellow',
  },
  search_icon: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginLeft: 12,
  },
  menu_item: {
    marginLeft: '10px !important',
    marginRight: '10px !important',
    padding: '10px !important',
    fontSize: '16px !important',
    lineHeight: '140% !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '400 !important',
  },
  drop_down_icon: {
    marginRight: '8px !important',
  },
  selection_root: {
    '& .MuiInputBase-root': {
      padding: 7.5,
      fontSize: '16px',
      lineHeight: '22.4px',
      fontFamily: `Lato, sans-serif`,
      fontWeight: 400,
      paddingLeft: 10,
      cursor: 'pointer',
      '& .MuiSelect-select': {
        color: Colors.DAVYS_GREY,
      },
    },
  },
  root_color: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-select': {
        color: Colors.DAVYS_GREY,
        opacity: 0.6,
      },
    },
  },
  dropdown_container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  dropdown_label: {
    color: Colors.FELDGRAU,
    fontWeight: 400,
    fontSize: 13,
    fontFamily: "Lato, sans-serif",
  },
  dropdown_error: {
    color: Colors.FUZZY_WUZZY,
    fontSize: 12,
    fontFamily: "Lato, sans-serif",
    fontWeight: 600,
    margin: 0,
  },
  // menu_item: {
  //   fontSize: '16px !important',
  //   lineHeight: '140% !important',
  //   fontFamily: 'Lato, sans-serif !important',
  //   fontWeight: '400 !important',
  // },
}));
