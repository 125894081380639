import { INVOICE_LIST } from '../../constants';

const initialState = {
  data: [],
  loading: false,
  error: {},
};

export default function (state = initialState, { type, payload } = {}) {
    switch (type) {
        case INVOICE_LIST.LOAD:
            return {
                ...state,
                loading: true,
            }
        case INVOICE_LIST.SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
            }
        case INVOICE_LIST.FAIL:
            return {
                ...state,
                data: payload,
                loading: false,
            }
        default:
            return state;
    } 
}