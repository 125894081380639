import axios from 'axios';
import { USER_LIST } from '../../constants';

export const fetchUserList = (data, onError, isUpdate) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  if (isUpdate !== 'update') {
    dispatch(loadUserList());
  }

  let queryStr = Object.entries(data)
    ?.map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    ?.join('&');

  axios
    .get(`/admin/listUsers?${queryStr}`, config)
    .then((res) => dispatch(setUserList(res)))
    .catch((err) => {
      dispatch(errorUserList(err.response));
      onError(err.response);
    });
};

export const loadUserList = () => ({
  type: USER_LIST.LOAD,
});

export const setUserList = (userList) => ({
  type: USER_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorUserList = (error) => ({
  type: USER_LIST.FAIL,
  payload: error,
});
