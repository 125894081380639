import { combineReducers } from 'redux';

//auth
import login from './authReducer/login';
import forgotPassword from './authReducer/forgotPassword';

//admin(users)
import listUser from './adminReducer/userList';
import deleteUser from './adminReducer/deleteUser';
import getUser from './adminReducer/getUser';
import addNote from './adminReducer/addNote';
import deleteNote from './adminReducer/deleteNote';
import updateNote from './adminReducer/updateNote';
import addUser from './adminReducer/addUser';
import updateUser from './adminReducer/updateUser';

//vendors
import vendorList from './vendorReducer/vendorList';
import addVendor from './vendorReducer/addVendor';
import deleteVendor from './vendorReducer/deleteVendor';
import updateVendor from './vendorReducer/updateVendor';
import getVendor from './vendorReducer/getVendor';
import getVendorUserList from './vendorReducer/getVendorUserList';

//incidents
import incidentList from './incidentReducer/incidentList';
import getIncident from './incidentReducer/getIncident';
import employeeList from './incidentReducer/employeeList';
import deleteIncident from './incidentReducer/deleteIncident';
import updateInventoryStatus from './inventoryReducer/updateInventoryStatus';
import assignToDesigner from './incidentReducer/assignToDesigner';
import assignToVendor from './incidentReducer/assignToVendor';
import updateEstimation from './incidentReducer/updateEstimation';
import createEstimation from './incidentReducer/createEstimation';
import completeIncident from './incidentReducer/completeIncident';

//rateSheet
import addRateSheet from './rateSheetReducer/addRateSheet';
import getDesigner from './rateSheetReducer/designerList';
import getClient from './rateSheetReducer/clientList';
import rateSheetList from './rateSheetReducer/rateSheetList';
import getRateSheetList from './rateSheetReducer/getRateSheet';
import updateRateSheetList from './rateSheetReducer/updateRateSheet';
import deleteRateSheet from './rateSheetReducer/deleteRateSheet';
import updateRateSheetStatus from './rateSheetReducer/updateRateSheetStatus';

//clients
import addClient from './clientReducer/addClient';
import updateClient from './clientReducer/updateClient';
import getClientData from './clientReducer/getClientData';
import clientList from './clientReducer/clientList';
import deleteClient from './clientReducer/deleteClient';
import addFee from './feeReducer/addFee';

//deliveries
import deliveryList from './deliveryReducer/deliveryList';
import getDelivery from './deliveryReducer/getDelivery';
import addDelivery from './deliveryReducer/addDelivery';
import updateDelivery from './deliveryReducer/updateDelivery';
import deleteDelivery from './deliveryReducer/deleteDelivery';
import generatePullList from './deliveryReducer/generatePullList';
import sendEmail from './deliveryReducer/sendEmail';
import closeDelivery from './deliveryReducer/closeDelivery';
import calculateAmount from './deliveryReducer/calculateAmount';

//setting
import changePassword from './settingReducer/changePassword';
import getUserById from './settingReducer/getUserById';
import uploadFile from './settingReducer/uploadFile';
import deleteFile from './settingReducer/deleteFile';
import updateUserProfile from './settingReducer/updateUserProfile';

//inventories
import inventoryList from './inventoryReducer/inventoryList';
import addInventory from './inventoryReducer/addInventory';
import getInventory from './inventoryReducer/getInventory';
import updateInventory from './inventoryReducer/updateInventory';
import deleteInventory from './inventoryReducer/deleteInventory';
import getInventoryListForDropdown from './inventoryReducer/getInventoryListForDropdown';
import barcodePrint from './inventoryReducer/barcodePrint';
import barcodePdf from './inventoryReducer/barcodePdf';

//invoices
import invoiceList from './invoiceReducer/invoiceList';
import deleteInvoice from './invoiceReducer/deleteInvoice';
import exportInvoice from './invoiceReducer/exportInvoice';
import getInvoice from './invoiceReducer/getInvoice';

//Report
import generateReport from './reportReducer/generateReport';
import generateReportPdf from './reportReducer/generateReportPdf';
import runReport from './reportReducer/runReport';

//Location
import addLocation from './locationReducer/addLocation';
import editLocation from './locationReducer/editLocation';
import deleteLocation from './locationReducer/deleteLocation';
import locationList from './locationReducer/locationList';

//Fee
import feeList from './feeReducer/feeList';
import updateFee from './feeReducer/updateFee';
import updateFeeStatus from './feeReducer/updateFeeStatus';
import inventoryListForNextPrev from './inventoryReducer/inventoryListForNextPrev';

export default combineReducers({
  //auth
  loginData: login,
  forgotPasswordData: forgotPassword,

  //users
  userListData: listUser,
  deleteUserData: deleteUser,
  getUserData: getUser,
  addNoteData: addNote,
  addUserData: addUser,
  updateUserData: updateUser,
  deleteNoteData: deleteNote,
  updateNoteData: updateNote,

  //vendors
  vendorListData: vendorList,
  addVendorData: addVendor,
  deleteVendorData: deleteVendor,
  updateVendorData: updateVendor,
  getVendorData: getVendor,
  getVendorUserListData: getVendorUserList,

  //incidents
  incidentListData: incidentList,
  getIncidentData: getIncident,
  deleteIncidentData: deleteIncident,
  employeeListData: employeeList,
  assignToDesignerData: assignToDesigner,
  assignToVendorData: assignToVendor,
  updateEstimationData: updateEstimation,
  createEstimationData: createEstimation,
  completeIncidentData: completeIncident,

  //rateSheet
  clientListData: getClient,
  designerListData: getDesigner,
  addRateSheetData: addRateSheet,
  rateSheetListData: rateSheetList,
  getRateSheetData: getRateSheetList,
  updateRateSheetListData: updateRateSheetList,
  deleteRateSheetData: deleteRateSheet,
  updateRateSheetStatusData: updateRateSheetStatus,

  //clients
  listClientData: clientList,
  addClientData: addClient,
  updateClientData: updateClient,
  getClientData: getClientData,
  deleteClientData: deleteClient,

  //fee
  addFeeData: addFee,
  feeListData: feeList,
  updateFeeData: updateFee,
  updateFeeStatusData: updateFeeStatus,

  //deliveries
  deliveryListData: deliveryList,
  getDeliveryData: getDelivery,
  addDeliveryData: addDelivery,
  updateDeliveryData: updateDelivery,
  deleteDeliveryData: deleteDelivery,
  generatePullListData: generatePullList,
  sendEmailData: sendEmail,
  closeDeliveryData: closeDelivery,
  calculateAmountData: calculateAmount,

  //setting
  changePasswordData: changePassword,
  getUserByIdData: getUserById,
  uploadFileData: uploadFile,
  deleteFileData: deleteFile,
  updateUserProfileData: updateUserProfile,

  // inventory
  inventoryListData: inventoryList,
  inventoryListDataForDropdown: getInventoryListForDropdown,
  addInventoryData: addInventory,
  getInventoryData: getInventory,
  updateInventoryData: updateInventory,
  deleteInventoryData: deleteInventory,
  barcodePrintData: barcodePrint,
  barcodePdfData: barcodePdf,
  inventoryListForNextPrevData: inventoryListForNextPrev,

  //invoice
  invoiceListData: invoiceList,
  deleteInvoiceData: deleteInvoice,
  updateInventoryStatusData: updateInventoryStatus,
  getInvoiceData: getInvoice,

  //Report
  generateReportData: generateReport,
  generateReportPdfData: generateReportPdf,
  runReportData: runReport,

  exportInvoiceData: exportInvoice,

  // Location
  addLocationData: addLocation,
  editLocationData: editLocation,
  deleteLocationData: deleteLocation,
  locationListData: locationList,
});
