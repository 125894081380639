import { createTheme } from '@material-ui/core/styles';
import { Colors } from '../common/colors';

const fontFamily = `Lato, sans-serif`;
const normal = {
  fontSize: '56px',
  lineHeight: '56px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const h1 = {
  fontSize: '50px',
  lineHeight: '50px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const h2 = {
  fontSize: '48px',
  lineHeight: '48px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const h3 = {
  fontSize: '44px',
  lineHeight: '44px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const h4 = {
  fontSize: '40px',
  lineHeight: '40px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const h5 = {
  fontSize: '32px',
  lineHeight: '32px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const h6 = {
  fontSize: '22px',
  lineHeight: '22px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const p = {
  fontSize: '16px',
  lineHeight: '22.4px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const p_small = {
  fontSize: '12px',
  lineHeight: '13.2px',
  fontFamily: fontFamily,
  fontWeight: 300,
};
const caption = {
  fontSize: '9px',
  lineHeight: '9.9px',
  fontFamily: fontFamily,
  fontWeight: 300,
};

const Theme = createTheme({
  typography: {
    normal: {
      ...normal,
    },
    normal_400: {
      ...normal,
      fontWeight: 400,
    },
    normal_700: {
      ...normal,
      fontWeight: 700,
    },
    //h1
    h1: {
      ...h1,
    },
    h1_400: {
      ...h1,
      fontWeight: 400,
    },
    h1_700: {
      ...h1,
      fontWeight: 700,
    },

    //h2
    h2: {
      ...h2,
    },
    h2_400: {
      ...h2,
      fontWeight: 400,
    },
    h2_700: {
      ...h2,
      fontWeight: 700,
    },

    //h3
    h3: {
      ...h3,
    },
    h3_400: {
      ...h3,
      fontWeight: 400,
    },
    h3_700: {
      ...h3,
      fontWeight: 700,
    },

    //h4
    h4: {
      ...h4,
    },
    h4_400: {
      ...h4,
      fontWeight: 400,
    },
    h4_700: {
      ...h4,
      fontWeight: 700,
    },

    //h5
    h5: {
      ...h5,
    },
    h5_400: {
      ...h5,
      fontWeight: 400,
    },
    h5_700: {
      ...h5,
      fontWeight: 700,
    },

    //h6
    h6: {
      ...h6,
    },
    h6_400: {
      ...h6,
      fontWeight: 400,
    },
    h6_600: {
      ...h6,
      fontWeight: 600,
    },
    h6_700: {
      ...h6,
      fontWeight: 700,
    },

    //p
    p: {
      ...p,
    },
    p_400: {
      ...p,
      fontWeight: 400,
    },
    p_600: {
      ...p,
      fontWeight: 600,
      color: Colors.FELDGRAU,
    },
    p_700: {
      ...p,
      fontWeight: 700,
    },

    //p small
    p_small: {
      ...p_small,
    },
    p_small_400: {
      ...p_small,
      fontWeight: 400,
    },
    p_small_600: {
      ...p_small,
      fontWeight: 600,
    },
    p_small_700: {
      ...p_small,
      fontWeight: 700,
    },

    //caption
    caption: {
      ...caption,
    },
    caption_400: {
      ...caption,
      fontWeight: 400,
    },
    caption_700: {
      ...caption,
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          fontFamily: fontFamily,
        },
      }),
    },

    //Button
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            gap: '10px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '8px',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontSize: 16,
            fontFamily: fontFamily,
            boxShadow: 'none',
            lineHeight: '140%',
            border: `2px solid ${Colors.SEA_GREEN}`,
            color: Colors.SEA_GREEN,
            flexGrow: 1,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
              color: Colors.SEA_GREEN,
              border: `2px solid ${Colors.SEA_GREEN}`,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            textTransform: 'none',
            gap: '10px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '8px',
            backgroundColor: Colors.MEDIUM_SEA_GREEN,
            color: Colors.WHITE,
            fontWeight: 700,
            fontFamily: fontFamily,
            fontSize: 16,
            boxShadow: 'none',
            lineHeight: '147%',
            flexGrow: 1,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: Colors.SEA_GREEN,
              color: Colors.WHITE,
            },
            '&:disabled': {
              boxShadow: 'none',
              backgroundColor: Colors.QUICK_SILVER,
              color: Colors.WHITE,
              opacity: 0.5,
            },
          },
        },
        {
          props: { variant: 'contained-radius' },
          style: {
            height: 56,
            textTransform: 'none',
            gap: '10px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '70px',
            backgroundColor: Colors.MEDIUM_SEA_GREEN,
            color: Colors.WHITE,
            fontWeight: 700,
            fontFamily: fontFamily,
            fontSize: 16,
            boxShadow: 'none',
            lineHeight: '147%',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: Colors.SEA_GREEN,
              color: Colors.WHITE,
            },
            '&:disabled': {
              boxShadow: 'none',
              backgroundColor: Colors.QUICK_SILVER,
              color: Colors.WHITE,
              opacity: 0.5,
            },
          },
        },
      ],
    },

    // TextField
    MuiTextField: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            input: {
              color: Colors.DAVYS_GREY,
              fontFamily: fontFamily,
              lineHeight: '140%',
              fontWeight: 400,
              fontSize: 16,
              padding: 12,
              gap: 10,
              '&::placeholder': {
                color: Colors.DAVYS_GREY,
                fontFamily: fontFamily,
                lineHeight: '140%',
                fontWeight: 400,
                fontSize: 16,
                opacity: 0.6,
              },
            },
          },
        },
      ],
    },

    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
          },
          '&:after': {
            borderBottom: `2px solid ${Colors.FELDGRAU}`,
          },
          '&.Mui-error': {
            '&:after': {
              borderBottom: `2px solid ${Colors.FUZZY_WUZZY}`,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.DAVYS_GREY,
          fontFamily: fontFamily,
          lineHeight: '140%',
          fontWeight: 400,
          fontSize: 18,
          '&.Mui-focused': {
            color: Colors.DAVYS_GREY,
          },
          '&.Mui-error': {
            color: Colors.DAVYS_GREY,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          color: Colors.AMERICAN_SILVER,
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            color: Colors.SEA_GREEN,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: Colors.SEA_GREEN,
          fontWeight: 500,
          fontFamily: fontFamily,
          textDecorationColor: Colors.SEA_GREEN,
          fontSize: 16,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: Colors.FUZZY_WUZZY,
            fontWeight: 600,
            fontFamily: fontFamily,
            fontSize: 12,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-root': {
            borderBottom: `1px solid ${Colors.CHINESE_SILVER}`,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.MuiPaginationItem-root': {
            fontSize: 16,
            fontFamily: fontFamily,
            fontWeight: 400,
            color: Colors.FELDGRAU,
          },
          '&.MuiPaginationItem-root.Mui-selected': {
            backgroundColor: Colors.FELDGRAU,
            color: Colors.WHITE,
            height: 31,
            width: 'fit-content',
            fontSize: 16,
            fontFamily: fontFamily,
            fontWeight: 400,
          },
        },
      },
    },
  },
});

export default Theme;
