import axios from 'axios';
import { UPDATE_USER } from '../../constants';

export const updateUser = (uID, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddUser());

  axios
    .put(`/admin/updateUser?userId=${uID}`, data, config)
    .then((res) => {
      dispatch(setUpdateUser(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateUser(err.response));
      onError(err.response);
    });
};

export const loadAddUser = () => ({
  type: UPDATE_USER.LOAD,
});

export const setUpdateUser = (data) => ({
  type: UPDATE_USER.SUCCESS,
  payload: data.data,
});

export const errorUpdateUser = (error) => ({
  type: UPDATE_USER.FAIL,
  payload: error,
});
