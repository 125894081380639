import React, { useState, useEffect } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { Strings } from '../../common/strings';

export default function BillingTabContainer(props) {
  const classes = useStyles();
  const [data, setData] = useState(null);
  useEffect(() => {
    if (
      (props?.isBilling && props?.billing?.sameAsShipping) ||
      props?.isShipping
    ) {
      setData(props?.shipping);
    } else {
      setData(props?.billing);
    }
  }, [props?.isBilling, props?.isShipping, props?.billing]);

  return (
    <div className={classes.tab_container}>
      <div className={classes.flex_row}>
        <img src={icons().location} className={classes.tab_data_icons} />
        <Typography variant='p_400'>
          {data?.address}
          <br />
          <span>{data?.zipcode}</span>
          <br />
          <span>{`${data?.city} - ${data?.state}`}</span>
        </Typography>
      </div>
      <div className={classes.flex_row}>
        <div className={classes.business_info}>
          <Typography variant='p_400' className={classes.sub_heading}>
            {Strings.BUSINESS}
          </Typography>

          <div className={classNames(classes.flex_row, classes.top_5)}>
            <img src={icons().phone} className={classes.tab_data_icons} />
            <Typography variant='p_400'>{data?.business}</Typography>
          </div>
        </div>
        <div className={classes.business_info}>
          <Typography variant='p_400' className={classes.sub_heading}>
            {Strings.CEL}
          </Typography>

          <div className={classNames(classes.flex_row, classes.top_5)}>
            <img src={icons().phone} className={classes.tab_data_icons} />
            <Typography variant='p_400'>{data?.cel}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.flex_row}>
        <div className={classes.business_info}>
          <Typography variant='p_400' className={classes.sub_heading}>
            {Strings.FAX}
          </Typography>

          <div className={classNames(classes.flex_row, classes.top_5)}>
            <img src={icons().phone} className={classes.tab_data_icons} />
            <Typography variant='p_400'>{data?.fax}</Typography>
          </div>
        </div>
        <div className={classes.business_info}>
          <Typography variant='p_400' className={classes.sub_heading}>
            {Strings.OTHER}
          </Typography>

          <div className={classNames(classes.flex_row, classes.top_5)}>
            <img src={icons().phone} className={classes.tab_data_icons} />
            <Typography variant='p_400'>{data?.other}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
