import React from 'react';
import classNames from 'classnames';
import useStyles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { icons } from '../../assets';

const CustomButton = (props) => {
  const classes = useStyles();
  return ( 
    <button
      className={classNames(
        classes.button
      )}
      onClick={props.handleClick}
    >
     <div className={classes.cal_icon}>
     {props.selectedDate}
     <img
        src={icons().calendar}
        className={classes.rightIcon}
        alt='rightIcon'
      />
     </div>
      
    </button>
  );
};
export default CustomButton;
