import { LIST_LOCATION } from '../../constants';
import axios from 'axios';

export const fetchLocationList =
  (search, pageSize, pageNumber, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadLocationList());
    axios
      .get(
        `/location/listLocations?searchString=${search}&page_size=${pageSize}&page_number=${pageNumber}`,
        config,
      )
      .then((res) => {
        dispatch(setLocationList(res?.data?.data));
      })
      .catch((err) => {
        dispatch(failLocationList(err.response));
        onError(err.response);
      });
  };

export const loadLocationList = () => ({
  type: LIST_LOCATION.LOAD,
});

export const setLocationList = (data) => ({
  type: LIST_LOCATION.SUCCESS,
  payload: data,
});

export const failLocationList = (err) => ({
  type: LIST_LOCATION.FAIL,
  payload: err,
});
