import axios from 'axios';
import { UPDATE_FEE } from '../../constants';

export const updateFeeList =
  (feeId, data, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(updateFee());

    axios
      .put(`/fee/updateFee?feeId=${feeId}`, data, config)
      .then((res) => {
        dispatch(setUpdateFee(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorUpdateFee(err.response));
        onError(err.response);
      });
  };

export const updateFee = () => ({
  type: UPDATE_FEE.LOAD,
});

export const setUpdateFee = (data) => ({
  type: UPDATE_FEE.SUCCESS,
  payload: data.data,
});

export const errorUpdateFee = (error) => ({
  type: UPDATE_FEE.FAIL,
  payload: error,
});
