import React, { useEffect, useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import {
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import { Form, FormikProvider, useFormik } from 'formik';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../../utils/appUtils';
import Loader from '../Loader';
import { addLocationSchema } from '../../utils/validationSchemas';
import { addLocation } from '../../redux/actions/locationActions/addLocation';
import { editLocation } from '../../redux/actions/locationActions/editLocation';
import TableHead from '../../components/TableHead';

const AddLocationModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [locationId, setLocationId] = useState(null);
  const [inventoryData, setInventoryData] = useState([]);

  const tableHeaders = [
    { label: '', width: '20%' },
    { label: '', width: '80%' },
  ];

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      enqueueSnackbar(msg, {
        variant: 'success',
      });
    }
  };

  useEffect(() => {
    if ((props?.isEdit || props?.isShowDetails) && props.selectedData) {
      let data = props?.selectedData;
      setInventoryData(data?.inventory);
      setFieldValue('location', data?.location || '');
      setLocationId(data?._id);
    }
  }, [props?.isEdit]);

  const formik = useFormik({
    initialValues: {
      location: '',
    },
    validationSchema: addLocationSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const handleDataSubmit = (value) => {
    if (props?.isEdit && locationId) {
      props.editLocation(locationId, value, onSuccess, onError, () => {
        props?.onClose();
        setLocationId(null);
      });
    } else {
      props.addLocation(value, onSuccess, onError, () => props?.onClose());
    }
  };

  const onClickTableRow = async (row) => {
    await props.setSelectedInventory(row._id);
    await props.onClose();
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => props.onClose()}
              />
            </div>
            <Typography variant='h5_700'>
              {props.isShowDetails
                ? Strings.VIEW_LOCATION
                : props?.isEdit
                ? Strings.EDIT_LOCATION
                : Strings.NEW_LOCATION}
            </Typography>
            <TextField
              id='filled-required'
              label={Strings.LOCATION_NUMBER}
              placeholder={Strings.TYPE_HERE}
              variant='standard'
              InputLabelProps={{
                shrink: true,
              }}
              className={classNames(
                classes.input,
                props.isShowDetails && classes.disabled_input
              )}
              value={values.location}
              onChange={handleChange('location')}
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location}
              disabled={props.isShowDetails}
            />
            {props?.isShowDetails && inventoryData.length > 0 && (
              <>
                <div className={classes.inventory_list}>
                  <Typography
                    variant='p_700'
                    color={Colors.FELDGRAU}
                    className={classes.title}
                  >
                    {'Inventories'}
                  </Typography>
                  <TableContainer>
                    <Table aria-label='simple table'>
                      <TableHead headCells={tableHeaders} />
                      <TableBody>
                        {inventoryData?.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => onClickTableRow(row)}
                            >
                              <TableCell component='th' width='20%'>
                                <Typography variant='p_700'>
                                  {row?.ID}
                                </Typography>
                              </TableCell>

                              <TableCell width='80%'>
                                <Typography variant='p_400'>
                                  {row?.description}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}

            {!props.isShowDetails && (
              <Button
                type='submit'
                className={classes.add_user_button}
                variant='contained-radius'
                disabled={props.addLocationLoading}
              >
                {props.addLocationLoading || props.editLocationLoading ? (
                  <Loader />
                ) : props.isEdit ? (
                  Strings.UPDATE_LOCATION.toUpperCase()
                ) : (
                  Strings.ADD_LOCATION.toUpperCase()
                )}
              </Button>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addLocationLoading: state.addLocationData.loading,
  editLocationLoading: state.editLocationData.loading,
});

export default connect(mapStateToProps, {
  addLocation,
  editLocation,
})(AddLocationModal);
