import axios from 'axios';
import { ADD_INVENTORY } from '../../constants';

export const createInventory = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addInventory());

  axios
    .post(`/inventory/createInventory`, data, config)
    .then((res) => {
      dispatch(setAddInventory(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorAddInventory(err.response));
      onError(err.response);
    });
};

export const addInventory = () => ({
  type: ADD_INVENTORY.LOAD,
});

export const setAddInventory = (data) => ({
  type: ADD_INVENTORY.SUCCESS,
  payload: data.data,
});

export const errorAddInventory = (error) => ({
  type: ADD_INVENTORY.FAIL,
  payload: error,
});
