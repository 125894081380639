import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    borderBottomWidth: '90%',
  },
  textbox: {
    border: 'none',
    padding: 8,
    fontSize: 16,
    color: Colors.RAISIN_BLACK,
    fontFamily: 'Lato',
    outline: 'none',
    fontWeight: 400,
    width: '100%',
    resize: 'none',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
  },
  totalBox: {
    border: 'none',
    padding: 8,
    fontSize: 16,
    color: Colors.RAISIN_BLACK,
    fontFamily: 'Lato',
    outline: 'none',
    fontWeight: 400,
    width: '20%',
    resize: 'none',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
  },
  send_icon: {
    height: 40,
    width: 40,
    // alignSelf: 'flex-start',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    cursor: 'pointer',
  },
  attach_icon: {
    width: 30,
    height: 40,
    // alignSelf: 'flex-start',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    cursor: 'pointer',
  },
  note_image: {
    height: 50,
    width: 50,
    borderRadius: 5,
  },

  close_icon_container: {
    position: 'absolute',
    top: -10,
    right: -10,
    height: 18,
    width: 18,
    backgroundColor:Colors.MEDIUM_SEA_GREEN,
    borderRadius:'50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  remove_img: {
    height: 50,
    width: 50,
    position: 'relative',
    borderRadius: 5,
  },
  totalContainer: {
    width: '20%',
    borderRadius: 0,
    display: 'flex',
    gap: 20,
    borderBottomWidth: '90%',
    marginTop: 10,
  },
  close_icon: {
    height: 14,
    width: 14,
  },
  align_center: {
    display: 'flex',
    alignItems: 'center',
  },
}));
