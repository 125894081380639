import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  tab_container: {
    // height: 350,
    width: '100%',
    display: 'flex',
    padding: 20,
    backgroundColor: Colors.BRIGHT_GRAY,
    borderRadius: 8,
    marginTop: 10,
    flexDirection: 'column',
  },
  tab_data_icons: {
    height: 18,
    width: 18,
    marginTop: 2,
  },
  phone_icon: {
    height: 14,
    width: 14,
  },
  email_icon: {
    height: 14,
    width: 14,
  },
  business_info: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  top_5: {
    marginTop: 5,
  },
  top_20: {
    marginTop: '20px !important',
  },
  flex_row_user_info: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'flex-start',
    // justifyContent: 'space-between',
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  flex_column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    flex: 1,
  },
  sub_heading: {
    marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
  total_text: {
    color: Colors.FELDGRAU,
    marginTop: 20,
  },
  timer_view: {
    alignItems: 'center',
    marginTop: 20,
    gap: 5,
    justifyContent: 'space-between',
  },
  timer_icon: {
    height: 15,
    width: 15,
  },
  date_txt: {
    color: Colors.QUICK_SILVER,
  },
  images: {
    height: 100,
    width: 100,
    borderRadius: 4,
    marginTop: 10,
  },
  approve_tag: {
    width: 160,
    height: 60,
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
    cursor: 'pointer',
    border: `1px solid ${Colors.SEA_GREEN}`,
    color: Colors.SEA_GREEN,
    marginTop: 25,
    marginBottom: 25,
  },
  decline_tag: {
    width: 160,
    height: 60,
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
    cursor: 'pointer',
    border: `1px solid ${Colors.LUST}`,
    color: Colors.LUST,
    marginTop: 25,
    marginBottom: 25,
  },
  tag_icon: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  approve_fill_tag: {
    width: 160,
    height: 60,
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
    border: `1px solid ${Colors.SEA_GREEN}`,
    color: Colors.WHITE,
    marginTop: 25,
    marginBottom: 25,
    backgroundColor: Colors.SEA_GREEN,
  },
  decline_fill_tag: {
    width: 160,
    height: 60,
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
    border: `1px solid ${Colors.LUST}`,
    color: Colors.WHITE,
    marginTop: 25,
    marginBottom: 25,
    backgroundColor: Colors.LUST,
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
}));
