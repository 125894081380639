import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useStyles from './styles';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import { icons } from '../../assets';
import Loader from '../Loader';
import { connect } from 'react-redux';
import { createDelivery } from '../../redux/actions/deliveryActions/addDelivery';
import { updateDelivery } from '../../redux/actions/deliveryActions/updateDelivery';
import { getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';

const DeliveryConfirmationDialog = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allowDelivery, setAllowDelivery] = useState(false);
  const [password, setPassword] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onConfirm = () => {
    const data = props?.formData;
    data.allowDelivery = allowDelivery;
    if (password) data.password = password;
    if (props.edit) {
      props.updateDelivery(
        props.selectedDelivery,
        data,
        () => {
          props.onClose();
          props?.onCloseParentForm();
        },
        (err) => onError(err)
      );
    } else {
      props.createDelivery(
        data,
        () => {
          props.onClose();
          props?.onCloseParentForm();
        },
        (err) => onError(err)
      );
    }
  };

  return (
    <div className={classes.dialog_mask_container}>
      <div className={classes.dialog_box_container}>
        <div className={classes.dialog_header_container}>
          <Typography variant='h6_700' color={Colors.BLACK}>
            {Strings.PERMISSION?.toUpperCase()}
          </Typography>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => props?.onClose()}
          />
        </div>
        <div className={classes.dialog_content_container}>
          <Stack
            gap={1.25}
            width='100%'
            alignItems='center'
            justifyContent='center'
          >
            {allowDelivery ? (
              <TextField
                // label={Strings.PASSWORD}
                fullWidth
                id='password'
                type='text'
                value={password}
                placeholder={Strings.PASSWORD}
                variant='standard'
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setPassword(e.target.value || '')}
              />
            ) : (
              <Typography variant='p_700'>
                {Strings.DELIVERY_CONFIRMATION_MESSAGE}
              </Typography>
            )}
          </Stack>
        </div>
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-end'
          gap={2}
        >
          {!allowDelivery && (
            <Button
              variant='contained'
              style={{ flexGrow: 0 }}
              onClick={() => props.onClose()}
            >
              {Strings.NO}
            </Button>
          )}
          <Button
            variant='contained'
            onClick={() => {
              if (!allowDelivery) setAllowDelivery(true);
              else onConfirm();
            }}
            style={{ flexGrow: 0 }}
            disabled={props?.addDeliveryLoading || props?.updateDeliveryLoading}
          >
            {!allowDelivery ? (
              Strings.YES
            ) : props?.addDeliveryLoading || props?.updateDeliveryLoading ? (
              <Loader color={Colors.CHINESE_SILVER} />
            ) : (
              Strings.CONFIRM
            )}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

const mapStatToProps = (state) => ({
  addDeliveryData: state.addDeliveryData.data,
  addDeliveryLoading: state.addDeliveryData.loading,

  updateDeliveryData: state.updateDeliveryData.data,
  updateDeliveryLoading: state.updateDeliveryData.loading,
});

export default connect(mapStatToProps, {
  createDelivery,
  updateDelivery,
})(DeliveryConfirmationDialog);
