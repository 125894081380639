import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog_container: {
    backgroundColor: Colors.WHITE,
    minWidth: 'min-content',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px',
    borderRadius: 16,
    alignItems: 'center',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: 'fit-content',
    alignItems: 'center',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  dialog_body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: 150,
    flexDirection: 'column',
    // gap: 30,
    marginTop: 10,
    paddingTop: 15,
    paddingBottom: 15,
    width: '100%',
  },
  print_button_container: {
    marginTop: 15,
  },
  print_button: {
    width: '145px !important',
    height: '45px !important',
  },
  details_one: {
    marginBottom: 10,
    paddingRight: 5,
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'flex-start',
  },
  detail_three: {
    marginBottom: 10,
    textAlign: 'center',
    paddingRight: 5,
    paddingLeft: 5,
  },
}));
