import axios from 'axios';
import { SEND_EMAIL } from '../../constants';

export const sendEmail = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(SendEmail());

  axios
    .post(`/delivery/deliveryConfirmation`, data, config)
    .then((res) => {
      dispatch(setSendEmail(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorSendEmail(err.response));
      onError(err.response);
    });
};

export const SendEmail = () => ({
  type: SEND_EMAIL.LOAD,
});

export const setSendEmail = (data) => ({
  type: SEND_EMAIL.SUCCESS,
  payload: data.data,
});

export const errorSendEmail = (error) => ({
  type: SEND_EMAIL.FAIL,
  payload: error,
});
