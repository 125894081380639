import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { Colors } from '../../common/colors';
import { icons } from '../../assets';
import CustomCheckbox from '../CustomCheckbox';
import { Typography } from '@mui/material';

const Input = styled('input')(({ theme, disabled, error, isShowDetails }) => ({
  padding: 13,
  paddingRight: 32,
  width: '100%',
  fontFamily: "'Open Sans', sans-serif",
  color: Colors.BLACK,
  fontWeight: 400,
  fontSize: 14,
  border: 'none',
  background: disabled && 'transparent',
  borderBottom: error
    ? `2px solid ${Colors.FUZZY_WUZZY} !important`
    : disabled && !isShowDetails
    ? `2px dotted ${Colors.CHINESE_SILVER} !important`
    : `2px solid ${Colors.CHINESE_SILVER}!important`,
  '&:hover': {
    borderBottom: error
      ? `2px solid ${Colors.FUZZY_WUZZY} !important`
      : disabled && !isShowDetails
      ? `2px dotted ${Colors.CHINESE_SILVER} !important`
      : `2px solid ${Colors.FELDGRAU} !important`,
  },
  '&:focus': {
    borderBottom: error
      ? `2px solid ${Colors.FUZZY_WUZZY} !important`
      : disabled && !isShowDetails
      ? `2px dotted ${Colors.CHINESE_SILVER} !important`
      : `2px solid ${Colors.FELDGRAU} !important`,
    outline: 'none',
  },
  '&::placeholder': {
    color: Colors.DAVYS_GREY,
    fontFamily: `Lato, sans-serif`,
    lineHeight: '140%',
    fontWeight: 400,
    fontSize: 16,
    opacity: disabled ? 0.4 : 0.6,
  },
  background: `transparent url(${
    icons().drop_down_feldgrau
  }) no-repeat right 0.8rem center/1.5rem 4rem`,
}));

const Listbox = styled('ul')(({ theme, multiple }) => ({
  width: '100%',
  margin: 0,
  marginTop: 4,
  padding: 8,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: Colors.WHITE,
  overflow: 'auto',
  maxHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  '& li': {
    fontFamily: 'Lato, sans-serif',
    color: Colors.FELDGRAU,
    fontWeight: 400,
    fontSize: 16,
    padding: multiple ? '13px 16px 13px 8px' : '13px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& li.${autocompleteClasses.focused}`]: {
    background: 'rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
  },
  '& li:active': {
    background: multiple ? 'transparent' : 'rgba(0, 0, 0, 0.04)',
  },
  boxShadow:
    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
}));

//multiple
const Root = styled('div')(
  ({ theme }) => `
  font-size: 14px;
  width: 100%;
`
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border-radius: none;
  display: flex;
  flex-wrap: wrap;
  border: none;
`
);

function Tag(props) {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <img
        src={icons().close}
        alt=''
        style={{ height: 18, width: 18, cursor: 'pointer', marginLeft: 10 }}
        onClick={() => !disabled && onDelete()}
      />
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  color: ${Colors.BLACK};
  font-weight: 400;
  font-size: 14px;
  background-color: ${Colors.EDGE_WATER};
  outline: 0;
  overflow: hidden;
  border-radius: 3px;
`
);

export default function MultiSelectDropdown(props) {
  const classes = useStyles();
  let selectedTags = props.tagsLimit
    ? props.value?.slice(0, props.tagsLimit)
    : props.value;

  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
    value,
  } = useAutocomplete({
    id: 'tags-standard',
    options: props.optionArray ? props.optionArray : [],
    onChange: (event, value) => {
      props.onChange(value);
    },
    getOptionLabel: (option) => option.label,
    multiple: true,
    value: props.value,
    disableCloseOnSelect: true,
  });

  return (
    <div
      className={classNames(
        classes.dropdown_container,
        props.containerClassName && props.containerClassName
      )}
    >
      {props.label && (
        <label
          className={classes.dropdown_label}
          htmlFor={props.label}
          style={{ opacity: props?.disabled && !props?.isShowDetails && 0.5 }}
        >
          {props.label}
        </label>
      )}
      <Root>
        <div style={{ width: '100%', display: 'flex' }} {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
            <div className={classes.tag_container}>
              {selectedTags?.map((option, index) => (
                <StyledTag
                  label={option.label}
                  disabled={props?.disabled}
                  {...getTagProps({ index })}
                />
              ))}
              {props.tagsLimit
                ? props.tagsLimit < value?.length && (
                    <Typography
                      variant='p_400'
                      color={Colors.FELDGRAU}
                      style={{ cursor: 'pointer', marginLeft: 4 }}
                      onClick={props.onShowMoreClick}
                    >
                      Show more...
                    </Typography>
                  )
                : null}
            </div>
            <Input
              style={{ border: 'none' }}
              placeholder={props.placeholder}
              disabled={props.disabled}
              isShowDetails={props?.isShowDetails}
              error={props.error}
              {...getInputProps()}
            />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox multiple {...getListboxProps()}>
            {groupedOptions.map((option, index) => {
              return (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.label}</span>
                  <CustomCheckbox
                    checked={props?.value?.includes(option)}
                    container={classes.checkbox_container}
                  />
                </li>
              );
            })}
          </Listbox>
        ) : null}
      </Root>
      {props.error && (
        <p className={classes.dropdown_error}>{props.helperText}</p>
      )}
    </div>
  );
}
