import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';
import { icons } from '../../assets';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: Colors.WHITE,
    width: '100vw',
    height: '100vh',
    padding: '1%',
    display: 'flex',
  },
  left_container: {
    width: '53%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  left_logo: {
    width: 76,
    height: 65,
    backgroundImage: `url(${icons().logo_sea_green})`,
    position: 'absolute',
    left: 25,
    top: 10,
    zIndex: 1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  login_form_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '55%',
    maxWidth: 400,
    textAlign: 'center',
  },
  right_container: {
    width: '47%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  right_background: {
    backgroundColor: Colors.MEDIUM_SEA_GREEN,
    borderRadius: 16,
    gap: 50,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  boxes_image: {
    backgroundImage: `url(${icons().boxes})`,
    zIndex: 1,
    width: '115%',
    height: '77%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    left: '-17%',
    backgroundPosition: 'center',
    marginTop: '3%',
  },
  email_input: {
    marginTop: '40px !important',
    width: '100% !important',
  },
  common_input: {
    marginTop: '35px !important',
    width: '100% !important',
  },
}));
