//Images
import logo_sea_green from './images/sea_green/logo.svg';
import logo_white from './images/white/logo.svg';
import boxes from './images/boxes-1.svg';
import dummyProfile from './images/dummyProfile.svg';
import boxesPng from './images/main-boxes.png';

//Icons
import eye_quick_silver from './icons/quick_silver/eye.svg';
import hide_password_eye_quick_silver from './icons/quick_silver/hidePassword.svg';
import timer_quick_silver from './icons/quick_silver/timer.svg';

import users_powder_blue from './icons/powder_blue/users.svg';
import incidents_powder_blue from './icons/powder_blue/incidents.svg';
import vendors_powder_blue from './icons/powder_blue/vendors.svg';
import rate_sheet_powder_blue from './icons/powder_blue/rate_sheet.svg';
import fees_powder_blue from './icons/powder_blue/fees.svg';
import clients_powder_blue from './icons/powder_blue/clients.svg';
import inventory_powder_blue from './icons/powder_blue/inventory.svg';
import settings_powder_blue from './icons/powder_blue/settings.svg';
import deliveries_powder_blue from './icons/powder_blue/deliveries.svg';
import invoice_powder_blue from './icons/powder_blue/invoice.svg';
import report_powder_blue from './icons/powder_blue/report.svg';
import location_powder_blue from './icons/powder_blue/location.svg';

import search_feldgrau from './icons/feldgrau/search.svg';
import left_arrow_feldgrau from './icons/feldgrau/left_arrow.svg';
import left_arrow_disable_feldgrau from './icons/feldgrau/left_arrow_disable.svg';
import right_arrow_feldgrau from './icons/feldgrau/right_arrow.svg';
import right_arrow_disable_feldgrau from './icons/feldgrau/right_arrow_disable.svg';
import drop_down_feldgrau from './icons/feldgrau/drop_down.svg';
import edit_feldgrau from './icons/feldgrau/edit.svg';
import delete_feldgrau from './icons/feldgrau/delete.svg';
import menu_feldgrau from './icons/feldgrau/menu.svg';
import attach_feldgrau from './icons/feldgrau/attach.svg';
import calendar_feldgrau from './icons/feldgrau/calendar.svg';
import timer_feldgrau from './icons/feldgrau/timer.svg';
import export_feldgrau from './icons/feldgrau/export.svg';
import eye_feldgrau from './icons/feldgrau/eye.svg';
import barcode_feldgrau from './icons/feldgrau/barcode_.png';

import close_black from './icons/black/close.svg';

import email_medium_sea_green from './icons/medium_sea_green/email.svg';
import location_medium_sea_green from './icons/medium_sea_green/location.svg';
import phone_medium_sea_green from './icons/medium_sea_green/phone.svg';
import send_medium_sea_green from './icons/medium_sea_green/send.svg';
import send_bg_medium_sea_green from './icons/medium_sea_green/send_bg.svg';
import toggle_enable_medium_sea_green from './icons/medium_sea_green/toggle_enable.svg';

import timer_chinese_silver from './icons/chinese_silver/timer.svg';
import toggle_disable_chinese_silver from './icons/chinese_silver/toggle_disable.svg';

import drop_down_sea_green from './icons/sea_green/drop_down.svg';

import true_icon_white from './icons/white/true_icon.svg';
import dash_icon_white from './icons/white/dash_icon.svg';
import wrong_icon_white from './icons/white/wrong.svg';

import right_icon_green from './icons/sea_green/right.svg';
import wrong_icon_red from './icons/red/wrong.svg';

import short_arrow_icon from './icons/feldgrau/up-arrow.svg';

import printer_icon from './icons/feldgrau/printer.svg'

export const icons = (theme) => {
  // const isDarkMode = theme && theme.palette.mode === 'dark';

  return {
    //Images
    logo_sea_green: logo_sea_green,
    logo_white: logo_white,
    boxes: boxes,
    dummyProfile: dummyProfile,
    boxesPng: boxesPng,

    //Icons
    eye_quick_silver: eye_quick_silver,
    hide_password_eye_quick_silver: hide_password_eye_quick_silver,
    timer_quick_silver: timer_quick_silver,

    users: users_powder_blue,
    incidents: incidents_powder_blue,
    vendors: vendors_powder_blue,
    rate_sheet: rate_sheet_powder_blue,
    fees: fees_powder_blue,
    clients: clients_powder_blue,
    inventory: inventory_powder_blue,
    settings: settings_powder_blue,
    deliveries: deliveries_powder_blue,
    invoice: invoice_powder_blue,
    report: report_powder_blue,
    location: location_powder_blue,

    search: search_feldgrau,
    left_arrow: left_arrow_feldgrau,
    left_arrow_disable: left_arrow_disable_feldgrau,
    right_arrow: right_arrow_feldgrau,
    right_arrow_disable: right_arrow_disable_feldgrau,
    drop_down_feldgrau: drop_down_feldgrau,
    edit: edit_feldgrau,
    delete: delete_feldgrau,
    menu: menu_feldgrau,
    attach: attach_feldgrau,
    calendar: calendar_feldgrau,
    timer_feldgrau: timer_feldgrau,
    export: export_feldgrau,
    eye_feldgrau: eye_feldgrau,
    barcode: barcode_feldgrau,

    close: close_black,

    email: email_medium_sea_green,
    location: location_medium_sea_green,
    phone: phone_medium_sea_green,
    send: send_medium_sea_green,
    send_bg: send_bg_medium_sea_green,
    toggle_enable: toggle_enable_medium_sea_green,

    timer_chinese_silver: timer_chinese_silver,
    toggle_disable: toggle_disable_chinese_silver,

    drop_down_sea_green: drop_down_sea_green,

    true_icon_white: true_icon_white,
    dash_icon_white: dash_icon_white,
    wrong_icon_white: wrong_icon_white,

    right_icon_green: right_icon_green,
    wrong_icon_red: wrong_icon_red,

    sort_arrow_icon: short_arrow_icon,

    printer_icon: printer_icon,
  };
};
