import axios from 'axios';
import { ASSIGN_TO_DESIGNER } from '../../constants';

export const assignToDesigner =
  (ID, data, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadAssignToDesigner());

    axios
      .put(`/incident/assignToDesigner?incidentId=${ID}`, data, config)
      .then((res) => {
        dispatch(setAssignToDesigner(res));
        // onSuccess();
      })
      .catch((err) => {
        dispatch(errorAssignToDesigner(err.response));
        onError(err.response);
      });
  };

export const loadAssignToDesigner = () => ({
  type: ASSIGN_TO_DESIGNER.LOAD,
});

export const setAssignToDesigner = (data) => ({
  type: ASSIGN_TO_DESIGNER.SUCCESS,
  payload: data.data,
});

export const errorAssignToDesigner = (error) => ({
  type: ASSIGN_TO_DESIGNER.FAIL,
  payload: error,
});
