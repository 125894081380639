import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    background: Colors.WHITE,
    borderRadius: 16,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected_text: {
    fontSize: '14px !important',
    color: Colors.FELDGRAU,
  },
  deselect_text: {
    fontSize: '14px !important',
    color: Colors.SEA_GREEN,
    cursor: 'pointer !important',
    marginLeft: '15px !important',
  },
  common_image: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  marginLeft: {
    marginLeft: 15,
  },
  background_button: {
    marginLeft: '15px !important',
    background: `${Colors.SEA_GREEN} !important`,
    padding: '8px 20px !important',
    height: 'auto !important',
  },
  outlined_button: {
    marginLeft: '15px !important',
    borderRadius: '50px !important',
    padding: '8px 20px !important',
    height: 'auto !important',
  },
}));
