import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  tab_container: {
    height: 274,
    width: '100%',
    display: 'flex',
    padding: 15,
    backgroundColor: Colors.BRIGHT_GRAY,
    borderRadius: 8,
    marginTop: 10,
    flexDirection: 'column',
  },
  notes_list: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  no_notes: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  }
}));
