import React, { useState } from 'react';
import useStyles from './styles';
import { Typography, Button, TextField } from '@mui/material';
import { Strings } from '../../common/strings';
import { Colors } from '../../common/colors';
import { icons } from '../../assets';
import CustomCheckbox from '../../components/CustomCheckbox';
import CommonLink from '../../components/CommonLink';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoginSchema } from '../../utils/validationSchemas';
import { connect } from 'react-redux';
import { login } from '../../redux/actions/authActions/login';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';

const Login = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem('rememberMe')
        ? localStorage.getItem('email')
        : '',
      password: localStorage.getItem('rememberMe')
        ? localStorage.getItem('password')
        : '',
      remember: localStorage.getItem('rememberMe') === 'true' ? true : false,
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      props.login(data, navigate, (err) => onError(err), values.remember);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  return (
    <div className={classes.container}>
      <div className={classes.left_container}>
        <div className={classes.left_logo} />

        <div className={classes.login_form_container}>
          <FormikProvider value={formik}>
            <Form
              className={classes.login_form}
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
            >
              <Typography variant='h5_700' color={Colors.BLACK}>
                {Strings.WELCOME_BACK}
              </Typography>
              <Typography variant='p_400' mt={0.7} color={Colors.FELDGRAU}>
                {Strings.LOGIN_INFO}
              </Typography>
              <TextField
                name='email'
                required
                id='filled-required'
                label={Strings.EMAIL}
                placeholder={Strings.YOUR_EMAIL}
                variant='standard'
                value={values.email}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange('email')}
                className={classes.email_input}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                required
                name='password'
                id='filled-required'
                label={Strings.PASSWORD}
                type={passwordFieldType}
                value={values.password}
                placeholder={Strings.YOUR_PASSWORD}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange('password')}
                className={classes.common_input}
                InputProps={{
                  endAdornment: (
                    <img
                      src={
                        passwordFieldType === 'password'
                          ? icons().hide_password_eye_quick_silver
                          : icons().eye_quick_silver
                      }
                      alt=''
                      className={classes.eye_icon}
                      onClick={() =>
                        setPasswordFieldType(
                          passwordFieldType === 'password' ? 'text' : 'password'
                        )
                      }
                    />
                  ),
                }}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
              />
              <div className={classes.forgot_link_container}>
                <CustomCheckbox
                  label={Strings.REMEMBER_ME}
                  checked={values.remember}
                  onChange={() => setFieldValue('remember', !values.remember)}
                />
                <CommonLink
                  text={Strings.FORGOT_PASSWORD}
                  onClick={() => {
                    navigate('/forgotPassword');
                  }}
                />
              </div>
              <Button
                type='submit'
                className={classes.common_input}
                variant='contained'
                disabled={props.loginDataLoading}
              >
                {props.loginDataLoading ? <Loader /> : Strings.LOGIN}
              </Button>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <div className={classes.right_container}>
        <div className={classes.right_background} />
        <div className={classes.boxes_image} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.loginData.data,
  loginDataLoading: state.loginData.loading,
});

export default connect(mapStateToProps, {
  login,
})(Login);
