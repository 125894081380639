import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    display: 'flex',
    height: 'inherit',
    flexDirection: 'column',
  },
  tableContainer: {
    display: 'flex',
    height: 'inherit',
    padding: 15,
    flexDirection: 'column',
  },
  row_flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  profile: {
    height: 54,
    width: 54,
    borderRadius: 4,
    background: Colors.IMAGE_PLACEHOLDER,
  },
  icons: {
    height: 23,
    width: 23,
    cursor: 'pointer',
  },
  align_right: {
    justifyContent: 'flex-end',
  },
  padding_right: {
    paddingRight: 15,
  },
  table: {
    display: 'flex',
    height: 'inherit',
    overflow: 'auto',
  },
  pagination: {
    display: 'flex',
    marginBottom: 120,
    padding: 20,
    justifyContent: 'center',
    flex: 1,
  },
  align_center: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  incident_status: {
    width: 'fit-content',
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
  },
  estimate_tag: {
    border: `1px solid ${Colors.NEW_CAR}`,
    color: Colors.NEW_CAR,
  },
  pending_estimate_tag: {
    border: `1px solid ${Colors.ORANGE}`,
    color: Colors.ORANGE,
  },
  pending_assignment_tag: {
    border: `1px solid ${Colors.LUST}`,
    color: Colors.LUST,
  },
  complete_tag: {
    border: `1px solid ${Colors.SEA_GREEN}`,
    color: Colors.SEA_GREEN,
  },
  approved_status: {
    background: Colors.POWDER_BLUE,
    color: Colors.SEA_GREEN,
  },
  declined_status: {
    background: Colors.DARK_YELLOW,
    color: Colors.CHOCOLATE_BROWN,
  },
  flex_view: {
    width: '100%',
    display: 'flex',
    marginTop: 60,
    justifyContent: 'center',
  },
}));
