import React from 'react';
import useStyles from './styles';
import ReportModal from '../../components/ReportModal';

const Report = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.main_container}>
        <ReportModal />
      </div>
    </div>
  );
};

export default Report;
