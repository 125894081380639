import React, { useEffect, useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import EstimationTabContainer from '../EstimationTabContainer';
import IncidentNotesTabContainer from '../IncidentNotesTabContainer';
import { connect, useDispatch } from 'react-redux';
import { addNote } from '../../redux/actions/adminActions/addNote';
import { updateNote } from '../../redux/actions/adminActions/updateNote';
import { deleteNote } from '../../redux/actions/adminActions/deleteNote';
import { fetchVendorUserList } from '../../redux/actions/vendorActions/getVendorUserList';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { assignToDesigner } from '../../redux/actions/incidentsActions/assignToDesigner';
import Loader from '../Loader';
import Selection from '../Selection';
import { assignToVendor } from '../../redux/actions/incidentsActions/assignToVendor';
import { getErrorMessage, isAdmin, isDesigner } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import MultiSelectDropdown from '../MultiSelectDropdown';
import ImageViewer from '../ImageViewer';
import { setGetIncident } from '../../redux/actions/incidentsActions/getIncident';
import { completeIncident } from '../../redux/actions/incidentsActions/completeIncident';
import TagsInputField from '../TagsInputField';

function IncidentDetailsModal(props) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [designerList, setDesignerList] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const TablTitle = [Strings.INCIDENT_NOTES, Strings.ESTIMATION];
  const [vendorList, setVendorList] = useState([]);
  const [selectVendor, setSelectVendor] = useState('0');
  const { enqueueSnackbar } = useSnackbar();
  const [estimation, setEstimation] = useState(
    props?.incidentData?.estimation || []
  );
  const [vendor, setVendor] = useState(props?.incidentData?.vendor || []);
  const [showMoreImage, setShowMoreImage] = useState(false);
  const [selectedDesigner, setSelectedDesigner] = useState([]);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [incidentStatus, setIncidentStatus] = useState('');

  const dispatch = useDispatch();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  // useEffect(() => {
  //   if (props?.isEdit) {
  //     props.fetchDesignerList((err) => onError(err));
  //   }
  // }, []);

  useEffect(() => {
    if (isDesigner()) {
      props.fetchVendorUserList((err) => onError(err));
    }
    props.getIncident(props.selectedIncident, props.onError, true);
  }, []);

  useEffect(() => {
    const vendorList = props?.getVendorUserListData?.map((e) => ({
      label: e?.name,
      value: e?._id,
    }));
    setVendorList(vendorList);
  }, [props.getVendorUserListData, props.incidentListData]);

  useEffect(() => {
    if (props?.isEdit && props?.designerListData?.length > 0) {
      const designerList =
        props?.designerListData?.length > 0
          ? props?.designerListData?.map((e) => ({
              label: e.name,
              value: e._id,
            }))
          : [];
      setDesignerList(designerList);
    }
  }, [props.designerListData]);

  useEffect(() => {
    if (props?.isEdit && props?.incidentData) {
      const newData = props?.incidentData?.designer?.map((el) => el?.email);
      setSelectedDesigner(newData || []);
    }
  }, [props?.incidentData]);

  useEffect(() => {
    setIncidentDetails(props?.incidentData);
    setNotesData(props?.incidentData?.notes);
    setEstimation(props?.incidentData?.estimation);
    setVendor(props?.incidentData?.vendor);
    setIncidentStatus(props?.incidentData?.status);
  }, [props.incidentData]);

  // ====== Custom Tab View ======
  const CustomTabView = () => {
    return (
      <div className={classes.tab_main_container}>
        <div className={classes.tab_header}>
          {TablTitle.map((item, index) => {
            return (
              <Typography
                key={index}
                variant='h6_700'
                style={{
                  paddingBottom: 8,
                  borderBottom:
                    selectedTab === index && `2px solid ${Colors.RAISIN_BLACK}`,
                  cursor: 'pointer',
                  color: Colors.RAISIN_BLACK,
                }}
                onClick={() => setSelectedTab(index)}
              >
                {index === 0 ? item : estimation ? item + ' (1)' : item}
              </Typography>
            );
          })}
        </div>
        {selectedTab === 0 && (
          <IncidentNotesTabContainer
            notes={notesData?.length > 0 ? [...notesData].reverse() : []}
            notesData={notesData}
            setNotesData={setNotesData}
            addNoteDataLoading={props.addNoteDataLoading}
            addNoteData={props.addNoteData}
            addNote={props.addNote}
            selectedIncident={props.selectedIncident}
            onError={props.onError}
            deleteNote={props?.deleteNote}
            deleteNoteData={props?.deleteNoteData}
            deleteNoteDataLoading={props?.deleteNoteDataLoading}
            updateNote={props?.updateNote}
            updateNoteData={props?.updateNoteData}
            updateNoteDataLoading={props?.updateNoteDataLoading}
          />
        )}
        {selectedTab === 1 && (
          <EstimationTabContainer
            estimation={estimation}
            vendor={vendor}
            incidentId={incidentDetails?._id}
            onClose={props.onClose}
          />
        )}
      </div>
    );
  };

  const onClickDelete = () => {
    // props.onClose();
    props.onClickDelete();
  };

  const onPressSave = () => {
    const { assignToVendor } = props;
    assignToVendor(
      incidentDetails._id,
      selectVendor,
      () => {
        props.onClose();
        props.getIncident(props.selectedIncident, props.onError, true);
      },
      (err) => onError(err)
    );
  };

  const onClickAssignDesigner = () => {
    const data = [...selectedDesigner];
    props.assignToDesigner(incidentDetails._id, { designers: data }, onError);
  };

  const onClickComplete = () => {
    props.completeIncident(
      incidentDetails._id,
      () => {
        setIncidentStatus('Completed');
      },
      onError
    );
  };

  // ===== Render Tags for multiple emails add =====
  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        <a className={classNameRemove} onClick={(e) => onRemove(key)} />
      </span>
    );
  };

  return (
    <>
      {openImageViewer ? (
        <ImageViewer
          onClose={() => {
            setOpenImageViewer(false);
            setSelectedImage('');
          }}
          image={selectedImage}
        />
      ) : (
        <div className={classes.main_container}>
          <div className={classes.modal_container}>
            {props.loader ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <div>
                <div className={classes.close_icon_container}>
                  <img
                    src={icons().close}
                    className={classes.close_icon}
                    onClick={async () => {
                      await setIncidentDetails({});
                      dispatch(setGetIncident({}));
                      props.onClose();
                    }}
                  />
                </div>
                <div
                  className={classNames(classes.flex_row, classes.align_center)}
                >
                  <Typography variant='h5_700'>{Strings.INCIDENT}</Typography>
                  {/* <img src={icons().edit} className={classes.icons} /> */}
                  <img
                    src={icons().delete}
                    className={classes.icons}
                    onClick={onClickDelete}
                  />
                </div>

                <div className={classes.status_container}>
                  <div
                    className={classNames(
                      classes.incident_status,
                      incidentStatus === 'Pending Estimate'
                        ? classes.pending_estimate_tag
                        : incidentStatus === 'Pending Assignment'
                        ? classes.pending_assignment_tag
                        : incidentStatus === 'Completed'
                        ? classes.complete_tag
                        : classes.tag
                    )}
                  >
                    <Typography variant='p_700'>{incidentStatus}</Typography>
                  </div>
                  {isAdmin() && incidentStatus !== 'Completed' && (
                    <div>
                      <Button
                        variant='contained'
                        className={classes.complete_btn}
                        onClick={onClickComplete}
                      >
                        {props?.completeIncidentDataLoading ? (
                          <Loader />
                        ) : (
                          Strings.Complete.toUpperCase()
                        )}
                      </Button>
                    </div>
                  )}
                  {incidentDetails?.estimation?.estimationStatus ===
                    'Approved' &&
                    !isAdmin() && (
                      <div
                        className={classes.incident_status}
                        style={{
                          background: Colors.POWDER_BLUE,
                          color: Colors.SEA_GREEN,
                        }}
                      >
                        <Typography variant='p_700'>
                          {incidentDetails?.estimation?.estimationStatus}
                        </Typography>
                      </div>
                    )}
                </div>

                <div
                  className={classNames(
                    classes.flex_row,
                    classes.barcode_data_container
                  )}
                >
                  {/* <Typography variant='p_400'>
                    {incidentDetails?.barCodeId}
                  </Typography> */}
                  <div className={classes.name_view}>
                    <Typography variant='h6_700'>
                      {`Barcode: ${incidentDetails?.barCodeId}`}
                    </Typography>
                  </div>
                </div>

                {/* <Typography variant='p_400' className={classes.sub_heading}>
                  {Strings.PREFERRED_CONTACT}{' '}
                </Typography>
    
                <Typography
                  variant='p_700'
                  className={classes.prefered_contact_value}
                >
                  {'Email'}
                </Typography> */}
                <div className={classes.flex_row}>
                  <div className={classes.flex_column} style={{ width: '50%' }}>
                    <Typography variant='p_400' className={classes.sub_heading}>
                      {Strings.BARCODE_ID || `-`}
                    </Typography>

                    <Typography
                      variant='p_400'
                      className={classes.incident_info_value}
                    >
                      {incidentDetails?.barCodeId || `-`}
                    </Typography>
                  </div>
                  <div className={classes.flex_column} style={{ width: '50%' }}>
                    <Typography variant='p_400' className={classes.sub_heading}>
                      {Strings.DESIGNER || `-`}
                    </Typography>
                    <div
                      className={classNames(classes.flex_row, classes.top_5)}
                    >
                      {!props.isEdit && (
                        <Stack direction='row' gap={1.75} flexWrap='wrap'>
                          {incidentDetails?.designer?.map((item) => (
                            <Typography
                              variant='p_400'
                              className={classes.designer_value}
                            >
                              {item?.name || `-`}
                            </Typography>
                          ))}
                        </Stack>
                      )}
                      {isAdmin() && props.isEdit ? (
                        <>
                          {/* <MultiSelectDropdown
                            containerClassName={classes.selection_input}
                            name='designer'
                            value={selectedDesigner || `-`}
                            onChange={(val) => {
                              setSelectedDesigner(val);
                            }}
                            placeholder={Strings.SELECT}
                            optionArray={designerList}
                          /> */}
                          <TagsInputField
                            selectedTags={(value) => setSelectedDesigner(value)}
                            placeholder={Strings.TYPE_HERE}
                            tags={selectedDesigner || []}
                            renderTag={renderTags}
                            containerClassName={classes.selection_input}
                          />
                          <Button
                            variant='contained'
                            className={classes.assignToDesignerBtn}
                            onClick={onClickAssignDesigner}
                          >
                            {props?.assignToDesignerLoading ? (
                              <Loader />
                            ) : (
                              Strings.ASSIGN_DESIGNER.toUpperCase()
                            )}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className={classes.flex_row}>
                  <div className={classes.flex_column} style={{ width: '50%' }}>
                    <Typography variant='p_400' className={classes.sub_heading}>
                      {Strings.WSI_NUMBER || `-`}
                    </Typography>

                    <Typography
                      variant='p_400'
                      className={classes.incident_info_value}
                    >
                      {incidentDetails?.wsiNumber || `-`}
                    </Typography>
                  </div>
                  <div className={classes.flex_column} style={{ width: '50%' }}>
                    <Typography variant='p_400' className={classes.sub_heading}>
                      {Strings.VENDOR || `-`}
                    </Typography>

                    {isDesigner() ? (
                      incidentDetails && incidentDetails?.vendor ? (
                        <Typography
                          variant='p_400'
                          className={classes.designer_value}
                        >
                          {incidentDetails?.vendor?.name || `-`}
                        </Typography>
                      ) : (
                        <>
                          <Selection
                            className={classes.selection_input}
                            placeHolder={'Select'}
                            handleChange={(e) => {
                              setSelectVendor(e.target.value);
                            }}
                            value={selectVendor}
                            title={Strings.SELECT}
                            options={vendorList}
                          />
                          <Button
                            type='submit'
                            className={classes.save_button}
                            variant='contained-radius'
                            // disabled={}
                            onClick={() => onPressSave()}
                            disabled={props?.assignToVendorDataLoading}
                          >
                            {props?.assignToVendorDataLoading ? (
                              <Loader />
                            ) : (
                              Strings.SAVE
                            )}
                          </Button>
                        </>
                      )
                    ) : (
                      <div
                        className={classNames(classes.flex_row, classes.top_5)}
                      >
                        {incidentDetails?.vendor?.name ? (
                          <Typography
                            variant='p_400'
                            className={classes.designer_value}
                          >
                            {incidentDetails?.vendor?.name}
                          </Typography>
                        ) : (
                          `-`
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className={classes.flex_column} style={{ width: '50%' }}>
                  <Typography variant='p_400' className={classes.sub_heading}>
                    {Strings.BARCODE_ITEM_DESC}
                  </Typography>

                  <Typography
                    variant='p_400'
                    className={classes.incident_info_value}
                  >
                    {incidentDetails?.description}
                  </Typography>
                </div>

                <div className={classes.flex_column} style={{ width: '50%' }}>
                  <Typography variant='p_400' className={classes.sub_heading}>
                    {Strings.DAMAGED}
                  </Typography>

                  <Typography
                    variant='p_400'
                    className={classes.incident_info_value}
                  >
                    {incidentDetails?.damaged}
                  </Typography>
                </div>
                {incidentDetails?.itemPicture?.length > 0 && (
                  <div
                    className={classNames(
                      classes.flex_row,
                      classes.images_container
                    )}
                  >
                    <div
                      className={classNames(
                        classes.image_row_container,
                        showMoreImage
                          ? classes.image_row_max_container
                          : classes.image_row_min_container
                      )}
                    >
                      {incidentDetails?.itemPicture?.map((img, index) => (
                        <div
                          className={classes.image_item_container}
                          key={index}
                        >
                          <img
                            src={img}
                            className={classes.images}
                            onClick={() => {
                              setSelectedImage(img);
                              setOpenImageViewer(true);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    {/* <Grid container columns={{ md: 12 }} spacing={2}>
                        {incidentDetails?.itemPicture?.map((i, index) => (
                          <Grid item md={12 / 5} key={index}>
                            <img src={i} className={classes.images} />
                          </Grid>
                        ))}
                      </Grid> */}
                  </div>
                )}
                {incidentDetails?.itemPicture?.length > 5 && (
                  <div
                    className={classNames(
                      classes.flex_row,
                      classes.justify_center,
                      classes.show_more_txt
                    )}
                  >
                    <Typography
                      variant='p_400'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowMoreImage(!showMoreImage)}
                    >
                      {showMoreImage ? Strings.SHOW_LESS : Strings.SHOW_MORE}
                    </Typography>
                    <img
                      src={icons().drop_down_sea_green}
                      className={
                        showMoreImage
                          ? classes.drop_down_up_icon
                          : classes.drop_down_icon
                      }
                      onClick={() => setShowMoreImage(!showMoreImage)}
                    />
                  </div>
                )}
                <CustomTabView />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  addNoteData: state.addNoteData.data,
  addNoteDataLoading: state.addNoteData.loading,

  deleteNoteData: state.deleteNoteData.data,
  deleteNoteDataLoading: state.deleteNoteData.loading,

  updateNoteData: state.updateNoteData.data,
  updateNoteDataLoading: state.updateNoteData.loading,

  getVendorUserListData: state.getVendorUserListData.data,
  getVendorUserListDataLoading: state.getVendorUserListData.loading,

  assignToVendorData: state.assignToVendorData.data,
  assignToVendorDataLoading: state.assignToVendorData.loading,

  designerListData: state.designerListData.data,
  assignToDesignerLoading: state.assignToDesignerData.loading,

  completeIncidentData: state.completeIncidentData.data,
  completeIncidentDataLoading: state.completeIncidentData.loading,
});

export default connect(mapStateToProps, {
  addNote,
  updateNote,
  deleteNote,
  fetchVendorUserList,
  assignToVendor,
  fetchDesignerList,
  assignToDesigner,
  completeIncident,
})(IncidentDetailsModal);
