import React, { useState, useEffect } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography, TextField, Button } from '@mui/material';
import { Strings } from '../../common/strings';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { getErrorMessage, slicePhoneNumber } from '../../utils/appUtils';
import { createVendor } from '../../redux/actions/vendorActions/addVendor';
import { AddVendorSchema } from '../../utils/validationSchemas';
import { updateVendor } from '../../redux/actions/vendorActions/updateVendor';
import Loader from '../Loader';
import { Colors } from '../../common/colors';
import TagsInputField from '../TagsInputField';

const AddVendorModal = (props) => {
  const classes = useStyles();
  //   const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props.edit) {
      props.getVendor(props.selectedVendor, props.onError);
    }
  }, []);

  useEffect(() => {
    if (props.vendorData) {
      // let newPhoneNumber = slicePhoneNumber(props.vendorData?.phoneNumber);
      setFieldValue(
        'additionalEmails',
        props.vendorData?.additionalEmails || []
      );
      setFieldValue('name', props.vendorData?.name || '');
      setFieldValue('email', props.vendorData?.email || '');
      setFieldValue('phone', props.vendorData?.phoneNumber || '');
      setFieldValue('address', props.vendorData?.address || '');
      setFieldValue('password', props.vendorData?.password ? '******' : '');
      setFieldValue('isEdit', props.edit ? true : false);
    }
  }, [props.vendorData]);

  const handleDataSubmit = (values) => {
    try {
      const data = {
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        address: values.address,
        password: values.password,
        additionalEmails: values.additionalEmails,
      };
      delete data?.isEdit;
      if (props.edit) {
        if (values?.password === '******') delete data.password;
        props.updateVendor(
          props.selectedVendor,
          data,
          () => {
            props.updateVendorDataById(values);
            props.onClose();
          },
          (err) => onError(err)
        );
      } else {
        props.createVendor(
          data,
          () => props.onClose(),
          (err) => onError(err)
        );
      }
    } catch (e) {
      console.log('eee', e, values);
    }
  };

  const handleSelectedTags = (key, tags) => {
    setFieldValue(key, tags);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      password: '',
      isEdit: false,
      additionalEmails: [],
    },
    validationSchema: AddVendorSchema,

    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled ? (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        ) : null}
      </span>
    );
  };

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => props.onClose()}
              />
            </div>
            <Typography variant='h5_700'>
              {props.isShowDetails
                ? Strings.VENDOR
                : props.edit
                ? Strings.EDIT_VENDOR
                : Strings.NEW_VENDOR}
            </Typography>
            {props?.loader ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <>
                <TextField
                  // required
                  id='filled-required'
                  label={Strings.NAME}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={
                    props.isShowDetails ? classes.disabled_input : classes.input
                  }
                  value={values.name}
                  onChange={handleChange('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  disabled={props.isShowDetails}
                />

                <TextField
                  // required
                  id='filled-required'
                  label={Strings.EMAIL}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={
                    props.isShowDetails ? classes.disabled_input : classes.input
                  }
                  value={values.email}
                  onChange={handleChange('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={props.isShowDetails}
                />

                <TagsInputField
                  selectedTags={(value) =>
                    handleSelectedTags('additionalEmails', value)
                  }
                  label={Strings.ADDITIONAL_EMAILS}
                  placeholder={Strings.TYPE_HERE}
                  tags={values.additionalEmails || []}
                  renderTag={renderTags}
                  containerClassName={classes.input}
                  disabled={props.isShowDetails}
                  isShowDetails={props.isShowDetails}
                />

                <TextField
                  // required
                  id='filled-required'
                  label={Strings.PHONE}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={
                    props.isShowDetails ? classes.disabled_input : classes.input
                  }
                  value={values.phone}
                  onChange={handleChange('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  disabled={props.isShowDetails}
                />

                <TextField
                  // required
                  id='filled-required'
                  label={Strings.ADDRESS}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={
                    props.isShowDetails ? classes.disabled_input : classes.input
                  }
                  value={values.address}
                  onChange={handleChange('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  disabled={props.isShowDetails}
                />

                {!props.isShowDetails && (
                  <>
                    <TextField
                      // required
                      id='filled-required'
                      label={Strings.PASSWORD}
                      placeholder={Strings.TYPE_HERE}
                      variant='standard'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.input}
                      value={values.password}
                      onChange={handleChange('password')}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      disabled={props.edit ? true : false}
                    />
                    <Button
                      type='submit'
                      className={classes.add_user_button}
                      variant='contained-radius'
                      disabled={
                        props.edit
                          ? props.updateVendorLoading
                          : props.addVendorLoading
                      }
                    >
                      {props.addVendorLoading || props.updateVendorLoading ? (
                        <Loader />
                      ) : props.edit ? (
                        Strings.UPDATE_USER.toUpperCase()
                      ) : (
                        Strings.ADD_USER.toUpperCase()
                      )}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addVendorData: state.addVendorData.data,
  addVendorLoading: state.addVendorData.loading,
  updateVendorData: state.updateVendorData.data,
  updateVendorLoading: state.updateVendorData.loading,
});

export default connect(mapStateToProps, {
  createVendor,
  updateVendor,
})(AddVendorModal);
