import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'flex-start',
    marginTop: 15,
    paddingBottom: 10,
  },
  flex_column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    flex: 1,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  timer_view: {
    alignItems: 'center',
  },
  timer_icon: {
    height: 15,
    width: 15,
  },
  date_txt: {
    color: Colors.QUICK_SILVER,
  },
}));
