import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  time_picker_icon: {
    // marginRight: '8px !important',
  },
  time_picker_root: {
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
    // cursor: 'pointer',
  },
}));
