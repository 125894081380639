import axios from 'axios';
import { GET_VENDOR } from '../../constants';

export const getVendor = (uID, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetVendor());
  axios
    .get(`/vendor/getVendor/${uID}`, config)
    .then((res) => dispatch(setGetVendor(res.data.data)))
    .catch((err) => {
      dispatch(errorGetVendor(err.response));
      onError(err.response);
    });
};

export const loadGetVendor = () => ({
  type: GET_VENDOR.LOAD,
});

export const setGetVendor = (data) => ({
  type: GET_VENDOR.SUCCESS,
  payload: data,
});

export const errorGetVendor = (error) => ({
  type: GET_VENDOR.FAIL,
  payload: error,
});
