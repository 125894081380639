import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'flex-end',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 559,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  icons: {
    height: 25,
    width: 25,
    cursor: 'pointer',
  },
  align_center: {
    alignItems: 'center',
  },
  position: {
    width: 'fit-content',
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
    cursor: 'pointer',
    marginTop: 25,
    marginBottom: 25,
  },
  name_view: {
    display: 'flex',
    width: '100%',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
    paddingBottom: 5,
  },
  email_icon: {
    height: 16,
    width: 16,
  },
  email_container: {
    marginTop: 15,
    gap: 10,
    color: Colors.SEA_GREEN,
  },
  sub_heading: {
    marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
  options_checkbox: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '50%',
  },
  prefered_contact_value: {
    marginTop: '10px !important',
    color: Colors.FELDGRAU,
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  // TAB
  tab_main_container: {
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
  },
}));
