import React, { useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { Strings } from '../../common/strings';
import CommonMenu from '../CommonMenu';
import moment from 'moment';
import { connect } from 'react-redux';
import { updateEstimation } from '../../redux/actions/incidentsActions/updateEstimation';
import { useSnackbar } from 'notistack';
import { getErrorMessage, isDesigner, isVendor } from '../../utils/appUtils';
import Loader from '../Loader';
import { Colors } from '../../common/colors';
import IncidentNotesInput from '../IncidentNotesInput';
import { createEstimation } from '../../redux/actions/incidentsActions/createEstimation';
import { uploadFile } from '../../redux/actions/settingActions/uploadFile';
import { deleteFile } from '../../redux/actions/settingActions/deleteFile';

function EstimationTabContainer(props) {
  const classes = useStyles();
  const { vendor } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [estimation, setEstimation] = useState(props.estimation || null);
  const [estimationStatus, setEstimationStatus] = useState(
    estimation?.estimationStatus || null
  );
  const [estimationDescription, setEstimationDescription] = useState('');
  const [estimationTotal, setEstimationTotal] = useState('');
  //   const [anchorEl, setAnchorEl] = useState(null);
  const [estimationImage, setEstimationImageImage] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onPressUpdateStatus = (status) => {
    const { updateEstimation, incidentId } = props;
    updateEstimation(
      incidentId,
      status,
      () => {
        setEstimationStatus(status);
        props.onClose();
      },
      (err) => onError(err)
    );
  };

  const onPressCreateEstimation = () => {
    const { createEstimation, incidentId } = props;
    const data = {
      description: estimationDescription,
      total: estimationTotal,
      file: estimationImage,
    };
    createEstimation(
      incidentId,
      data,
      () => {
        setEstimation(data);
        props.onClose();
      },
      (err) => onError(err)
    );
  };

  const handleFileSelect = (e) => {
    const file = {
      files: e.target.files[0],
    };
    if (estimationImage) {
      const deleteData = {
        url: estimationImage,
      };
      props.deleteFile(deleteData, onError);
      props.uploadFile(file, setEstimationImageImage, onError);
    } else {
      props.uploadFile(file, setEstimationImageImage, onError);
    }
  };

  const removeFile = () => {
    const deleteData = {
      url: estimationImage,
    };
    props.deleteFile(deleteData, onError);
    setEstimationImageImage('');
  }

  return estimation ? (
    <>
      {props.updateEstimationDataLoading ? (
        <div className={classes.display_flex}>
          <Loader color={Colors.RAISIN_BLACK} size={40} />
        </div>
      ) : (
        <div className={classes.tab_container}>
          <div className={classes.flex_row_user_info}>
            <div className={classes.flex_column}>
              <Typography variant='h6_700'>{vendor?.name || ''}</Typography>
              <div className={classes.flex_row}>
                <img
                  src={icons().location}
                  className={classes.tab_data_icons}
                />
                <Typography variant='p_400'>{vendor?.address || ''}</Typography>
              </div>
            </div>

            <div className={classes.flex_column}>
              <div className={classNames(classes.flex_row)}>
                <img src={icons().phone} className={classes.phone_icon} />
                <Typography variant='p_400'>
                  {vendor?.phoneNumber || ''}
                </Typography>
              </div>

              <div className={classNames(classes.flex_row)}>
                <img src={icons().email} className={classes.email_icon} />
                <Typography variant='p_400'>{vendor?.email || ''}</Typography>
              </div>
            </div>

            {/* <CommonMenu options={[{ title: 'Delete' }, { title: 'Copy' }]} /> */}
          </div>
          <Typography variant='p_400' className={classes.top_20}>
            {estimation?.description || ''}
          </Typography>
          {estimation?.file && (
            <Grid md={12 / 5}>
              <img src={estimation?.file} className={classes.images} />
            </Grid>
          )}
          <div className={classNames(classes.flex_row, classes.total_text)}>
            <Typography variant='h6_700' style={{ width: '50%' }}>
              {Strings.TOTAL}
            </Typography>
            <Typography
              variant='h6_700'
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}>{`$${estimation?.total || 0}`}</Typography>
          </div>
          <div className={classNames(classes.flex_row, classes.timer_view)}>
            <div className={classes.flex_row}>
              <img
                src={icons().timer_quick_silver}
                className={classes.timer_icon}
              />
              <Typography variant='p_small_600' className={classes.date_txt}>
                {moment(estimation?.estimationDate).format('lll') ||
                  moment().format('lll')}
              </Typography>
            </div>
            {isDesigner() ? (
              estimationStatus === 'Pending' ? (
                <div className={classes.flex_row}>
                  <div
                    className={classes.approve_tag}
                    onClick={() => {
                      onPressUpdateStatus('Approved');
                    }}>
                    <img
                      src={icons().right_icon_green}
                      className={classes.tag_icon}
                    />
                    <Typography variant='p_700'>{Strings.APPROVE}</Typography>
                  </div>
                  <div
                    className={classes.decline_tag}
                    onClick={() => {
                      onPressUpdateStatus('Declined');
                    }}>
                    <img
                      src={icons().wrong_icon_red}
                      className={classes.tag_icon}
                    />
                    <Typography variant='p_700'>{Strings.DECLINE}</Typography>
                  </div>
                </div>
              ) : estimationStatus === 'Approved' ? (
                <div className={classes.approve_fill_tag}>
                  <img
                    src={icons().true_icon_white}
                    className={classes.tag_icon}
                  />
                  <Typography variant='p_700'>{Strings.APPROVE}</Typography>
                </div>
              ) : (
                <div className={classes.decline_fill_tag}>
                  <img
                    src={icons().wrong_icon_white}
                    className={classes.tag_icon}
                  />
                  <Typography variant='p_700'>{Strings.DECLINE}</Typography>
                </div>
              )
            ) : null}
          </div>
        </div>
      )}
    </>
  ) : isVendor() ? (
    <>
      <IncidentNotesInput
        placeholder={Strings.DESCRIPTION}
        onChange={(e) => setEstimationDescription(e.target.value)}
        value={estimationDescription}
        onClickSend={() => {
          onPressCreateEstimation();
        }}
        total
        totalPlaceholder={'$'}
        totalValue={estimationTotal}
        totalOnChange={(e) => setEstimationTotal(e.target.value)}
        loader={props.createEstimationDataLoading}
        handleFileUpload={handleFileSelect}
        noteImage={estimationImage}
        fileLoader={props?.uploadFileLoading}
        removeSelectedFile={removeFile}
        disabled={
          props?.createEstimationDataLoading ||
          props?.updateEstimationDataLoading
        }
      />
    </>
  ) : (
    <Typography
      variant='h6_600'
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
      }}>
      {Strings.NO_DATA_FOUND}
    </Typography>
  );
}

const mapStateToProps = (state) => ({
  updateEstimationData: state.updateEstimationData.data,
  updateEstimationDataLoading: state.updateEstimationData.loading,

  createEstimationData: state.createEstimationData.data,
  createEstimationDataLoading: state.createEstimationData.loading,

  uploadFileLoading: state.uploadFileData.loading,
  deleteFileLoading: state.deleteFileData.loading,
});

export default connect(mapStateToProps, {
  updateEstimation,
  createEstimation,
  uploadFile,
  deleteFile,
})(EstimationTabContainer);
