import {
  Box,
  Button,
  FormLabel,
  InputAdornment,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { icons } from '../../assets';
import { Strings } from '../../common/strings';
import CustomCheckbox from '../CustomCheckbox';
import CustomeDatePicker from '../CustomDatePicker';
import CustomRadioButton from '../CustomRadioButtonGroup';
import CustomeTimePicker from '../CustomTimePicker';
import Loader from '../Loader';
import Selection from '../Selection';
import ToggleButton from '../ToggleButton';
import useStyles from './styles';
import { createDelivery } from '../../redux/actions/deliveryActions/addDelivery';
import { fetchDeliveryList } from '../../redux/actions/deliveryActions/deliveryList';
import { updateDelivery } from '../../redux/actions/deliveryActions/updateDelivery';
import { getErrorMessage, makeUniqueId, PAGE_SIZE } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { fetchClientList } from '../../redux/actions/rateSheetActions/clientList';
import { fetchInventoryListForDropdown } from '../../redux/actions/inventoryActions/getInventoryListForDropdown';
import { Colors } from '../../common/colors';
import { Form, FormikProvider, useFormik } from 'formik';
import { AddDeliverySchema } from '../../utils/validationSchemas';
import moment from 'moment-timezone';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { generatePullList } from '../../redux/actions/deliveryActions/generatePullList';
import { sendEmail } from '../../redux/actions/deliveryActions/sendEmail';
import PdfViewer from '../PdfViewer';
import DeliveryCalculationDialog from '../DeliveryCalculationDialog';
import SelectInventoryItemDialog from '../SelectInventoryItemDialog';
import DeliveryConfirmationDialog from '../DeliveryConfirmationDialog';
import { fetchFeeList } from '../../redux/actions/feeActions/feeList';
import { calculateAmount } from '../../redux/actions/deliveryActions/calculateAmount';
import { setGetDelivery } from '../../redux/actions/deliveryActions/getDelivery';

const AddDeliveriesModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [designerList, setDesignerList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [selectedDesigner, setSelectedDesigner] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPdfButton, setSelectedPdfButton] = useState('');
  const [pdfBlobUrl, setPdfBlobUrl] = useState('');
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [uniqJobId, setUniqJobId] = useState(makeUniqueId(5));
  const [showCalculationDialog, setShowCalculationDialog] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState({});
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [formData, setFormData] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [itemListed, setItemListed] = useState(false);
  const dispatch = useDispatch();

  const optionsArray = [
    { label: 'COD', value: 'cod' },
    { label: 'Billed', value: 'billed' },
    { label: 'Prepay', value: 'prepay' },
  ];

  useEffect(() => {
    props.fetchDesignerList((err) => onError(err));
  }, []);

  useEffect(() => {
    if (selectedDesigner && selectedDesigner !== '0') {
      props.fetchClientList(selectedDesigner, (err) => onError(err), false);
    }
  }, [selectedDesigner]);

  useEffect(() => {
    if (selectedClient && selectedDesigner) {
      props.fetchInventoryListForDropdown(
        selectedClient,
        selectedDesigner,
        (err) => onError(err)
      );
    }
  }, [selectedClient, selectedDesigner, props?.updateInventoryData]);

  useEffect(() => {
    const designerList = props?.designerListData?.map((e) => ({
      label: e.name,
      value: e._id,
    }));
    setDesignerList(designerList);
    const clientList = props?.clientListData?.map((e) => ({
      label: e.client,
      value: e._id,
    }));
    setClientList(clientList);
    const sortByWsiNumber = (data) => {
      let newSortData = structuredClone(data ?? []);
      newSortData = newSortData?.sort((a, b) => {
        if (a?.ID < b?.ID) return -1;
        if (a?.ID > b?.ID) return 1;
        return 0;
      });
      return newSortData ?? [];
    };
    const inventoryList = sortByWsiNumber(props?.inventoryListData)?.length
      ? sortByWsiNumber(props?.inventoryListData)?.map((e) => ({
          label: e.ID + ' - ' + e.description,
          value: e._id,
        }))
      : [];
    if (props?.edit) {
      const newData = sortByWsiNumber(props?.deliveryData?.item) || [];
      const newList =
        newData.length > 0
          ? newData?.map((e) => ({
              label: e.ID + ' - ' + e.description,
              value: e._id,
            }))
          : [];
      setInventoryList([...newList, ...inventoryList]);
      setItemListed(true);
      setFieldValue('item', newList || []);
    } else {
      setInventoryList(inventoryList);
      // if (
      //   !props?.inventoryListLoading &&
      //   selectedClient &&
      //   !props?.isShowDetails
      // ) {
      //   setFieldValue('item', inventoryList || []);
      // }
    }
  }, [
    props?.designerListData,
    props?.clientListData,
    props?.inventoryListData,
  ]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props.edit) {
      props.getDelivery(props.selectedDelivery, props.onError);
    }
  }, []);

  useEffect(() => {
    if (props.deliveryData && props.edit) {
      setDeliveryFee(props?.deliveryData?.deliveryFee || {});
      setFieldValue('ID', props?.deliveryData?.ID || '');
      setFieldValue('designer', props?.deliveryData?.designer || '');
      setSelectedDesigner(props?.deliveryData?.designer || '');
      setFieldValue('client', props?.deliveryData?.client || '');
      setSelectedClient(props?.deliveryData?.client || '');
      // setFieldValue('item', props?.deliveryData?.item || '0');
      setFieldValue('deliveredOnDate', props?.deliveryData?.deliveredOn || '');
      setFieldValue('deliveredOnTime', props?.deliveryData?.deliveredOn || '');
      setFieldValue('doNotBill', props?.deliveryData?.doNotBill || false);
      setFieldValue('clientPickUp', props?.deliveryData?.clientPickUp || false);
      setFieldValue('notes', props?.deliveryData?.notes || false);
      setFieldValue('notesDetail', props?.deliveryData?.notesDetail || '');
      setFieldValue('total', props?.deliveryData?.total || '');
      setFieldValue('invoicedOn', props?.deliveryData?.invoicedOn || '');
      setFieldValue('closedOn', props?.deliveryData?.closedOn || '');
      setFieldValue(
        'options',
        props?.deliveryData?.options || {
          cod: false,
          billed: false,
          prepay: false,
        }
      );
      setFieldValue(
        'designerNotes',
        props.deliveryData?.designerNotes || false
      );
      setUniqJobId(props.deliveryData?.jobId || makeUniqueId(5));
    }
  }, [props.deliveryData]);

  const handleDataSubmit = async (values) => {
    const data = {
      ...values,
      item: values?.item?.map((el) => el.value),
      invoicedOn: moment(values?.invoicedOn).format('YYYY-MM-DD'),
      notesDetail: values?.notesDetail !== '' ? values?.notesDetail : '',
      total: values?.total !== '0' ? Number(values?.total) : 0,
      deliveredOn: moment(
        moment(values?.deliveredOnDate).format('YYYY-MM-DD') +
          ' ' +
          moment(values?.deliveredOnTime).format('HH:mm:ss')
      ).format(),
      deliveryFee,
    };
    if (uniqJobId) data.jobId = uniqJobId;
    if (!data.closedOn) delete data?.closedOn;
    if (data.invoicedOn === 'Invalid date') delete data?.invoicedOn;
    delete data?.deliveredOnDate;
    delete data?.deliveredOnTime;
    try {
      setFormData(data);
      if (props?.edit) {
        props?.updateDelivery(
          props?.selectedDelivery,
          data,
          () => {
            props.onClose();
            resetForm();
            setInventoryList([]);
            setFormData({});
          },
          (err) => {
            if (err?.data?.askForPermission) setOpenConfirmationDialog(true);
            onError(err);
          }
        );
      } else {
        props.createDelivery(
          data,
          () => {
            props.onClose();
            resetForm();
            setInventoryList([]);
            setFormData({});
          },
          (err) => {
            if (err?.data?.askForPermission) setOpenConfirmationDialog(true);
            onError(err);
          }
        );
      }
    } catch (e) {
      // console.log('eee', e, values);
    }
  };

  const generatePdf = async (fileName) => {
    // setOpenPdfViewer(true);
    setSelectedPdfButton(fileName);
    const isOptionSelect = Object.keys(values?.options)?.some(
      (el) => values?.options[el] === true
    );

    let data = {};
    if (values?.ID) data['jobId'] = values?.ID;
    if (values.clientPickUp) data['clientPickUp'] = values.clientPickUp;
    if (values?.designer) data['designer'] = values?.designer;
    if (values?.client) data['client'] = values?.client;
    if (isOptionSelect) data['options'] = values?.options;
    if (values?.closedOn) data['closedOn'] = values?.closedOn;
    if (values?.item?.length > 0)
      data['item'] = values?.item?.map((el) => el.value);
    if (values?.invoicedOn)
      data['invoicedOn'] = moment(values?.invoicedOn).format();
    // if (values?.deliveredOnDate && values?.deliveredOnTime)
    data['deliveredOn'] = moment(
      `${moment(values?.deliveredOnDate || new Date()).format(
        'YYYY-MM-DD'
      )} ${moment(values?.deliveredOnTime || new Date()).format('hh:mm:ss a')}`
    ).format('MM/DD/YYYY   hh:mm a');
    if (fileName === 'JobSheet') {
      data['notes'] = values?.notes;
      data['notesDetail'] = values.notesDetail;
      data['jobSheet'] = true;
    }
    if (fileName === 'PullList') data['pullList'] = true;
    data?.options?._id && delete data?.options?._id;

    props.generatePullList(data, onError, fileName, true, async (data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      await setPdfBlobUrl(url);
    });
  };

  const emailDesigner = async () => {
    setSelectedPdfButton('Email');

    const isOptionSelect = Object.keys(values?.options)?.some(
      (el) => values?.options[el] === true
    );
    let data = { timeZone: moment.tz.guess(), jobId: values?.ID };
    if (values.clientPickUp) data['clientPickUp'] = values.clientPickUp;
    if (values?.designer) data['designer'] = values?.designer;
    if (values?.client) data['client'] = values?.client;
    if (isOptionSelect) data['options'] = values?.options;
    if (values?.closedOn) data['closedOn'] = values?.closedOn;
    if (values?.item?.length > 0)
      data['item'] = values?.item?.map((el) => el.value);
    if (values?.invoicedOn)
      data['invoicedOn'] = moment(values?.invoicedOn).format();
    // if (values?.deliveredOnDate && values?.deliveredOnTime)
    data['deliveredOn'] = moment(
      `${moment(values?.deliveredOnDate || new Date()).format(
        'YYYY-MM-DD'
      )} ${moment(values?.deliveredOnTime || new Date()).format('hh:mm:ss a')}`
    ).format('MM/DD/YYYY   hh:mm a');
    data['notes'] = values?.notes;
    data['notesDetail'] = values.notesDetail;
    data['jobSheet'] = true;
    data?.options?._id && delete data?.options?._id;

    props.sendEmail(
      data,
      () => {
        // props.onClose();
        // resetForm();
        // setInventoryList([]);
      },
      (err) => onError(err)
    );
    // handleDataSubmit(values);
  };

  useEffect(() => {
    if (props.generatePullListData) setSelectedPdfButton('');
  }, [props.generatePullListData]);

  let storedPickupFee = localStorage.getItem('feesListData') || '';
  let pickupFee =
    storedPickupFee !== 'undefined'
      ? JSON.parse(localStorage.getItem('feesListData') || '')
      : '';

  const formik = useFormik({
    initialValues: {
      ID: '',
      designer: '',
      client: '',
      item: [],
      deliveredOnDate: '',
      deliveredOnTime: '',
      doNotBill: false,
      clientPickUp: false,
      notes: false,
      notesDetail: '',
      total: pickupFee[0]?.pullFee || '',
      invoicedOn: '',
      closedOn: '',
      options: {
        cod: false,
        billed: false,
        prepay: false,
      },
      designerNotes: false,
    },
    validationSchema: AddDeliverySchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue, resetForm } =
    formik;
  const calculateDeliveryFees = () => {
    const deliveryData = Object.entries(deliveryFee)?.length
      ? deliveryFee
      : props?.deliveryData?.deliveryFee;
    const data = {
      ...deliveryData,
      noOfItem: values?.item?.length,
      clientPickUp: values?.clientPickUp,
    };
    setDeliveryFee(data);
    if ((!firstRender && props?.edit) || !props?.edit) {
      props.calculateAmount(data, onError, (data) => {
        setFieldValue('total', data?.feeData);
      });
    }
  };

  useEffect(() => {
    if (
      (!props?.edit && pickupFee?.length) ||
      (props?.edit && pickupFee?.length && itemListed)
    ) {
      const deliveryData = Object.entries(deliveryFee)?.length
        ? deliveryFee
        : props?.deliveryData?.deliveryFee;
      const deliveryFeesExist =
        deliveryData &&
        Object.keys(deliveryData)?.filter(
          (el) => el !== '_id' && el !== 'clientPickUp' && el !== 'noOfItem'
        );

      if (values?.item?.length && firstRender && props?.edit) {
        setFirstRender(false);
        setFieldValue('total', props?.deliveryData?.total);
      } else if (values?.item?.length && deliveryFeesExist?.length) {
        calculateDeliveryFees();
      } else if (values?.item?.length && !deliveryFeesExist?.length) {
        setFieldValue(
          'total',
          values?.clientPickUp
            ? pickupFee[0]?.pickupFee * values?.item?.length
            : pickupFee[0]?.pullFee * values?.item?.length
        );
      } else {
        setFieldValue(
          'total',
          values?.clientPickUp ? pickupFee[0]?.pickupFee : pickupFee[0]?.pullFee
        );
      }
    }
  }, [values?.clientPickUp, values?.item?.length]);

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => {
                  resetForm();
                  setInventoryList([]);
                  dispatch(setGetDelivery(null));
                  props?.onClose();
                }}
              />
            </div>
            <Typography variant='h5_700'>
              {props.isShowDetails
                ? Strings.DELIVERIES
                : props.edit
                ? Strings.EDIT_DELIVERY
                : Strings.NEW_DELIVERY}
            </Typography>
            {props.loader || props?.designerListLoading ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <>
                <Selection
                  isSearchable
                  containerClassName={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.selection_input
                  }
                  label={Strings.DESIGNER}
                  placeHolder={Strings.SELECT}
                  handleChange={(e, value) => {
                    if (value) {
                      setFieldValue('designer', value);
                      setSelectedDesigner(value);
                      setSelectedClient('');
                      setFieldValue('client', '');
                      setFieldValue('item', []);
                    }
                  }}
                  value={values.designer}
                  title={Strings.SELECT}
                  error={Boolean(touched.designer && errors.designer)}
                  options={designerList}
                  helperText={touched.designer && errors.designer}
                  disabled={props?.isShowDetails}
                  isShowDetails={props.isShowDetails}
                />
                <Selection
                  isSearchable
                  containerClassName={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.selection_input
                  }
                  label={Strings.CLIENT}
                  placeHolder={Strings.SELECT}
                  handleChange={(e, value) => {
                    if (value) {
                      setFieldValue('client', value);
                      setSelectedClient(value);
                      setFieldValue('item', []);
                    }
                  }}
                  error={Boolean(touched.client && errors.client)}
                  value={values.client}
                  title={Strings.SELECT}
                  options={clientList}
                  helperText={touched.client && errors.client}
                  disabled={
                    props?.clientListLoading ||
                    !selectedDesigner ||
                    props?.isShowDetails
                  }
                  isShowDetails={props.isShowDetails}
                />

                <Stack marginTop={2}>
                  <Typography
                    className={classNames(classes.label_text, {
                      [classes.disabled_label_text]:
                        props?.inventoryListLoading || !selectedClient,
                    })}
                  >
                    {Strings.ITEM}
                    {values?.item?.length !== 0
                      ? ` (${values?.item?.length})`
                      : null}
                  </Typography>
                  <Stack
                    direction='row'
                    gap={1}
                    className={classNames(classes.item_container, {
                      [classes.disabledItemContainer]:
                        props?.inventoryListLoading || !selectedClient,
                      [classes.errorBorder]: Boolean(
                        touched.item && errors.item
                      ),
                    })}
                    onClick={() => {
                      !props?.inventoryListLoading &&
                        selectedClient &&
                        !props?.isShowDetails &&
                        setOpenItemDialog(true);
                    }}
                  >
                    {values.item?.length > 0 ? (
                      <>
                        {values.item?.slice(0, 2)?.map((el, index) => (
                          <Box className={classes.tags} key={index}>
                            {el?.label}
                          </Box>
                        ))}
                        {values.item?.length > 2 && (
                          <Typography
                            variant='p_400'
                            color={Colors.FELDGRAU}
                            style={{ cursor: 'pointer' }}
                          >
                            {Strings.SHOW_MORE}...
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography className={classes.placeHolderText}>
                        {Strings.SELECT}
                      </Typography>
                    )}
                  </Stack>
                  {Boolean(touched.item && errors.item) ? (
                    <FormLabel className={classes.errorText}>
                      {errors.item}
                    </FormLabel>
                  ) : null}
                </Stack>
                {/* <MultiSelectDropdown
                  containerClassName={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.selection_input
                  }
                  name='item'
                  tagsLimit={2}
                  label={Strings.ITEM}
                  value={values.item}
                  onChange={(val) => setFieldValue('item', val)}
                  placeholder={Strings.SELECT}
                  optionArray={inventoryList}
                  error={Boolean(touched.item && errors.item)}
                  helperText={touched.item && errors.item}
                  disabled={
                    props?.inventoryListLoading ||
                    !selectedClient ||
                    props?.isShowDetails
                  }
                  isShowDetails={props?.isShowDetails}
                  onShowMoreClick={() => console.log('Hello')}
                /> */}

                <div className={classes.flex_row}>
                  <CustomeDatePicker
                    className={
                      props?.isShowDetails
                        ? classes.disabled_input
                        : classes.input
                    }
                    label={Strings.DELIVERED_ON}
                    value={values.deliveredOnDate}
                    onChange={(value) => {
                      setFieldValue('deliveredOnDate', value);
                    }}
                    error={Boolean(
                      touched.deliveredOnDate && errors.deliveredOnDate
                    )}
                    helperText={
                      touched.deliveredOnDate && errors.deliveredOnDate
                    }
                    placeholder={Strings.SELECT}
                    disabled={props?.isShowDetails}
                    // disablePast
                  />

                  <CustomeTimePicker
                    className={
                      props?.isShowDetails
                        ? classes.disabled_input
                        : classes.input
                    }
                    label={' '}
                    value={values.deliveredOnTime}
                    onChange={(value) => {
                      setFieldValue('deliveredOnTime', value);
                    }}
                    error={Boolean(
                      touched.deliveredOnTime && errors.deliveredOnTime
                    )}
                    helperText={
                      touched.deliveredOnTime && errors.deliveredOnTime
                    }
                    placeholder={Strings.SELECT}
                    disabled={props?.isShowDetails}
                  />
                </div>

                <div className={classes.top_margin}>
                  <div className={classes.doNotBill_container}>
                    <Typography
                      variant='h6_700'
                      className={classNames(classes.sub_title, classes.flex)}
                    >
                      {Strings.DO_NOT_BILL}
                    </Typography>
                    <ToggleButton
                      className={classes.toggle_btn}
                      checked={values?.doNotBill}
                      onChange={(e) => {
                        setFieldValue('doNotBill', e.target.checked);
                      }}
                      disabled={props?.isShowDetails}
                    />
                  </div>
                  <div className={classes.doNotBill_container}>
                    <Typography
                      variant='h6_700'
                      className={classNames(classes.sub_title, classes.flex)}
                    >
                      {Strings.CLIENT_PICK_UP}
                    </Typography>
                    <ToggleButton
                      className={classes.toggle_btn}
                      checked={values?.clientPickUp}
                      onChange={(e) =>
                        setFieldValue('clientPickUp', e.target.checked)
                      }
                      disabled={props?.isShowDetails}
                    />
                  </div>
                  <div className={classes.doNotBill_container}>
                    <Typography
                      variant='h6_700'
                      className={classNames(classes.sub_title, classes.flex)}
                    >
                      {Strings.NOTES}
                    </Typography>
                    <ToggleButton
                      className={classes.toggle_btn}
                      checked={values?.notes}
                      onChange={(e) => setFieldValue('notes', e.target.checked)}
                      disabled={props?.isShowDetails}
                    />
                  </div>
                  {values.notes && (
                    <textarea
                      className={classNames(
                        classes.textbox,
                        props.inputClass && props.inputClass
                      )}
                      rows={'3'}
                      value={values?.notesDetail}
                      onChange={(e) =>
                        setFieldValue('notesDetail', e.target.value)
                      }
                      placeholder={Strings.TYPE_YOUR_NOTE_HERE}
                      autoFocus={props.autoFocus}
                      disabled={props?.isShowDetails}
                    />
                  )}
                </div>

                <div className={classes.flex_row}>
                  <TextField
                    // required
                    label={Strings.TOTAL}
                    className={
                      props?.isShowDetails
                        ? classes.disabled_input
                        : classes.input
                    }
                    id='total'
                    type='number'
                    placeholder={Strings.TOTAL}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // className={classNames(
                    //   classes.flex_3,
                    //   classes.button_input
                    // )}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position='start'
                          style={{
                            width: 20,
                            height: 20,
                            cursor:
                              Object.values(values?.options).some((el) => el) &&
                              'pointer',
                          }}
                        >
                          $
                        </InputAdornment>
                      ),
                      value: values?.total,
                    }}
                    onChange={(e) => setFieldValue('total', e.target.value)}
                    error={Boolean(touched.total && errors.total)}
                    helperText={touched.total && errors.total}
                    disabled={true}
                    // props?.isShowDetails ||
                    // Object.values(values?.options)?.some(
                    //   (key) => key === true
                    // ) ||
                    // values?.clientPickUp === true
                    // }
                    onClick={(e) => {
                      const isDisabled =
                        Object.values(values?.options)?.some(
                          (key) => key === true
                        ) || values?.clientPickUp === true;
                      if (
                        isDisabled &&
                        !props?.isShowDetails &&
                        values?.item.length
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowCalculationDialog(true);
                      }
                    }}
                  />
                  <CustomeDatePicker
                    className={
                      props?.isShowDetails
                        ? classes.disabled_input
                        : classes.input
                    }
                    label={Strings.INVOICED_ON}
                    value={values.invoicedOn}
                    onChange={(value) => {
                      setFieldValue('invoicedOn', moment(value).format());
                    }}
                    error={Boolean(touched.invoicedOn && errors.invoicedOn)}
                    helperText={touched.invoicedOn && errors.invoicedOn}
                    placeholder={Strings.SELECT}
                    disabled={props?.isShowDetails}
                    // disablePast
                  />
                </div>

                <CustomeDatePicker
                  className={
                    props?.isShowDetails
                      ? classes.disabled_input
                      : classes.input
                  }
                  label={Strings.CLOSED_ON}
                  value={values.closedOn}
                  onChange={(value) => {
                    setFieldValue('closedOn', moment(value).format());
                  }}
                  error={Boolean(touched.closedOn && errors.closedOn)}
                  helperText={touched.closedOn && errors.closedOn}
                  placeholder={Strings.SELECT}
                  disabled={props?.isShowDetails}
                  // disablePast
                />

                <div
                  className={classNames(
                    classes.flex_column,
                    classes.top_margin
                  )}
                >
                  <Typography variant='p_400' className={classes.sub_heading}>
                    {Strings.OPTIONS}
                  </Typography>
                  <div className={classes.radio_button_container}>
                    <RadioGroup
                      name=''
                      onChange={(e) => {
                        let option;
                        Object.entries(values.options).forEach(
                          ([key, value]) => {
                            option = {
                              ...option,
                              [key]:
                                key !== '_id' ? key === e.target.value : value,
                            };
                          }
                        );
                        setFieldValue('options', option);
                      }}
                      value={
                        values?.options &&
                        Object.entries(values.options).find(
                          ([key, value]) => value === true
                        )
                          ? Object.entries(values.options).find(
                              ([key, value]) => value === true
                            )[0]
                          : '0'
                      }
                      className={classes.flex_row}
                    >
                      {optionsArray?.map((el, index) => (
                        <div className={classes.flex} key={index}>
                          <CustomRadioButton
                            label={el.label}
                            value={el.value}
                            disabled={props?.isShowDetails}
                          />
                        </div>
                      ))}
                    </RadioGroup>
                    {(touched?.options?.cod ||
                      touched?.options?.prepay ||
                      touched?.options?.billed) && (
                      <label className={classes.optionErrorText}>
                        {errors?.options?.cod ||
                          errors?.options?.prepay ||
                          errors?.options?.billed}
                      </label>
                    )}
                  </div>
                </div>

                <div className={classes.top_margin}>
                  <CustomCheckbox
                    container={classes.checkbox_container}
                    label={Strings.DESIGNER_NOTES}
                    checked={values?.designerNotes}
                    onChange={(e) =>
                      setFieldValue('designerNotes', e.target.checked)
                    }
                    disabled={props?.isShowDetails}
                  />
                </div>

                {values?.client && !props?.isShowDetails && (
                  <div className={classes.flex_row}>
                    <Button
                      type='button'
                      className={classes.outlined_button}
                      variant='outlined'
                      onClick={() => generatePdf('JobSheet')}
                      disabled={props.generatePullListLoading}
                    >
                      {props.generatePullListLoading &&
                      selectedPdfButton === 'JobSheet' ? (
                        <Loader color={Colors.CHINESE_SILVER} />
                      ) : (
                        Strings.JOB_SHEET.toUpperCase()
                      )}
                    </Button>
                    <Button
                      type='button'
                      className={classes.outlined_button}
                      variant='outlined'
                      onClick={() => generatePdf('PullList')}
                      disabled={props.generatePullListLoading}
                    >
                      {props.generatePullListLoading &&
                      selectedPdfButton === 'PullList' ? (
                        <Loader color={Colors.CHINESE_SILVER} />
                      ) : (
                        Strings.PULL_LIST.toUpperCase()
                      )}
                    </Button>
                    <Button
                      type='button'
                      className={classes.outlined_button}
                      variant='outlined'
                      onClick={() => emailDesigner()}
                      disabled={props.sendEmailLoading}
                    >
                      {props.sendEmailLoading &&
                      selectedPdfButton === 'Email' ? (
                        <Loader color={Colors.CHINESE_SILVER} />
                      ) : (
                        Strings.EMAIL.toUpperCase()
                      )}
                    </Button>
                    {/* <Button
                      type='button'
                      className={classes.outlined_button}
                      variant='outlined'
                    >
                      {Strings.EMAIL_FAX.toUpperCase()}
                    </Button> */}
                  </div>
                )}

                {!props?.isShowDetails && (
                  <Button
                    type='submit'
                    className={classes.add_delivery_button}
                    variant='contained-radius'
                    disabled={
                      props.edit
                        ? props.updateDeliveryLoading
                        : props.addDeliveryLoading
                    }
                  >
                    {(!props.edit && props.addDeliveryLoading) ||
                    (props.edit && props.updateDeliveryLoading) ? (
                      <Loader />
                    ) : props.edit ? (
                      Strings.UPDATE_DELIVERY.toUpperCase()
                    ) : (
                      Strings.ADD_DELIVERY.toUpperCase()
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
      {/* {openPdfViewer && (
        <PdfViewer
          onClose={() => {
            setOpenPdfViewer(false);
            setPdfBlobUrl('');
          }}
          isLoading={props.generatePullListLoading}
          dataUrl={pdfBlobUrl}
        />
      )} */}
      {showCalculationDialog && (
        <DeliveryCalculationDialog
          onClose={() => setShowCalculationDialog(false)}
          setTotalValue={(value) => setFieldValue('total', value)}
          onSave={(data) => setDeliveryFee(data)}
          noOfItem={values.item?.length || 0}
          data={deliveryFee}
          clientPickUp={values?.clientPickUp}
        />
      )}
      {openItemDialog && (
        <SelectInventoryItemDialog
          itemList={inventoryList || []}
          selectedItems={values?.item || []}
          setSelectedItems={(val) => setFieldValue('item', val)}
          onClose={() => setOpenItemDialog(false)}
        />
      )}
      {openConfirmationDialog && (
        <DeliveryConfirmationDialog
          formData={formData}
          edit={props?.edit}
          selectedDelivery={props?.selectedDelivery}
          onCloseParentForm={() => props?.onClose?.()}
          onClose={() => {
            setOpenConfirmationDialog(false);
            setFormData({});
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  addDeliveryData: state.addDeliveryData.data,
  addDeliveryLoading: state.addDeliveryData.loading,

  updateDeliveryData: state.updateDeliveryData.data,
  updateDeliveryLoading: state.updateDeliveryData.loading,

  designerListData: state.designerListData.data,
  designerListLoading: state.designerListData.loading,

  clientListData: state.clientListData.data,
  clientListLoading: state.clientListData.loading,

  inventoryListData: state.inventoryListDataForDropdown.data,
  inventoryListLoading: state.inventoryListDataForDropdown.loading,

  generatePullListData: state.generatePullListData.data,
  generatePullListLoading: state.generatePullListData.loading,

  sendEmailData: state.sendEmailData.data,
  sendEmailLoading: state.sendEmailData.loading,

  updateInventoryData: state.updateInventoryData.data,
});

export default connect(mapStateToProps, {
  createDelivery,
  updateDelivery,
  fetchDeliveryList,
  fetchDesignerList,
  fetchClientList,
  fetchInventoryListForDropdown,
  generatePullList,
  sendEmail,
  calculateAmount,
})(AddDeliveriesModal);
