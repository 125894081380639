import React, { useState } from 'react';
import { Colors } from '../../common/colors';
import useStyles from './styles';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ToggleButton(props) {
  const classes = useStyles();

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName='.Mui-focusVisible'
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 39,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: Colors.WHITE,
        '& + .MuiSwitch-track': {
          backgroundColor: Colors.MEDIUM_SEA_GREEN,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.8,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: Colors.MEDIUM_SEA_GREEN,
        border: `6px solid ${Colors.WHITE}`,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
      color: Colors.WHITE,
    },
    '& .MuiSwitch-track': {
      borderRadius: 24 / 2,
      backgroundColor: Colors.CHINESE_SILVER,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    // <img
    //   src={enable ? icons().toggle_enable : icons().toggle_disable}
    //   onClick={() => setEnable(!enable)}
    //   className={classNames(props.className, classes.toggle)}
    // />
    <div className={props.className}>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
            onClick={props.onClick}
          />
        }
      />
    </div>
  );
}
