import axios from 'axios';
import { DownloadCSVorPDF } from '../../../utils/appUtils';
import { GENERATE_REPORT_PDF } from '../../constants';

export const generateReportPdf = (data, onError, fileName, isShowPdf, onCallBack) => (dispatch) => {
  const token = localStorage.jwtToken;
  let param = '';
  for (let i in data) {
    param += `${i}=${data[i]}&`;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  };
  dispatch(loadGenerateReportPdf());
  axios
    .post(`/admin/generateReportPdf?${param}`, {}, config)
    .then((res) => {
      !isShowPdf && DownloadCSVorPDF(res?.data, `${fileName}.pdf`);
      dispatch(setGenerateReportPdf(res?.data));
      onCallBack(res.data);
    })
    .catch((err) => {
      dispatch(errorGenerateReportPdf(err.response));
      onError(err.response);
    });
};

export const loadGenerateReportPdf = () => ({
  type: GENERATE_REPORT_PDF.LOAD,
});

export const setGenerateReportPdf = (data) => ({
  type: GENERATE_REPORT_PDF.SUCCESS,
  payload: data,
});

export const errorGenerateReportPdf = (error) => ({
  type: GENERATE_REPORT_PDF.FAIL,
  payload: error,
});
