import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    width: 'calc(100% - 650px)',
    padding: 16,
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'column',
  },
}));
