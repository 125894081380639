import axios from 'axios';
import { DELIVERY_LIST } from '../../constants';

export const fetchDeliveryList = (data, onError, isUpdate) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  if (isUpdate !== 'update') dispatch(loadDeliveryList());

  let queryStr = Object.entries(data)
    ?.map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    ?.join('&');

  axios
    .get(`/delivery/listDeliveries?${queryStr}`, config)
    .then((res) => dispatch(setDeliveryList(res)))
    .catch((err) => {
      dispatch(errorDeliveryList(err.response));
      onError(err.response);
    });
};

export const loadDeliveryList = () => ({
  type: DELIVERY_LIST.LOAD,
});

export const setDeliveryList = (incidentList) => ({
  type: DELIVERY_LIST.SUCCESS,
  payload: incidentList.data.data,
});

export const errorDeliveryList = (error) => ({
  type: DELIVERY_LIST.FAIL,
  payload: error,
});
