import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import useStyles from './styles';
import Sidebar from '../../helpers/Sidebar';
import NavBar from '../../helpers/Navbar';

const MainLayout = () => {
  const classes = useStyles();
  const [navbarButtonArray, setNavbarButtonArray] = useState([]);
  const [designerList, setDesignerList] = useState([]);

  return (
    <div className={classes.layout_conainer}>
      <Sidebar />
      <div className={classes.main_layout}>
        <NavBar
          navbarButtonArray={navbarButtonArray}
          designerList={designerList}
        />
        <Outlet context={[setNavbarButtonArray, setDesignerList]} />
      </div>
    </div>
  );
};

export default MainLayout;
