import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  right_modal: {
    position: 'absolute',
    height: '100%',
    width: 650,
    backgroundColor: Colors.WHITE,
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    top: 0,
    right: 0,
    paddingLeft: 30,
    paddingRight: 50,
    paddingTop: 60,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    // justifyContent: 'center'
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: 50,
  },
  editIcon: {
    height: 26,
    width: 26,
    marginLeft: 15,
  },
  profileContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
  },
  profile: {
    height: '104px !important',
    width: '104px !important',
    marginTop: '10px !important',
    border: `2px solid ${Colors.CHINESE_SILVER} !important`,
    fontSize: '60px !important',
    lineHeight: '1px !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '400 !important',
    marginRight: '20px !important',
  },
  input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  eye_icon: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginRight: 12,
  },
  save_button: {
    width: '143px !important',
    height: '48px !important',
    marginRight: '16px !important',
    marginTop: '15px !important',
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  editProfileContainer: {
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: Colors.CHINESE_SILVER,
    position: 'absolute',
    left: 100,
    top: 230,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  editProfile: {
    height: 20,
    width: 20,
  },
  imageLoad: {
    height: '104px !important',
    width: '104px !important',
    marginTop: '10px !important',
    border: `2px solid ${Colors.CHINESE_SILVER} !important`,
    marginRight: '20px !important',
    justifyContent: 'center',
    display: 'flex',
    alignItems:'center',
    borderRadius:'54px'
  },
}));
