import * as React from 'react';
import moment from 'moment';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { icons } from '../../assets';
import useStyles from './styles';
import classNames from 'classnames';

export default function CustomeDatePicker(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(moment('2022-04-07'));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        value={props?.value}
        onChange={(newValue) => {
          props.onChange(newValue);
        }}
        disabled={props.disabled}
        inputFormat={props.format || 'MM/DD/YYYY'}
        className={classNames(props.className, classes.date_picker_root)}
        disablePast={props?.disablePast}
        renderInput={(params) => (
          <TextField
            id='filled-required'
            variant='standard'
            {...params}
            error={props.error}
            label={props.label}
            placeholder={props.placeholder}
            helperText={props.helperText}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <img
                  src={icons().calendar}
                  alt=''
                  className={classes.date_picker_icon}
                />
              ),
            }}
          />
        )}
      />
      {/* <DesktopDatePicker
          label="For desktop"
          value={value}
          minDate={dayjs('2017-01-01')}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          disableFuture
          label="Responsive"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        /> */}
    </LocalizationProvider>
  );
}
