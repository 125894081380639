import { CHANGE_PASSWORD } from '../../constants';

const initialState = {
  data: {},
  loading: false,
  error: {},
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
    case CHANGE_PASSWORD.LOAD:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case CHANGE_PASSWORD.FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
