import axios from 'axios';
import { GET_VENDOR_USER_LIST } from '../../constants';

export const fetchVendorUserList = (onError, isUpdate) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  if (isUpdate !== 'update') {
    dispatch(loadVendorUserList());
  }
  axios
    .get(`/vendor/getVendorList`, config)
    .then((res) => {
      dispatch(setVendorUserList(res));
    })
    .catch((err) => {
      dispatch(errorVendorUserList(err.response));
      onError(err.response);
    });
};

export const loadVendorUserList = () => ({
  type: GET_VENDOR_USER_LIST.LOAD,
});

export const setVendorUserList = (userList) => ({
  type: GET_VENDOR_USER_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorVendorUserList = (error) => ({
  type: GET_VENDOR_USER_LIST.FAIL,
  payload: error,
});
