import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Colors } from '../../common/colors';
import CustomCheckbox from '../CustomCheckbox';
import useStyles from './styles';
import { icons } from '../../assets';
import classNames from 'classnames';

export default function TableHeader(props) {
  const classes = useStyles();

  const {
    onSelectAllClick,
    headCells,
    selectedAllRow,
    selectedSomeRow,
    order,
    orderBy,
    onSortRequest,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            style={{
              border: 'none',
              padding: headCell.checkbox ? '7px 0px 7px 16px' : '7px 16px',
            }}
            width={headCell.width}
          >
            {headCell.checkbox && (
              <CustomCheckbox
                container={classes.checkbox_container}
                indeterminate={selectedSomeRow}
                checked={selectedAllRow}
                // label={Strings.REMEMBER_ME}
                onChange={onSelectAllClick}
              />
            )}
            {headCell.label && (
              <div
                className={classes.label_container}
                style={{ cursor: headCell?.isSort && 'pointer' }}
                onClick={(e) =>
                  headCell?.isSort && onSortRequest(e, headCell.name)
                }
              >
                <Typography variant='p_700' color={Colors.FELDGRAU}>
                  {headCell.label}
                </Typography>
                {headCell?.isSort && orderBy === headCell?.name && (
                  <img
                    src={icons().sort_arrow_icon}
                    alt=''
                    className={classNames(
                      classes.sort_icon,
                      order === 'desc' && classes.sort_down,
                    )}
                  />
                )}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
