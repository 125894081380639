import axios from 'axios';
import { UPDATE_INVENTORY } from '../../constants';

export const updateInventory = (ID, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddInventory());

  axios
    .put(`/inventory/updateInventory?inventoryId=${ID}`, data, config)
    .then((res) => {
      dispatch(setUpdateInventory(res));
      onSuccess(res.data.data);
    })
    .catch((err) => {
      dispatch(errorUpdateInventory(err.response));
      onError(err.response);
    });
};

export const loadAddInventory = () => ({
  type: UPDATE_INVENTORY.LOAD,
});

export const setUpdateInventory = (data) => ({
  type: UPDATE_INVENTORY.SUCCESS,
  payload: data.data,
});

export const errorUpdateInventory = (error) => ({
  type: UPDATE_INVENTORY.FAIL,
  payload: error,
});
