import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    backgroundColor: 'transparent',
    padding: 16,
  },
  container: {
    height: 'inherit',
    backgroundColor: Colors.RAISIN_BLACK,
    borderRadius: 16,
    paddingTop: 30,
    paddingBottom: 20,
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    flex: 0.07,
  },
  logo: {
    height: 42,
    width: 33,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 10,
    overflow: 'auto',
    flex: 0.8,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    flexDirection: 'column',
    textDecoration: 'none',
  },
  navItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icons: {
    height: 24,
    width: 24,
    marginTop: 12.5,
    marginBottom: 12.5,
    cursor: 'pointer',
    // width: '100%',
  },
  divider: {
    position: 'absolute',
    borderRight: `3px solid ${Colors.POWDER_BLUE}`,
    height: 24,
    right: 1,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 0.13,
  },
  profile: {
    height: '50px !important',
    width: '50px !important',
    marginTop: '10px !important',
    border: `2px solid ${Colors.CHINESE_SILVER} !important`,
    fontSize: '20px !important',
    lineHeight: '1px !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '400 !important',
  },
  menu_paper: {
    borderRadius: '8px !important',
    gap: 8,
    background: `${Colors.WHITE} !important`,
    boxShadow: `${Colors.MENU_SHADOW} !important`,
    width: '200px !important',
  },
  menu_item: {
    fontSize: '16px !important',
    lineHeight: '140% !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '400 !important',
    justifyContent: 'center !important',
    color: `${Colors.RAISIN_BLACK} !important`,
  },
}));
