import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    zIndex: 1,
    top: 0,
    right: 0,
    justifyContent: 'flex-end',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 872,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 40px 25px 30px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  selection_input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  form_container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 40,
  },
  form_left_container: {
    width: '35%',
    paddingRight: 50,
  },
  form_right_container: {
    width: '65%',
  },
  form_right_field_content: {
    borderLeft: `1px solid ${Colors.CHINESE_SILVER}`,
    paddingLeft: 50,
  },
  form_right_button_content: {
    paddingLeft: 0,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },
  sendEmail_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  flex_1: {
    flex: 1,
  },
  sub_title: {
    color: Colors.FELDGRAU,
  },
  toggle_btn: {
    // height: '24px !important',
    // width: '39px !important',
    // marginTop: '10 !important'
    // display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important',
      '& .MuiSwitch-root': {
        marginRight: '0px !important',
      },
    },
  },
  button_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25,
    gap: 15,
    width: '100%',
  },
  generate_pdf_button: {
    width: '40% !important',
  },
  outlined_button: {
    maxWidth: '40% !important',
    borderRadius: '50px !important',
  },
  radio_button_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    gap: 18,
  },
}));
