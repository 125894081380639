import axios from 'axios';
import { DELETE_INCIDENT } from '../../constants';

export const deleteIncident =
  (incidentId, setDeleteDialog, onError, onSuccess) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteIncident());
    axios
      .delete(`/incident/deleteIncident?incidentId=${incidentId}`, config)
      .then((res) => {
        dispatch(setDeleteIncident(res));
        setDeleteDialog(false);
        onSuccess()
      })
      .catch((err) => {
        dispatch(errorDeleteIncident(err.response));
        onError(err.response);
      });
  };

export const loadDeleteIncident = () => ({
  type: DELETE_INCIDENT.LOAD,
});

export const setDeleteIncident = (data) => ({
  type: DELETE_INCIDENT.SUCCESS,
  payload: data,
});

export const errorDeleteIncident = (error) => ({
  type: DELETE_INCIDENT.FAIL,
  payload: error,
});
