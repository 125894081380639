import { DELETE_LOCATION } from '../../constants';
import axios from 'axios';

export const deleteLocation = (locId, onError, onDialogClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteLocation());
  axios
    .delete(`/location/deleteLocation?locationId=${locId}`, config)
    .then((res) => {
      dispatch(setDeleteLocation(res.data));
      onDialogClose();
    })
    .catch((err) => {
      dispatch(failDeleteLocation(err.response));
      onError(err.response);
    });
};

export const loadDeleteLocation = () => ({
  type: DELETE_LOCATION.LOAD,
});

export const setDeleteLocation = (data) => ({
  type: DELETE_LOCATION.SUCCESS,
  payload: data,
});

export const failDeleteLocation = (err) => ({
  type: DELETE_LOCATION.FAIL,
  payload: err,
});
