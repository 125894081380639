import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Tabs,
  Tab,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import UserDetailsModal from '../../components/UserDetailsModal';
import { fetchUserList } from '../../redux/actions/adminActions/userList';
import { deleteUser } from '../../redux/actions/adminActions/deleteUser';
import { getUser } from '../../redux/actions/adminActions/getUser';
import { fetchFeeList } from '../../redux/actions/feeActions/feeList';
// import { addNote } from '../../redux/actions/adminActions/addNote';
// import { deleteNote } from '../../redux/actions/adminActions/deleteNote';
// import { updateNote } from '../../redux/actions/adminActions/updateNote';
import { connect } from 'react-redux';
import { getErrorMessage, PAGE_SIZE, userTypeList } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import Dialog from '../../components/Dialog';
import AddUserModal from '../../components/AddUserModal';
import debounce from 'lodash/debounce';

const positionBgColor = {
  designer: Colors.POWDER_BLUE,
  employee: Colors.PALE_SPRING_BUD,
  admin: Colors.PALE_LAVENDER,
};

const Users = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const totalRecords = props.userListData?.totalRecords || 0;
  const [selectedItem, setSelectedItem] = useState('all');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState('active');

  const handleChangePagination = async (e, p) => {
    await setPage(p);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    setPage(1);
  };

  useEffect(() => {
    setUsers(props.userListData?.users);
  }, [props.userListData?.users]);

  const delayedSearch = useCallback(
    debounce((q) => fetchUsers(q, selectedTab), 600),
    [selectedTab, selectedItem]
  );

  const fetchUsers = (searchStr, activeTab) => {
    const data = {
      searchString: searchStr,
      type: selectedItem === 'all' ? '' : selectedItem,
      page_size: PAGE_SIZE,
      page_number: page,
      status: activeTab || selectedTab,
    };
    props.fetchUserList(data, (err) => onError(err));
  };

  useEffect(() => {
    fetchUsers(search);
  }, [
    selectedItem,
    page,
    selectedTab,
    props.deleteUserData,
    props.addUserData,
    props.updateUserData,
  ]);

  useEffect(() => {
    const data = props.feeListData?.fees;
    localStorage.setItem('feesListData', JSON.stringify(data));
  }, [props.feeListData.fees]);

  useEffect(() => {
    props.fetchFeeList(PAGE_SIZE, 1, onError);
  }, [ props.addFeeData, props.updateFeeData]);

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedUser(item);
    await setShowEditUserModal(true);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedUser(item);
    await setShowDeleteUserDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteUser(selectedUser._id, setShowDeleteUserDialog, onError);
  };

  const onClickTableRow = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedUser(item);
    await setShowUserDetailsModal(true);
  };

  // const updateUserDataById = (values) => {
  //   const userIndex = users.findIndex((obj) => obj._id === selectedUser?._id);
  //   let newUser = [...users];
  //   let tempUser = { ...users[userIndex] };
  //   if (values.name !== users[userIndex].name) tempUser.name = values.name;
  //   if (values.email !== users[userIndex].email) tempUser.email = values.email;
  //   if (values.type !== users[userIndex].type) tempUser.type = values.type;
  //   newUser[userIndex] = tempUser;
  //   setUsers(newUser);
  // };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        isSelect
        searchPlaceholder={Strings.SEARCH_FOR_AN_USER}
        selectedItem={selectedItem}
        selectOption={[
          { label: 'All user types', value: 'all' },
          ...userTypeList,
        ]}
        handleSelectChange={(e) => {
          setPage(1);
          setSelectedItem(e.target.value);
        }}
        searchValue={search}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      />
      <div className={classes.tableContainer}>
        <div className={classes.tab_container}>
          <Tabs
            value={selectedTab}
            style={{ minHeight: 38, height: 38 }}
            onChange={handleTabChange}
            className={classes.tabs}
            // variant='scrollable'
            scrollButtons={false}
            allowScrollButtonsMobile
            aria-label='scrollable force tabs example'
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={Strings.ACTIVE}
              value='active'
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={Strings.INACTIVE}
              value='inActive'
            />
          </Tabs>
        </div>
        <div className={classes.table}>
          {props.userListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords && totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableBody>
                      {users?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            onClick={(e) => onClickEdit(e, row)}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell component='th' width='32.5%'>
                              {
                                <div className={classes.row_flex}>
                                  <Avatar
                                    sx={{ bgcolor: Colors.RAISIN_BLACK }}
                                    classes={{ root: classes.profile }}
                                    src={row.profilePicture}
                                  >
                                    {row.name?.charAt(0).toUpperCase()}
                                  </Avatar>
                                  <Typography variant='p_700'>
                                    {row.name}
                                  </Typography>
                                </div>
                              }
                            </TableCell>
                            <TableCell width='32.5%'>
                              {
                                <Typography variant='p_400'>
                                  {row.email}
                                </Typography>
                              }
                            </TableCell>
                            <TableCell width='20%'>
                              {
                                <div
                                  className={classes.position}
                                  style={{
                                    backgroundColor: positionBgColor[row.type],
                                    cursor:
                                      row.type === 'employee' && 'default',
                                  }}
                                >
                                  <Typography variant='p_700'>
                                    {row.type?.charAt(0).toUpperCase() +
                                      row.type?.slice(1)}
                                  </Typography>
                                </div>
                              }
                            </TableCell>
                            <TableCell width='15%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().eye_feldgrau}
                                    className={classes.icons}
                                    onClick={(e) => onClickTableRow(e, row)}
                                  />
                                  <img
                                    src={icons().edit}
                                    className={classes.icons}
                                    onClick={(e) => onClickEdit(e, row)}
                                  />
                                  <img
                                    src={icons().delete}
                                    className={classes.icons}
                                    onClick={(e) => onClickDelete(e, row)}
                                  />
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
      {showUserDetailsModal && (
        <UserDetailsModal
          onClose={() => setShowUserDetailsModal(false)}
          onClickDelete={() => setShowDeleteUserDialog(true)}
          loader={props.getUserDataLoading}
          getUser={props.getUser}
          selectedUser={selectedUser?._id}
          onError={onError}
          userData={props?.getUserData}
          // addNoteData={props?.addNoteData}
          // addNoteDataLoading={props?.addNoteDataLoading}
          // addNote={props.addNote}
          onClickEdit={async () => {
            await setShowUserDetailsModal(false);
            await setShowEditUserModal(true);
          }}
          deleteNote={props?.deleteNote}
          deleteNoteData={props?.deleteNoteData}
          deleteNoteDataLoading={props?.deleteNoteDataLoading}
          // updateNote={props?.updateNote}
          // updateNoteData={props?.updateNoteData}
          // updateNoteDataLoading={props?.updateNoteDataLoading}
        />
      )}
      {showDeleteUserDialog && (
        <Dialog
          title={Strings.DELETE_USER}
          message={Strings.ARE_YOU_SURE}
          onClose={() => setShowDeleteUserDialog(false)}
          loading={props.deleteUserDataLoading}
          onClickYesButton={onClickYesButton}
        />
      )}
      {showEditUserModal && (
        <AddUserModal
          onClose={() => setShowEditUserModal(false)}
          edit
          selectedUser={selectedUser?._id}
          getUser={props.getUser}
          userData={props?.getUserData}
          loader={props.getUserDataLoading}
          // updateUserDataById={updateUserDataById}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userListData: state.userListData.data,
  userListLoading: state.userListData.loading,
  deleteUserDataLoading: state.deleteUserData.loading,
  deleteUserData: state.deleteUserData.data,
  getUserData: state.getUserData.data,
  getUserDataLoading: state.getUserData.loading,
  // addNoteData: state.addNoteData.data,
  // addNoteDataLoading: state.addNoteData.loading,
  // deleteNoteData: state.deleteNoteData.data,
  // deleteNoteDataLoading: state.deleteNoteData.loading,
  // updateNoteData: state.updateNoteData.data,
  // updateNoteDataLoading: state.updateNoteData.loading,

  addUserData: state.addUserData.data,
  updateUserData: state.updateUserData.data,

  feeListLoading: state.feeListData?.loading,
  feeListData: state.feeListData?.data,

  addFeeData: state.addFeeData.data,
  updateFeeData: state.updateFeeData.data,
});

export default connect(mapStateToProps, {
  fetchUserList,
  deleteUser,
  getUser,
  // addNote,
  // deleteNote,
  // updateNote,
  fetchFeeList,
})(Users);
