import React, { useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { Strings } from '../../common/strings';
import AddUserModal from '../../components/AddUserModal';
import AddVendorModal from '../../components/AddVendorModal';
import AddClientModal from '../../components/AddClientModal';
import AddInventoryModal from '../../components/AddInventoryModal';
import AddLocationModal from '../../components/AddLocationModal';
import AddDeliveriesModal from '../../components/AddDeliveriesModal';
import AddRateSheetModal from '../../components/AddRateSheetModal';
import FeesModal from '../../components/FeesModal';
import { isDesigner, isVendor } from '../../utils/appUtils';
import classNames from 'classnames';
import Loader from '../../components/Loader';
import { NavbarDataContext } from '../../App';

export default function Navbar(props) {
  const { pathname } = useLocation();
  const { feesData } = useContext(NavbarDataContext);
  const classes = useStyles();
  const theme = useTheme();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showAddInventoryModal, setShowAddInventoryModal] = useState(false);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [showAddDeliveriesModal, setShowAddDeliveriesModal] = useState(false);
  const [showAddRateSheetModal, setShowAddRateSheetModal] = useState(false);
  const [showAddFeeModal, setShowAddFeeModal] = useState(false);

  const routingList = [
    {
      pathName: '/app/users',
      title: 'Users',
      addNewBtn: true,
      onClick: () => setShowAddUserModal(true),
    },
    { pathName: '/app/incidents', title: 'Incidents' },
    {
      pathName: '/app/vendors',
      title: 'Vendors',
      addNewBtn: true,
      onClick: () => setShowAddVendorModal(true),
    },
    {
      pathName: '/app/rate-sheet',
      title: 'Rate Sheet',
      addNewBtn: true,
      onClick: () => setShowAddRateSheetModal(true),
    },
    {
      pathName: '/app/fees',
      title: 'Fees',
      addNewBtn: feesData,
      onClick: () => setShowAddFeeModal(true),
    },
    {
      pathName: '/app/clients',
      title: 'Clients',
      addNewBtn: true,
      onClick: () => setShowAddClientModal(true),
    },
    {
      pathName: '/app/inventory',
      title: 'Inventory',
      addNewBtn: true,
      onClick: () => setShowAddInventoryModal(true),
    },
    {
      pathName: '/app/location',
      title: 'Location',
      addNewBtn: true,
      onClick: () => setShowAddLocationModal(true),
    },
    {
      pathName: '/app/deliveries',
      title: 'Deliveries',
      addNewBtn: true,
      onClick: () => setShowAddDeliveriesModal(true),
    },
    { pathName: '/app/invoice', title: 'Invoice' },
    { pathName: '/app/report', title: 'Report' },
    { pathName: '/app/settings', title: 'Settings' },
  ];

  const venderRoutingList = [
    { pathName: '/app/incidents', title: 'Incidents' },
    { pathName: '/app/settings', title: 'Settings' },
  ];

  const designerRoutingList = [
    { pathName: '/app/incidents', title: 'Incidents' },
    { pathName: '/app/settings', title: 'Settings' },
    {
      pathName: '/app/inventory',
      title: 'Inventory',
      addNewBtn: false,
      onClick: () => setShowAddInventoryModal(true),
    },
  ];

  const hideTitle = ['Report'];
  const getTitle = () => {
    const filterList = isVendor()
      ? venderRoutingList
      : isDesigner()
      ? designerRoutingList
      : routingList;
    const item = filterList.find((item) => item.pathName === pathname);
    return item;
  };

  const onClickAddNew = () => {
    const filterList = isVendor()
      ? venderRoutingList
      : isDesigner()
      ? designerRoutingList
      : routingList;
    const item = filterList.find((item) => item.pathName === pathname);
    return item?.onClick();
  };

  useEffect(() => {
    window.onpopstate = () => {
      setShowAddUserModal(false);
      setShowAddVendorModal(false);
      setShowAddClientModal(false);
      setShowAddInventoryModal(false);
      setShowAddLocationModal(false);
      setShowAddDeliveriesModal(false);
      setShowAddRateSheetModal(false);
      setShowAddFeeModal(false);
    };
  });

  return (
    <>
      <div className={classes.mainContainer}>
        <Typography
          display='inline-block'
          variant='h5_700'
          className={classes.title}
        >
          {(hideTitle.includes(getTitle()?.title) ? '' : getTitle()?.title) ||
            ''}
        </Typography>
        {props.navbarButtonArray?.length > 0 &&
          props.navbarButtonArray?.map((el, index) => {
            return (
              <Button
                key={index}
                variant={el?.btnVariant || 'contained'}
                className={classNames(classes.addNewBtn, {
                  [el.btnClassName]: el?.btnClassName,
                })}
                onClick={el?.btnOnClick}
                disabled={el?.disabled || el?.loading}
              >
                {el?.loading ? <Loader /> : el?.btnText.toUpperCase()}
              </Button>
            );
          })}
        {getTitle()?.addNewBtn && (
          <Button
            variant='contained'
            className={classes.addNewBtn}
            onClick={onClickAddNew}
          >
            {Strings.ADD_NEW.toUpperCase()}
          </Button>
        )}
        {showAddUserModal && (
          <AddUserModal onClose={() => setShowAddUserModal(false)} />
        )}
        {showAddVendorModal && (
          <AddVendorModal onClose={() => setShowAddVendorModal(false)} />
        )}

        {showAddClientModal && (
          <AddClientModal onClose={() => setShowAddClientModal(false)} />
        )}
        {showAddInventoryModal && (
          <AddInventoryModal
            onClose={() => setShowAddInventoryModal(false)}
            designerList={props.designerList}
          />
        )}
        {showAddLocationModal && (
          <AddLocationModal onClose={() => setShowAddLocationModal(false)} />
        )}
        {showAddDeliveriesModal && (
          <AddDeliveriesModal
            onClose={() => setShowAddDeliveriesModal(false)}
          />
        )}
        {showAddRateSheetModal && (
          <AddRateSheetModal onClose={() => setShowAddRateSheetModal(false)} />
        )}
        {showAddFeeModal && (
          <FeesModal onClose={() => setShowAddFeeModal(false)} />
        )}
      </div>
    </>
  );
}
