import axios from 'axios';
import { DELETE_INVOICE } from './../../constants';

export const deleteInvoice = (data, setShowDeleteInvoiceDialog,onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        data: data
    };
    dispatch(loadInvoiceList());
    axios
        .delete(
            `/invoice/deleteInvoice`,config
        )
        .then((res) => {
            dispatch(setDeleteInvoice(res));
            setShowDeleteInvoiceDialog(false);
        })
        .catch((err) => {
            alert("Errr",err)
            dispatch(errorDeleteInvoice(err.response));
            onError(err.response);
        });
}

export const loadInvoiceList = () => ({
    type: DELETE_INVOICE.LOAD,
});

export const setDeleteInvoice = (data) => ({
    type: DELETE_INVOICE.SUCCESS,
    payload: data,
});

export const errorDeleteInvoice = (error) => ({
    type: DELETE_INVOICE.FAIL,
    payload: error,
});
