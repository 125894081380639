import React, { useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import IncidentNotesInput from '../IncidentNotesInput';
import IncidentNotes from '../IncidentNotes';
import useWindowDimensions from '../../Hooks/windowDimention';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../../utils/appUtils';
import { connect } from 'react-redux';
import { uploadFile } from '../../redux/actions/settingActions/uploadFile';
import { deleteFile } from '../../redux/actions/settingActions/deleteFile';

const IncidentNotesTabContainer = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const data = props?.notes || [];
  // const [data, setData] = useState(props?.notes || []);
  const [note, setNote] = useState('');
  const { width } = useWindowDimensions();
  const scrollRef = useRef(null);
  const textAreaRef = useRef(null);
  const [selectedNote, setSelectedNote] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [noteImage, setNoteImage] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const handleFileSelect = (e) => {
    const file = {
      files: e.target.files[0],
    };
    if (noteImage) {
      const deleteData = {
        url: noteImage,
      };
      props.deleteFile(deleteData, onError);
      props.uploadFile(file, setNoteImage, onError);
    } else {
      props.uploadFile(file, setNoteImage, onError);
    }
  };

  
  const removeFile = () => {
    const deleteData = {
      url: noteImage,
    };
    props.deleteFile(deleteData, onError);
    setNoteImage('');
  }

  const onClickSend = async () => {
    if (note !== '') {
      const noteData = {
        incidentId: props?.selectedIncident,
        note: note,
        file: noteImage,
      };
      await props.addNote(
        noteData,
        (data) => {
          props.setNotesData([...props.notesData, data?.data]);
          setNoteImage('');
        },
        props?.onError
      );
    }
  };

  const onClickDeleteNote = async (e) => {
    e.preventDefault();
    const newData = props.notesData?.filter((i) => {
      return i._id !== selectedNote._id;
    });
    props.setNotesData([...newData]);
    if (selectedNote?._id) {
      await props?.deleteNote(
        selectedNote._id,
        props.onError
      );
    }
  };

  const handleMenuClick = async (event, item) => {
    await setSelectedNote(item?.data || item);
    if (event === 'Delete') {
      await setShowDeleteDialog(true);
    } else {
      await setIsEditNote(true);
      await setNote(item.note || '');
      await setNoteImage(item?.file || '');
    }
  };

  const updateNote = async () => {
    if (note !== '') {
      const data = {
        note: note,
        file: noteImage,
      };
      await props.updateNote(
        selectedNote._id,
        data,
        () => {
          const newData = props?.notesData?.map((i) => {
            if (i._id === selectedNote._id) {
              i.note = note;
              i.file = noteImage;
            }
            return i;
          });
          setIsEditNote(false);
          setNoteImage('');
          props.setNotesData([...newData]);
        },
        props.onError,
      );
    }
  };

  const sendNote = () => {
    if (isEditNote) {
      updateNote();
    } else {
      onClickSend();
    }
  };

  return (
    <div className={classes.tab_container}>
      <IncidentNotesInput
        placeholder={Strings.TYPE_YOUR_COMMENT_HERE}
        onChange={(e) => setNote(e.target.value)}
        value={note}
        onClickSend={sendNote}
        loader={props.addNoteDataLoading || props.updateNoteDataLoading}
        textAreaRef={textAreaRef}
        isEditNote={isEditNote}
        handleFileUpload={handleFileSelect}
        noteImage={noteImage}
        fileLoader={props?.uploadFileLoading}
        deleteFileData={props?.deleteFileData}
        removeSelectedFile={removeFile}
      />

      <div ref={scrollRef} className={classes.notes_list}>
        {data?.length > 0 ? (
          data?.map((item, index) => {
            return (
              <IncidentNotes
                data={item}
                index={index}
                key={index + 'note'}
                lastIndex={data.length - 1}
                handleMenuClick={handleMenuClick}
                onClickDeleteNote={onClickDeleteNote}
                // deleteNoteDataLoading={props?.deleteNoteDataLoading}
                showDeleteDialog={
                  selectedNote?._id === item?._id && showDeleteDialog
                }
                setShowDeleteDialog={setShowDeleteDialog}
              />
            );
          })
        ) : (
          <Typography variant='p_400' className={classes.no_notes} mt={2}>
            {Strings.THERE_IS_NO_NOTES}
          </Typography>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  uploadFileLoading: state.uploadFileData.loading,
  deleteFileLoading: state.deleteFileData.loading,
  deleteFileData: state.deleteFileData.data
})

export default connect(mapStateToProps, {
  uploadFile,
  deleteFile,
})(IncidentNotesTabContainer);