import axios from 'axios';
import { UPDATE_DELIVERY } from '../../constants';

export const updateDelivery =
  (deliveryID, data, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateDelivery());

    axios
      .put(`/delivery/updateDelivery?deliveryId=${deliveryID}`, data, config)
      .then((res) => {
        dispatch(setUpdateDelivery(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorUpdateDelivery(err.response));
        onError(err.response);
      });
  };

export const loadUpdateDelivery = () => ({
  type: UPDATE_DELIVERY.LOAD,
});

export const setUpdateDelivery = (data) => ({
  type: UPDATE_DELIVERY.SUCCESS,
  payload: data.data,
});

export const errorUpdateDelivery = (error) => ({
  type: UPDATE_DELIVERY.FAIL,
  payload: error,
});
