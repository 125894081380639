import { CircularProgress } from '@mui/material';
import React from 'react';
import { Colors } from '../../common/colors';

export default function Loader(props) {
  return (
    <CircularProgress
      style={{
        color: props.color ? props.color : Colors.WHITE,
      }}
      size={props.size ? props.size : 20}
    />
  );
}
