import axios from 'axios';
import { CLOSE_DELIVERY } from '../../constants';

export const closeDelivery = (deliveryId, isActive, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addCloseDelivery());

  axios
    .put(
      `/delivery/updateDeliveryStatus?isActive=${isActive}&deliveryId=${deliveryId}`,
      {},
      config,
    )
    .then((res) => {
      dispatch(setCloseDelivery(res));
    })
    .catch((err) => {
      dispatch(errorCloseDelivery(err.response));
      onError(err.response);
    });
};

export const addCloseDelivery = () => ({
  type: CLOSE_DELIVERY.LOAD,
});

export const setCloseDelivery = (data) => ({
  type: CLOSE_DELIVERY.SUCCESS,
  payload: data.data,
});

export const errorCloseDelivery = (error) => ({
  type: CLOSE_DELIVERY.FAIL,
  payload: error,
});
