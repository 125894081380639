import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  modal_mask_container: {
    backgroundColor: Colors.RAISIN_BLACK,
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
  },
  close_icon_container: {
    height: 36,
    width: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.TRANSPARENT,
    '&:hover': {
      backgroundColor: '#525659b3',
    },
    position: 'absolute',
    zIndex: 11,
    top: 10,
    right: 135,
    cursor: 'pointer',
  },
  close_icon: {
    height: 22,
    width: 22,
    // cursor: 'pointer',
  },
  frame_container: {
    height: '100%',
    width: '100%',
    border: 'none',
  },
  loader_container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
