import { useTheme } from '@mui/material/styles';
import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import Profile from '../../components/Profile';
import CustomRadioButton from '../../components/CustomRadioButtonGroup';

const radioButtonArray = [
  {
    label: 'Update inventory location codes ',
    value: 'Update inventory location codes',
  },
  { label: 'Update loaded inventory', value: 'Update loaded inventory' },
  { label: 'Send recept notifications', value: 'Send recept notifications' },
  { label: 'Generate new barcodes', value: 'Generate new barcodes' },
  { label: 'Clear printed barcodes', value: 'Clear printed barcodes' },
  {
    label: 'Sync Quickbooks and Clients',
    value: 'Sync Quickbooks and Clients',
  },
  { label: 'Send a letter', value: 'Send a letter' },
];

const Settings = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      {/* <div className={classes.main_container}>
        <Typography variant='h6_700'>{Strings.IMPORT_EXPORT}</Typography>
        <div className={classes.form_container}>
          {radioButtonArray?.map((el, index) => (
            <CustomRadioButton
              key={index}
              name=''
              onChange={() => {}}
              label={el.label}
            />
          ))}
          <Button
            type='submit'
            className={classes.process_button}
            variant='contained-radius'>
            {Strings.PROCESS}
          </Button>
        </div>
      </div> */}
      <Profile />
    </div>
  );
};

export default Settings;
