import { ADD_CLIENT } from '../../constants';

const initialState = {
    error : false,
    data: null,
    loading: false
}

export default function (state = initialState, { type, payload } = {} ) {
    switch (type) {
        case ADD_CLIENT.LOAD:
            return {
                ...state,
                loading: true
            }
        case ADD_CLIENT.SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
            };
        case ADD_CLIENT.FAIL:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}