import React from 'react';
import Link from '@mui/material/Link';

export default function CommonLink(props) {
  return (
    <Link component='button' variant='body2' onClick={props.onClick}>
      {props.text}
    </Link>
  );
}
