import React from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography } from '@mui/material';
import { Colors } from '../../common/colors';
import classNames from 'classnames';
import CommonMenu from '../CommonMenu';
import moment from 'moment';
import Dialog from '../Dialog';
import { Strings } from '../../common/strings';

export default function IncidentNotes(props) {
  const classes = useStyles();

  return (
    <div key={props.index} className={classes.container}>
      <div className={classes.flex_column}>
        {props?.data?.file && (
          <img src={props?.data?.file} alt='' className={classes.note_image} />
        )}
        <Typography variant='p_400'>
          {props?.data?.note || props?.data?.data?.note || ''}
        </Typography>
        <div className={classNames(classes.flex_row, classes.timer_view)}>
          <img
            src={icons().timer_chinese_silver}
            className={classes.timer_icon}
          />
          <Typography variant='p_small_600' className={classes.date_txt}>
            {moment(props.data.createdAt).format('MMM DD, YYYY')}
          </Typography>
        </div>
      </div>
      <CommonMenu
        options={[{ title: 'Edit' }, { title: 'Delete', color: Colors.LUST }]}
        handleMenuClick={(e) =>
          props?.handleMenuClick(e, props?.data || props?.data?.data)
        }
      />
      
      {props.showDeleteDialog && (
        <Dialog
          title={Strings.NOTE}
          message={Strings.ARE_YOU_SURE_DELETE_NOTE}
          loading={props?.deleteNoteDataLoading}
          onClose={() => props?.setShowDeleteDialog(false)}
          onClickYesButton={(e) => props?.onClickDeleteNote(e)}
        />
      )}
    </div>
  );
}
