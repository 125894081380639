import axios from 'axios';
import { UPDATE_USER_PROFILE } from '../../constants';

export const updateUserProfile = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateUserProfile());
  axios
    .put(`admin/userUpdate`, data, config)
    .then((res) => {
      dispatch(setUpdateUserProfile(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateUserProfile(err.response));
      onError(err.response);
    });
};

export const loadUpdateUserProfile = () => ({
  type: UPDATE_USER_PROFILE.LOAD,
});

export const setUpdateUserProfile = (data) => ({
  type: UPDATE_USER_PROFILE.SUCCESS,
  payload: data,
});

export const errorUpdateUserProfile = (error) => ({
  type: UPDATE_USER_PROFILE.FAIL,
  payload: error,
});
