import React, { useState } from 'react';
import useStyles from './styles';
import { Typography, Button, TextField } from '@mui/material';
import { Strings } from '../../common/strings';
import { Colors } from '../../common/colors';
import { connect } from 'react-redux';
import { forgotPassword } from '../../redux/actions/authActions/forgotPassword';
import { getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import CommonLink from '../../components/CommonLink';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onSuccess = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'success',
    });
  };

  const onPressForgotPassword = () => {
    props.forgotPassword(
      { email: email.toLocaleLowerCase() },
      () => onSuccess('Reset Email has sent successfully'),
      (err) => onError(err)
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.left_container}>
        <div className={classes.left_logo} />

        <div className={classes.login_form_container}>
          <Typography variant='h5_700' color={Colors.BLACK}>
            {Strings.FORGOT_PASSWORD}
          </Typography>
          <TextField
            name='email'
            required
            id='filled-required'
            label={Strings.EMAIL}
            placeholder={Strings.YOUR_EMAIL}
            variant='standard'
            value={email}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className={classes.email_input}
          />
          <Button
            type='submit'
            className={classes.common_input}
            variant='contained'
            disabled={props.forgotPasswordDataLoading}
            onClick={() => onPressForgotPassword()}
          >
            {props.forgotPasswordDataLoading ? (
              <Loader />
            ) : (
              Strings.RESET_PASSWORD
            )}
          </Button>
          <div className={classes.common_input}>
            <CommonLink
              text={Strings.LOGIN}
              onClick={() => {
                navigate('/login');
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.right_container}>
        <div className={classes.right_background} />
        <div className={classes.boxes_image} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  forgotPasswordData: state.forgotPasswordData.data,
  forgotPasswordDataLoading: state.forgotPasswordData.loading,
});

export default connect(mapStateToProps, {
  forgotPassword,
})(ForgotPassword);
