import { TextField, Typography, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import Avatar from '@mui/material/Avatar';
import { Colors } from '../../common/colors';
import { connect } from 'react-redux';
import { changePassword } from '../../redux/actions/settingActions/changePassword';
import { getUserById } from '../../redux/actions/settingActions/getUser';
import { uploadFile } from '../../redux/actions/settingActions/uploadFile';
import { deleteFile } from '../../redux/actions/settingActions/deleteFile';
import { updateUserProfile } from '../../redux/actions/settingActions/updateUserProfile';
import { useFormik, Form, FormikProvider } from 'formik';
import { changePasswordSchema } from '../../utils/validationSchemas';
import { useSnackbar } from 'notistack';
import {
  digitsRegex,
  emailRegex,
  getErrorMessage,
  slicePhoneNumber,
} from '../../utils/appUtils';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

const Profile = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [oldPasswordFieldType, setOldPasswordFieldType] = useState('password');
  const [newPasswordFieldType, setNewPasswordFieldType] = useState('password');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [type, setType] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const inputFile = useRef(null);
  const [errorObj, setErrorObj] = useState({});

  const onSuccess = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'success',
    });
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    props.getUserById((err) => onError(err));
  }, []);

  useEffect(() => {
    const userData = props?.getUserByIdData?.data || {};
    setName(userData?.name || '');
    setEmail(userData?.email || '');
    setType(userData?.role || '');
    setUserProfile(userData?.profilePicture || '');
    if (userData?.role === 'admin' || userData?.role === 'vendor') {
      setPhoneNumber(userData?.phoneNumber);
    } else {
      setPhoneNumber(userData?.shipping?.business);
    }
    localStorage.setItem('profilePicture', userData?.profilePicture)
  }, [props.getUserByIdData]);

  
  const onPressChangePassword = (values) => {
    try {
      props.changePassword(
        values,
        () => {
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('userName');
          localStorage.removeItem('profilePicture');
          localStorage.removeItem('userRole');
          navigate('/login');
        },
        (err) => onError(err)
      );
    } catch (e) {
      console.log('eee', e, values);
    }
  };

  const handleFileUpload = async (event) => {
    if (userProfile) {
      const data = { url: userProfile };
      props.deleteFile(data, (err) => onError(err));
    }
    const file = event.target.files[0];
    if (file) await uploadFile(file);
  };

  const uploadFile = (file) => {
    const data = {
      files: file,
    };
    props.uploadFile(data, setUserProfile, onError);
  };

  const onUploadClick = () => {
    inputFile.current.click();
  };

  const onPressUpdateUser = () => {
    if (!email) setErrorObj({ ...errorObj, email: 'Email is required' });
    else if (email && !emailRegex.test(email))
      setErrorObj({ ...errorObj, email: 'Please enter valid email' });
    else if (!phoneNumber)
      setErrorObj({ ...errorObj, phoneNumber: 'PhoneNumber is required' });
    // else if (phoneNumber && !phoneNumber.match(digitsRegex))
    //   setErrorObj({ ...errorObj, phoneNumber: 'Only digit allow' });
    // else if (phoneNumber && phoneNumber?.length !== 10)
    //   setErrorObj({
    //     ...errorObj,
    //     phoneNumber: 'Please enter valid PhoneNumber',
    //   });
    else {
      setErrorObj({});
      const data = {email, phoneNumber, profilePicture: userProfile};
      props.updateUserProfile(
        data,
        () => {
          localStorage.setItem("profilePicture", userProfile);
          onSuccess('update successfully!');
          props.getUserById((err) => onError(err));
        },
        (err) => onError(err),
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      onPressChangePassword(values);
    },
  });
  const { errors, touched, values, handleSubmit, handleChange } = formik;

  return (
    <div className={classes.right_modal}>
      <div className={classes.titleContainer}>
        <Typography variant='h6_700'>{Strings.MY_ACCOUNT}</Typography>
        <img src={icons().edit} className={classes.editIcon} />
      </div>
      <div className={classes.profileContainer}>
        {props?.uploadFileDataLoading ? (
          <div className={classes.imageLoad}>
            <Loader color={Colors.RAISIN_BLACK} size={25} />
          </div>
        ) : (
          <Avatar
            sx={{ bgcolor: Colors.BRIGHT_GRAY }}
            classes={{ root: classes.profile }}
            src={userProfile}
          >
            {name.charAt(0).toUpperCase()}
          </Avatar>
        )}
        <div
          style={{ cursor: 'pointer' }}
          className={classes.editProfileContainer}
          onClick={() => onUploadClick()}
        >
          <input
            style={{ display: 'none' }}
            ref={inputFile}
            onChange={(event) => handleFileUpload(event)}
            type='file'
            accept='image/*'
          />
          <img src={icons().edit} className={classes.editProfile} />
        </div>
        <Typography variant='h6_400'>{name}</Typography>
      </div>
      {props?.getUserByIdDataLoading ? (
        <div className={classes.display_flex}>
          <Loader color={Colors.RAISIN_BLACK} size={40} />
        </div>
      ) : (
        <>
          <TextField
            disabled
            id='filled-required'
            label={Strings.TYPE}
            placeholder={Strings.ACTION}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(text) => setType(text)}
            value={type}
            className={classes.input}
          />
          <TextField
            id='filled-required'
            label={Strings.EMAIL}
            placeholder={Strings.YOUR_EMAIL}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorObj({});
            }}
            value={email}
            className={classes.input}
            error={Boolean(errorObj.email && errorObj.email)}
            helperText={errorObj.email && errorObj.email}
          />
          <TextField
            id='filled-required'
            label={Strings.PHONE}
            placeholder={Strings.ENTER_PHONE_NUMBER}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setErrorObj({});
            }}
            value={phoneNumber}
            className={classes.input}
            error={Boolean(errorObj.phoneNumber && errorObj.phoneNumber)}
            helperText={errorObj.phoneNumber && errorObj.phoneNumber}
          />
          <Button
            // type='submit'
            className={classes.save_button}
            variant='contained-radius'
            onClick={() => onPressUpdateUser()}
            disabled={props.updateUserProfileDataLoading}
          >
            {props.updateUserProfileDataLoading ? <Loader /> : Strings.SAVE}
          </Button>
          <div className={classes.titleContainer} style={{ marginTop: 60 }}>
            <Typography variant='h6_700'>{Strings.PASSWORD}</Typography>
            <img src={icons().edit} className={classes.editIcon} />
          </div>
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <TextField
                id='filled-required'
                type={oldPasswordFieldType}
                placeholder={Strings.CURRENT_PASSWORD}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <img
                      src={
                        oldPasswordFieldType === 'password'
                          ? icons().hide_password_eye_quick_silver
                          : icons().eye_quick_silver
                      }
                      alt=''
                      className={classes.eye_icon}
                      onClick={() =>
                        setOldPasswordFieldType(
                          oldPasswordFieldType === 'password'
                            ? 'text'
                            : 'password'
                        )
                      }
                    />
                  ),
                }}
                className={classes.input}
                value={values.oldPassword}
                onChange={handleChange('oldPassword')}
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                helperText={touched.oldPassword && errors.oldPassword}
              />
              <TextField
                id='filled-required'
                type={newPasswordFieldType}
                placeholder={Strings.YOUR_PASSWORD}
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <img
                      src={
                        newPasswordFieldType === 'password'
                          ? icons().hide_password_eye_quick_silver
                          : icons().eye_quick_silver
                      }
                      alt=''
                      className={classes.eye_icon}
                      onClick={() =>
                        setNewPasswordFieldType(
                          newPasswordFieldType === 'password'
                            ? 'text'
                            : 'password'
                        )
                      }
                    />
                  ),
                }}
                className={classes.input}
                value={values.newPassword}
                onChange={handleChange('newPassword')}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <Button
                type='submit'
                className={classes.save_button}
                variant='contained-radius'
                disabled={props.changePasswordDataLoading}
              >
                {Strings.SAVE_PASSWORD}
              </Button>
            </Form>
          </FormikProvider>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  changePasswordData: state.changePasswordData.data,
  changePasswordDataLoading: state.changePasswordData.loading,
  getUserByIdData: state.getUserByIdData.data,
  getUserByIdDataLoading: state.getUserByIdData.loading,
  uploadFileData: state.uploadFileData.data,
  uploadFileDataLoading: state.uploadFileData.loading,
  deleteFileData: state.deleteFileData.data,
  deleteFileDataLoading: state.deleteFileData.loading,
  updateUserProfileData: state.updateUserProfileData.data,
  updateUserProfileDataLoading: state.updateUserProfileData.loading,
});

export default connect(mapStateToProps, {
  changePassword,
  getUserById,
  uploadFile,
  deleteFile,
  updateUserProfile,
})(Profile);
