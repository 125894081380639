import { Button, Typography } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { icons } from '../../assets';
import { Strings } from '../../common/strings';
import useStyles from './styles';

const TableMenuBar = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.main_container,
        props.containerClassName && props.containerClassName,
      )}>
      <div className={classes.flex_row}>
        <Typography variant='p_700' className={classes.selected_text}>{`${
          props?.selectedDataCount || 0
        } items selected`}</Typography>
        <Typography
          variant='p_700'
          className={classes.deselect_text}
          onClick={props?.onDeselectClick}>{`Deselect all`}</Typography>
      </div>
      <div className={classes.flex_row}>
        {props?.showExportButton && (
          <img
            src={icons().export}
            alt='export'
            onClick={() => props?.onExportClick()}
            className={classes.common_image}
          />
        )}
        {props?.showDeleteButton && (
          <img
            src={icons().delete}
            alt='delete'
            onClick={(e) => props?.onDeleteClick(e)}
            className={classNames(classes.common_image, classes.marginLeft)}
          />
        )}
        {props?.showInvoicesButton && (
          <>
            <Button
              type='button'
              className={classes.outlined_button}
              variant='outlined'
              onClick={() => props?.onMorthInvoicesClick()}>
              {Strings.MORTH_AND_INVOICES.toUpperCase()}
            </Button>
            <Button
              type='button'
              className={classes.background_button}
              variant='contained-radius'
              onClick={() => props?.onGenerateInvoiceClick()}>
              {Strings.GENERATE_INVOICE.toUpperCase()}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TableMenuBar;
