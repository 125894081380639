import React from 'react';
import {
  TextField,
  MenuItem,
  autocompleteClasses,
  styled,
  useAutocomplete,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import classNames from 'classnames';
import { Colors } from '../../common/colors';

const Input = styled('input')(({ theme, disabled, error, isShowDetails }) => ({
  padding: 12,
  paddingRight: 32,
  width: '100%',
  fontSize: 16,
  lineHeight: '20px',
  fontFamily: `Lato, sans-serif`,
  fontWeight: 400,
  color: disabled && !isShowDetails ? 'rgb(204, 204, 204)' : Colors.DAVYS_GREY,
  border: 'none',
  background: disabled && 'transparent',
  borderBottom: error
    ? `2px solid ${Colors.FUZZY_WUZZY} !important`
    : disabled && !isShowDetails
    ? `2px dotted ${Colors.CHINESE_SILVER} !important`
    : `2px solid ${Colors.CHINESE_SILVER}!important`,
  '&:hover': {
    borderBottom: error
      ? `2px solid ${Colors.FUZZY_WUZZY} !important`
      : disabled && !isShowDetails
      ? `2px dotted ${Colors.CHINESE_SILVER} !important`
      : isShowDetails
      ? `2px solid ${Colors.CHINESE_SILVER} !important`
      : `2px solid ${Colors.FELDGRAU} !important`,
  },
  '&:focus': {
    borderBottom: error
      ? `2px solid ${Colors.FUZZY_WUZZY} !important`
      : disabled && !isShowDetails
      ? `2px dotted ${Colors.CHINESE_SILVER} !important`
      : `2px solid ${Colors.FELDGRAU} !important`,
    outline: 'none',
  },
  '&::placeholder': {
    color: Colors.DAVYS_GREY,
    fontFamily: `Lato, sans-serif`,
    lineHeight: '140%',
    fontWeight: 400,
    fontSize: 16,
    opacity: disabled ? 0.4 : 0.6,
  },
  background: `transparent url(${
    icons().drop_down_feldgrau
  }) no-repeat right 0.8rem center/1.5rem 4rem`,
}));

const Listbox = styled('ul')(({ theme }) => ({
  width: '100%',
  minWidth: 'fit-content',
  margin: 0,
  marginTop: 4,
  padding: 8,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: Colors.WHITE,
  overflow: 'auto',
  maxHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  '& li': {
    fontFamily: 'Lato, sans-serif',
    color: Colors.FELDGRAU,
    fontWeight: 400,
    fontSize: 16,
    padding: '13px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& li.${autocompleteClasses.focused}`]: {
    background: 'rgba(63, 81, 181, 0.08)',
    cursor: 'pointer',
  },
  '& li:active': {
    background: 'rgba(63, 81, 181, 0.08)',
  },
  boxShadow:
    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
}));

export default function Selection(props) {
  const {
    label,
    error,
    helperText,
    isShowDetails,
    value,
    handleChange,
    disabled,
    options,
    containerClassName,
    placeHolder,
    isSearchable,
    title,
  } = props;
  const classes = useStyles();

  const getSelectedItem = () => {
    const item =
      options &&
      options?.find((opt) => {
        if (opt.value === value) return opt;
      });
    if (value) return item || {};
    else return null;
  };

  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'searchable-dropdown',
    options: options ? options : [],
    onChange: (event, value) => {
      handleChange(event, value?.value);
    },
    getOptionLabel: (option) => option.label || '',
    value: getSelectedItem(),
    isOptionEqualToValue:(option, value) => option.id === value.id,
  });

  return (
    <div
      className={classNames(
        classes.dropdown_container,
        containerClassName && containerClassName,
      )}
    >
      {isSearchable ? (
        <>
          {label && (
            <label
              className={classes.dropdown_label}
              htmlFor={label}
              style={{
                opacity: disabled && !isShowDetails && 0.5,
              }}
              {...getInputLabelProps}
            >
              {label}
            </label>
          )}
          <div>
            <div style={{ width: '100%', display: 'flex' }} {...getRootProps()}>
              <Input
                style={{ border: 'none' }}
                placeholder={placeHolder}
                disabled={disabled}
                isShowDetails={isShowDetails}
                error={error}
                {...getInputProps()}
              />
            </div>
            {groupedOptions.length > 0 ? (
              <Listbox {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                  <li {...getOptionProps({ option, index })} key={index}>
                    <span>{option.label}</span>
                  </li>
                ))}
              </Listbox>
            ) : null}
          </div>
          {error && <p className={classes.dropdown_error}>{helperText}</p>}
        </>
      ) : (
        <TextField
          id='filled-required'
          select
          value={value}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          //helperText='Please select your currency'
          variant='standard'
          error={error}
          helperText={helperText}
          className={classNames(classes.input, props.className)}
          classes={{
            root: classNames(
              classes.selection_root,
              value === '0' && classes.root_color,
              props.inputBase,
            ),
          }}
          // InputProps={{
          //   endAdornment: (
          //     <img
          //       src={icons().drop_down_feldgrau}
          //       alt=''
          //       className={classes.drop_down_icon}
          //     />
          //   ),
          // }}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            IconComponent: () => (
              <img
                src={icons().drop_down_feldgrau}
                alt=''
                className={classes.drop_down_icon}
              />
            ),
          }}
        >
          {title && (
            <MenuItem
              disabled
              key={'0'}
              value={'0'}
              className={classes.menu_item}
            >
              {title}
            </MenuItem>
          )}
          {options?.map((option, index) => (
            <MenuItem
              key={option.value + index}
              value={option.value}
              className={classes.menu_item}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </div>
  );
}
