import axios from 'axios';
import { INVENTORY_LIST, INVENTORY_LIST_FOR_NEXT_PREV } from '../../constants';

export const fetchInventoryList =
  (isActive, search, data, pageSize, pageNumber, onError, onSuccess, type) =>
  (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (type === 'listForNextPrev') dispatch(loadInventoryListForNextPrev());
    else dispatch(loadInventoryList());

    axios
      .post(
        `/inventory/listInventory?isActive=${isActive}&searchString=${search}&page_size=${pageSize}&page_number=${pageNumber}`,
        data,
        config
      )
      .then((res) => {
        if (type === 'listForNextPrev')
          dispatch(setInventoryListForNextPrev(res.data.data));
        else dispatch(setInventoryList(res.data.data));
        onSuccess?.(res.data.data);
      })
      .catch((err) => {
        if (type === 'listForNextPrev')
          dispatch(errorInventoryListForNextPrev(err.response));
        else dispatch(errorInventoryList(err.response));
        onError(err.response);
      });
  };

export const loadInventoryList = () => ({
  type: INVENTORY_LIST.LOAD,
});

export const setInventoryList = (InventoryList) => ({
  type: INVENTORY_LIST.SUCCESS,
  payload: InventoryList,
});

export const errorInventoryList = (error) => ({
  type: INVENTORY_LIST.FAIL,
  payload: error,
});

export const loadInventoryListForNextPrev = () => ({
  type: INVENTORY_LIST_FOR_NEXT_PREV.LOAD,
});

export const setInventoryListForNextPrev = (InventoryList) => ({
  type: INVENTORY_LIST_FOR_NEXT_PREV.SUCCESS,
  payload: InventoryList,
});

export const errorInventoryListForNextPrev = (error) => ({
  type: INVENTORY_LIST_FOR_NEXT_PREV.FAIL,
  payload: error,
});
