export const Strings = {
  //Sidebar Menu List
  USERS: 'Users',
  INCIDENTS: 'Incidents',
  VENDORS: 'Vendors',
  RATE_SHEET: 'Rate Sheet',
  FEES: 'Fees',
  CLIENTS: 'Clients',
  INVENTORY: 'Inventory',
  LOCATION: 'Location',
  DELIVERIES: 'Deliveries',
  INVOICE: 'Invoice',
  REPORT: 'Report',
  FEE: 'Fee',
  SETTINGS: 'Settings',

  //Login
  WELCOME_BACK: 'Welcome back',
  LOGIN_INFO: 'Fill out the information to access your account',
  EMAIL: 'Email',
  YOUR_EMAIL: 'Your email',
  PASSWORD: 'Password',
  YOUR_PASSWORD: 'New Password',
  LOGIN: 'LOGIN',
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD: 'Forgot password',
  CURRENT_PASSWORD: 'Current Password',
  SAVE_PASSWORD: 'Save Password',
  SAVE: 'Save',
  RESET_PASSWORD: 'RESET PASSWORD',

  // Logout
  LOGOUT: 'Logout',
  ARE_YOU_SURE_LOGOUT: 'Are you sure, you want to logout?',

  //Navbar
  ADD_NEW: 'Add New',

  //Settings
  MY_ACCOUNT: 'My Account',
  TYPE: 'Type',
  ACTION: 'Action',
  PHONE: 'Phone',
  ENTER_PHONE_NUMBER: 'Enter your phone number',
  IMPORT_EXPORT: 'Import/Export Data',
  PROCESS: 'Process',

  //Add User Modal
  NEW_USER: 'New user',
  NAME: 'Name',
  SHIPPING: 'Shipping',
  ZIPCODE: 'Zipcode',
  CITY: 'City',
  STATE: 'State',
  ADDRESS: 'Address',
  BUSINESS: 'Business',
  CEL: 'Cel',
  FAX: 'Fax',
  OTHER: 'Other',
  BILLING: 'Billing',
  SAME_AS_SHIPPING: 'Same as shipping',
  COPY_TO_CLIENT: 'Copy to client',
  PREFERRED_CONTACT: 'Prefered contact',
  BILLING_OPTIONS: 'Billing options',
  BILL_RECEIVING_FEES: 'Bill receiving fees',
  BILL_STORAGE_FEES: 'Bill storage fees',
  SHOW_DETAIL_ON_INVOICE: 'Show detail on invoice',
  ADD_USER: 'Add User',
  EDIT_USER: 'Edit user',
  UPDATE_USER: 'Update User',
  DESIGNER_CODE: 'Designer Code',
  ADDITIONAL_EMAILS: 'Additional Emails',

  //Users
  SEARCH_FOR_AN_USER: 'Search for an user',
  SELECT: 'Select',
  TYPE_HERE: 'Type here',
  NO_DATA_FOUND: 'No data found!',
  DELETE_USER: 'Delete User',
  ARE_YOU_SURE: 'Are you sure, you want to delete user?',

  //User Details Modal
  DETAILS: 'Details',
  NOTES: 'Notes',
  NOTE: 'Note',
  ARE_YOU_SURE_DELETE_NOTE: 'Are you sure, you want to delete note?',
  THERE_IS_NO_NOTES: 'There is no notes',
  THERE_IS_NO_CLIENTS: 'There is no clients',
  BALANCE: 'Balance',

  // Vendor
  SEARCH_FOR_NAME: 'Search for a name',

  //Add Vendor Modal
  NEW_VENDOR: 'New Vendor',
  EDIT_VENDOR: 'Edit Vendor',
  DELETE_VENDOR: 'Delete Vendor',
  ARE_YOU_SURE_DELETE_VENDOR: 'Are you sure, you want to delete vendor?',

  //Incidents
  ASSIGNED: 'Assigned',
  INCIDENT: 'Incident',
  DELETE_INCIDENT: 'Delete Incident',
  ARE_YOU_SURE_TO_DELETE_INCIDENT: 'Are you sure, you want to delete incident?',
  APPROVE: 'APPROVE',
  DECLINE: 'DECLINE',
  SEARCH_FOR_USER_OR_BARCODE: 'Search for a barcode',
  ASSIGN_DESIGNER: 'Assign to designer',
  Complete: 'Complete',

  // Incident details
  BARCODE_ID: 'Barcode ID',
  DESIGNER: 'Designer',
  WSI_NUMBER: 'Barcode WSI Number',
  VENDOR: 'Vendor',
  BARCODE_ITEM_DESC: 'Barcode Item Description',
  DAMAGED: 'Damaged',
  SHOW_MORE: 'Show more',
  SHOW_LESS: 'Show less',
  INCIDENT_NOTES: 'Incident Notes',
  ESTIMATION: 'Estimation',
  TYPE_YOUR_COMMENT_HERE: 'Type your comment here',
  TOTAL: 'Total',

  //Dialog
  NO: 'NO',
  YES: 'YES',

  // client
  SEARCH_FOR_NAME_ID: 'Search for a name or ID',

  //Add Client Modal
  NEW_CLIENT: 'New Client',
  CLIENT: 'Client',
  CLIENT_CODE: 'Client Code',
  PRIMARY_ADDRESS: 'Primary Address',
  SECONDARY_ADDRESS: 'Secondary Address',
  OPTIONS: 'Options',
  ACCEPT_DVP: 'Accept DVP',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DISPLAY_NOTES: 'Display notes',
  ULTRA_SERVICE: 'Ultra Service',
  RETAIL: 'Retail',
  ADD_CLIENT: 'Add Client',
  UPDATE_CLIENT: 'Update Client',
  DELETE_CLIENT: 'Delete Client',
  ARE_YOU_SURE_DELETE_CLIENT: 'Are you sure, you want to delete client?',

  //Fees Modal
  DVP_DAILY_RATE: 'DVP Daily Rate*',
  DVP_TRANSIT_RATE: 'DVP Transit Rate*',
  PULL_LOAD_FEE: 'Pull / Load Fee*',
  PICKUP_FEE: 'Pickup Fee*',
  TRASH_FEE: 'Trash Fee*',
  BLANKET_FEE: 'Blanket Fee*',
  LABOR_RATES: 'Labor Rates',
  DESIGNER_RATE: 'Designer Rate',
  CUSTOMER_RATE: 'Customer Rate',
  SURROUNDINGS_RATE: 'Surroundings Rate',

  // Add fee
  ADD_FEE: 'Add Fee',
  UPDATE_FEE: 'Update Fee',
  EDIT_FEE: 'Edit Fee',

  // Inventory
  SEARCH_FOR_ITEM_OR_ID: 'Search for a item or ID',
  DELETE_INVENTORY: 'Delete Inventory',
  ARE_YOU_SURE_DELETE_INVENTORY: 'Are you sure, you want to delete inventory?',
  PRINT_BARCODE: 'Print Barcodes',
  VIEW_BARCODES: 'View Barcodes',
  SELECT_DESIGNER: 'Select Designer',

  //Add Inventory Modal
  NEW_ITEM: 'New Item',
  PO_REFERENCE: 'PO / Reference',
  DESCRIPTION: 'Description',
  MANUFACTURER: 'Manufacturer',
  SIZE: 'Size',
  WEIGHT: 'Weight',
  LOCATION: 'Location',
  FREIGHT_LINE: 'Freight Line',
  DECLARED_VALUE: 'Declared Value',
  NUM_OF_BLANKETS: 'No of Blankets',
  RECEIVED_ON: 'Received On',
  DELIVERED_ON: 'Delivered On',
  CREATE_COPIES: 'Create copies',
  ADD_ITEM: 'Add Item',
  EDIT_ITEM: 'Edit Item',
  UPDATE_ITEM: 'Update Item',
  COPY_COUNT: 'Copy Count',

  //Barcode Modal
  BARCODE_VIEWER: 'Barcode Viewer',
  SCAN_BARCODE: 'Please scan barcode ',
  PRINT: 'Print',

  //Deliveries
  SEARCH_FOR_DELIVERIES: 'Search for a client or ID',
  DELETE_DELIVERY: 'Delete Delivery',
  ARE_YOU_SURE_TO_DELETE_DELIVERY: 'Are you sure, you want to delete delivery?',
  TYPE_YOUR_NOTE_HERE: 'Type your note here',
  CLOSED: 'Closed',

  // Add Deliveries Modal
  NEW_DELIVERY: 'New delivery',
  EDIT_DELIVERY: 'Edit delivery',
  ITEM: 'Item',
  CLOSED_ON: 'Closed on',
  ADD_DELIVERY: 'Add Delivery',
  UPDATE_DELIVERY: 'UPDATE Delivery',
  DO_NOT_BILL: 'Do not bill',
  CLIENT_PICK_UP: 'Client pick up',
  INVOICED_ON: 'Invoiced on',
  DESIGNER_NOTES: 'Designer notes',
  JOB_SHEET: 'Job sheet',
  PULL_LIST: 'Pull list',
  EMAIL_FAX: 'Email / Fax',
  SELECT_ITEMS: 'Select Items',
  SELECT_ALL: 'Select All',
  ITEMS: 'Items',
  SELECTED_ITEMS: 'Selected Items',
  PERMISSION: 'Permission',
  CONFIRM: 'Confirm',
  DELIVERY_CONFIRMATION_MESSAGE:
    'Client is not paid for previous invoices, would you still like to proceed?',

  // Invoices
  MORTH_AND_INVOICES: 'Morth and invoices',
  GENERATE_INVOICE: 'Generate invoice',
  DELETE_INVOICE: 'Delete invoice',
  ARE_YOU_SURE_DELETE_INVOICE: 'Are you sure, you want to delete Invoice?',
  INVOICE_DATE: 'Invoice Date',
  DELIVERY_FEE: 'Delivery Fee',
  DVP_FEE: 'DVP Fee',
  PICKUP: 'Pick Up',
  METHOD: 'Method',

  //RateSheet
  SEARCH_FOR_RATESHEET: 'Search for an user or ID',
  EDIT_RATE_SHEET: 'Edit Rate Sheet',
  NEW_RATE_SHEET: 'New Rate Sheet',

  // Add RateSheet
  EFFECTIVE_ON: 'Effective on',
  ADD: 'Add',
  RECEIVING_FEE: 'Receiving Fee',
  DISC: 'Disc %',
  STORAGE_FEE: 'Storage Fee',
  DELETE_RATE_SHEET: 'Delete Ratesheet',
  ARE_YOU_SURE_RATE_SHEET: 'Are you sure, you want to delete rate sheet?',
  EDIT: 'Edit',
  APPLY_ALL_DESIGNER_CLIENT: 'Apply to all the designers and clients',
  APPLY_ALL_CLIENT: 'Apply to all the clients',

  // Report
  START_ON: 'Start on',
  END_ON: 'End on',
  SORT_BY: 'Sort by',
  SEND_EMAIL: 'Send Email',
  PRINT: 'Print',
  RUN_REPORT: 'Run Report',
  GENERATE_PDF: 'Generate Pdf',
  GENERATE_CSV: 'Generate CSV',

  // Location
  NEW_LOCATION: 'New Location',
  EDIT_LOCATION: 'Edit Location',
  VIEW_LOCATION: 'View Location',
  ADD_LOCATION: 'Add Location',
  UPDATE_LOCATION: 'Update Location',
  LOCATION_NUMBER: 'Location Number',
  SEARCH_FOR_LOCATION_NUMBER: 'Search for location number',
  DELETE_LOCATION: 'Delete Location',
  ARE_YOU_SURE_DELETE_LOCATION: 'Are you sure, you want to delete location?',

  // Calculation Modal
  NUM_OF_MEN: 'Number of Men',
  TRAVEL_TIME: 'Travel Time',
  DELIVERY_TIME: 'Delivery Time',
  FUELS_CHARGE: 'Fuels Charge',
  PIECES_OF_TRASH: 'Pieces of Trash',
  MISC_FEES: 'Misc Fees',
};
