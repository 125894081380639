import axios from 'axios';
import { DELETE_INVENTORY } from '../../constants';

export const deleteInventory = (ID, setDeleteDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteInventory());
  axios
    .delete(`/inventory/deleteInventory?inventoryId=${ID}`, config)
    .then((res) => {
      dispatch(setDeleteInventory(res));
      setDeleteDialog(false);
    })
    .catch((err) => {
      dispatch(errorDeleteInventory(err.response));
      onError(err.response);
    });
};

export const loadDeleteInventory = () => ({
  type: DELETE_INVENTORY.LOAD,
});

export const setDeleteInventory = (data) => ({
  type: DELETE_INVENTORY.SUCCESS,
  payload: data,
});

export const errorDeleteInventory = (error) => ({
  type: DELETE_INVENTORY.FAIL,
  payload: error,
});
