import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import { connect } from 'react-redux';
import { fetchFeeList } from '../../redux/actions/feeActions/feeList';
import { getErrorMessage, PAGE_SIZE } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import moment from 'moment';
import ToggleButton from '../../components/ToggleButton';
import { changeFeeStatus } from '../../redux/actions/feeActions/updateFeeStatus';
import debounce from 'lodash/debounce';
import FeesModal from '../../components/FeesModal';
import { NavbarDataContext } from '../../App';

const tableHeaders = [
  { label: 'DVP Daily Rate', width: '12%' },
  { label: 'DVP Transit Rate', width: '14%' },
  { label: 'Pull / Load Fee', width: '12%' },
  { label: 'Pickup Fee', width: '12%' },
  { label: 'Trash Fee', width: '13%' },
  { label: 'Blanket Fee', width: '13%' },
  { label: 'Status', width: '12%' },
  { label: '', width: '12%' },
];

const positionBgColor = {
  Designer: Colors.POWDER_BLUE,
  Employee: Colors.PALE_SPRING_BUD,
  Admin: Colors.PALE_LAVENDER,
};


const Fees = (props) => {
  const { setFeesData } = useContext(NavbarDataContext);
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [feesListData, setFeesListData] = useState([]);
  const [selectedFee, setSelectedFee] = useState(null);
  const [showEditFeeModal, setShowEditFeeModal] = useState(false);

  // const totalRecords = props.feeListData?.totalRecords || 0;

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props?.feeListData?.fees?.length > 0) {
      setFeesData(false);
    } else if (props?.feeListData?.fees?.length === 0) {
      setFeesData(true);
    } 
  }, [props?.feeListData?.fees, props?.feeListLoading]);

  // const delayedSearch = useCallback(
  //   debounce(
  //     (q) =>
  //       props.fetchInventoryList(
  //         q,
  //         selectedItem === 'all' ? '' : selectedItem,
  //         PAGE_SIZE,
  //         page,
  //         onError
  //       ),
  //     600
  //   ),
  //   []
  // );

  useEffect(() => {
    const data = props.feeListData?.fees;
    setFeesListData(data);
  }, [props.feeListData.fees]);

  useEffect(() => {
    props.fetchFeeList(PAGE_SIZE, page, onError);
  }, [page, props.addFeeData, props.updateFeeData]);

  const handleChangePagination = async (e, p) => {
    // await jump(p);
    await setPage(p);
  };

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedFee(item);
    await setShowEditFeeModal(true);
  };

  const updateStatus = (item, status) => {
    const { changeFeeStatus } = props;
    const data = { isActive: status };
    changeFeeStatus(item._id, data, (err) => onError(err));
  };

  return (
    <div className={classes.main_container}>
      {/* <TableToolbox
        isSearch
        isSelect
        searchPlaceholder={Strings.SEARCH_FOR_ITEM_OR_ID}
        selectedItem={selectedItem}
        selectOption={[{ label: 'All designers', value: 'all' }, ...designers]}
        handleSelectChange={(e) => {
          setSelectedItem(e.target.value);
          setPage(1);
        }}
        searchValue={search}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      /> */}
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          {props.feeListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {props?.feeListData?.fees?.length > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead headCells={tableHeaders} />
                    <TableBody>
                      {feesListData?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            // onClick={(e) => onClickTableRow(row)}
                          >
                            <TableCell component='th' width='12%'>
                              <Typography variant='p_400'>
                                {row?.dailyRate || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell component='th' width='14%'>
                              <Typography variant='p_700'>
                                {row?.transitRate || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='12%'>
                              <Typography variant='p_400'>
                                {row?.pullFee || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='12%'>
                              <Typography variant='p_400'>
                                {row?.pickupFee || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='13%'>
                              <Typography variant='p_400'>
                                {row?.trashFee || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='13%'>
                              <Typography variant='p_400'>
                                {row?.barketFee || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='12%'>
                              <Typography variant='p_400'>
                                <ToggleButton
                                  checked={row.isActive}
                                  className={classes.toggle_btn}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const updateData = feesListData.map(
                                      (data, i) => {
                                        if (i === index) {
                                          return {
                                            ...data,
                                            isActive: e.target.checked,
                                          };
                                        } else {
                                          return {
                                            ...data,
                                          };
                                        }
                                      }
                                    );
                                    setFeesListData(updateData);
                                    updateStatus(row, e.target.checked);
                                  }}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell width='12%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().edit}
                                    className={classes.icons}
                                    onClick={(e) => onClickEdit(e, row)}
                                  />
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {/* {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )} */}
        {showEditFeeModal && (
          <FeesModal
            onClose={() => setShowEditFeeModal(false)}
            selectedFee={selectedFee}
            isEdit={true}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  feeListLoading: state.feeListData?.loading,
  feeListData: state.feeListData?.data,
  addFeeData: state.addFeeData.data,
  updateFeeData: state.updateFeeData.data,
});

export default connect(mapStateToProps, {
  fetchFeeList,
  changeFeeStatus,
})(Fees);
