import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  layout_conainer: {
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: Colors.BRIGHT_GRAY
  },
  main_layout: {
    width: '100%',
    margin: '0px 0px 24px 0px',
    overflow: 'hidden',
    height: '100vh',
  },
}));
