import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'flex-end',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 559,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  selection_input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  sub_title: {
    // marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
  flex: {
    flex: 1,
  },
  toggle_btn: {
    // height: '24px !important',
    // width: '39px !important',
    // marginTop: '10 !important'
    // display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important',
      '& .MuiSwitch-root': {
        marginRight: '0px !important',
      },
    },
  },
  input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  flex_row: {
    display: 'flex !important',
    flexDirection: 'row !important',
    gap: 15,
    width: '100%',
  },
  outlined_button: {
    marginTop: '20px !important',
    width: '100% !important',
    borderRadius: '50px !important',
  },
  top_margin: {
    marginTop: 15,
  },
  doNotBill_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  flex_column: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sub_heading: {
    fontSize: '14px !important',
    color: Colors.DAVYS_GREY,
  },
  price_text: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginLeft: '12px !important',
    color: Colors.FELDGRAU,
  },
  radio_button_container: {
    marginTop: 15,
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
  },
  add_delivery_button: {
    marginTop: '20px !important',
    width: '100% !important',
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  optionErrorText: {
    color: Colors.FUZZY_WUZZY,
    fontWeight: 600,
    fontFamily: 'Lato, sans-serif',
    fontSize: 12,
  },
  disabled_input: {
    marginTop: '15px !important',
    width: '100% !important',
    '& .MuiInputBase-input.Mui-disabled': {
      '-webkit-text-fill-color': `${Colors.DAVYS_GREY} !important`,
    },
    '& .MuiInput-root': {
      '&::before': {
        borderBottom: `2px solid ${Colors.CHINESE_SILVER} !important`,
      },
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: `${Colors.DAVYS_GREY} !important`,
    },
  },
  textbox: {
    border: 'none',
    padding: 8,
    fontSize: 16,
    color: Colors.DAVYS_GREY,
    fontFamily: 'Lato',
    outline: 'none',
    fontWeight: 400,
    width: '100%',
    resize: 'none',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
  },
  label_text: {
    fontSize: '15px !important',
    color: Colors.FELDGRAU,
    fontFamily: `Lato, sans-serif !important`,
  },
  disabled_label_text: {
    opacity: 0.5,
  },
  item_container: {
    cursor: 'pointer',
    padding: '10px 12px',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
    '&:hover': {
      borderBottom: `2px solid ${Colors.FELDGRAU}`,
    },
  },
  disabledItemContainer: {
    cursor: 'default',
    borderBottom: `2px dotted ${Colors.CHINESE_SILVER}`,
    '&:hover': {
      borderBottom: `2px dotted ${Colors.CHINESE_SILVER}`,
    },
  },
  tags: {
    padding: '0 8px',
    color: Colors.BLACK,
    backgroundColor: Colors.EDGE_WATER,
    borderRadius: 3,
  },
  placeHolderText: {
    fontSize: '15px !important',
    color: Colors.FELDGRAU,
    fontFamily: `Lato, sans-serif !important`,
    opacity: 0.5,
  },
  errorText: {
    color: `${Colors.FUZZY_WUZZY} !important`,
    margin: '0 !important',
    fontSize: '12px !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '600 !important',
  },
  errorBorder: {
    cursor: 'default',
    borderBottom: `2px solid ${Colors.FUZZY_WUZZY} !important`,
    '&:hover': {
      borderBottom: `2px solid ${Colors.FUZZY_WUZZY} !important`,
    },
  },
}));
