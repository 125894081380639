import axios from 'axios';
import { DownloadCSVorPDF } from '../../../utils/appUtils';
import { GENERATE_PULL_LIST } from '../../constants';

export const generatePullList =
  (data, onError, fileName, isShowPdf, onCallBack) => (dispatch) => {
    const token = localStorage.jwtToken;
    dispatch(loadGeneratePullList());
    axios({
      method: 'post',
      url: `/delivery/generatePullList`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
      .then((res) => {
        !isShowPdf && DownloadCSVorPDF(res.data, `${fileName}.pdf`);
        dispatch(setGeneratePullList(res));
        onCallBack(res.data);
      })
      .catch(async (err) => {
        const isJsonBlob = (data) =>
          data instanceof Blob && data.type === 'application/json';
        const responseData = isJsonBlob(err.response?.data)
          ? await err.response?.data?.text()
          : err.response?.data || {};
        const responseJson =
          typeof responseData === 'string'
            ? JSON.parse(responseData)
            : responseData;

        dispatch(errorGeneratePullList({ data: responseJson }));
        onError({ data: responseJson });
      });
  };

export const loadGeneratePullList = () => ({
  type: GENERATE_PULL_LIST.LOAD,
});

export const setGeneratePullList = (data) => ({
  type: GENERATE_PULL_LIST.SUCCESS,
  payload: data,
});

export const errorGeneratePullList = (error) => ({
  type: GENERATE_PULL_LIST.FAIL,
  payload: error,
});
