import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  dialog_mask_container: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_box_container: {
    backgroundColor: Colors.WHITE,
    width: 600,
    padding: 25,
    height: 'fit-content',
    maxHeight: '70%',
    overflow: 'auto',
    borderRadius: 12,
  },
  dialog_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  dialog_content_container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 15,
    marginTop: 20,
  },
  radio_button_container: {
    marginTop: 25,
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
  },
  flex_row: {
    display: 'flex !important',
    flexDirection: 'row !important',
    gap: 15,
    width: '100%',
  },
  optionErrorText: {
    color: Colors.FUZZY_WUZZY,
    fontWeight: 600,
    fontFamily: 'Lato, sans-serif',
    fontSize: 12,
  },
  dialog_footer_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
  },
  save_button: {
    minWidth: 'fit-content !important',
    width: '140px !important',
    height: '45px !important',
    flexGrow: 'inherit !important',
  }
}));
