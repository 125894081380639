import axios from 'axios';
import { GET_USER } from '../../constants';

export const getUser = (uID, onError, isClientsRequired) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetUser());
  axios
    .get(`/admin/getUser/${uID}`, config)
    .then((res) => {
      const data = res?.data?.data;
      if (isClientsRequired && data[0]?.role !== 'employee') {
        axios
          .get(`/admin/listClients?userId=${uID}`, config) //added api here for list clients in user details modal
          .then((response) => {
            const newData = data?.map((item) => {
              item.clients = response?.data?.data || [];
              return item;
            });
            dispatch(setGetUser(newData[0]));
          })
          .catch((err) => {
            dispatch(setGetUser(data[0])); // temporary set here due to listClients api is not deployed so
            dispatch(errorGetUser(err.response));
            onError(err.response);
          });
      } else {
        dispatch(setGetUser(data[0]));
      }
    })
    .catch((err) => {
      dispatch(errorGetUser(err.response));
      onError(err.response);
    });
};

export const loadGetUser = () => ({
  type: GET_USER.LOAD,
});

export const setGetUser = (data) => ({
  type: GET_USER.SUCCESS,
  payload: data,
});

export const errorGetUser = (error) => ({
  type: GET_USER.FAIL,
  payload: error,
});
