import React, { useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import classNames from 'classnames';
import Loader from '../Loader';
import { Colors } from '../../common/colors';

export default function NotesInput(props) {
  const classes = useStyles();
  //   const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      className={classNames(classes.container, props.className)}
      style={{ border: props?.isEditNote && `1px solid ${Colors.SEA_GREEN}` }}
    >
      <textarea
        className={classNames(
          classes.textbox,
          props.inputClass && props.inputClass
        )}
        rows={'3'}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        ref={props.textAreaRef}
      />
      {props?.loader ? (
        <div className={classes.send_icon}>
          <Loader color={Colors.SEA_GREEN} size={22} />
        </div>
      ) : (
        <img
          src={icons().send}
          className={classes.send_icon}
          onClick={props.onClickSend}
        />
      )}
    </div>
  );
}
