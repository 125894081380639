import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    right: 0,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.71)',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 872,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  flex_column: {
    display: 'flex',
    flexDirection: 'column',
  },
  icons: {
    height: 25,
    width: 25,
  },
  align_center: {
    alignItems: 'center',
  },
  margin_top_15: {
    marginTop: '15px !important',
  },
  margin_top_10: {
    marginTop: '10px !important',
  },
  input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  gap_30: {
    gap: 30,
  },
  sub_heading: {
    marginTop: '30px !important',
    color: Colors.BLACK,
  },
  null_div: {
    width: '180px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    color: Colors.DAVYS_GREY,
    width: '100% !important',
  },
  labour_row_bg: {
    backgroundColor: Colors.BRIGHT_GRAY,
    color: Colors.FELDGRAU,
    padding: '8px 8px 8px 0px',
    alignItems: 'center',
  },
  add_fee_button: {
    marginTop: '20px !important',
    width: '100% !important',
  },
}));
