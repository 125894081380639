import { makeStyles } from '@mui/styles';


export default makeStyles((theme) => ({
  date_calendar_button: {
    textAlign: 'left',
    borderRadius: 8,
    border: '1px solid #CDCDCD',
    background: 'transparent',
    padding: '10px 14px',
    '&:hover': {
      color: 'white',
      background: 'transparent',
    },
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'IBM Plex Sans',
    fontWeight: 100,
    fontSize: 15,
  },
  btnsView: {
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    gap: 15,
  },
  custom_button: {
    '& .MuiButtonBase-root': {
      borderBottom:'1px solid black',
      backgroundColor:'red'
    },
  },
  main_container : {
    display:'flex',
  }

}));
