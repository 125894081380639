import axios from 'axios';
import { UPDATE_NOTE } from '../../constants';

export const updateNote = (noteID, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateNote());
  axios
    .put(`/admin/updateNote?noteId=${noteID}`, data, config)
    .then((res) => {
      dispatch(setUpdateNote(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateNote(err.response));
      onError(err.response);
    });
};

export const loadUpdateNote = () => ({
  type: UPDATE_NOTE.LOAD,
});

export const setUpdateNote = (data) => ({
  type: UPDATE_NOTE.SUCCESS,
  payload: data,
});

export const errorUpdateNote = (error) => ({
  type: UPDATE_NOTE.FAIL,
  payload: error,
});
