import { Strings } from './strings';
import { icons } from '../assets';

const menuList = (theme) => {
  const list = [
    {
      icon: icons(theme).users,
      title: Strings.USERS.toLowerCase(),
      title_sentence_case: Strings.USERS,
      path: '/app/users',
    },
    {
      icon: icons(theme).incidents,
      title: Strings.INCIDENTS.toLowerCase(),
      title_sentence_case: Strings.INCIDENTS,
      path: '/app/incidents',
    },
    {
      icon: icons(theme).vendors,
      title: Strings.VENDORS.toLowerCase(),
      title_sentence_case: Strings.VENDORS,
      path: '/app/vendors',
    },
    {
      icon: icons(theme).rate_sheet,
      title: Strings.RATE_SHEET.toLowerCase(),
      title_sentence_case: Strings.RATE_SHEET,
      path: '/app/rate-sheet',
    },
    {
      icon: icons(theme).fees,
      title: Strings.FEES.toLowerCase(),
      title_sentence_case: Strings.FEES,
      path: '/app/fees',
    },
    {
      icon: icons(theme).clients,
      title: Strings.CLIENTS.toLowerCase(),
      title_sentence_case: Strings.CLIENTS,
      path: '/app/clients',
    },
    {
      icon: icons(theme).inventory,
      title: Strings.INVENTORY.toLowerCase(),
      title_sentence_case: Strings.INVENTORY,
      path: '/app/inventory',
    },
    {
      icon: icons(theme).location,
      title: Strings.LOCATION.toLowerCase(),
      title_sentence_case: Strings.LOCATION,
      path: '/app/location',
    },
    {
      icon: icons(theme).deliveries,
      title: Strings.DELIVERIES.toLowerCase(),
      title_sentence_case: Strings.DELIVERIES,
      path: '/app/deliveries',
    },
    {
      icon: icons(theme).invoice,
      title: Strings.INVOICE.toLowerCase(),
      title_sentence_case: Strings.INVOICE,
      path: '/app/invoice',
    },
    {
      icon: icons(theme).report,
      title: Strings.REPORT.toLowerCase(),
      title_sentence_case: Strings.REPORT,
      path: '/app/report',
    },
    // {
    //   icon: icons(theme).settings,
    //   title: Strings.SETTINGS.toLowerCase(),
    //   path: '/app/settings',
    // },
  ];
  return list;
};
const venderMenuList = (theme) => {
  const list = [
    {
      icon: icons(theme).incidents,
      title: Strings.INCIDENTS.toLowerCase(),
      title_sentence_case: Strings.INCIDENTS,
      path: '/app/incidents',
    },
  ];
  return list;
};

const designerMenuList = (theme) => {
  const list = [
    {
      icon: icons(theme).incidents,
      title: Strings.INCIDENTS.toLowerCase(),
      title_sentence_case: Strings.INCIDENTS,
      path: '/app/incidents',
    },
    {
      icon: icons(theme).inventory,
      title: Strings.INVENTORY.toLowerCase(),
      title_sentence_case: Strings.INVENTORY,
      path: '/app/inventory',
    },
  ];
  return list;
};
export { menuList, venderMenuList, designerMenuList };
