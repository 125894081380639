import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'flex-end',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 872,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  flex_column: {
    display: 'flex',
    flexDirection: 'column',
  },
  icons: {
    height: 25,
    width: 25,
    cursor: 'pointer',
  },
  align_center: {
    alignItems: 'center',
  },
  justify_center: {
    justifyContent: 'center',
  },
  position: {
    width: 'fit-content',
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
    cursor: 'pointer',
    marginTop: 25,
    marginBottom: 25,
  },
  name_view: {
    display: 'flex',
    width: '100%',
    paddingBottom: 5,
    // marginLeft: 25,
  },
  email_icon: {
    height: 16,
    width: 16,
  },
  email_container: {
    marginTop: 15,
    gap: 10,
    color: Colors.SEA_GREEN,
  },
  sub_heading: {
    marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
  options_checkbox: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '50%',
  },
  prefered_contact_value: {
    marginTop: '10px !important',
    color: Colors.FELDGRAU,
  },
  barcode_data_container: {
    paddingBottom: 10,
    borderBottom: `1px solid ${Colors.BRIGHT_GRAY}`,
  },
  status_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    margin: '25px 0',
    justifyContent: 'space-between',
  },
  // TAB
  tab_main_container: {
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
  },
  tab_header: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 25,
    marginBottom: 10,
    gap: 35,
  },
  tag: {
    border: `1px solid ${Colors.NEW_CAR}`,
    color: Colors.NEW_CAR,
  },
  incident_info_value: {
    color: Colors.RAISIN_BLACK,
  },
  designer_value: {
    backgroundColor: Colors.POWDER_BLUE,
    borderRadius: 40,
    padding: '5px 15px 5px 15px',
    width: 'fit-content',
  },
  top_5: {
    marginTop: 5,
  },
  vendor_value: {
    backgroundColor: Colors.BRIGHT_GRAY,
    borderRadius: 40,
    padding: '5px 15px 5px 15px',
    width: 'fit-content',
  },
  images_container: {
    display: 'flex',
    padding: '20px 20px 10px 20px',
  },
  image_row_container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    maxHeight: 143,
    overflow: 'hidden',
  },
  image_row_min_container: {
    maxHeight: 143,
    overflow: 'hidden',
  },
  image_row_max_container: {
    maxHeight: 295,
    overflow: 'auto',
  },
  image_item_container: {
    width: 'calc(20% - 8px)',
    maxHeight: 142,
    borderRadius: 4,
    display: 'flex',
  },
  images: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    cursor: 'pointer',
  },
  drop_down_icon: {
    cursor: 'pointer',
  },
  drop_down_up_icon: {
    transform: 'rotate(180deg)',
  },
  show_more_txt: {
    color: Colors.SEA_GREEN,
    gap: 10,
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  selection_input: {
    // marginTop: '15px !important',
    width: '100% !important',
  },
  save_button: {
    width: '143px !important',
    height: '48px !important',
    marginRight: '16px !important',
    marginTop: '15px !important',
    alignSelf: 'flex-end',
  },
  incident_status: {
    width: 'fit-content',
    borderRadius: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px 5px 10px',
  },
  estimate_tag: {
    border: `1px solid ${Colors.NEW_CAR}`,
    color: Colors.NEW_CAR,
  },
  pending_estimate_tag: {
    border: `1px solid ${Colors.ORANGE}`,
    color: Colors.ORANGE,
  },
  pending_assignment_tag: {
    border: `1px solid ${Colors.LUST}`,
    color: Colors.LUST,
  },
  approved_status: {
    background: Colors.POWDER_BLUE,
    color: Colors.SEA_GREEN,
  },
  declined_status: {
    background: Colors.DARK_YELLOW,
    color: Colors.CHOCOLATE_BROWN,
  },
  assignToDesignerBtn: {
    width: '200px !important',
    height: 60,
    alignSelf: 'center',
  },
  complete_btn: {
    height: 40,
    alignSelf: 'center',
    padding: '0 20px',
  },
  complete_tag: {
    border: `1px solid ${Colors.SEA_GREEN}`,
    color: Colors.SEA_GREEN,
  },
}));
