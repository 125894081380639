import React, { useRef, useState, useEffect } from 'react';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import NotesInput from '../NotesInput';
import UserNotes from '../UserNotes';
import useWindowDimensions from '../../Hooks/windowDimention';
import { Typography } from '@mui/material';

const NotesTabContainer = (props) => {
  const classes = useStyles();
  const data = props?.notes || [];
  // const [data, setData] = useState(props?.notes || []);
  const [note, setNote] = useState('');
  const { width, height } = useWindowDimensions();
  const scrollRef = useRef(null);
  const textAreaRef = useRef(null);
  const [selectedNote, setSelectedNote] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);

  const onClickSend = async () => {
    if (note !== '') {
      const noteData = {
        userId: props?.selectedUser,
        note: note,
      };
      await props.addNote(
        noteData,
        (data) => {
          props.setNotesData([...props.notesData, data?.data]);
        },
        props?.onError
      );
    }
  };

  const onKeyDownHandler = async (e) => {
    if (width > 1024 && e.keyCode === 13 && !e.shiftKey) {
      // (Enter button) Key KeyCode - (shift + enter) to go next line
      e.preventDefault(); // Prevent textfield newline when enter
      await sendNote();
    }
  };

  const onClickDeleteNote = async (e) => {
    e.preventDefault();
    if (selectedNote?._id) {
      const newData = props.notesData?.filter((i) => {
        return i._id !== selectedNote._id;
      });
      props.setNotesData([...newData]);
      await props?.deleteNote(
        selectedNote._id,
        props.onError
      );
    }
  };

  const handleMenuClick = async (event, item) => {
    await setSelectedNote(item);
    if (event === 'Delete') {
      await setShowDeleteDialog(true);
    } else {
      await setIsEditNote(true);
      await setNote(item.note);
    }
  };

  const updateNote = async () => {
    if (note !== '') {
      const data = {
        note: note,
      };
      await props.updateNote(
        selectedNote._id,
        data,
        () => {
          const newData = props?.notesData?.map((i) => {
            if (i._id === selectedNote._id) {
              i.note = note;
            }
            return i;
          });
          props.setNotesData([...newData]);
          setIsEditNote(false);
        },
        props.onError
      );
    }
  };

  const sendNote = () => {
    if (isEditNote) {
      updateNote();
    } else {
      onClickSend();
    }
  };
  return (
    <div className={classes.tab_container} onKeyDown={onKeyDownHandler}>
      <NotesInput
        placeholder={Strings.TYPE_HERE}
        onChange={(e) => setNote(e.target.value)}
        value={note}
        onClickSend={sendNote}
        loader={props.addNoteDataLoading || props.updateNoteDataLoading}
        textAreaRef={textAreaRef}
        isEditNote={isEditNote}
      />
      <div ref={scrollRef} className={classes.notes_list}>
        {data?.length > 0 ? (
          data?.map((item, index) => {
            return (
              <UserNotes
                data={item}
                index={index}
                key={index + 'note'}
                lastIndex={data.length - 1}
                handleMenuClick={handleMenuClick}
                onClickDeleteNote={onClickDeleteNote}
                deleteNoteDataLoading={props?.deleteNoteDataLoading}
                showDeleteDialog={
                  selectedNote?._id === item?._id && showDeleteDialog
                }
                setShowDeleteDialog={setShowDeleteDialog}
              />
            );
          })
        ) : (
          <Typography variant='p_400' className={classes.no_notes} mt={2}>
            {Strings.THERE_IS_NO_NOTES}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default NotesTabContainer;
