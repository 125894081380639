import axios from 'axios';
import { ADD_RATE_SHEET } from '../../constants';

export const createRateSheet = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addRateSheet());

  axios
    .post(`/ratesheet/createRatesheet`, data, config)
    .then((res) => {
      dispatch(setAddRateSheet(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorAddRateSheet(err.response));
      onError(err.response);
    });
};

export const addRateSheet = () => ({
  type: ADD_RATE_SHEET.LOAD,
});

export const setAddRateSheet = (data) => ({
  type: ADD_RATE_SHEET.SUCCESS,
  payload: data.data,
});

export const errorAddRateSheet = (error) => ({
  type: ADD_RATE_SHEET.FAIL,
  payload: error,
});
