import axios from 'axios';
import { ADD_VENDOR } from '../../constants';

export const createVendor = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addVendor());

  axios
    .post(`/vendor/createVendor`, data, config)
    .then((res) => {
      dispatch(setAddVendor(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorAddVendor(err.response));
      onError(err.response);
    });
};

export const addVendor = () => ({
  type: ADD_VENDOR.LOAD,
});

export const setAddVendor = (data) => ({
  type: ADD_VENDOR.SUCCESS,
  payload: data.data,
});

export const errorAddVendor = (error) => ({
  type: ADD_VENDOR.FAIL,
  payload: error,
});
