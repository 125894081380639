import axios from 'axios';
import { INCIDENT_LIST } from '../../constants';

export const fetchIncidentList =
  (search, type, pageSize, pageNumber, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadIncidentList());
    }

    axios
      .get(
        `/incident/listIncidents?searchString=${search}&employeeFilter=${type}&page_size=${pageSize}&page_number=${pageNumber}`,
        config
      )
      .then((res) => dispatch(setIncidentList(res)))
      .catch((err) => {
        dispatch(errorIncidentList(err.response));
        onError(err.response);
      });
  };

export const loadIncidentList = () => ({
  type: INCIDENT_LIST.LOAD,
});

export const setIncidentList = (incidentList) => ({
  type: INCIDENT_LIST.SUCCESS,
  payload: incidentList.data.data,
});

export const errorIncidentList = (error) => ({
  type: INCIDENT_LIST.FAIL,
  payload: error,
});
