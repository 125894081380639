import { makeStyles } from '@mui/styles';
import { icons } from '../../assets';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  root: {
    padding: '0px !important',
    color: `${Colors.AMERICAN_SILVER} !important`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 4,
    width: 24,
    height: 24,
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.AMERICAN_SILVER}`,
  },
  checked_icon: {
    backgroundColor: Colors.SEA_GREEN,
    border: `1px solid ${Colors.SEA_GREEN}`,
    '&:before': {
      display: 'block',
      width: '100%',
      height: '100%',
      background: `url(${icons().true_icon_white}) no-repeat center`,
      content: '""',
    },
  },
  checked_indeterminate: {
    backgroundColor: Colors.SEA_GREEN,
    border: `1px solid ${Colors.SEA_GREEN}`,
    '&:before': {
      display: 'block',
      width: '100%',
      height: '100%',
      background: `url(${icons().dash_icon_white}) no-repeat center`,
      content: '""',
    },
  },

}));
