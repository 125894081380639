import React, { useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import { TextField, Pagination, Stack, PaginationItem } from '@mui/material';
import { PAGE_SIZE } from '../../utils/appUtils';

export default function CustomPagination(props) {
  const classes = useStyles();
  const theme = useTheme();
  const PageSize = props.pageSize || PAGE_SIZE;
  const count = Math.ceil(props.totalRecords / PageSize) || 0;
  return (
    <>
      <Stack spacing={2}>
        <Pagination
          count={count}
          size='large'
          page={props.pageCount}
          shape='rounded'
          onChange={(e, p) => props.handleChange(e, p)}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: () => <img src={icons().left_arrow} />,
                next: () => <img src={icons().right_arrow} />,
              }}
              {...item}
            />
          )}
        />
      </Stack>
    </>
  );
}
