import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  input_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input_label: {
    color: Colors.FELDGRAU,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Lato, sans-serif',
  },
  input_field: {
    width: '100%',
    border: 'none',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
    padding: 12,
    '&:hover': {
      borderBottom: `2px solid ${Colors.BLACK}`,
    },
  },
  error_text: {
    color: Colors.FUZZY_WUZZY,
    fontWeight: 600,
    fontFamily: 'Lato, sans-serif',
    fontSize: 12,
  },
  tagsElementContainer: {
    maxHeight: 90,
    overflow: 'auto',
  },
}));
