import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: Colors.WHITE,
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  textbox: {
    border: 'none',
    padding: 5,
    fontSize: 16,
    borderRadius: 8,
    color: Colors.RAISIN_BLACK,
    fontFamily: 'Lato',
    outline: 'none',
    fontWeight: 400,
    width: '100%',
    resize: 'none',
  },
  send_icon: {
    height: 25,
    width: 23,
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
}));
