import React from 'react';
import { TextField } from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';

export default function SearchInput(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <TextField
      placeholder={props.placeholder}
      variant='standard'
      InputLabelProps={{
        shrink: true,
      }}
      value={props.value}
      onChange={props.onChange}
      className={classNames(classes.input, props.className)}
      InputProps={{
        startAdornment: (
          <img src={icons().search} alt='' className={classes.search_icon} />
        ),
      }}
      classes={{ root: classNames(classes.selection_root, props.inputBase) }}
    />
  );
}
