import React, { useState, useEffect } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography } from '@mui/material';
import { Strings } from '../../common/strings';

export default function ClientsTabContainer(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props?.clients);
  }, [props?.clients]);

  return (
    <div className={classes.tab_container}>
      {data?.length > 0 ? (
        data?.map((item, index) => {
          return (
            <div
              key={index}
              className={classes.clients_item}
              onClick={() => {
                props.setSelectedClient(item);
                props.setShowClientDetail(true);
              }}
            >
              <Typography variant='p_400' className={classes.ref_number}>
                {item?.ID}
              </Typography>

              <Typography variant='p_700' className={classes.company_name}>
                {item?.client}
              </Typography>

              <Typography variant='p_400' className={classes.company_addr}>
                {item?.primaryAddress?.address}
              </Typography>

              {/* <div className={classes.icon_container}>
                <img src={icons().edit} className={classes.edit_icon} />
              </div> */}
            </div>
          );
        })
      ) : (
        <Typography
          variant='p_400'
          className={classes.no_notes}
          mt={2}
          alignSelf='center'
        >
          {Strings.THERE_IS_NO_CLIENTS}
        </Typography>
      )}
    </div>
  );
}
