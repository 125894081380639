import * as React from 'react';
import moment from 'moment';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { icons } from '../../assets';
import useStyles from './styles';
import classNames from 'classnames';

export default function CustomeTimePicker(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(moment('2022-04-07'));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileTimePicker
        value={props?.value}
        onChange={(newValue) => {
          props?.onChange(newValue._d);
        }}
        disabled={props.disabled}
        InputLabelProps={{ shrink: true }}
        className={classNames(props.className, classes.time_picker_root)}
        renderInput={(params) => (
          <TextField
            id='filled-required'
            variant='standard'
            {...params}
            label={props.label}
            error={props.error}
            helperText={props.helperText}
            placeholder={props.placeholder}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <img
                  src={icons().timer_feldgrau}
                  alt=''
                  className={classes.time_picker_icon}
                />
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
