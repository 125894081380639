import axios from 'axios';
import { GET_USER_BY_ID } from '../../constants';

export const getUserById = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(getUserByIdData());

  axios
    .get(`/admin/getUserById`, config)
    .then((res) => {
      dispatch(setGetUserById(res));
    })
    .catch((err) => {
      dispatch(errorGetUserById(err.response));
      onError(err.response);
    });
};

export const getUserByIdData = () => ({
  type: GET_USER_BY_ID.LOAD,
});

export const setGetUserById = (data) => ({
  type: GET_USER_BY_ID.SUCCESS,
  payload: data.data,
});

export const errorGetUserById = (error) => ({
  type: GET_USER_BY_ID.FAIL,
  payload: error,
});
