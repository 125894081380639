import React, { useEffect, useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import {
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import Selection from '../Selection';
import classNames from 'classnames';
import CustomeDatePicker from '../CustomDatePicker';
import CustomTimePicker from '../CustomTimePicker';
import { Form, FormikProvider, useFormik } from 'formik';
import { connect } from 'react-redux';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { fetchClientList } from '../../redux/actions/rateSheetActions/clientList';
import { createInventory } from '../../redux/actions/inventoryActions/addInventory';
import { getInventory } from '../../redux/actions/inventoryActions/getInventory';
import { updateInventory } from '../../redux/actions/inventoryActions/updateInventory';
import { useSnackbar } from 'notistack';
import { getErrorMessage, isDesigner, sizeList } from '../../utils/appUtils';
import Loader from '../Loader';
import moment from 'moment';
import { AddInventorySchema } from '../../utils/validationSchemas';
import ToggleButton from '../ToggleButton';

const nextPrevActionList = [
  {
    icon: icons().left_arrow,
    disableIcon: icons().left_arrow_disable,
    label: 'Previous',
    value: 'previous',
  },
  {
    icon: icons().right_arrow,
    disableIcon: icons().right_arrow_disable,
    label: 'Next',
    value: 'next',
  },
];

const AddInventoryModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [designers, setDesigners] = useState([]);
  const [clients, setClients] = useState([]);
  const [designerId, setDesignerId] = useState('');
  //   const [anchorEl, setAnchorEl] = useState(null);
  const [inventories, setInventories] = useState([]);
  const [inventoryId, setInventoryId] = useState(props?.selectedID ?? null);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [page, setPage] = useState(props?.pageNumber);
  const totalRecords = props?.inventoryListData?.page_count;

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props?.isEdit && props?.pageNumber) {
      setPage(props?.pageNumber);
      props?.fetchInventories(props?.pageNumber, 'listForNextPrev');
    }
  }, [props?.pageNumber, props?.isEdit]);

  useEffect(() => {
    setDesigners(props.designerList || []);
  }, [props.designerList]);

  useEffect(() => {
    if (props.isDelivery || isDesigner())
      props.fetchDesignerList((err) => onError(err));
  }, [props?.isDelivery]);

  useEffect(() => {
    if (designerId && designerId !== 0) {
      props.fetchClientList(designerId, onError, false);
    }
  }, [designerId]);

  useEffect(() => {
    const designerData = props.designerListData?.map((el) => ({
      label: `${el?.name} (${el?.designerCode})`,
      value: el?._id,
    }));
    const clientData = props.clientListData?.map((el) => ({
      label: `${el?.client} (${el?.clientCode})`,
      value: el?._id,
    }));
    setDesigners(designerData);
    setClients(clientData);
  }, [props.designerListData, props.clientListData]);

  useEffect(() => {
    if (props?.isEdit && inventoryId) {
      props.getInventory(inventoryId, (err) => onError(err));
    }
  }, [inventoryId, props?.isEdit]);

  useEffect(() => {
    if (props?.getInventoryData && props?.isEdit) {
      const data = props?.getInventoryData;
      setFieldValue('client', data?.client || '');
      setFieldValue('designer', data?.designer || '');
      setFieldValue('poReference', data?.poReference || '');
      setFieldValue('description', data?.description || '');
      setFieldValue('size', data?.size || '');
      setFieldValue('weight', data?.weight || '');
      setFieldValue('location', data?.location || '');
      setFieldValue('freightLine', data?.freightLine || '');
      setFieldValue('declaredValue', Number(data?.declaredValue) || '');
      setFieldValue('noOfBarket', data?.noOfBarket || '');
      setFieldValue('receivedDate', data?.receivedDate || '');
      // setFieldValue('receivedTime', data?.receivedDate || '');
      setFieldValue('deliveredDate', data?.deliveredDate || '');
      setFieldValue('deliveredTime', data?.deliveredDate || '');
      setDesignerId(data?.designer || '');
      setFieldValue('manufacturer', data?.manufacturer || '');
    }
  }, [props.getInventoryData]);

  const formik = useFormik({
    initialValues: {
      client: '',
      designer: '',
      poReference: '',
      description: '',
      size: '',
      weight: '',
      location: '',
      freightLine: '',
      declaredValue: '',
      noOfBarket: '',
      receivedDate: '',
      // receivedTime: '',
      deliveredDate: '',
      deliveredTime: '',
      copy: false,
      copyCount: 1,
      manufacturer: '',
    },
    validationSchema: AddInventorySchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const handleDataSubmit = (value, isNextPrev) => {
    // let receivedDate = moment(
    //   moment(value?.receivedDate).format('YYYY-MM-DD') +
    //     ' ' +
    //     moment(value?.receivedTime).format('HH:mm:ss'),
    // ).format();
    let receivedDate = moment(value?.receivedDate).format();
    let deliveredDate =
      value?.deliveredDate && value?.deliveredTime
        ? moment(
            moment(value?.deliveredDate).format('YYYY-MM-DD') +
              ' ' +
              moment(value?.deliveredTime).format('HH:mm:ss')
          ).format()
        : value?.deliveredDate
        ? moment(value?.deliveredDate).format()
        : '';
    const data = {
      client: value?.client,
      designer: value?.designer,
      poReference: value?.poReference,
      description: value?.description,
      size: value?.size,
      weight: value?.weight,
      location: value?.location,
      freightLine: value?.freightLine,
      declaredValue: value?.declaredValue,
      noOfBarket: value?.noOfBarket,
      receivedDate: receivedDate,
      deliveredDate: deliveredDate,
      copy: value?.copy,
      copyCount: value?.copyCount,
      manufacturer: value?.manufacturer,
    };
    // Object.keys(data).forEach((key) => {
    //   if (data[key] === '' || data[key] === 0) delete data[key];
    // });
    setHasDataChanged(false);
    if (!data?.copy) delete data?.copyCount;
    if (props.isEdit) {
      // delete data?.copy;
      props.updateInventory(
        inventoryId,
        data,
        (data) => (isNextPrev ? {} : props.onClose(data)),
        onError
      );
    } else {
      props.createInventory(
        data,
        () => props.onClose(),
        (err) => onError(err)
      );
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    onChange,
  } = formik;

  useEffect(() => {
    const data = props.inventoryListData?.inventories;
    setInventories(data);
  }, [props.inventoryListData?.inventories]);

  const isNextPrevDisabled = (type) => {
    const copyInventories = structuredClone(inventories);
    let fIndex = copyInventories?.findIndex((el) => el?._id === inventoryId);
    if (type === 'next')
      return fIndex === copyInventories?.length - 1 && page >= totalRecords;
    else if (type === 'previous') return fIndex <= 0 && page <= 1;
  };

  const handleChangeFields = (field, value) => {
    setFieldValue(field, value);
    if (props?.isEdit) setHasDataChanged(true);
  };

  const handleNextPrevClick = (type) => {
    if (hasDataChanged) handleDataSubmit(values, true);
    const copyInventories = structuredClone(inventories);
    let fIndex = copyInventories?.findIndex((el) => el?._id === inventoryId);
    if (type === 'next') {
      if (fIndex >= 9 && page < totalRecords) {
        setPage(page + 1);
        props?.fetchInventories(page + 1, 'listForNextPrev', (res) => {
          setInventoryId(res.inventories[0]?._id);
        });
      } else setInventoryId(copyInventories[fIndex + 1]?._id);
    } else if (type === 'previous') {
      if (fIndex <= 0 && page > 1) {
        setPage(page - 1);
        props?.fetchInventories(page - 1, 'listForNextPrev', (res) => {
          setInventoryId(res.inventories[9]?._id);
        });
      } else setInventoryId(copyInventories[fIndex - 1]?._id);
    }
  };

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <Stack direction='row' alignItems='center' gap={1.25}>
                <Typography variant='h5_700'>
                  {props.isShowDetails
                    ? `${Strings.ITEM}(${
                        props?.getInventoryData?.wsiNumber || ''
                      })`
                    : props?.isEdit
                    ? `${Strings.EDIT_ITEM}(${
                        props?.getInventoryData?.wsiNumber || ''
                      })`
                    : Strings.NEW_ITEM}
                </Typography>
                {props?.isEdit && !props.isDelivery ? (
                  <Stack direction='row' alignItems='center' gap={2.5}>
                    {nextPrevActionList?.map((_el) => (
                      <Tooltip arrow title={_el?.label}>
                        <IconButton
                          disabled={isNextPrevDisabled(_el?.value)}
                          onClick={() => handleNextPrevClick(_el?.value)}
                        >
                          <img
                            src={
                              isNextPrevDisabled(_el?.value)
                                ? _el?.disableIcon
                                : _el?.icon
                            }
                            alt={_el?.label}
                          />
                        </IconButton>
                      </Tooltip>
                    ))}
                  </Stack>
                ) : null}
              </Stack>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => props.onClose()}
              />
            </div>
            {props.designerListLoading || props.getInventoryLoading ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <>
                <Selection
                  isSearchable
                  containerClassName={classNames(
                    classes.selection_input,
                    props.isShowDetails && classes.disabled_input
                  )}
                  label={Strings.DESIGNER}
                  handleChange={(e, value) => {
                    if (value) {
                      handleChangeFields('designer', value);
                      setDesignerId(value);
                      setFieldValue('client', '');
                    }
                  }}
                  value={values.designer}
                  placeHolder={Strings.SELECT}
                  options={designers}
                  error={Boolean(touched.designer && errors.designer)}
                  helperText={touched.designer && errors.designer}
                  disabled={props.isShowDetails}
                  isShowDetails={props.isShowDetails}
                />
                <Selection
                  isSearchable
                  containerClassName={classNames(
                    classes.selection_input,
                    props.isShowDetails && classes.disabled_input
                  )}
                  label={Strings.CLIENT}
                  handleChange={(e, value) =>
                    value && handleChangeFields('client', value)
                  }
                  value={values.client}
                  placeHolder={Strings.SELECT}
                  options={clients}
                  error={Boolean(touched.client && errors.client)}
                  helperText={touched.client && errors.client}
                  disabled={
                    props.clientListLoading ||
                    !designerId ||
                    props.isShowDetails
                  }
                  isShowDetails={props.isShowDetails}
                />

                <TextField
                  id='filled-required'
                  label={Strings.PO_REFERENCE}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classNames(
                    classes.input,
                    classes.top_margin,
                    props.isShowDetails && classes.disabled_input
                  )}
                  value={values.poReference}
                  onChange={(e) =>
                    handleChangeFields('poReference', e.target.value)
                  }
                  error={Boolean(touched.poReference && errors.poReference)}
                  helperText={touched.poReference && errors.poReference}
                  disabled={props.isShowDetails}
                />

                <TextField
                  id='filled-required'
                  label={Strings.MANUFACTURER}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classNames(
                    classes.input,
                    classes.top_margin,
                    props.isShowDetails && classes.disabled_input
                  )}
                  value={values.manufacturer}
                  onChange={(e) =>
                    handleChangeFields('manufacturer', e.target.value)
                  }
                  error={Boolean(touched.manufacturer && errors.manufacturer)}
                  helperText={touched.manufacturer && errors.manufacturer}
                  disabled={props.isShowDetails}
                />

                <TextField
                  id='filled-required'
                  label={Strings.DESCRIPTION}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classNames(
                    classes.input,
                    props.isShowDetails && classes.disabled_input
                  )}
                  value={values.description}
                  onChange={(e) =>
                    handleChangeFields('description', e.target.value)
                  }
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  disabled={props.isShowDetails}
                />

                <div className={classes.flex_row}>
                  <Stack width={'100%'}>
                    <Selection
                      isSearchable
                      containerClassName={classNames(
                        classes.selection_input,
                        props.isShowDetails && classes.disabled_input
                      )}
                      label={Strings.SIZE}
                      title={Strings.SELECT}
                      options={sizeList || []}
                      handleChange={(e, value) => {
                        value && handleChangeFields('size', value);
                      }}
                      value={values.size}
                      // title={Strings.SELECT}
                      placeHolder={Strings.SELECT}
                      error={Boolean(touched.size && errors.size)}
                      helperText={touched.size && errors.size}
                      disabled={props.isShowDetails}
                      isShowDetails={props.isShowDetails}
                    />
                  </Stack>
                  <TextField
                    id='filled-required'
                    type='number'
                    label={Strings.WEIGHT}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    value={values.weight}
                    onChange={(e) =>
                      handleChangeFields('weight', e.target.value)
                    }
                    error={Boolean(touched.weight && errors.weight)}
                    helperText={touched.weight && errors.weight}
                    disabled={props.isShowDetails}
                  />
                  <TextField
                    type='number'
                    id='filled-required'
                    label={Strings.LOCATION}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    value={values.location}
                    onChange={(e) =>
                      handleChangeFields('location', e.target.value)
                    }
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                    disabled={props.isShowDetails}
                  />
                </div>

                <div className={classes.flex_row}>
                  <TextField
                    id='filled-required'
                    label={Strings.FREIGHT_LINE}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    value={values.freightLine}
                    onChange={(e) =>
                      handleChangeFields('freightLine', e.target.value)
                    }
                    error={Boolean(touched.freightLine && errors.freightLine)}
                    helperText={touched.freightLine && errors.freightLine}
                    disabled={props.isShowDetails}
                  />
                  <TextField
                    id='filled-required'
                    type='number'
                    label={Strings.DECLARED_VALUE}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    value={values.declaredValue}
                    onChange={(e) =>
                      handleChangeFields('declaredValue', e.target.value)
                    }
                    error={Boolean(
                      touched.declaredValue && errors.declaredValue
                    )}
                    helperText={touched.declaredValue && errors.declaredValue}
                    disabled={props.isShowDetails}
                  />
                  <TextField
                    id='filled-required'
                    type='number'
                    label={Strings.NUM_OF_BLANKETS}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    value={values.noOfBarket}
                    onChange={(e) =>
                      handleChangeFields('noOfBarket', e.target.value)
                    }
                    error={Boolean(touched.noOfBarket && errors.noOfBarket)}
                    helperText={touched.noOfBarket && errors.noOfBarket}
                    disabled={props.isShowDetails}
                  />
                </div>

                <div className={classes.flex_row}>
                  <CustomeDatePicker
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    label={Strings.RECEIVED_ON}
                    value={values.receivedDate}
                    onChange={(value) =>
                      handleChangeFields('receivedDate', value)
                    }
                    error={Boolean(touched.receivedDate && errors.receivedDate)}
                    helperText={touched.receivedDate && errors.receivedDate}
                    placeholder={Strings.SELECT}
                    disabled={props.isShowDetails}
                    // disablePast
                  />

                  {/* <CustomTimePicker
                    className={classes.input}
                    label={' '}
                    value={values.receivedTime}
                    onChange={(value) => setFieldValue('receivedTime', value)}
                    error={Boolean(touched.receivedTime && errors.receivedTime)}
                    helperText={touched.receivedTime && errors.receivedTime}
                    placeholder={Strings.SELECT}
                  /> */}
                </div>

                <div className={classes.flex_row}>
                  <CustomeDatePicker
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    label={Strings.DELIVERED_ON}
                    value={values.deliveredDate}
                    onChange={(value) =>
                      handleChangeFields('deliveredDate', value)
                    }
                    error={Boolean(
                      touched.deliveredDate && errors.deliveredDate
                    )}
                    helperText={touched.deliveredDate && errors.deliveredDate}
                    placeholder={Strings.SELECT}
                    disabled={props.isShowDetails}
                    // disablePast
                  />

                  <CustomTimePicker
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    label={' '}
                    value={values.deliveredTime}
                    onChange={(value) =>
                      handleChangeFields('deliveredTime', value)
                    }
                    error={Boolean(
                      touched.deliveredTime && errors.deliveredTime
                    )}
                    helperText={touched.deliveredTime && errors.deliveredTime}
                    placeholder={Strings.SELECT}
                    disabled={props.isShowDetails}
                  />
                </div>

                {!props?.selectedTab && !props?.isShowDetails && (
                  <>
                    <div className={classes.copies_container}>
                      <Typography
                        variant='h6_700'
                        style={{ color: Colors.FELDGRAU }}
                      >
                        {Strings.CREATE_COPIES}
                      </Typography>
                      <ToggleButton
                        className={classes.toggle_btn}
                        checked={values.copy}
                        onChange={(e) =>
                          setFieldValue('copy', e.target.checked)
                        }
                      />
                    </div>
                    {values.copy && (
                      <TextField
                        id='filled-required'
                        type='number'
                        label={Strings.COPY_COUNT}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        value={values.copyCount}
                        onChange={(e) =>
                          handleChangeFields('copyCount', e.target.value)
                        }
                        error={Boolean(touched.copyCount && errors.copyCount)}
                        helperText={touched.copyCount && errors.copyCount}
                      />
                    )}
                  </>
                )}

                {!props.isShowDetails && (
                  <Button
                    type='submit'
                    className={classes.add_user_button}
                    variant='contained-radius'
                    disabled={
                      props.addInventoryLoading || props.updateInventoryLoading
                    }
                  >
                    {props.addInventoryLoading ||
                    props.updateInventoryLoading ? (
                      <Loader />
                    ) : props.isEdit ? (
                      Strings.UPDATE_ITEM.toUpperCase()
                    ) : (
                      Strings.ADD_ITEM.toUpperCase()
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  designerListData: state.designerListData.data,
  designerListLoading: state.designerListData.loading,

  clientListData: state.clientListData.data,
  clientListLoading: state.clientListData.loading,

  addInventoryLoading: state.addInventoryData.loading,

  getInventoryData: state.getInventoryData.data,
  getInventoryLoading: state.getInventoryData.loading,

  updateInventoryLoading: state.updateInventoryData.loading,

  inventoryListData: state.inventoryListForNextPrevData?.data,
});

export default connect(mapStateToProps, {
  fetchDesignerList,
  fetchClientList,
  createInventory,
  getInventory,
  updateInventory,
})(AddInventoryModal);
