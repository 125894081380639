import axios from 'axios';
import { DESIGNER_LIST } from '../../constants';

export const fetchDesignerList =
  (onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadDesignerList());
    }
    axios
      .get(
        `admin/listDesigners`,
        config
      )
      .then((res) => {dispatch(setDesignerList(res))})
      .catch((err) => {
        dispatch(errorDesignerList(err.response));
        onError(err.response);
      });
  };

export const loadDesignerList = () => ({
  type: DESIGNER_LIST.LOAD,
});

export const setDesignerList = (incidentList) => ({
  type: DESIGNER_LIST.SUCCESS,
  payload: incidentList.data.data,
});

export const errorDesignerList = (error) => ({
  type: DESIGNER_LIST.FAIL,
  payload: error,
});
