import axios from 'axios';
import { FEE_LIST } from '../../constants';

export const fetchFeeList = (pageSize, pageNumber, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadFeeList());
  axios
    .get(
      `/fee/listFees?page_size=${pageSize}&page_number=${pageNumber}`,
      config
    )
    .then((res) => dispatch(setFeeList(res)))
    .catch((err) => {
      dispatch(errorFeeList(err.response));
      onError(err.response);
    });
};

export const loadFeeList = () => ({
  type: FEE_LIST.LOAD,
});

export const setFeeList = (incidentList) => ({
  type: FEE_LIST.SUCCESS,
  payload: incidentList.data.data,
});

export const errorFeeList = (error) => ({
  type: FEE_LIST.FAIL,
  payload: error,
});
