import axios from 'axios';
import { UPDATE_CLIENT } from '../../constants';

export const updateClient = (uID, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddClient());

  axios
    .put(`/client/updateClient?clientId=${uID}`, data, config)
    .then((res) => {
      dispatch(setUpdateClient(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateClient(err.response));
      onError(err.response);
    });
};

export const loadAddClient = () => ({
  type: UPDATE_CLIENT.LOAD,
});

export const setUpdateClient = (data) => ({
  type: UPDATE_CLIENT.SUCCESS,
  payload: data.data,
});

export const errorUpdateClient = (error) => ({
  type: UPDATE_CLIENT.FAIL,
  payload: error,
});
