import { Button, Typography, RadioGroup } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { icons } from '../../assets';
import { Strings } from '../../common/strings';
import { getErrorMessage, sortByArray } from '../../utils/appUtils';
import CustomeDatePicker from '../CustomDatePicker';
import CustomRadioButton from '../CustomRadioButtonGroup';
import CustomeTimePicker from '../CustomTimePicker';
import Selection from '../Selection';
import ToggleButton from '../ToggleButton';
import useStyles from './styles';
import { connect } from 'react-redux';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { fetchClientList } from '../../redux/actions/rateSheetActions/clientList';
import { generateReport } from '../../redux/actions/reportActions/generateReport';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { ReportSchema } from '../../utils/validationSchemas';
import moment from 'moment';
import Loader from '../Loader';
import { Colors } from '../../common/colors';
import { generateReportPdf } from '../../redux/actions/reportActions/generateReportPdf';
import { runReport } from '../../redux/actions/reportActions/runReport';

const noNeedToPassData = [
  'Missing Location',
  'Declared Value',
  'Designer List',
  'Ultra Service Clients',
  'DVP Clients',
  'Non bilable deliveries',
  'Month End Invoice',
];

const radioButtonArray = [
  { label: 'Missing locations', value: 'Missing Location' },
  { label: 'Declared value', value: 'Declared Value' },
  { label: 'Receiving log', value: 'Receiving Log' },
  { label: 'Designer list', value: 'Designer List' },
  { label: 'Ultra service clients', value: 'Ultra Service Clients' },
  { label: 'DVP clients', value: 'DVP Clients' },
  { label: 'Inventory Snapshot', value: 'Inventory Snapshot' },
  { label: 'Non bilable deliveries', value: 'Non bilable deliveries' },
  { label: 'Month End Invoice', value: 'Month End Invoice' },
];

const ReportModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [designerList, setDesignerList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [disableItems1, setDisableItems1] = useState(false);
  const [disableItems2, setDisableItems2] = useState(false);
  const [disableItems3, setDisableItems3] = useState(false);
  const [designer, setDesigner] = useState('');
  const [isPdfOrCsv, setIsPdfOrCsv] = useState('');
  const [pdfBlobUrl, setPdfBlobUrl] = useState('');

  useEffect(() => {
    props.fetchDesignerList(onError);
    // props.fetchClientList(designer, onError, false);
  }, []);

  useEffect(() => {
    if (designer && designer !== 0 && designer !== 'all') {
      props.fetchClientList(designer, onError);
    }
  }, [designer]);

  useEffect(() => {
    const designerList = props.designerListData.map((e) => ({
      label: e.name,
      value: e._id,
    }));
    setDesignerList(designerList);
    const clientList = props.clientListData.map((e) => ({
      label: e.client,
      value: e._id,
    }));
    setClientList(clientList);
  }, [props.designerListData, props.clientListData]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      enqueueSnackbar(msg, { variant: 'success' });
    }
  };

  const onChange = (key, value) => {
    setErrors({});
    setFieldValue(key, value || '');
  };

  const formik = useFormik({
    initialValues: {
      radioGroup: 'Missing Location',
      designer: 'all',
      client: '',
      sortBy: 'location',
      startOnDate: '',
      startOnTime: '',
      endOnDate: '',
      endOnTime: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ReportSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue, setErrors } =
    formik;

  const setStartEndDate = (isFillValue) => {
    setFieldValue('startOnDate', isFillValue ? new Date() : '');
    setFieldValue('startOnTime', isFillValue ? new Date() : '');
    setFieldValue('endOnDate', isFillValue ? new Date() : '');
    setFieldValue('endOnTime', isFillValue ? new Date() : '');
  };

  useEffect(() => {
    if (noNeedToPassData.includes(values?.radioGroup)) {
      setDisableItems1(true);
      setDisableItems2(true);
      setStartEndDate();
    } else if (values?.radioGroup === 'Inventory Snapshot') {
      setDisableItems1(true);
      setDisableItems2(false);
      setStartEndDate();
    }
    // else if (values?.radioGroup === 'Month End Invoice') {
    //   setFieldValue('designer', '');
    //   setDisableItems1(false);
    //   setDisableItems2(false);
    //   setDisableItems3(true);
    //   setStartEndDate(true);
    // }
    else {
      setStartEndDate(true);
      setDisableItems1(false);
      setDisableItems2(false);
    }
  }, [values?.radioGroup]);

  const handleDataSubmit = (values) => {
    let data = {};
    if (noNeedToPassData.includes(values.radioGroup)) {
      data.type = values.radioGroup;
    } else if (values.radioGroup === 'Receiving Log') {
      data.type = values.radioGroup;
      data.designer = values.designer === 'all' ? '' : values.designer;
      data.client = values.client;
      data.startOn = moment(values.startOnDate).format('YYYY-MM-DD');
      data.endOn = moment(values.endOnDate).format('YYYY-MM-DD');
      data.sortBy = values.sortBy;
      values.designer === 'all' && delete data.client;
    } else if (values.radioGroup === 'Inventory Snapshot') {
      data.type = values.radioGroup;
      data.sortBy = values.sortBy;
      data.designer = values.designer === 'all' ? '' : values.designer;
      data.client = values.client;
      values.designer === 'all' && delete data.client;
    }
    // else if (values.radioGroup === 'Month End Invoice') {
    //   data.type = values.radioGroup;
    //   data.designer = values.designer === 'all' ? '' : values.designer;
    //   data.startOn = moment(values.startOnDate).format('YYYY-MM-DD');
    //   data.endOn = moment(values.endOnDate).format('YYYY-MM-DD');
    // }
    if (isPdfOrCsv === 'PDF') {
      props.generateReportPdf(
        data,
        onError,
        values?.radioGroup || 'Report',
        true,
        async (data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          await setPdfBlobUrl(url);
        }
      );
      setIsPdfOrCsv('');
    } else if (isPdfOrCsv === 'REPORT') {
      props.runReport(data, onError, () =>
        onSuccess('Month End Invoice has been successfully generated')
      );
      setIsPdfOrCsv('');
    } else {
      props.generateReport(data, onError, values?.radioGroup || 'Report');
      setIsPdfOrCsv('');
    }
  };

  return (
    <div className={classes.main_container}>
      <div className={classes.modal_container}>
        <Typography variant='h5_700'>{Strings.REPORT}</Typography>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className={classes.form_container}>
              <div className={classes.form_left_container}>
                <RadioGroup defaultValue='Missing Location'>
                  <div className={classes.radio_button_container}>
                    {radioButtonArray?.map((el, index) => {
                      return (
                        <CustomRadioButton
                          key={index}
                          // name=''
                          onChange={(e) =>
                            onChange('radioGroup', e.target.value)
                          }
                          label={el.label}
                          value={el.value}
                        />
                      );
                    })}
                  </div>
                </RadioGroup>
              </div>
              <div className={classes.form_right_container}>
                <div className={classes.form_right_field_content}>
                  <div className={classes.flex_row}>
                    <CustomeDatePicker
                      className={classes.selection_input}
                      label={Strings.START_ON}
                      error={Boolean(touched.startOnDate && errors.startOnDate)}
                      helperText={touched.startOnDate && errors.startOnDate}
                      placeholder={Strings.SELECT}
                      value={values.startOnDate}
                      onChange={(e) => {
                        onChange('startOnDate', e);
                      }}
                      disabled={disableItems1}
                    />
                    {/* <CustomeTimePicker
                      className={classes.selection_input}
                      label={' '}
                      error={Boolean(touched.startOnTime && errors.startOnTime)}
                      helperText={touched.startOnTime && errors.startOnTime}
                      placeholder={Strings.SELECT}
                      value={values.startOnTime}
                      onChange={(e) => {
                        onChange('startOnTime', e);
                      }}
                      disabled={disableItems1}
                    /> */}
                  </div>
                  <div className={classes.flex_row}>
                    <CustomeDatePicker
                      className={classes.selection_input}
                      label={Strings.END_ON}
                      error={Boolean(touched.endOnDate && errors.endOnDate)}
                      helperText={touched.endOnDate && errors.endOnDate}
                      placeholder={Strings.SELECT}
                      value={values.endOnDate}
                      onChange={(e) => {
                        onChange('endOnDate', e);
                      }}
                      disabled={disableItems1}
                    />
                    {/* <CustomeTimePicker
                      className={classes.selection_input}
                      label={' '}
                      error={Boolean(touched.endOnTime && errors.endOnTime)}
                      helperText={touched.endOnTime && errors.endOnTime}
                      placeholder={Strings.SELECT}
                      value={values.endOnTime}
                      onChange={(e) => {
                        onChange('endOnTime', e);
                      }}
                      disabled={disableItems1}
                    /> */}
                  </div>
                  <Selection
                    isSearchable
                    containerClassName={classes.selection_input}
                    label={Strings.DESIGNER}
                    handleChange={(e, value) => {
                      if (value) {
                        onChange('designer', value);
                        setDesigner(value);
                        setFieldValue('client', '');
                      }
                    }}
                    value={values.designer}
                    placeHolder={Strings.SELECT}
                    error={Boolean(touched.designer && errors.designer)}
                    options={
                      values?.radioGroup === 'Month End Invoice'
                        ? [...designerList]
                        : [
                            { label: 'All Designer', value: 'all' },
                            ...designerList,
                          ]
                    }
                    helperText={touched.designer && errors.designer}
                    disabled={disableItems2}
                  />
                  <Selection
                    isSearchable
                    containerClassName={classes.selection_input}
                    label={Strings.CLIENT}
                    handleChange={(e, value) => {
                      value && onChange('client', value);
                    }}
                    value={values.client}
                    placeHolder={Strings.SELECT}
                    error={Boolean(touched.client && errors.client)}
                    options={clientList}
                    helperText={touched.client && errors.client}
                    disabled={
                      disableItems2 || disableItems3 || props.clientListLoading
                      // !designer ||
                      // designer === 'all'
                    }
                  />
                  <Selection
                    className={classes.selection_input}
                    label={Strings.SORT_BY}
                    handleChange={(e) => {
                      onChange('sortBy', e.target.value);
                    }}
                    value={values.sortBy}
                    title={Strings.SELECT}
                    error={Boolean(touched.sortBy && errors.sortBy)}
                    options={sortByArray}
                    helperText={touched.sortBy && errors.sortBy}
                    disabled={disableItems2 || disableItems3}
                  />
                </div>
                <div className={classes.form_right_button_content}>
                  {/* <div className={classes.sendEmail_container}>
                <Typography
                  variant='h6_700'
                  className={classNames(classes.sub_title, classes.flex)}
                >
                  {Strings.SEND_EMAIL}
                </Typography>
                <ToggleButton
                  className={classes.toggle_btn}
                  //   checked={}
                  onChange={() => {}}
                />
              </div> */}
                  <div className={classes.button_container}>
                    <Button
                      type='submit'
                      className={classes.outlined_button}
                      variant='outlined'
                      disabled={
                        props.runReportLoading ||
                        values?.radioGroup !== 'Month End Invoice'
                      }
                      onClick={() => setIsPdfOrCsv('REPORT')}
                    >
                      {props.runReportLoading ? (
                        <Loader color={Colors.RAISIN_BLACK} />
                      ) : (
                        Strings.RUN_REPORT.toUpperCase()
                      )}
                    </Button>
                    <Button
                      type='submit'
                      className={classes.outlined_button}
                      variant='outlined'
                      disabled={
                        props.generateReportPdfLoading ||
                        props.generateReportLoading ||
                        values?.radioGroup === 'Month End Invoice'
                      }
                      onClick={() => setIsPdfOrCsv('PDF')}
                    >
                      {props.generateReportPdfLoading ? (
                        <Loader color={Colors.RAISIN_BLACK} />
                      ) : (
                        Strings.GENERATE_PDF.toUpperCase()
                      )}
                    </Button>
                    <Button
                      type='submit'
                      className={classes.generate_pdf_button}
                      variant='contained-radius'
                      disabled={
                        props.generateReportPdfLoading ||
                        props.generateReportLoading ||
                        values?.radioGroup === 'Month End Invoice'
                      }
                      onClick={() => setIsPdfOrCsv('CSV')}
                    >
                      {props.generateReportLoading ? (
                        <Loader color={Colors.RAISIN_BLACK} />
                      ) : (
                        Strings.GENERATE_CSV.toUpperCase()
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  designerListData: state.designerListData.data,
  designerListLoading: state.designerListData.loading,
  clientListData: state.clientListData.data,
  clientListLoading: state.clientListData.loading,
  generateReportData: state.generateReportData.data,
  generateReportLoading: state.generateReportData.loading,
  generateReportPdfData: state.generateReportPdfData.data,
  generateReportPdfLoading: state.generateReportPdfData.loading,
  runReportData: state.runReportData.data,
  runReportLoading: state.runReportData.loading,
});

export default connect(mapStateToProps, {
  fetchDesignerList,
  fetchClientList,
  generateReport,
  generateReportPdf,
  runReport,
})(ReportModal);
