export const PAGE_SIZE = 10;

export const getErrorMessage = (error) =>
  error && error.data && error.data.message;

export const sortByArray = [
  { label: 'Location', value: 'location' },
  { label: 'Manufacturer', value: 'manufacturer' },
  { label: 'WSI number', value: 'wsi-number' },
  { label: 'Freight Line', value: 'freight-line' },
];

export const userTypeList = [
  { label: 'Designer', value: 'designer' },
  { label: 'Employee', value: 'employee' },
];

export const preferedContactType = [
  { label: 'Email', value: 'Email' },
  { label: 'Fax', value: 'Fax' },
];

export const removeEmptyProperties = (object, keepFalseValues = true) => {
  if (!object || typeof object !== 'object') {
    throw new TypeError(
      'Invalid argument passed to the function `removeEmptyProperties`. Object required'
    );
  }

  Object.entries(object).forEach(([prop, value]) => {
    if (!value && value !== 0 && (!keepFalseValues || value !== false)) {
      delete object[prop];
    }
  });
  return object;
};

export const sizeList = [
  { label: 'XS', value: 'XS' },
  { label: 'SM', value: 'SM' },
  { label: 'MD', value: 'MD' },
  { label: 'LG', value: 'LG' },
  { label: 'XL', value: 'XL' },
  { label: 'Vault', value: 'Vault' },
  { label: 'Carton', value: 'Carton' },
  { label: 'Crate', value: 'Cost' },
];

export const DownloadCSVorPDF = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const PrintCSVorPDF = (data, type, onLoadCallback) => {
  const blob = new Blob([data], { type: type });
  const url = window.URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url;
  document.body.appendChild(iframe);

  iframe.onload = () => {
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
    onLoadCallback?.();
  };
};

export const digitsRegex = /^[0-9]+$/g;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const userRole = () => {
  return localStorage.getItem('userRole');
};

export const isAdmin = () => {
  return userRole() === 'admin';
};

export const isDesigner = () => {
  return userRole() === 'designer';
};

export const isVendor = () => {
  return userRole() === 'vendor';
};

export const slicePhoneNumber = (num) => {
  if (num) {
    const val =
      num?.startsWith('+1') || num?.startsWith('+') ? num?.slice(2) : num;
    return val;
  } else {
    return '';
  }
};

export function makeUniqueId(length) {
  var result = ''
  var characters = '0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result;
}