import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { icons } from '../../assets';
import moment from 'moment';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import CustomCheckbox from '../../components/CustomCheckbox';
import TableMenuBar from '../../components/TableToolBar';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { fetchInvoiceList } from '../../redux/actions/invoiceActions/invoiceList';
import { deleteInvoice } from '../../redux/actions/invoiceActions/deleteInvoice';
import { exportInvoice } from '../../redux/actions/invoiceActions/exportInvoice';
import { connect } from 'react-redux';
import { getErrorMessage, PAGE_SIZE } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import Dialog from '../../components/Dialog';
import InvoiceDetailsModal from '../../components/InvoiceDetailsModal';
import debounce from 'lodash/debounce';

const tableHeaders = [
  { label: '', checkbox: true },
  { label: 'ID', width: '5%' },
  { label: 'Designer', width: '20%' },
  { label: 'Client', width: '20%' },
  { label: 'Invoice Date', width: '15%' },
  { label: 'Delivery Fee', width: '10%' },
  { label: 'DVP Fee', width: '10%' },
  { label: 'Pick up?', width: '10%' },
  { label: 'Method', width: '10%' },
];

const positionBgColor = {
  Designer: Colors.POWDER_BLUE,
  Employee: Colors.PALE_SPRING_BUD,
  Admin: Colors.PALE_LAVENDER,
};

const Invoice = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState('all');
  // const { currentData, jump, next } = usePagination(InvoiceDummyData, 10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedIdData, setSelectedIdData] = useState([]);
  const [selectedAllRow, setSelectedAllRow] = useState(false);
  const [selectedSomeRow, setSelectedSomeRow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [designerList, setDesignerList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [exportInvoice, setExportInvoice] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startEndDate, setStartEndDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(false);
  const [position, setPosition] = useState(null);
  const [showDeleteInvoiceDialog, setShowDeleteInvoiceDialog] = useState(false);
  const [showInvoiceDetailsDialog, setShowInvoiceDetailsDialog] =
    useState(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState(null);
  const totalRecords = props.invoiceListData?.totalRecords || 0;

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const delayedSearch = useCallback(
    debounce(
      (q) =>
        props?.fetchInvoiceList(
          q,
          selectedItem === 'all' ? '' : selectedItem,
          PAGE_SIZE,
          page,
          startDate ? moment(startDate).format('YYYY-MM-DD') : startDate,
          endDate ? moment(endDate).format('YYYY-MM-DD') : endDate,
          (err) => onError(err)
        ),
      600
    ),
    [startDate, endDate]
  );

  // useEffect(() => {
  //   props.fetchDesignerList((err) => onError(err));
  // }, []);

  // useEffect(() => {
  //   const designAarray = props?.designerListData?.map((d) => ({
  //     label: d?.name,
  //     value: d?._id,
  //   }));
  //   setDesignerList(designAarray);
  // }, [props?.designerListData]);

  useEffect(() => {
    setInvoiceList(props.invoiceListData?.invoices);
  }, [props.invoiceListData?.invoices]);

  useEffect(() => {
    props?.fetchInvoiceList(
      search,
      selectedItem === 'all' ? '' : selectedItem,
      PAGE_SIZE,
      page,
      startDate ? moment(startDate).format('YYYY-MM-DD') : startDate,
      endDate ? moment(endDate).format('YYYY-MM-DD') : endDate,
      (err) => onError(err)
    );
  }, [
    selectedItem,
    // search,
    page,
    props.deleteInvoiceData,
    startEndDate,
  ]);

  useEffect(() => {
    if (selectedRow.length > 0) {
      let newData = [];
      selectedRow?.map((el) => {
        if (el?.selected?.length > 0) {
          el.selected.map((el) => {
            newData.push(el);
          });
        }
      });

      setSelectedIdData(newData);
      const isPageExist = selectedRow?.some((el) => el.page === page);
      if (isPageExist) {
        const newData = selectedRow?.filter((el) => el.page === page);
        const isAllDataSelect = newData?.some(
          (el) => el?.selected?.length === invoiceList?.length
        );

        const isSomeDataSelect = newData?.some(
          (el) =>
            el?.selected?.length > 0 &&
            el?.selected?.length < invoiceList?.length
        );

        if (isAllDataSelect) {
          setSelectedAllRow(true);
          setSelectedSomeRow(false);
        } else if (isSomeDataSelect) {
          setSelectedAllRow(false);
          setSelectedSomeRow(true);
        } else {
          setSelectedAllRow(false);
          setSelectedSomeRow(false);
        }
      } else {
        setSelectedAllRow(false);
        setSelectedSomeRow(false);
      }
    } else {
      setSelectedIdData([]);
      setSelectedAllRow(false);
      setSelectedSomeRow(false);
    }
  }, [page, selectedRow, invoiceList]);

  const handleChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const handleChangePagination = async (e, p) => {
    // await jump(p);
    await setPage(p);
  };

  const handleCheckedAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = invoiceList?.map((el) => el._id);
      const data = { page: page, selected: newSelected };
      const isPageExist = selectedRow?.some((el) => el.page === page);
      if (isPageExist) {
        let newData = selectedRow;
        newData = newData.map((el) => {
          if (el.page === page) return { ...el, selected: newSelected };
          else return { ...el };
        });
        setSelectedRow(newData);
      } else {
        setSelectedRow((prevState) => [...prevState, data]);
      }
      setSelectedSomeRow(false);
      setSelectedAllRow(true);
    } else {
      const newSelectedRow = [...selectedRow];
      let fIndex = newSelectedRow?.findIndex((el) => el.page === page);
      newSelectedRow?.splice(fIndex, 1);
      setSelectedRow(newSelectedRow);
      setSelectedAllRow(false);
    }
  };

  const handleCheckedClick = (event, id) => {
    const selectedPageIndex = selectedRow?.findIndex((el) => el?.page === page);
    let newSelected = [];
    let newItem = [];

    if (selectedPageIndex === -1) {
      newSelected = newSelected.concat(selectedRow, {
        page: page,
        selected: [id],
      });
    } else if (selectedPageIndex !== -1 && selectedPageIndex >= 0) {
      const index = selectedRow[selectedPageIndex]?.selected?.findIndex(
        (el) => el === id
      );

      newSelected = selectedRow;
      if (index === -1) {
        newItem = newItem.concat(selectedRow[selectedPageIndex]?.selected, id);
        newSelected = newSelected?.map((el, index) => {
          if (index === selectedPageIndex) return { ...el, selected: newItem };
          else return el;
        });
        // } else if (index === 0) {
        //   console.log("when index...0");
        //   newItem = newItem.concat(
        //     selectedRow[selectedPageIndex]?.selected?.slice(1),
        //   );
        //   newSelected = newSelected?.map((el, index) => {
        //     console.log("map_index",index, selectedPageIndex);
        //     if (index === selectedPageIndex) return { ...el, selected: newItem };
        //     else return el;
        //   });
        //   if (newSelected[selectedPageIndex]?.selected?.length === 0) {
        //     console.log("slicing.....", newSelected.slice(0, selectedPageIndex),newSelected.slice(selectedPageIndex + 1));
        //     newSelected = [
        //       ...newSelected.slice(0, selectedPageIndex),
        //       ...newSelected.slice(selectedPageIndex + 1),
        //     ];
        //   }
      } else if (index >= 0) {
        newItem = newItem.concat(
          selectedRow[selectedPageIndex]?.selected?.slice(0, index),
          selectedRow[selectedPageIndex]?.selected?.slice(index + 1)
        );
        newSelected = newSelected?.map((el, index) => {
          if (index === selectedPageIndex) return { ...el, selected: newItem };
          else return el;
        });
      }
    }
    setSelectedRow(newSelected);
  };

  const checkValueExist = (id) => {
    if (selectedRow?.length) {
      const isExist = selectedRow?.some((el) =>
        el?.selected?.some((el) => el === id)
      );
      return isExist;
    }
  };

  const onClickDelete = async (e) => {
    e.preventDefault();
    await setShowDeleteInvoiceDialog(true);
  };

  const onClickYesButton = async () => {
    const invoiceData = {
      invoices: selectedIdData,
    };
    await props.deleteInvoice(invoiceData, setShowDeleteInvoiceDialog, onError);
  };

  const exportInvoiceCSV = () => {
    const exportdata = {
      invoices: selectedIdData,
    };
    props.exportInvoice(exportdata, onError, 'Invoices');
  };

  const handleDateSelect = (range) => {
    setStartDate(range.selection.startDate);
    setEndDate(range.selection.endDate);
  };

  const onApplyDateRange = () => {
    setAnchorEl(false);
    setStartEndDate(
      `${moment(startDate).format('MMM DD, YYYY')} to ${moment(endDate).format(
        'MMM DD, YYYY'
      )}`
    );
  };

  const clearDates = () => {
    setAnchorEl(false);
    setStartDate('');
    setEndDate('');
    setStartEndDate('');
  };

  const onClickTableRow = (data) => {
    setShowInvoiceDetailsDialog(true);
    setSelectedInvoiceData(data);
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        searchPlaceholder={'Search for a client or ID'}
        // isSelect
        // selectOption={[
        //   { label: 'All Designers', value: 'all' },
        //   ...designerList,
        // ]}
        // handleSelectChange={(e) => {
        //   setPage(1);
        //   setSelectedItem(e.target.value);
        // }}
        searchValue={search}
        // selectedItem={selectedItem}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
        isCalendar
        startDate={startDate}
        endDate={endDate}
        anchorEl={anchorEl}
        startEndDate={startEndDate}
        handleDateClick={(e) => {
          // if (startDate || endDate) {
          //   setStartDate('');
          //   setEndDate('');
          // }

          setPosition(e.currentTarget);
          setAnchorEl(true);
        }}
        handleDateSelect={handleDateSelect}
        handleOnClose={() => setAnchorEl(false)}
        applyDateRange={() => {
          setPage(1);
          onApplyDateRange();
        }}
        clearDateRange={clearDates}
        position={position}
      />

      <div className={classes.tableContainer}>
        {selectedIdData?.length ? (
          <TableMenuBar
            showExportButton
            showDeleteButton
            // showInvoicesButton
            containerClassName={classes.menuBar_container}
            selectedDataCount={selectedIdData?.length}
            onDeselectClick={() => {
              setSelectedRow([]);
              setSelectedIdData([]);
              setSelectedAllRow(false);
              setSelectedSomeRow(false);
            }}
            onExportClick={exportInvoiceCSV}
            onDeleteClick={onClickDelete}
            onMorthInvoicesClick={() => {}}
            onGenerateInvoiceClick={() => {}}
          />
        ) : null}
        <div className={classes.table}>
          {props.invoiceListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords && totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead
                      headCells={tableHeaders}
                      onSelectAllClick={(e) => handleCheckedAllClick(e)}
                      selectedAllRow={selectedAllRow}
                      selectedSomeRow={selectedSomeRow}
                      totalRowCount={invoiceList?.length}
                    />
                    <TableBody>
                      {invoiceList &&
                        invoiceList.map((row, index) => {
                          const isChecked = checkValueExist(row._id);
                          return (
                            <TableRow
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickTableRow(row)}
                            >
                              <TableCell
                                component='th'
                                style={{ paddingRight: 0 }}
                              >
                                <CustomCheckbox
                                  checked={isChecked || false}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCheckedClick(e, row._id);
                                  }}
                                />
                              </TableCell>
                              <TableCell component='th' width='5%'>
                                <Typography variant='p_400'>
                                  {row.ID}
                                </Typography>
                              </TableCell>
                              <TableCell component='th' width='20%'>
                                <Typography variant='p_700'>
                                  {row.designer || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell width='20%'>
                                <Typography variant='p_400'>
                                  {row.client}
                                </Typography>
                              </TableCell>
                              <TableCell width='15%'>
                                <Typography variant='p_400'>
                                  {row.invoiceDate
                                    ? moment(row.invoiceDate).format(
                                        'MMM DD, YYYY'
                                      )
                                    : '-'}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row.deliveryFee}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row.DVP_Fee}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row.pickUp ? 'Yes' : 'No'}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row.method}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
      {showDeleteInvoiceDialog && (
        <Dialog
          title={Strings.DELETE_INVOICE}
          message={Strings.ARE_YOU_SURE_DELETE_INVOICE}
          onClose={() => setShowDeleteInvoiceDialog(false)}
          loading={props.deleteInvoiceLoading}
          onClickYesButton={onClickYesButton}
        />
      )}
      {showInvoiceDetailsDialog && (
        <InvoiceDetailsModal
          onClose={() => {
            setShowInvoiceDetailsDialog(false);
            setSelectedInvoiceData(null);
          }}
          selectedInvoiceData={selectedInvoiceData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  designerListData: state.designerListData.data,
  invoiceListData: state.invoiceListData.data,
  invoiceListLoading: state.invoiceListData.loading,
  deleteInvoiceLoading: state.deleteInvoiceData.loading,
  deleteInvoiceData: state.deleteInvoiceData.data,
  exportInvoiceData: state.exportInvoiceData.data,
});

export default connect(mapStateToProps, {
  fetchDesignerList,
  fetchInvoiceList,
  deleteInvoice,
  exportInvoice,
})(Invoice);
