import React, { useState, useEffect } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography, TextField, Button, Stack } from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import Selection from '../Selection';
import classNames from 'classnames';
import CustomCheckbox from '../CustomCheckbox';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '../../utils/appUtils';
import { useFormik, Form, FormikProvider } from 'formik';
import { AddClientSchema } from '../../utils/validationSchemas';
import { connect } from 'react-redux';
import { createClient } from '../../redux/actions/clientActions/addClient';
import { updateClient } from '../../redux/actions/clientActions/updateClient';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import {
  getClientData,
  setGetClient,
} from '../../redux/actions/clientActions/getClientData';
import { State, City } from 'country-state-city';
import TagsInputField from '../TagsInputField';
import { useDispatch } from 'react-redux';

const AddClientModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [designers, setDesigners] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityListPrimaryAdd, setCityListPrimaryAdd] = useState([]);
  const [cityListSecondaryAdd, setCityListSecondaryAdd] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const COUNTRY_CODE = 'US';

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props.edit) {
      props.getClientData(props.selectedClient, props.onError);
    }
    const states = State.getStatesOfCountry(COUNTRY_CODE);
    setStateList(
      states
        .map((state) => {
          const cities = City.getCitiesOfState(COUNTRY_CODE, state.isoCode);
          if (cities?.length > 0) {
            return {
              label: state?.name,
              value: state?.name,
              isoCode: state?.isoCode,
            };
          } else {
            return null;
          }
        })
        .filter((state) => state)
    );
    return () => dispatch(setGetClient([]));
  }, []);

  useEffect(() => {
    props.fetchDesignerList((err) => onError(err));
  }, []);

  useEffect(() => {
    const designAarray = props.designerList?.map((d) => {
      const data = {
        label: `${d.name} (${d?.designerCode})`,
        value: d._id,
      };
      return data;
    });
    setDesigners(designAarray);
  }, [props.designerList]);

  useEffect(() => {
    if (props?.clientData) {
      setFieldValue(
        'additionalEmails',
        props.clientData?.additionalEmails || []
      );
      setFieldValue('clientCode', props.clientData?.clientCode || '');
      setFieldValue('designer', props.clientData?.designer || '');
      setFieldValue('client', props.clientData?.client || '');
      setFieldValue('email', props.clientData?.email || '');
      setFieldValue('notes', props.clientData?.notes || '');
      setFieldValue('primaryAddress', {
        zipcode: props.clientData?.primaryAddress?.zipcode || 909099,
        city: props.clientData?.primaryAddress?.city || '',
        state: props.clientData?.primaryAddress?.state || '',
        address: props.clientData?.primaryAddress?.address || '',
        business: props.clientData?.primaryAddress?.business,
        cel: props.clientData?.primaryAddress?.cel,
        fax: props.clientData?.primaryAddress?.fax,
        other: props.clientData?.primaryAddress?.other,
      });
      setSelectedState({
        primaryAddress: stateList.find(
          (s) => s.value === props.clientData?.primaryAddress?.state
        ),
        // secondaryAddress: stateList.find(
        //   (s) => s.value === props.clientData?.secondaryAddress?.state
        // ),
      });
      // setFieldValue('secondaryAddress', {
      //   zipcode: props.clientData?.secondaryAddress?.zipcode || '',
      //   city: props.clientData?.secondaryAddress?.city || '',
      //   state: props.clientData?.secondaryAddress?.state || '',
      //   address: props.clientData?.secondaryAddress?.address || '',
      //   business: props.clientData?.secondaryAddress?.business,
      //   cel: props.clientData?.secondaryAddress?.cel,
      //   fax: props.clientData?.secondaryAddress?.fax,
      //   other: props.clientData?.secondaryAddress?.other,
      //   secondaryAddress: props.clientData.secondaryAddress || true,
      //   sameAsPrimaryAddress:
      //     props?.clientData?.sameAsPrimaryAddress === false
      //       ? false
      //       : props?.clientData?.sameAsPrimaryAddress,
      // });
      setFieldValue('acceptDVP', props?.clientData?.acceptDVP || false);
      setFieldValue('options', {
        active: props.clientData?.options?.active || false,
        displayNotes: props.clientData?.options?.displayNotes || false,
        ultraService: props.clientData?.options?.ultraService || false,
        retail: props.clientData?.options?.retail || false,
      });
    }
  }, [props?.clientData]);

  useEffect(() => {
    if (selectedState) {
      if (selectedState?.primaryAddress?.isoCode) {
        const cities = City.getCitiesOfState(
          COUNTRY_CODE,
          selectedState.primaryAddress.isoCode
        );
        if (cities && cities?.length > 0) {
          setCityListPrimaryAdd(
            cities.map((c) => {
              return {
                label: c?.name,
                value: c?.name,
              };
            })
          );
        }
      }
      if (selectedState?.secondaryAddress?.isoCode) {
        const cities = City.getCitiesOfState(
          COUNTRY_CODE,
          selectedState.secondaryAddress.isoCode
        );
        if (cities && cities?.length > 0) {
          setCityListSecondaryAdd(
            cities.map((c) => {
              return {
                label: c?.name,
                value: c?.name,
              };
            })
          );
        }
      }
    }
  }, [selectedState]);

  function handleDataSubmit(values) {
    try {
      const data = {
        additionalEmails: values.additionalEmails,
        clientCode: values.clientCode,
        designer: values.designer,
        client: values.client,
        email: values.email,
        notes: values.notes,
        primaryAddress: {
          zipcode: parseInt(values.primaryAddress.zipcode),
          city: values.primaryAddress.city,
          state: values.primaryAddress.state,
          address: values.primaryAddress.address,
          business: values.primaryAddress.business,
          cel: values.primaryAddress.cel,
          fax: values.primaryAddress.fax,
          other: values.primaryAddress.other,
        },
        // sameAsPrimaryAddress: values.secondaryAddress.sameAsPrimaryAddress,
        acceptDVP: values.acceptDVP || false,
        options: {
          active: values?.options?.active || false,
          displayNotes: values?.options?.displayNotes || false,
          ultraService: values?.options?.ultraService || false,
          retail: values?.options?.retail || false,
        },
      };
      // if (!values.secondaryAddress.sameAsPrimaryAddress) {
      //   data.secondaryAddress = {};
      //   data.secondaryAddress.zipcode = parseInt(
      //     values.secondaryAddress.zipcode
      //   );
      //   data.secondaryAddress.city = values.secondaryAddress.city;
      //   data.secondaryAddress.state = values.secondaryAddress.state;
      //   data.secondaryAddress.address = values.secondaryAddress.address;
      //   data.secondaryAddress.business = values.secondaryAddress.business;
      //   data.secondaryAddress.cel = values.secondaryAddress.cel;
      //   data.secondaryAddress.fax = values.secondaryAddress.fax;
      //   data.secondaryAddress.other = values.secondaryAddress.other;
      // }
      if (!data.primaryAddress.business) delete data.primaryAddress.business;
      if (!data.primaryAddress.cel) delete data.primaryAddress.cel;
      if (!data.primaryAddress.fax) delete data.primaryAddress.fax;
      if (!data.primaryAddress.other) delete data.primaryAddress.other;
      // if (!values.secondaryAddress.sameAsPrimaryAddress) {
      //   if (!data.secondaryAddress.business)
      //     delete data?.secondaryAddress?.business;
      //   if (!data.secondaryAddress.cel) delete data?.secondaryAddress?.cel;
      //   if (!data.secondaryAddress.fax) delete data?.secondaryAddress?.fax;
      //   if (!data.secondaryAddress.other) delete data?.secondaryAddress?.other;
      // }
      if (props.edit) {
        props.updateClient(
          props.selectedClient,
          data,
          () => {
            // props.updateClientDataById(values);
            props.onClose();
          },
          (err) => onError(err)
        );
      } else {
        props.createClient(
          data,
          () => props.onClose(),
          (err) => onError(err)
        );
      }
    } catch (e) {
      console.log('eee', e);
    }
  }

  const onChange = (key, value) => {
    setErrors({});
    setFieldValue(key, value || '');
  };

  const handleSelectedTags = (key, tags) => {
    setFieldValue(key, tags);
  };

  const formik = useFormik({
    initialValues: {
      clientCode: '',
      designer: '',
      client: '',
      email: '',
      additionalEmails: [],
      notes: '',
      primaryAddress: {
        zipcode: '',
        city: '',
        state: '',
        address: '',
        business: '',
        cel: '',
        fax: '',
        other: '',
      },

      // secondaryAddress: {
      //   zipcode: '',
      //   city: '',
      //   state: '',
      //   address: '',
      //   business: '',
      //   cel: '',
      //   fax: '',
      //   other: '',
      //   sameAsPrimaryAddress: false,
      // },
      acceptDVP: false,
      options: {
        active: false,
        displayNotes: false,
        ultraService: false,
        retail: false,
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: AddClientSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
  } = formik;

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled ? (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        ) : null}
      </span>
    );
  };

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => props.onClose()}
              />
            </div>
            <Typography variant='h5_700'>
              {props.isShowDetails
                ? Strings.CLIENT
                : props.edit
                ? Strings.UPDATE_CLIENT
                : Strings.NEW_CLIENT}
            </Typography>
            {props.loader ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <>
                <TextField
                  // required
                  id='filled-required'
                  label={Strings.CLIENT_CODE}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => onChange('clientCode', e.target.value)}
                  value={values.clientCode}
                  className={classNames(
                    classes.input,
                    classes.top_margin,
                    props.isShowDetails && classes.disabled_input
                  )}
                  error={Boolean(touched.clientCode && errors.clientCode)}
                  helperText={touched.clientCode && errors.clientCode}
                  disabled={
                    (props?.edit && props?.clientData?.clientCode) ||
                    props.isShowDetails
                  }
                />
                <TextField
                  // required
                  id='filled-required'
                  label={Strings.CLIENT}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => onChange('client', e.target.value)}
                  value={values.client}
                  className={classNames(
                    classes.input,
                    classes.top_margin,
                    props.isShowDetails && classes.disabled_input
                  )}
                  error={Boolean(touched.client && errors.client)}
                  helperText={touched.client && errors.client}
                  disabled={props.isShowDetails}
                />
                <Selection
                  isSearchable
                  containerClassName={classNames(
                    classes.selection_input,
                    props.isShowDetails && classes.disabled_input
                  )}
                  label={Strings.DESIGNER}
                  placeHolder={'All Designers'}
                  handleChange={(e, value) =>
                    value && onChange('designer', value)
                  }
                  value={values.designer}
                  // value={'0'}
                  title={Strings.SELECT}
                  error={Boolean(touched.designer && errors.designer)}
                  helperText={touched.designer && errors.designer}
                  options={designers}
                  disabled={props.isShowDetails}
                  isShowDetails={props.isShowDetails}
                />

                <TextField
                  // required
                  id='filled-required'
                  label={Strings.EMAIL}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  value={values.email}
                  onChange={(e) => onChange('email', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  className={classNames(
                    classes.input,
                    props.isShowDetails && classes.disabled_input
                  )}
                  disabled={props.isShowDetails}
                />

                <TagsInputField
                  selectedTags={(value) =>
                    handleSelectedTags('additionalEmails', value)
                  }
                  label={Strings.ADDITIONAL_EMAILS}
                  placeholder={Strings.TYPE_HERE}
                  tags={values.additionalEmails || []}
                  renderTag={renderTags}
                  containerClassName={classes.input}
                  disabled={props.isShowDetails}
                  isShowDetails={props.isShowDetails}
                />

                <div className={classes.input}>
                  <label
                    className={classNames(classes.input_label)}
                    htmlFor={Strings.NOTES}
                  >
                    {Strings.NOTES}
                  </label>
                  <textarea
                    label={Strings.EMAIL}
                    className={classNames(
                      classes.textbox,
                      props.inputClass && props.inputClass
                    )}
                    rows={'3'}
                    value={values?.notes}
                    onChange={(e) => setFieldValue('notes', e.target.value)}
                    placeholder={Strings.TYPE_YOUR_NOTE_HERE}
                    autoFocus={props.autoFocus}
                    disabled={props?.isShowDetails}
                  />
                </div>

                <Typography
                  variant='h6_700'
                  className={classNames(
                    classes.sub_title,
                    classes.label_margin
                  )}
                >
                  {Strings.PRIMARY_ADDRESS}
                </Typography>

                <div className={classes.flex_row}>
                  <TextField
                    // required
                    id='filled-required'
                    type='number'
                    label={Strings.ZIPCODE}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    value={values.primaryAddress.zipcode}
                    onChange={(e) => {
                      onChange('primaryAddress', {
                        ...values.primaryAddress,
                        zipcode: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    error={Boolean(
                      touched.primaryAddress &&
                        touched.primaryAddress.zipcode &&
                        touched.primaryAddress.zipcode &&
                        errors.primaryAddress &&
                        errors.primaryAddress.zipcode &&
                        errors.primaryAddress.zipcode
                    )}
                    helperText={
                      touched.primaryAddress &&
                      touched.primaryAddress.zipcode &&
                      touched.primaryAddress.zipcode &&
                      errors.primaryAddress &&
                      errors.primaryAddress.zipcode &&
                      errors.primaryAddress.zipcode
                    }
                    disabled={props.isShowDetails}
                    // onWheel={(e) => e.target.blur()}
                  />
                  <Stack mt={1.6} width={'100%'}>
                    <Selection
                      isSearchable
                      className={classNames(
                        classes.input,
                        classes.selection_input,
                        props.isShowDetails && classes.disabled_input
                      )}
                      label={Strings.STATE}
                      title={Strings.SELECT}
                      options={stateList}
                      placeHolder={Strings.STATE}
                      value={values?.primaryAddress?.state}
                      handleChange={(e, val) => {
                        onChange('primaryAddress', {
                          ...values?.primaryAddress,
                          state: val || '',
                          city: '',
                        });

                        setSelectedState({
                          ...selectedState,
                          primaryAddress: stateList.find(
                            (s) => s.value === val
                          ),
                        });
                      }}
                      error={Boolean(
                        touched.primaryAddress &&
                          touched.primaryAddress.state &&
                          touched.primaryAddress.state &&
                          errors.primaryAddress &&
                          errors.primaryAddress.state &&
                          errors.primaryAddress.state
                      )}
                      helperText={
                        touched.primaryAddress &&
                        touched.primaryAddress.state &&
                        touched.primaryAddress.state &&
                        errors.primaryAddress &&
                        errors.primaryAddress.state &&
                        errors.primaryAddress.state
                      }
                      disabled={props.isShowDetails}
                    />
                  </Stack>
                  <Stack mt={1.6} width={'100%'}>
                    <Selection
                      isSearchable
                      className={classNames(
                        classes.input,
                        classes.selection_input,
                        props.isShowDetails && classes.disabled_input
                      )}
                      label={Strings.CITY}
                      title={Strings.SELECT}
                      placeHolder={Strings.CITY}
                      options={cityListPrimaryAdd}
                      value={values?.primaryAddress?.city}
                      handleChange={(e, val) =>
                        onChange('primaryAddress', {
                          ...values.primaryAddress,
                          city: val,
                        })
                      }
                      error={Boolean(
                        touched.primaryAddress &&
                          touched.primaryAddress.city &&
                          touched.primaryAddress.city &&
                          errors.primaryAddress &&
                          errors.primaryAddress.city &&
                          errors.primaryAddress.city
                      )}
                      helperText={
                        touched.primaryAddress &&
                        touched.primaryAddress.city &&
                        touched.primaryAddress.city &&
                        errors.primaryAddress &&
                        errors.primaryAddress.city &&
                        errors.primaryAddress.city
                      }
                      disabled={props.isShowDetails}
                    />
                  </Stack>
                </div>
                <TextField
                  // required
                  label={Strings.ADDRESS}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  value={values.primaryAddress.address}
                  onChange={(e) => {
                    onChange('primaryAddress', {
                      ...values.primaryAddress,
                      address: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classNames(
                    classes.input,
                    props.isShowDetails && classes.disabled_input
                  )}
                  error={Boolean(
                    touched.primaryAddress &&
                      touched.primaryAddress.address &&
                      touched.primaryAddress.address &&
                      errors.primaryAddress &&
                      errors.primaryAddress.address &&
                      errors.primaryAddress.address
                  )}
                  helperText={
                    touched.primaryAddress &&
                    touched.primaryAddress.address &&
                    touched.primaryAddress.address &&
                    errors.primaryAddress &&
                    errors.primaryAddress.address &&
                    errors.primaryAddress.address
                  }
                  disabled={props.isShowDetails}
                  multiline
                  rows={2}
                />
                <div className={classes.flex_row}>
                  <TextField
                    // required
                    id='filled-required'
                    label={Strings.BUSINESS}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    value={values.primaryAddress.business}
                    onChange={(e) => {
                      onChange('primaryAddress', {
                        ...values.primaryAddress,
                        business: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    error={Boolean(
                      touched.primaryAddress &&
                        touched.primaryAddress.business &&
                        touched.primaryAddress.business &&
                        errors.primaryAddress &&
                        errors.primaryAddress.business &&
                        errors.primaryAddress.business
                    )}
                    helperText={
                      touched.primaryAddress &&
                      touched.primaryAddress.business &&
                      touched.primaryAddress.business &&
                      errors.primaryAddress &&
                      errors.primaryAddress.business &&
                      errors.primaryAddress.business
                    }
                    disabled={props.isShowDetails}
                  />
                  <TextField
                    // required
                    id='filled-required'
                    label={Strings.CEL}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    value={values.primaryAddress.cel}
                    onChange={(e) => {
                      onChange('primaryAddress', {
                        ...values.primaryAddress,
                        cel: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    error={Boolean(
                      touched.primaryAddress &&
                        touched.primaryAddress.cel &&
                        touched.primaryAddress.cel &&
                        errors.primaryAddress &&
                        errors.primaryAddress.cel &&
                        errors.primaryAddress.cel
                    )}
                    helperText={
                      touched.primaryAddress &&
                      touched.primaryAddress.cel &&
                      touched.primaryAddress.cel &&
                      errors.primaryAddress &&
                      errors.primaryAddress.cel &&
                      errors.primaryAddress.cel
                    }
                    disabled={props.isShowDetails}
                  />
                </div>
                <div className={classes.flex_row}>
                  <TextField
                    // required
                    id='filled-required'
                    label={Strings.FAX}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    value={values.primaryAddress.fax}
                    onChange={(e) => {
                      onChange('primaryAddress', {
                        ...values.primaryAddress,
                        fax: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    error={Boolean(
                      touched.primaryAddress &&
                        touched.primaryAddress.fax &&
                        touched.primaryAddress.fax &&
                        errors.primaryAddress &&
                        errors.primaryAddress.fax &&
                        errors.primaryAddress.fax
                    )}
                    helperText={
                      touched.primaryAddress &&
                      touched.primaryAddress.fax &&
                      touched.primaryAddress.fax &&
                      errors.primaryAddress &&
                      errors.primaryAddress.fax &&
                      errors.primaryAddress.fax
                    }
                    disabled={props.isShowDetails}
                  />
                  <TextField
                    // required
                    id='filled-required'
                    label={Strings.OTHER}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    value={values.primaryAddress.other}
                    onChange={(e) => {
                      onChange('primaryAddress', {
                        ...values.primaryAddress,
                        other: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classNames(
                      classes.input,
                      props.isShowDetails && classes.disabled_input
                    )}
                    error={Boolean(
                      touched.primaryAddress &&
                        touched.primaryAddress.other &&
                        touched.primaryAddress.other &&
                        errors.primaryAddress &&
                        errors.primaryAddress.other &&
                        errors.primaryAddress.other
                    )}
                    helperText={
                      touched.primaryAddress &&
                      touched.primaryAddress.other &&
                      touched.primaryAddress.other &&
                      errors.primaryAddress &&
                      errors.primaryAddress.other &&
                      errors.primaryAddress.other
                    }
                    disabled={props.isShowDetails}
                  />
                </div>
                {/* <div className={classes.secondary_address}>
                  <Typography
                    variant='h6_700'
                    className={classNames(classes.sub_title, classes.flex)}
                  >
                    {Strings.SECONDARY_ADDRESS}
                  </Typography>
                  <div
                    className={classNames(
                      classes.flex_row,
                      classes.toggle_container
                    )}
                  >
                    <ToggleButton
                      className={classes.toggle_btn}
                      checked={values.secondaryAddress.sameAsPrimaryAddress}
                      onChange={(e) =>
                        onChange('secondaryAddress', {
                          ...values.secondaryAddress,
                          sameAsPrimaryAddress: e.target.checked,
                        })
                      }
                      disabled={props.isShowDetails}
                    />
                  </div>
                </div>
                {!values.secondaryAddress.sameAsPrimaryAddress && (
                  <>
                    <div className={classes.flex_row}>
                      <TextField
                        // required
                        id='filled-required'
                        type='number'
                        label={Strings.ZIPCODE}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        value={values.secondaryAddress.zipcode}
                        className={classNames(
                          classes.input,
                          props.isShowDetails && classes.disabled_input
                        )}
                        onChange={(e) => {
                          onChange('secondaryAddress', {
                            ...values.secondaryAddress,
                            zipcode: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(
                          touched.secondaryAddress &&
                            touched.secondaryAddress.zipcode &&
                            touched.secondaryAddress.zipcode &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.zipcode &&
                            errors.secondaryAddress.zipcode
                        )}
                        helperText={
                          touched.secondaryAddress &&
                          touched.secondaryAddress.zipcode &&
                          touched.secondaryAddress.zipcode &&
                          errors.secondaryAddress &&
                          errors.secondaryAddress.zipcode &&
                          errors.secondaryAddress.zipcode
                        }
                        onWheel={(e) => e.target.blur()}
                        disabled={props.isShowDetails}
                      />

                      <Stack mt={1.6} width={'100%'}>
                        <Selection
                          isSearchable
                          label={Strings.STATE}
                          title={Strings.SELECT}
                          options={stateList}
                          value={values.secondaryAddress.state}
                          placeHolder={Strings.STATE}
                          className={classNames(
                            classes.input,
                            props.isShowDetails && classes.disabled_input
                          )}
                          handleChange={(e, val) => {
                            onChange('secondaryAddress', {
                              ...values.secondaryAddress,
                              state: val,
                              city: '',
                            });
                            setSelectedState({
                              ...selectedState,
                              secondaryAddress: stateList.find(
                                (s) => s.value === val
                              ),
                            });
                          }}
                          error={Boolean(
                            touched.secondaryAddress &&
                              touched.secondaryAddress.state &&
                              touched.secondaryAddress.state &&
                              errors.secondaryAddress &&
                              errors.secondaryAddress.state &&
                              errors.secondaryAddress.state
                          )}
                          helperText={
                            touched.secondaryAddress &&
                            touched.secondaryAddress.state &&
                            touched.secondaryAddress.state &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.state &&
                            errors.secondaryAddress.state
                          }
                          disabled={props.isShowDetails}
                        />
                      </Stack>

                      <Stack mt={1.6} width={'100%'}>
                        <Selection
                          isSearchable
                          label={Strings.CITY}
                          title={Strings.SELECT}
                          placeHolder={Strings.CITY}
                          options={cityListSecondaryAdd}
                          value={values.secondaryAddress.city}
                          className={classNames(
                            classes.input,
                            props.isShowDetails && classes.disabled_input
                          )}
                          handleChange={(e, val) =>
                            onChange('secondaryAddress', {
                              ...values.secondaryAddress,
                              city: val,
                            })
                          }
                          error={Boolean(
                            touched.secondaryAddress &&
                              touched.secondaryAddress.city &&
                              touched.secondaryAddress.city &&
                              errors.secondaryAddress &&
                              errors.secondaryAddress.city &&
                              errors.secondaryAddress.city
                          )}
                          helperText={
                            touched.secondaryAddress &&
                            touched.secondaryAddress.city &&
                            touched.secondaryAddress.city &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.city &&
                            errors.secondaryAddress.city
                          }
                          disabled={props.isShowDetails}
                        />
                      </Stack>
                    </div>
                    <TextField
                      // required
                      id='filled-required'
                      label={Strings.ADDRESS}
                      placeholder={Strings.TYPE_HERE}
                      variant='standard'
                      value={values.secondaryAddress.address}
                      onChange={(e) => {
                        onChange('secondaryAddress', {
                          ...values.secondaryAddress,
                          address: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classNames(
                        classes.input,
                        props.isShowDetails && classes.disabled_input
                      )}
                      error={Boolean(
                        touched.secondaryAddress &&
                          touched.secondaryAddress.address &&
                          touched.secondaryAddress.address &&
                          errors.secondaryAddress &&
                          errors.secondaryAddress.address &&
                          errors.secondaryAddress.address
                      )}
                      helperText={
                        touched.secondaryAddress &&
                        touched.secondaryAddress.address &&
                        touched.secondaryAddress.address &&
                        errors.secondaryAddress &&
                        errors.secondaryAddress.address &&
                        errors.secondaryAddress.address
                      }
                      disabled={props.isShowDetails}
                    />
                    <div className={classes.flex_row}>
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.BUSINESS}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        value={values.secondaryAddress.business}
                        onChange={(e) => {
                          onChange('secondaryAddress', {
                            ...values.secondaryAddress,
                            business: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classNames(
                          classes.input,
                          props.isShowDetails && classes.disabled_input
                        )}
                        error={Boolean(
                          touched.secondaryAddress &&
                            touched.secondaryAddress.business &&
                            touched.secondaryAddress.business &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.business &&
                            errors.secondaryAddress.business
                        )}
                        helperText={
                          touched.secondaryAddress &&
                          touched.secondaryAddress.business &&
                          touched.secondaryAddress.business &&
                          errors.secondaryAddress &&
                          errors.secondaryAddress.business &&
                          errors.secondaryAddress.business
                        }
                        disabled={props.isShowDetails}
                      />
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.CEL}
                        placeholder={Strings.TYPE_HERE}
                        value={values.secondaryAddress.cel}
                        variant='standard'
                        onChange={(e) => {
                          onChange('secondaryAddress', {
                            ...values.secondaryAddress,
                            cel: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classNames(
                          classes.input,
                          props.isShowDetails && classes.disabled_input
                        )}
                        error={Boolean(
                          touched.secondaryAddress &&
                            touched.secondaryAddress.cel &&
                            touched.secondaryAddress.cel &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.cel &&
                            errors.secondaryAddress.cel
                        )}
                        helperText={
                          touched.secondaryAddress &&
                          touched.secondaryAddress.cel &&
                          touched.secondaryAddress.cel &&
                          errors.secondaryAddress &&
                          errors.secondaryAddress.cel &&
                          errors.secondaryAddress.cel
                        }
                        disabled={props.isShowDetails}
                      />
                    </div>
                    <div className={classes.flex_row}>
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.FAX}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        value={values.secondaryAddress.fax}
                        onChange={(e) => {
                          onChange('secondaryAddress', {
                            ...values.secondaryAddress,
                            fax: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classNames(
                          classes.input,
                          props.isShowDetails && classes.disabled_input
                        )}
                        error={Boolean(
                          touched.secondaryAddress &&
                            touched.secondaryAddress.fax &&
                            touched.secondaryAddress.fax &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.fax &&
                            errors.secondaryAddress.fax
                        )}
                        helperText={
                          touched.secondaryAddress &&
                          touched.secondaryAddress.fax &&
                          touched.secondaryAddress.fax &&
                          errors.secondaryAddress &&
                          errors.secondaryAddress.fax &&
                          errors.secondaryAddress.fax
                        }
                        disabled={props.isShowDetails}
                      />
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.OTHER}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        value={values.secondaryAddress.other}
                        onChange={(e) => {
                          onChange('secondaryAddress', {
                            ...values.secondaryAddress,
                            other: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classNames(
                          classes.input,
                          props.isShowDetails && classes.disabled_input
                        )}
                        error={Boolean(
                          touched.secondaryAddress &&
                            touched.secondaryAddress.other &&
                            touched.secondaryAddress.other &&
                            errors.secondaryAddress &&
                            errors.secondaryAddress.other &&
                            errors.secondaryAddress.other
                        )}
                        helperText={
                          touched.secondaryAddress &&
                          touched.secondaryAddress.other &&
                          touched.secondaryAddress.other &&
                          errors.secondaryAddress &&
                          errors.secondaryAddress.other &&
                          errors.secondaryAddress.other
                        }
                        disabled={props.isShowDetails}
                      />
                    </div>
                  </>
                )} */}
                <div className={classes.options_checkbox}>
                  <CustomCheckbox
                    container={classes.checkbox_container}
                    // indeterminate={true}
                    label={Strings.ACCEPT_DVP}
                    checked={values.acceptDVP}
                    onChange={(e) => onChange('acceptDVP', e.target.checked)}
                    disabled={props.isShowDetails}
                  />
                </div>

                <Typography variant='p_400' className={classes.sub_heading}>
                  {Strings.OPTIONS}
                </Typography>

                <div className={classes.flex_row}>
                  <div className={classes.options_checkbox}>
                    <CustomCheckbox
                      container={classes.checkbox_container}
                      // indeterminate={true}
                      label={Strings.ACTIVE}
                      checked={values?.options?.active}
                      onChange={(e) => {
                        onChange('options', {
                          ...values.options,
                          active: e.target.checked,
                        });
                      }}
                      disabled={props.isShowDetails}
                      // onChange={() => props.onSelectAllClick()}
                    />
                  </div>
                  <div className={classes.options_checkbox}>
                    <CustomCheckbox
                      container={classes.checkbox_container}
                      label={Strings.DISPLAY_NOTES}
                      checked={values?.options?.displayNotes}
                      onChange={(e) => {
                        onChange('options', {
                          ...values.options,
                          displayNotes: e.target.checked,
                        });
                      }}
                      disabled={props.isShowDetails}
                    />
                  </div>
                </div>

                <div className={classes.flex_row}>
                  <div className={classes.options_checkbox}>
                    <CustomCheckbox
                      container={classes.checkbox_container}
                      // indeterminate={true}
                      label={Strings.ULTRA_SERVICE}
                      checked={values?.options?.ultraService}
                      onChange={(e) => {
                        onChange('options', {
                          ...values.options,
                          ultraService: e.target.checked,
                        });
                      }}
                      disabled={props.isShowDetails}
                      // onChange={() => props.onSelectAllClick()}
                    />
                  </div>
                  <div className={classes.options_checkbox}>
                    <CustomCheckbox
                      container={classes.checkbox_container}
                      label={Strings.RETAIL}
                      checked={values?.options?.retail}
                      onChange={(e) => {
                        onChange('options', {
                          ...values.options,
                          retail: e.target.checked,
                        });
                      }}
                      disabled={props.isShowDetails}
                    />
                  </div>
                </div>

                {!props.isShowDetails && (
                  <Button
                    type='submit'
                    className={classes.add_user_button}
                    variant='contained-radius'
                    disabled={
                      props.addClientLoading || props.updateClientLoading
                    }
                  >
                    {props.addClientLoading || props.updateClientLoading ? (
                      <Loader />
                    ) : props.edit ? (
                      Strings.UPDATE_CLIENT.toUpperCase()
                    ) : (
                      Strings.ADD_CLIENT.toUpperCase()
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addClientData: state.addClientData && state.addClientData.data,
  addClientLoading: state.addClientData && state.addClientData.loading,
  updateClientData: state.updateClientData && state.updateClientData.data,
  updateClientLoading: state.updateClientData && state.updateClientData.loading,
  designerList: state.designerListData && state.designerListData.data,
});

export default connect(mapStateToProps, {
  createClient,
  updateClient,
  fetchDesignerList,
  getClientData,
})(AddClientModal);
