import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
    main_container: {
        width: 'calc(100% - 650px)',
        padding: 16,
        paddingTop: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    form_container: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        gap: 20,
    },
    radio_button: {
        marginBottom: 20,
    },
    process_button: {
        borderRadius: '8px !important',
    },
}));
