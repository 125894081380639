import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'flex-end',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 559,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  selection_input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  disabled_input: {
    marginTop: '15px !important',
    width: '100% !important',
    '& .MuiInputBase-input.Mui-disabled': {
      '-webkit-text-fill-color': `${Colors.DAVYS_GREY} !important`,
    },
    '& .MuiInput-root': {
      '&::before': {
        borderBottom: `2px solid ${Colors.CHINESE_SILVER} !important`,
      },
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: `${Colors.DAVYS_GREY} !important`,
    },
  },
  add_user_button: {
    marginTop: '20px !important',
    width: '100% !important',
  },
  fees_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  fees_header_container: {
    display: 'flex',
    // alignItems: 'center',
    marginBottom: 10,
  },
  flex_1: {
    flex: 1,
  },
  flex_2: {
    flex: 2,
  },
  flex_3: {
    flex: 3,
  },
  sub_heading: {
    fontSize: '14px !important',
    color: Colors.DAVYS_GREY,
  },
  fees_content_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  fees_content_item: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.LIGHT_GRAY,
    padding: '8px 0',
  },
  button_input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  flex_row: {
    display: 'flex',
    alignItems: 'center',
  },
  toggle_btn: {
    marginLeft: 'auto',
    '& .MuiFormControlLabel-root': {
      margin: '0px !important',
      '& .MuiSwitch-root': {
        margin: '0px !important',
      },
    },
  },
  desc_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    flex: 1,
  },
}));
