import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import { connect } from 'react-redux';
import { fetchVendorList } from '../../redux/actions/vendorActions/vendorList';
import { PAGE_SIZE, getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import Dialog from '../../components/Dialog';
import { deleteVendor } from '../../redux/actions/vendorActions/deleteVendor';
import AddVendorModal from '../../components/AddVendorModal';
import { getVendor } from '../../redux/actions/vendorActions/getVendor';
import debounce from 'lodash/debounce';

const positionBgColor = {
  Designer: Colors.POWDER_BLUE,
  Employee: Colors.PALE_SPRING_BUD,
  Admin: Colors.PALE_LAVENDER,
};

const Vendors = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [vendors, setVendors] = useState([]);
  const [showDeleteVendorDialog, setShowDeleteVendorDialog] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [showEditVendorModal, setShowEditVendorModal] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isShowDetails, setIsShowDetails] = useState(false);

  const totalRecords = props.vendorListData?.totalRecords || 0;

  const handleChangePagination = async (e, p) => {
    // await jump(p);
    await setPage(p);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };
  const delayedSearch = useCallback(
    debounce(
      (q) => props.fetchVendorList(q, PAGE_SIZE, page, (err) => onError(err)),
      600
    ),
    []
  );

  useEffect(() => {
    setVendors(props.vendorListData?.users);
  }, [props?.vendorListData?.users]);

  useEffect(() => {
    props.fetchVendorList(search, PAGE_SIZE, page, (err) => onError(err));
  }, [
    page,
    // search,
    props.deleteVendorData,
    props.addVendorData,
    props.updateVendorData,
  ]);

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedVendor(item);
    await setShowEditVendorModal(true);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedVendor(item);
    await setShowDeleteVendorDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteVendor(
      selectedVendor._id,
      setShowDeleteVendorDialog,
      onError
    );
  };

  const updateVendorDataById = (values) => {
    const vendorIndex = vendors.findIndex(
      (obj) => obj._id === selectedVendor?._id
    );
    let newVendor = [...vendors];
    let tempUser = { ...vendors[vendorIndex] };
    if (values.name !== vendors[vendorIndex].name) tempUser.name = values.name;
    if (values.email !== vendors[vendorIndex].email)
      tempUser.email = values.email;
    newVendor[vendorIndex] = tempUser;
    setVendors(newVendor);
  };

  const onClickTableRow = (data) => {
    // setIsShowDetails(true);
    setShowEditVendorModal(true);
    setSelectedVendor(data);
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        searchPlaceholder={Strings.SEARCH_FOR_NAME}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      />
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          {props.vendorListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords && totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 700 }}>
                    <TableBody>
                      {vendors?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onClickTableRow(row)}
                          >
                            <TableCell component='th' width='15%'>
                              <Typography variant='p_700'>
                                {row?.name}
                              </Typography>
                            </TableCell>
                            {/* <TableCell component='th' width='15%'>
                              <Typography variant='p_400'>-</Typography>
                            </TableCell> */}
                            <TableCell width='25%'>
                              <Typography variant='p_400'>
                                {row?.email}
                              </Typography>
                            </TableCell>
                            <TableCell width='25%'>
                              <Typography variant='p_400'>
                                {row?.phoneNumber}
                              </Typography>
                            </TableCell>
                            <TableCell width='15%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().eye_feldgrau}
                                    className={classes.icons}
                                    onClick={(e) => {
                                      onClickEdit(e, row);
                                      setIsShowDetails(true);
                                    }}
                                  />
                                  <img
                                    src={icons().edit}
                                    className={classes.icons}
                                    onClick={(e) => onClickEdit(e, row)}
                                  />
                                  <img
                                    src={icons().delete}
                                    className={classes.icons}
                                    onClick={(e) => onClickDelete(e, row)}
                                  />
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
      {showDeleteVendorDialog && (
        <Dialog
          title={Strings.DELETE_VENDOR}
          message={Strings.ARE_YOU_SURE_DELETE_VENDOR}
          onClose={() => setShowDeleteVendorDialog(false)}
          loading={props.deleteVendorLoading}
          onClickYesButton={onClickYesButton}
        />
      )}
      {showEditVendorModal && (
        <AddVendorModal
          onClose={() => {
            setShowEditVendorModal(false);
            setIsShowDetails(false);
          }}
          edit
          selectedVendor={selectedVendor?._id}
          getVendor={props?.getVendor}
          vendorData={props?.getVendorData}
          loader={props?.getVendorLoading}
          updateVendorDataById={updateVendorDataById}
          isShowDetails={isShowDetails}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendorListData: state.vendorListData.data,
  vendorListLoading: state.vendorListData.loading,
  deleteVendorData: state.deleteVendorData.data,
  deleteVendorLoading: state.deleteVendorData.loading,
  addVendorData: state.addVendorData.data,
  getVendorData: state.getVendorData.data,
  getVendorLoading: state.getVendorData.loading,
  updateVendorData: state.updateVendorData.data,
});
export default connect(mapStateToProps, {
  fetchVendorList,
  deleteVendor,
  getVendor,
})(Vendors);
