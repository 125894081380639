import axios from 'axios';
import { LIST_CLIENT } from '../../constants';

export const fetchClientList = (data, onError, isUpdate) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  if (isUpdate !== 'update') {
    dispatch(loadClientList());
  }

  let queryStr = Object.entries(data)
    ?.map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    ?.join('&');

  axios
    .get(`/client/listClients?${queryStr}`, config)
    .then((res) => dispatch(setClientList(res)))
    .catch((err) => {
      dispatch(errorClientList(err.response));
      onError(err.response);
    });
};

export const loadClientList = () => ({
  type: LIST_CLIENT.LOAD,
});

export const setClientList = (userList) => ({
  type: LIST_CLIENT.SUCCESS,
  payload: userList.data.data,
});

export const errorClientList = (error) => ({
  type: LIST_CLIENT.FAIL,
  payload: error,
});
