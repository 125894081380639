import { Button, Typography, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { icons } from '../../assets';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import useStyles from './styles';

export default function Dialog(props) {
  const classes = useStyles();

  return (
    <div className={classes.main_container}>
      <div className={classes.dialog_container}>
        <div className={classes.close_icon_container}>
          <Typography variant='h6_700' color={Colors.BLACK}>
            {props.title}
          </Typography>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => props.onClose()}
          />
        </div>
        <div className={classes.dialog_body}>
          <Typography variant='p_400' color={Colors.BLACK}>
            {props.message}
          </Typography>
        </div>
        <div className={classes.dialog_action_container}>
          <Button
            type='button'
            variant='contained'
            className={classNames(classes.action_button, classes.no_button)}
            onClick={() => props.onClose()}
          >
            {Strings.NO}
          </Button>
          <Button
            type='button'
            variant='contained'
            className={classes.action_button}
            loading={true}
            onClick={props.onClickYesButton}
            disabled={props.loading || false}
          >
            {props.loading ? (
              <CircularProgress size={20} style={{ color: Colors.WHITE }} />
            ) : (
              Strings.YES
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
