import axios from 'axios';
import { DELETE_RATE_SHEET } from '../../constants';

export const deleteRateSheet = (ID, setDeleteDialog, onError, ) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteRateSheet());
  axios
    .delete(`/ratesheet/deleteRatesheet?ratesheetId=${ID}`, config)
    .then((res) => {
      dispatch(setDeleteRateSheet(res));
      setDeleteDialog(false);
    })
    .catch((err) => {
      dispatch(errorDeleteRateSheet(err.response));
      onError(err.response);
    });
};

export const loadDeleteRateSheet = () => ({
  type: DELETE_RATE_SHEET.LOAD,
});

export const setDeleteRateSheet = (data) => ({
  type: DELETE_RATE_SHEET.SUCCESS,
  payload: data,
});

export const errorDeleteRateSheet = (error) => ({
  type: DELETE_RATE_SHEET.FAIL,
  payload: error,
});
