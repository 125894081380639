import React, { useState } from 'react';
import useStyles from './styles';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { icons } from '../../assets';
import { Strings } from '../../common/strings';
import CustomCheckbox from '../CustomCheckbox';
import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddInventoryModal from '../AddInventoryModal';

// ==== Common Card ====
const CommonCard = (props) => {
  const classes = useStyles();

  return (
    <Tooltip title={props?.text} arrow>
      <Box className={classes.item_box}>
        {props?.showCheck && (
          <CustomCheckbox
            checked={props?.isChecked || false}
            onClick={props?.onClickChecked}
          />
        )}
        <Typography variant='p_small_600' className={classes.cardText}>
          {props?.text}
        </Typography>
        <img
          className={classes.edit_icon}
          src={icons().edit}
          onClick={props.onClick}
        />
      </Box>
    </Tooltip>
  );
};

const SelectInventoryItemDialog = (props) => {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(false);
  const [allListData, setAllListData] = useState([]);
  const [openEditInventory, setOpenEditInventory] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [selectedRow, setSelectedRow] = useState([]);

  // ===== Set All-List data =====
  const setListData = () => {
    const itemList =
      props?.selectedItems?.length > 0
        ? props.itemList?.filter((el) => {
            const fIndex = props?.selectedItems?.findIndex(
              (item) => item?.value === el?.value
            );
            if (fIndex === -1) return el;
          })
        : props.itemList;
    const newData = [
      { id: Strings.ITEMS, title: Strings.ITEMS, list: itemList || [] },
      {
        id: Strings.SELECTED_ITEMS,
        title: Strings.SELECTED_ITEMS,
        list: props?.selectedItems || [],
      },
    ];
    setAllListData(newData);
  };

  useEffect(() => {
    if (!selectedItemId) {
      setListData();
    }
  }, [props?.selectedItems]);

  // ==== checked/unchecked select all changes ====
  const onCheckedSelectAll = () => {
    setSelectAll(false);
    const newData = structuredClone(allListData);
    let data = newData?.find((el) => el?.id === 'Items')?.list;
    let listData = data?.filter((e) => !selectedRow.includes(e.value));
    let selectedData = data?.filter((e) => selectedRow.includes(e.value));

    newData.map((el) => {
      if (el.id === 'Items') el.list = listData;
      else if (el.id === 'Selected Items')
        el.list = [...selectedData, ...el.list];
      return el;
    });
    setAllListData(newData);
    setSelectedRow([]);
  };

  // ==== select items ====
  const selectAllItems = (isChecked) => {
    let newData = [];
    if (isChecked) {
      newData = structuredClone(allListData);
      newData = newData?.find((el) => el?.id === 'Items')?.list;
      newData = newData?.map((e) => e.value);
    }
    setSelectedRow(newData);
  };

  const handleCheckboxClick = (id) => {
    let data = selectedRow;
    if (data.includes(id)) {
      data = data.filter((e) => e !== id);
    } else {
      data = [...data, id];
    }
    setSelectedRow(data);
  };

  // ==== Drag-Drop Functionality ====
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const newData = structuredClone(allListData);

      const sourceColIndex = newData?.findIndex(
        (el) => el?.id === source?.droppableId
      );
      const destinationColIndex = newData?.findIndex(
        (el) => el?.id === destination?.droppableId
      );

      const sourceCol = newData[sourceColIndex];
      const destinationCol = newData[destinationColIndex];

      const sourceList = [...sourceCol?.list];
      const destinationList = [...destinationCol?.list];

      const removed = sourceList?.splice(source.index, 1)[0];
      destinationList?.splice(destination.index, 0, removed);

      newData[sourceColIndex].list = sourceList;
      newData[destinationColIndex].list = destinationList;

      setAllListData(newData);
    }
  };

  // ===== when edit inventory =====
  const editCardDetails = async (data) => {
    const { description, ID } = data;
    const newData = structuredClone(allListData);

    const fIndexOfItems = newData?.findIndex((el) => el.id === 'Items');
    const fIndexOfSelectedItems = newData?.findIndex(
      (el) => el.id === 'Selected Items'
    );
    const items = newData?.find((el) => el?.id === 'Items');
    const selectedItems = newData?.find((el) => el?.id === 'Selected Items');
    const itemsList = items?.list?.map((el) => {
      if (el.value === selectedItemId) el.label = `${ID} - ${description}`;
      return el;
    });
    const selectedItemsList = selectedItems?.list?.map((el) => {
      if (el.value === selectedItemId) el.label = `${ID} - ${description}`;
      return el;
    });
    newData[fIndexOfItems].list = itemsList;
    newData[fIndexOfSelectedItems].list = selectedItemsList;

    await setAllListData(newData);
  };

  // ==== onSave Click Functionality ====
  const onSave = () => {
    const selectedItemList =
      allListData?.find((el) => el?.id === 'Selected Items')?.list || [];
    props.setSelectedItems(selectedItemList || []);
    props.onClose();
  };

  if (openEditInventory) {
    return (
      <AddInventoryModal
        onClose={async (data) => {
          await setOpenEditInventory(false);
          await editCardDetails(data);
          setSelectedItemId('');
        }}
        isEdit
        isDelivery
        selectedID={selectedItemId}
      />
    );
  }

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      className={classes.outer_container}
    >
      <Stack padding={3} gap={3} className={classes.box_container}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6_600'>{Strings.SELECT_ITEMS}</Typography>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => {
              props.onClose();
            }}
          />
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack direction='row' gap={1.5}>
            <Typography variant='p_600'>{Strings.SELECT_ALL}</Typography>
            <CustomCheckbox
              checked={selectAll}
              onChange={(e) => {
                setSelectAll(e.target.checked);
                selectAllItems(e.target.checked);
              }}
            />
          </Stack>
          <Stack direction='row'>
            <Button
              variant='contained'
              className={classes.save_btn}
              onClick={() => onCheckedSelectAll()}
            >
              {Strings.SELECT}
            </Button>
          </Stack>
        </Stack>

        {/* ======== DragDrop Functionality ======== */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Stack
            direction='row'
            spacing={2}
            width='100%'
            height='100%'
            style={{ overflow: 'hidden' }}
          >
            {allListData?.map((el) => {
              return (
                <Stack gap={1} flex={1}>
                  <Typography variant='p_600'>{el?.title}</Typography>
                  <Droppable key={el?.id} droppableId={el?.id}>
                    {(provided) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        padding={2}
                        gap={1}
                        className={classes.item_list_container}
                      >
                        {el?.list?.map((item, index) => (
                          <Draggable
                            key={item?.value}
                            draggableId={item?.value}
                            index={index}
                          >
                            {(provided, snapShot) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ ...provided.draggableProps.style }}
                              >
                                <CommonCard
                                  key={item?.value}
                                  text={item?.label}
                                  onClick={() => {
                                    setSelectedItemId(item?.value);
                                    setOpenEditInventory(true);
                                  }}
                                  showCheck={el?.id === 'Items' || false}
                                  isChecked={selectedRow?.includes(item?.value)}
                                  onClickChecked={() =>
                                    handleCheckboxClick(item?.value)
                                  }
                                />
                              </Stack>
                            )}
                          </Draggable>
                        ))}
                      </Stack>
                    )}
                  </Droppable>
                </Stack>
              );
            })}
          </Stack>
        </DragDropContext>

        <Stack direction='row' justifyContent='flex-end'>
          <Button
            variant='contained'
            className={classes.save_btn}
            onClick={onSave}
          >
            {Strings.SAVE}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectInventoryItemDialog;
