import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  menu_icon: {
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
  menu_paper: {
    borderRadius: '8px !important',
    gap: 8,
    background: `${Colors.WHITE} !important`,
    boxShadow: `${Colors.MENU_SHADOW} !important`,
  },
  menu_item: {
    fontSize: '16px !important',
    lineHeight: '140% !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '400 !important',
  },
}));
