import * as Yup from 'yup';
import { digitsRegex, passwordRegex } from './appUtils';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const AddUserSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  designerCode: Yup.string().when('type', {
    is: (type) => {
      return type === 'designer' || type === '0';
    },
    then: Yup.string().trim().required('DesignerCode is required'),
    otherwise: Yup.string(),
  }),
  type: Yup.string()
    .required('Type is required')
    .test('Type is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Type is required`,
        });
      }
      return true;
    }),
  email: Yup.string().email('Enter valid email').required('Email is required'),
  shipping: Yup.object().when('type', {
    is: (type) => {
      return type === 'designer' || type === '0';
    },
    then: Yup.object().shape({
      zipcode: Yup.string()
        .required('ZipCode Required')
        .test('ZipCode Required', '', function (value) {
          if (value && !value.match(digitsRegex)) {
            return this.createError({
              message: `Only digits`,
            });
          }
          if (value && value.match(digitsRegex) && value.length !== 5) {
            return this.createError({
              message: `Only 5 digits`,
            });
          }
          return true;
        }),
      city: Yup.string()
        .required('City is required')
        .test('City is required', '', function (value) {
          if (value === '0') {
            return this.createError({
              message: `City is required`,
            });
          }
          return true;
        }),
      state: Yup.string()
        .required('State is required')
        .test('State is required', '', function (value) {
          if (value === '0') {
            return this.createError({
              message: `State is required`,
            });
          }
          return true;
        }),
      address: Yup.string().trim().required('Address is required'),
      business: Yup.string().required('Required'),
      // .test('Enter valid phone number', '', function (value) {
      //   if (value && !value.match(digitsRegex)) {
      //     return this.createError({
      //       message: `Only digits`,
      //     });
      //   }
      //   if (value && value.match(digitsRegex) && value.length !== 10) {
      //     return this.createError({
      //       message: `Only 10 digits`,
      //     });
      //   }
      //   return true;
      // }),
      cel: Yup.string(),
      // .required('Required')
      // .test('Enter valid phone number', '', function (value) {
      //   if (value && !value.match(digitsRegex)) {
      //     return this.createError({
      //       message: `Only digits`,
      //     });
      //   }
      //   if (value && value.match(digitsRegex) && value.length !== 10) {
      //     return this.createError({
      //       message: `Only 10 digits`,
      //     });
      //   }
      //   return true;
      // }),
      fax: Yup.string(),
      // .required('Required')
      // .test('Enter valid phone number', '', function (value) {
      //   if (value && !value.match(digitsRegex)) {
      //     return this.createError({
      //       message: `Only digits`,
      //     });
      //   }
      //   if (value && value.match(digitsRegex) && value.length !== 10) {
      //     return this.createError({
      //       message: `Only 10 digits`,
      //     });
      //   }
      //   return true;
      // }),
      other: Yup.string(),
      // .required('Required')
      // .test('Enter valid phone number', '', function (value) {
      //   if (value && !value.match(digitsRegex)) {
      //     return this.createError({
      //       message: `Only digits`,
      //     });
      //   }
      //   if (value && value.match(digitsRegex) && value.length !== 10) {
      //     return this.createError({
      //       message: `Only 10 digits`,
      //     });
      //   }
      //   return true;
      // }),
    }),
    otherwise: Yup.object(),
  }),
  billing: Yup.object().when('type', {
    is: (type) => {
      return type === 'designer' || type === '0';
    },
    then: Yup.object().shape({
      zipcode: Yup.string().test('ZipCode Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        if (!value) {
          return this.createError({
            message: `ZipCode Required`,
          });
        } else if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits`,
          });
        } else if (value && value.match(digitsRegex) && value.length !== 5) {
          return this.createError({
            message: `Only 5 digits`,
          });
        }
        return true;
      }),
      city: Yup.string().test('City Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        if (value === '0') {
          return this.createError({
            message: `City Required`,
          });
        }
        return true;
      }),
      state: Yup.string().test('State Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        if (value === '0') {
          return this.createError({
            message: `State Required`,
          });
        }
        return true;
      }),
      address: Yup.string()
        .trim()
        .test('Address is required', '', function (value, ctx) {
          if (ctx.parent.sameAsShipping) return true;
          if (!value) {
            return this.createError({
              message: `Address is required`,
            });
          }
          return true;
        }),
      business: Yup.string().test('Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        if (!value) {
          return this.createError({
            message: `Required`,
          });
          // } else if (value && !value.match(digitsRegex)) {
          //   return this.createError({
          //     message: `Only digits`,
          //   });
          // } else if (value && value.match(digitsRegex) && value.length !== 10) {
          //   return this.createError({
          //     message: `Only 10 digits`,
          //   });
        }
        return true;
      }),
      cel: Yup.string().test('Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        // if (!value) {
        //   return this.createError({
        //     message: `Required`,
        //   });
        // }
        // if (value && !value.match(digitsRegex)) {
        //   return this.createError({
        //     message: `Only digits`,
        //   });
        // } else if (value && value.match(digitsRegex) && value.length !== 10) {
        //   return this.createError({
        //     message: `Only 10 digits`,
        //   });
        // }
        return true;
      }),
      fax: Yup.string().test('Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        // if (!value) {
        //   return this.createError({
        //     message: `Required`,
        //   });
        // }
        // if (value && !value.match(digitsRegex)) {
        //   return this.createError({
        //     message: `Only digits`,
        //   });
        // } else if (value && value.match(digitsRegex) && value.length !== 10) {
        //   return this.createError({
        //     message: `Only 10 digits`,
        //   });
        // }
        return true;
      }),
      other: Yup.string().test('Required', '', function (value, ctx) {
        if (ctx.parent.sameAsShipping) return true;
        // if (!value) {
        //   return this.createError({
        //     message: `Required`,
        //   });
        // }
        // if (value && !value.match(digitsRegex)) {
        //   return this.createError({
        //     message: `Only digits`,
        //   });
        // } else if (value && value.match(digitsRegex) && value.length !== 10) {
        //   return this.createError({
        //     message: `Only 10 digits`,
        //   });
        // }
        return true;
      }),
      preferedContact: Yup.string()
        .required('Prefered contact is required')
        .test('Prefered contact is required', '', function (value) {
          if (value === '0') {
            return this.createError({
              message: `Prefered contact is required`,
            });
          }
          return true;
        }),
    }),
    otherwise: Yup.object(),
  }),
});
export const AddClientSchema = Yup.object().shape({
  clientCode: Yup.string().required('clientCode is required'),
  client: Yup.string().required('client is required'),
  designer: Yup.string()
    .required('designer is required')
    .test('designer is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `designer is required`,
        });
      }
      return true;
    }),
  email: Yup.string().email('Enter valid email'),
  primaryAddress: Yup.object().shape({
    zipcode: Yup.string()
      .required('ZipCode Required')
      .test('ZipCode Required', '', function (value) {
        if (value && !value.match(digitsRegex)) {
          return this.createError({
            message: `Only digits`,
          });
        }
        if (value && value.match(digitsRegex) && value.length !== 5) {
          return this.createError({
            message: `Only 5 digits`,
          });
        }
        return true;
      }),
    city: Yup.string()
      .required('City is required')
      .test('City is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `City is required`,
          });
        }
        return true;
      }),
    state: Yup.string()
      .required('State is required')
      .test('State is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `State is required`,
          });
        }
        return true;
      }),
    business: Yup.string(),
    // .required('Required')
    // .test('Enter valid phone number', '', function (value) {
    //   if (value && !value.match(digitsRegex)) {
    //     return this.createError({
    //       message: `Only digits`,
    //     });
    //   }
    //   if (value && value.match(digitsRegex) && value.length !== 10) {
    //     return this.createError({
    //       message: `Only 10 digits`,
    //     });
    //   }
    //   return true;
    // }),
    cel: Yup.string(),
    // .required('Required')
    // .test('Enter valid phone number', '', function (value) {
    //   if (value && !value.match(digitsRegex)) {
    //     return this.createError({
    //       message: `Only digits`,
    //     });
    //   }
    //   if (value && value.match(digitsRegex) && value.length !== 10) {
    //     return this.createError({
    //       message: `Only 10 digits`,
    //     });
    //   }
    //   return true;
    // }),
    fax: Yup.string(),
    // .required('Required')
    // .test('Enter valid phone number', '', function (value) {
    //   if (value && !value.match(digitsRegex)) {
    //     return this.createError({
    //       message: `Only digits`,
    //     });
    //   }
    //   if (value && value.match(digitsRegex) && value.length !== 10) {
    //     return this.createError({
    //       message: `Only 10 digits`,
    //     });
    //   }
    //   return true;
    // }),
    other: Yup.string(),
    // .required('Required')
    // .test('Enter valid phone number', '', function (value) {
    //   if (value && !value.match(digitsRegex)) {
    //     return this.createError({
    //       message: `Only digits`,
    //     });
    //   }
    //   if (value && value.match(digitsRegex) && value.length !== 10) {
    //     return this.createError({
    //       message: `Only 10 digits`,
    //     });
    //   }
    //   return true;
    // }),
  }),
  // secondaryAddress: Yup.object().shape({
  //   zipcode: Yup.string().test('ZipCode Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (!value) {
  //     //   return this.createError({
  //     //     message: `ZipCode Required`,
  //     //   });
  //     // }
  //     if (value && !value.match(digitsRegex)) {
  //       return this.createError({
  //         message: `Only digits`,
  //       });
  //     } else if (value && value.match(digitsRegex) && value.length !== 5) {
  //       return this.createError({
  //         message: `Only 5 digits`,
  //       });
  //     }
  //     return true;
  //   }),
  //   city: Yup.string().test('City Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (value === '0') {
  //     //   return this.createError({
  //     //     message: `City Required`,
  //     //   });
  //     // }
  //     return true;
  //   }),
  //   state: Yup.string().test('State Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (value === '0') {
  //     //   return this.createError({
  //     //     message: `State Required`,
  //     //   });
  //     // }
  //     return true;
  //   }),
  //   address: Yup.string().test(
  //     'Address is required',
  //     '',
  //     function (value, ctx) {
  //       if (ctx.parent.sameAsPrimaryAddress) return true;
  //       // if (!value) {
  //       //   return this.createError({
  //       //     message: `Address is required`,
  //       //   });
  //       // }
  //       return true;
  //     }
  //   ),
  //   business: Yup.string().test('Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (!value) {
  //     //   return this.createError({
  //     //     message: `Required`,
  //     //   });
  //     // }
  //     // if (value && !value.match(digitsRegex)) {
  //     //   return this.createError({
  //     //     message: `Only digits`,
  //     //   });
  //     // } else if (value && value.match(digitsRegex) && value.length !== 10) {
  //     //   return this.createError({
  //     //     message: `Only 10 digits`,
  //     //   });
  //     // }
  //     return true;
  //   }),
  //   cel: Yup.string().test('Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (!value) {
  //     //   return this.createError({
  //     //     message: `Required`,
  //     //   });
  //     // }
  //     // if (value && !value.match(digitsRegex)) {
  //     //   return this.createError({
  //     //     message: `Only digits`,
  //     //   });
  //     // } else if (value && value.match(digitsRegex) && value.length !== 10) {
  //     //   return this.createError({
  //     //     message: `Only 10 digits`,
  //     //   });
  //     // }
  //     return true;
  //   }),
  //   fax: Yup.string().test('Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (!value) {
  //     //   return this.createError({
  //     //     message: `Required`,
  //     //   });
  //     // }
  //     // if (value && !value.match(digitsRegex)) {
  //     //   return this.createError({
  //     //     message: `Only digits`,
  //     //   });
  //     // } else if (value && value.match(digitsRegex) && value.length !== 10) {
  //     //   return this.createError({
  //     //     message: `Only 10 digits`,
  //     //   });
  //     // }
  //     return true;
  //   }),
  //   other: Yup.string().test('Required', '', function (value, ctx) {
  //     if (ctx.parent.sameAsPrimaryAddress) return true;
  //     // if (!value) {
  //     //   return this.createError({
  //     //     message: `Required`,
  //     //   });
  //     // }
  //     // if (value && !value.match(digitsRegex)) {
  //     //   return this.createError({
  //     //     message: `Only digits`,
  //     //   });
  //     // } else if (value && value.match(digitsRegex) && value.length !== 10) {
  //     //   return this.createError({
  //     //     message: `Only 10 digits`,
  //     //   });
  //     // }
  //     return true;
  //   }),
  // }),
});

export const AddVendorSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  phone: Yup.string().required('Phone number is Required'),
  // .test('Enter valid phone number', '', function (value) {
  //   if (value && !value.match(digitsRegex)) {
  //     return this.createError({
  //       message: `Only digits`,
  //     });
  //   }
  //   if (value && value.match(digitsRegex) && value.length !== 10) {
  //     return this.createError({
  //       message: `Only 10 digits`,
  //     });
  //   }
  //   return true;
  // }),
  address: Yup.string().required('Address is required'),
  password: Yup.string().when('isEdit', {
    is: (isEdit) => {
      return isEdit === false;
    },
    then: Yup.string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum')
      .matches(
        passwordRegex,
        'Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
      ),
    otherwise: Yup.string(),
  }),
});

export const AddRateSheetSchema = Yup.object().shape({
  designer: Yup.string().when('forAll', {
    is: (forAll) => {
      return forAll;
    },
    then: Yup.string(),
    otherwise: Yup.string()
      .required('Designer is required')
      .test('Designer is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Designer is required`,
          });
        }
        return true;
      }),
  }),
  client: Yup.string().when(['forAll', 'forAllClient'], {
    is: (forAll, forAllClient) => {
      return forAll || forAllClient;
    },
    then: Yup.string(),
    otherwise: Yup.string()
      .required('Client is required')
      .test('Client is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Client is required`,
          });
        }
        return true;
      }),
  }),
  effectiveOn: Yup.string().required('EffectiveOn is required'),
});

export const AddDeliverySchema = Yup.object().shape({
  designer: Yup.string()
    .required('Designer is required')
    .test('Designer is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Designer is required`,
        });
      }
      return true;
    }),
  client: Yup.string()
    .required('Client is required')
    .test('Client is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Client is required`,
        });
      }
      return true;
    }),
  // item: Yup.string()
  //   .required('Item is required')
  //   .test('Item is required', '', function (value) {
  //     if (value === '0') {
  //       return this.createError({
  //         message: `Item is required`,
  //       });
  //     }
  //     return true;
  //   }),
  item: Yup.array()
    .required('Item is required')
    .test('Item is required', '', function (value) {
      if (value.length === 0) {
        return this.createError({
          message: `Item is required`,
        });
      }
      return true;
    }),
  deliveredOnDate: Yup.string().required('Delivered date is required'),
  deliveredOnTime: Yup.string().required('Delivered time is required'),
  doNotBill: Yup.boolean().required('Do not bill is required'),
  clientPickUp: Yup.boolean().required('Client pickup is required'),
  notes: Yup.boolean().required('Note is required'),
  notesDetail: Yup.string(),
  // total: Yup.number()
  //   .positive('Please enter valid total')
  //   .required('Total is required'),
  // invoicedOn: Yup.string().required('Invoiced On is required'),
  // closedOn: Yup.string().required('Closed On is required'),
  options: Yup.object().shape(
    {
      cod: Yup.bool().when(['billed', 'prepay'], {
        is: (billed, prepay) => !billed && !prepay,
        then: Yup.bool().oneOf(
          [true],
          'Please select atleast one billing option.'
        ),
      }),
      billed: Yup.bool().when(['cod', 'prepay'], {
        is: (cod, prepay) => !cod && !prepay,
        then: Yup.bool().oneOf(
          [true],
          'Please select atleast one billing option.'
        ),
      }),
      prepay: Yup.bool().when(['cod', 'billed'], {
        is: (cod, billed) => !cod && !billed,
        then: Yup.bool().oneOf(
          [true],
          'Please select atleast one billing option.'
        ),
      }),
    },
    [
      ['cod', 'billed'],
      ['cod', 'prepay'],
      ['billed', 'prepay'],
    ]
  ),
});

export const ReportSchema = Yup.object().shape({
  // radioGroup: Yup.string().test('Required', '', function (value) {
  //   // if (!value && !ctx.parent.sameAsPrimaryAddress) {
  //   //   return this.createError({
  //   //     message: `Required`,
  //   //   });
  //   // }
  //   // return true;
  // }),
  startOnDate: Yup.string().when('radioGroup', {
    is: (radioGroup) => {
      return radioGroup === 'Receiving Log';
    },
    then: Yup.string()
      .required('Date is required')
      .test('Date is required', '', function (value, ctx) {
        if (value === '') {
          return this.createError({
            message: `Date is required`,
          });
        }
        return true;
      }),
    otherwise: Yup.string(),
  }),
  // startOnTime: Yup.string().when('radioGroup', {
  //   is: (radioGroup) => {
  //     return radioGroup === 'Receiving Log';
  //   },
  //   then: Yup.string().required('Time is required'),
  //   otherwise: Yup.string(),
  // }),
  endOnDate: Yup.string().when('radioGroup', {
    is: (radioGroup) => {
      return radioGroup === 'Receiving Log';
    },
    then: Yup.string().required('Date is required'),
    otherwise: Yup.string(),
  }),
  // endOnTime: Yup.string().when('radioGroup', {
  //   is: (radioGroup) => {
  //     return radioGroup === 'Receiving Log';
  //   },
  //   then: Yup.string().required('Time is required'),
  //   otherwise: Yup.string(),
  // }),
  designer: Yup.string().when('radioGroup', {
    is: (radioGroup) => {
      return (
        radioGroup === 'Receiving Log' || radioGroup === 'Inventory Snapshot'
      );
    },
    then: Yup.string()
      .required('Designer is required')
      .test('Designer is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Designer is required`,
          });
        }
        return true;
      }),
    otherwise: Yup.string(),
  }),
  client: Yup.string().when('radioGroup', {
    is: (radioGroup) => {
      return (
        radioGroup === 'Receiving Log' || radioGroup === 'Inventory Snapshot'
      );
    },
    then: Yup.string().when('designer', {
      is: (designer) => {
        return designer !== 'all';
      },
      // then: Yup.string()
      //   .required('Client is required')
      //   .test('Client is required', '', function (value) {
      //     if (value === '0') {
      //       return this.createError({
      //         message: `Client is required`,
      //       });
      //     }
      //     return true;
      //   }),
      otherwise: Yup.string(),
    }),
    otherwise: Yup.string(),
  }),
  sortBy: Yup.string().when('radioGroup', {
    is: (radioGroup) => {
      return (
        radioGroup === 'Receiving Log' || radioGroup === 'Inventory Snapshot'
      );
    },
    then: Yup.string()
      .required('Sort By is required')
      .test('Sort By is required', '', function (value) {
        if (value === '0') {
          return this.createError({
            message: `Sort By is required`,
          });
        }
        return true;
      }),
    otherwise: Yup.string(),
  }),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      passwordRegex,
      'Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
    ),
});

export const addLocationSchema = Yup.object().shape({
  location: Yup.string()
    .matches(digitsRegex, 'Location number must be a number.')
    .required('Location number is required'),
});

export const AddInventorySchema = Yup.object().shape({
  designer: Yup.string()
    .required('Designer is required')
    .test('Designer is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Designer is required`,
        });
      }
      return true;
    }),
  client: Yup.string()
    .required('Client is required')
    .test('Client is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Client is required`,
        });
      }
      return true;
    }),
  size: Yup.string()
    .required('Size is required')
    .test('Size is required', '', function (value) {
      if (value === '0') {
        return this.createError({
          message: `Size is required`,
        });
      }
      return true;
    }),
  description: Yup.string().required('Description is required'),
  weight: Yup.string().required('Weight is required'),
  // declaredValue: Yup.string().required('Declared value is required'),
  receivedDate: Yup.string().required('Received date is required'),
  // receivedTime: Yup.string().required('Received time is required'),
  // deliveredDate: Yup.string().required('Delivered date is required'),
  // deliveredTime: Yup.string().required('Delivered time is required'),
  copyCount: Yup.number().when('copy', {
    is: (copy) => {
      return copy;
    },
    then: Yup.number()
      .min(1, 'Minimum 1 copy-count is required.')
      .positive('Enter valid copy-count.')
      .required('copy-count is required.'),
    otherwise: Yup.number(),
  }),
});

export const AddFeeSchema = Yup.object().shape({
  dailyRate: Yup.number()
    .positive('Please enter valid DailyRate')
    .required('DVP Daily Rate is required'),
  transitRate: Yup.number()
    .positive('Please enter valid TransitRate')
    .required('DVP Transit Rate is required'),
  pullFee: Yup.number()
    .positive('Please enter valid PullFee')
    .required('DVP Pull Fee is required'),
  pickupFee: Yup.number()
    .positive('Please enter valid PickupFee')
    .required('Pickup Fee is required'),
  trashFee: Yup.number()
    .positive('Please enter valid TrashFee')
    .required('Trash Fee is required'),
  barketFee: Yup.number()
    .positive('Please enter valid BlanketFee')
    .required('Blanket Fee is required'),
});

export const TotalCalculationSchema = Yup.object().shape({
  noOfMens: Yup.number()
    .min(1, 'minimum value 1 is required')
    .max(8, 'Number Of Mens not more than 8')
    .required('Number Of Mens is required'),
  travelTime: Yup.number().required('Travel time is required'),
  deliveryTime: Yup.number().required('Delivery time is required'),
  fuelsCharge: Yup.number().required('Fuels charge is required'),
  piecesOfTrash: Yup.number().required('Pieces of trash is required'),
  miscFees: Yup.number().required('Misc Fees is required'),
  rateType: Yup.object().shape(
    {
      designerRate: Yup.bool().when(['customerRate', 'surroundingRate'], {
        is: (customerRate, surroundingRate) =>
          !customerRate && !surroundingRate,
        then: Yup.bool().oneOf([true], 'Please select at least one option.'),
      }),
      customerRate: Yup.bool().when(['designerRate', 'surroundingRate'], {
        is: (designerRate, surroundingRate) =>
          !designerRate && !surroundingRate,
        then: Yup.bool().oneOf([true], 'Please select at least one option.'),
      }),
      surroundingRate: Yup.bool().when(['designerRate', 'customerRate'], {
        is: (designerRate, customerRate) => !designerRate && !customerRate,
        then: Yup.bool().oneOf([true], 'Please select at least one option.'),
      }),
    },
    [
      ['designerRate', 'customerRate'],
      ['designerRate', 'surroundingRate'],
      ['customerRate', 'surroundingRate'],
    ]
  ),
});
