import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import { connect } from 'react-redux';
import {
  fetchInventoryList,
  setInventoryList,
} from '../../redux/actions/inventoryActions/inventoryList';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import { deleteInventory } from '../../redux/actions/inventoryActions/deleteInventory';
import { barcodePrint } from '../../redux/actions/inventoryActions/barcodePrint';
import { barcodePdf } from '../../redux/actions/inventoryActions/barcodePdf';
import { getErrorMessage, PAGE_SIZE } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import moment from 'moment';
import AddInventoryModal from '../../components/AddInventoryModal';
import Dialog from '../../components/Dialog';
import BarcodeModal from '../../components/BarcodeModal';
import ToggleButton from '../../components/ToggleButton';
import { updateInventoryStatus } from '../../redux/actions/inventoryActions/updateInventoryStatus';
import debounce from 'lodash/debounce';
import { isDesigner, isAdmin } from '../../utils/appUtils';
import { useOutletContext } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

const tableHeaders = [
  { label: 'WSI Number', width: '8%' },
  { label: 'Item', width: '20%' },
  { label: 'Designer', width: '20%' },
  { label: 'Client', width: '20%' },
  { label: 'Status', width: '5%' },
  { label: 'Received On', width: '10%' },
  { label: 'Closed On', width: '10%' },
  { label: '', width: '7%' },
];

const positionBgColor = {
  Designer: Colors.POWDER_BLUE,
  Employee: Colors.PALE_SPRING_BUD,
  Admin: Colors.PALE_LAVENDER,
};

const Inventory = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [setNavbarButtonArray, setDesignerList] = useOutletContext();
  const token = isDesigner() && localStorage.getItem('jwtToken');
  const userId = isDesigner() && token ? jwtDecode(token)?.userId : null;

  const [selectedItem, setSelectedItem] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [inventories, setInventories] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [showEditInventoryModal, setShowEditInventoryModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showBarcodeDialog, setShowBarcodeDialog] = useState(false);
  const [selectedBarcode, setSelectedBarcode] = useState({});
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [navbarClickBtnType, setNavbarClickBtnType] = useState('');
  const [tableHeaderList, setTableHeaderList] = useState(tableHeaders || []);
  const totalRecords = props.inventoryListData?.totalRecords || 0;

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => {
      let allDesigner = selectedItem?.some((item) => item.value === 'all');
      let designerValue =
        !isDesigner() && q.length > 0 && !selectedItem.length
          ? []
          : isDesigner() && userId
          ? [userId]
          : allDesigner
          ? designers
              ?.filter((designer) => designer.value !== 'all')
              .map((designer) => designer.value)
          : selectedItem?.map((item) => item.value);

      const data = { designer: designerValue || [] };
      props.fetchInventoryList(isActive, q, data, PAGE_SIZE, page, onError);
    }, 600),
    [selectedItem, isActive, userId]
  );

  const manageTableHeader = (activeTab) => {
    const copyData = structuredClone(tableHeaders);
    if (activeTab === 0) {
      let fIndex = copyData?.findIndex((el) => el?.label === 'Closed On');
      copyData.splice(fIndex, 1);
      setTableHeaderList(copyData);
    } else setTableHeaderList(tableHeaders);
  };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    setPage(1);
    manageTableHeader(value);
    // setSearch('');
    if (value === 1) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  useEffect(() => {
    !isDesigner() && props.fetchDesignerList((err) => onError(err));
    manageTableHeader(selectedTab);
  }, []);

  useEffect(() => {
    const data = props.inventoryListData?.inventories;
    setInventories(data);
  }, [props.inventoryListData?.inventories]);

  const getInventories = (pageNumber, type, onSuccess) => {
    let allDesigner = selectedItem?.some((item) => item.value === 'all');
    let designerValue =
      !isDesigner() && search && !selectedItem.length
        ? []
        : isDesigner() && userId
        ? [userId]
        : allDesigner
        ? designers
            ?.filter((designer) => designer.value !== 'all')
            .map((designer) => designer.value)
        : selectedItem?.map((item) => item.value);

    const data = { designer: designerValue || [] };

    props.fetchInventoryList(
      isActive,
      search,
      data,
      PAGE_SIZE,
      pageNumber || page,
      onError,
      (res) => {
        onSuccess?.(res);
      },
      type || ''
    );
  };

  useEffect(() => {
    if (props?.designerListData || userId) {
      getInventories(page);
    }
  }, [
    selectedItem,
    page,
    userId,
    props?.addInventoryData,
    props?.updateInventoryData,
    props?.deleteInventoryData,
    props?.barcodePrintData,
    props?.barcodePdfData,
    isActive,
  ]);

  useEffect(() => {
    if (!isDesigner()) {
      const data = props.designerListData?.map((el) => ({
        label: el?.name,
        value: el?._id,
      }));
      setDesigners([{ label: 'All designers', value: 'all' }, ...data]);
      setDesignerList(data);
    }
  }, [props.designerListData]);

  useEffect(() => {
    return () => dispatch(setInventoryList([]));
  }, []);

  const handleChangePagination = async (e, p) => {
    // await jump(p);
    await setPage(p);
  };

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedInventory(item);
    await setShowEditInventoryModal(true);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedInventory(item);
    await setShowDeleteDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteInventory(
      selectedInventory?._id,
      setShowDeleteDialog,
      onError
    );
  };

  const updateStatus = (item, status) => {
    const { updateInventoryStatus } = props;
    const data = { status };
    updateInventoryStatus(item._id, data, (err) => onError(err));
  };

  const onClickBarcode = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBarcode(row);
    setShowBarcodeDialog(true);
  };

  const onClickPrintButton = () => {
    const inventoryData = {
      inventory: selectedBarcode._id,
    };
    props.barcodePrint(
      inventoryData,
      () => setShowBarcodeDialog(false),
      onError
    );
  };

  const onClickPdfButton = (isPrint) => {
    setNavbarClickBtnType(isPrint ? 'Print' : 'View');

    let allDesigner = selectedItem?.some((item) => item.value === 'all');
    let designerValue = allDesigner
      ? designers
          ?.filter((designer) => designer.value !== 'all')
          .map((designer) => designer.value)
      : selectedItem?.map((item) => item.value);

    const data = { designer: designerValue || [] };
    props.barcodePdf(
      isPrint,
      onError,
      (data) => {
        setNavbarClickBtnType('');
        if (!isPrint) {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      },
      data
    );
  };

  const onClickTableRow = (data) => {
    // setIsShowDetails(true);
    setSelectedInventory(data);
    setShowEditInventoryModal(true);
    if (isDesigner()) setIsShowDetails(true);
  };

  // === Set Button On Navbar ===
  useEffect(() => {
    if (isAdmin()) {
      setNavbarButtonArray([
        {
          btnOnClick: () => onClickPdfButton(false),
          btnText: Strings.VIEW_BARCODES,
          btnClassName: classes.printBtn,
          loading: navbarClickBtnType === 'View' && props.barcodePdfLoading,
        },
        {
          btnOnClick: () => onClickPdfButton(true),
          btnText: Strings.PRINT_BARCODE,
          btnClassName: classes.printBtn,
          loading: navbarClickBtnType === 'Print' && props.barcodePdfLoading,
        },
      ]);
    }
    return () => setNavbarButtonArray([]);
  }, [props.barcodePdfLoading, selectedItem]);

  return (
    <div className={classes.main_container}>
      <div className={classes.nav_container}>
        <TableToolbox
          isSearch
          isMultiSelection={!isDesigner()}
          isSearchableSelect
          dropDownPlaceholder={Strings.SELECT_DESIGNER}
          searchPlaceholder={Strings.SEARCH_FOR_ITEM_OR_ID}
          selectedItem={selectedItem}
          selectOption={designers}
          handleSelectChange={(value) => {
            setSelectedItem(value);
            setPage(1);
          }}
          searchValue={search}
          handleSearch={(e) => {
            setPage(1);
            setSearch(e.target.value);
            delayedSearch(e.target.value);
          }}
        />
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.tab_container}>
          <Tabs
            value={selectedTab}
            style={{ minHeight: 38, height: 38 }}
            onChange={handleTabChange}
            className={classes.tabs}
            // variant='scrollable'
            scrollButtons={false}
            allowScrollButtonsMobile
            aria-label='scrollable force tabs example'
          >
            <Tab disableRipple className={classes.tab} label={Strings.ACTIVE} />
            <Tab disableRipple className={classes.tab} label={Strings.CLOSED} />
          </Tabs>
        </div>
        <div className={classes.table}>
          {props.inventoryListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead headCells={tableHeaderList} />
                    <TableBody>
                      {inventories?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => onClickTableRow(row)}
                          >
                            <TableCell component='th' width='5%'>
                              <Typography variant='p_400'>{row?.ID}</Typography>
                            </TableCell>
                            <TableCell component='th' width='20%'>
                              <Typography variant='p_700'>
                                {row?.description || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='17.5%'>
                              <Typography variant='p_400'>
                                {row?.designer || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='17.5%'>
                              <Typography variant='p_400'>
                                {row?.client || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='10%'>
                              <Typography variant='p_400'>
                                {/* {row?.status || '-'} */}
                                <ToggleButton
                                  checked={row.status}
                                  className={classes.toggle_btn}
                                  disabled={true}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const updateData = inventories.map(
                                      (data, i) => {
                                        if (i === index) {
                                          return {
                                            ...data,
                                            status: e.target.checked,
                                          };
                                        } else {
                                          return {
                                            ...data,
                                          };
                                        }
                                      }
                                    );
                                    setInventories(updateData);
                                    updateStatus(row, e.target.checked);
                                  }}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell width='10%'>
                              <Typography variant='p_400'>
                                {row?.receivedDate
                                  ? moment(row?.receivedDate).format(
                                      'MMMM DD,YYYY'
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                            {selectedTab === 1 ? (
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row?.deliveredDate
                                    ? moment(row?.deliveredDate).format(
                                        'MMMM DD,YYYY'
                                      )
                                    : '-'}
                                </Typography>
                              </TableCell>
                            ) : null}
                            <TableCell width='10%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  {!isDesigner() && (
                                    <img
                                      src={icons().eye_feldgrau}
                                      className={classes.icons}
                                      onClick={(e) => {
                                        onClickEdit(e, row);
                                        setIsShowDetails(true);
                                      }}
                                    />
                                  )}
                                  {!isDesigner() && (
                                    <img
                                      src={icons().barcode}
                                      className={classes.icons}
                                      onClick={(e) => onClickBarcode(e, row)}
                                    />
                                  )}
                                  {!isDesigner() && (
                                    <img
                                      src={icons().edit}
                                      className={classes.icons}
                                      onClick={(e) => onClickEdit(e, row)}
                                    />
                                  )}
                                  {!isDesigner() && (
                                    <img
                                      src={icons().delete}
                                      className={classes.icons}
                                      onClick={(e) => onClickDelete(e, row)}
                                    />
                                  )}
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && !props.inventoryListLoading && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
        {showEditInventoryModal && (
          <AddInventoryModal
            onClose={() => {
              setShowEditInventoryModal(false);
              setIsShowDetails(false);
            }}
            isEdit
            selectedID={selectedInventory?._id}
            selectedTab={selectedTab}
            isShowDetails={isShowDetails}
            designerList={designers
              ?.filter((designer) => designer.value !== 'all')
              .map((designer) => designer)}
            pageNumber={page}
            fetchInventories={getInventories}
          />
        )}
        {showDeleteDialog && (
          <Dialog
            title={Strings.DELETE_INVENTORY}
            message={Strings.ARE_YOU_SURE_DELETE_INVENTORY}
            onClose={() => setShowDeleteDialog(false)}
            loading={props.deleteInventoryLoading}
            onClickYesButton={onClickYesButton}
          />
        )}
        {showBarcodeDialog && (
          <BarcodeModal
            title={Strings.BARCODE_VIEWER}
            // message={Strings.ARE_YOU_SURE_DELETE_NOTE}
            selectedBarcode={selectedBarcode}
            onClose={() => setShowBarcodeDialog(false)}
            isBarcodeDetailRequired={true}
            onClickPrint={onClickPrintButton}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inventoryListData: state.inventoryListData?.data,
  inventoryListLoading: state.inventoryListData?.loading,

  designerListData: state.designerListData.data,

  addInventoryData: state.addInventoryData.data,

  updateInventoryData: state.updateInventoryData.data,

  deleteInventoryData: state.deleteInventoryData.data,
  deleteInventoryLoading: state.deleteInventoryData.loading,

  updateInventoryStatusData: state.updateInventoryStatusData.data,

  barcodePrintData: state.barcodePrintData.data,
  barcodePdfLoading: state.barcodePdfData.loading,
  barcodePdfData: state.barcodePdfData.data,
});

export default connect(mapStateToProps, {
  fetchInventoryList,
  fetchDesignerList,
  deleteInventory,
  updateInventoryStatus,
  barcodePrint,
  barcodePdf,
})(Inventory);
