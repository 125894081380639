import axios from 'axios';
import { RATE_SHEET_LIST } from '../../constants';

export const fetchRateSheetList =
  (search, pageSize, pageNumber, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadRateSheetList());
    }

    axios
      .get(
        `/ratesheet/listRatesheets?searchString=${search}&page_size=${pageSize}&page_number=${pageNumber}`,
        config
      )
      .then((res) => dispatch(setRateSheetList(res)))
      .catch((err) => {
        dispatch(errorRateSheetList(err.response));
        onError(err.response);
      });
  };

export const loadRateSheetList = () => ({
  type: RATE_SHEET_LIST.LOAD,
});

export const setRateSheetList = (rateList) => ({
  type: RATE_SHEET_LIST.SUCCESS,
  payload: rateList.data.data,
});

export const errorRateSheetList = (error) => ({
  type: RATE_SHEET_LIST.FAIL,
  payload: error,
});
