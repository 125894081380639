import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    display: 'flex',
    height: 'inherit',
    flexDirection: 'column',
  },
  tableContainer: {
    display: 'flex',
    height: 'inherit',
    padding: 15,
    flexDirection: 'column',
  },
  row_flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  icons: {
    height: 23,
    width: 23,
    cursor: 'pointer'
  },
  align_right: {
    justifyContent: 'flex-end',
  },
  padding_right: {
    paddingRight: 15,
  },
  table: {
    display: 'flex',
    height: 'inherit',
    overflow: 'auto',
  },
  pagination: {
    display: 'flex',
    marginBottom: 120,
    padding: 20,
    justifyContent: 'center',
    flex: 1,
  },
  flex_view: {
    width: '100%',
    display: 'flex',
    marginTop: 60,
    justifyContent: 'center',
  },
  toggle_btn: {
    marginLeft: 'auto',
    '& .MuiFormControlLabel-root': {
      margin: '0px !important',
      '& .MuiSwitch-root': {
        margin: '0px !important',
      },
    },
  },
}));
