import axios from 'axios';
import { UPLOAD_FILE } from '../../constants';

export const uploadFile = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;

  const bodyFormData = new FormData();
  bodyFormData.append('files', data.files);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  dispatch(uploadFileData());

  axios
    .post(`/admin/uploadFile`, bodyFormData, config)
    .then((res) => {
      onSuccess(res.data?.data[0]?.url);
      dispatch(setUploadFileData(res));
    })
    .catch((err) => {
      dispatch(errorUploadFile(err.response));
      onError(err.response);
    });
};

export const uploadFileData = () => ({
  type: UPLOAD_FILE.LOAD,
});

export const setUploadFileData = (data) => ({
  type: UPLOAD_FILE.SUCCESS,
  payload: data.data,
});

export const errorUploadFile = (error) => ({
  type: UPLOAD_FILE.FAIL,
  payload: error,
});
