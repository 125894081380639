import axios from 'axios';
import { DELETE_DELIVERY } from '../../constants';

export const deleteDelivery =
  (deliveryID, setDeleteDialog, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteDelivery());
    axios
      .delete(`/delivery/deleteDelivery?deliveryId=${deliveryID}`, config)
      .then((res) => {
        dispatch(setDeleteDelivery(res));
        onSuccess();
        setDeleteDialog(false);
      })
      .catch((err) => {
        dispatch(errorDeleteDelivery(err.response));
        onError(err.response);
      });
  };

export const loadDeleteDelivery = () => ({
  type: DELETE_DELIVERY.LOAD,
});

export const setDeleteDelivery = (data) => ({
  type: DELETE_DELIVERY.SUCCESS,
  payload: data,
});

export const errorDeleteDelivery = (error) => ({
  type: DELETE_DELIVERY.FAIL,
  payload: error,
});
