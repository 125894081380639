import axios from 'axios';
import { FORGOT_PASSWORD } from '../../constants';

export const forgotPassword = (data, onSuccess, onError) => (dispatch) => {
  dispatch(forgotPasswordData());
  axios
    .post(`/auth/forgotPassword`, data)
    .then((res) => {
      dispatch(setForgotPassword(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorForgotPassword(err.response));
      onError(err.response);
    });
};

export const forgotPasswordData = () => ({
  type: FORGOT_PASSWORD.LOAD,
});

export const setForgotPassword = (data) => ({
  type: FORGOT_PASSWORD.SUCCESS,
  payload: data.data,
});

export const errorForgotPassword = (error) => ({
  type: FORGOT_PASSWORD.FAIL,
  payload: error,
});
