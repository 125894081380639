import axios from 'axios';
import { GET_DELIVERY } from '../../constants';

export const getDelivery =
  (deliveryId, onError, isClientsRequired) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadGetDelivery());
    axios
      .get(`/delivery/getDelivery/${deliveryId}`, config)
      .then((res) => {
        const data = res?.data?.data;
        dispatch(setGetDelivery(data));
      })
      .catch((err) => {
        dispatch(errorGetDelivery(err.response));
        onError(err.response);
      });
  };

export const loadGetDelivery = () => ({
  type: GET_DELIVERY.LOAD,
});

export const setGetDelivery = (data) => ({
  type: GET_DELIVERY.SUCCESS,
  payload: data,
});

export const errorGetDelivery = (error) => ({
  type: GET_DELIVERY.FAIL,
  payload: error,
});
