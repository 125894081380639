import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import ToggleButton from '../../components/ToggleButton';
import { connect } from 'react-redux';
import { fetchRateSheetList } from '../../redux/actions/rateSheetActions/rateSheetList';
import { getErrorMessage, PAGE_SIZE, userTypeList } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import moment from 'moment';
import AddRateSheetModal from '../../components/AddRateSheetModal';
import { getRateSheet } from '../../redux/actions/rateSheetActions/getRateSheet';
import { deleteRateSheet } from '../../redux/actions/rateSheetActions/deleteRateSheet';
import { updateRateSheetStatus } from '../../redux/actions/rateSheetActions/updateRatesheetStatus';
import Dialog from '../../components/Dialog';
import debounce from 'lodash/debounce';

const tableHeaders = [
  { isSort: true, name: 'ID', label: 'ID', width: '5%' },
  { isSort: true, name: 'designer', label: 'Designer', width: '25%' },
  { isSort: true, name: 'client', label: 'Client', width: '20%' },
  {
    isSort: false,
    name: 'ApplicableToAll',
    label: 'Applicable to All',
    width: '15%',
  },
  { isSort: true, name: 'effectiveOn', label: 'Effective On', width: '20%' },
  { name: 'ultraService', label: 'Ultra Service', width: '10%' },
  { name: 'retail', label: 'Retail', width: '10%' },
  { name: '', label: '', width: '5%' },
];

const RateSheet = (props) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState('all');
  const { jump } = usePagination([], 10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [rateSheet, setRateSheet] = useState([]);
  const [showAddRateSheetModal, setShowAddRateSheetModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectRateSheetId, setSelectRateSheetId] = useState({});
  const totalRecords = props?.rateSheetListData?.totalRecords || 0;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [isShowDetails, setIsShowDetails] = useState(false);

  const delayedSearch = useCallback(
    debounce(
      (q) =>
        props.fetchRateSheetList(q, PAGE_SIZE, page, (err) => onError(err)),
      600
    ),
    []
  );

  useEffect(() => {
    props.fetchRateSheetList(search, PAGE_SIZE, page, (err) => onError(err));
  }, [
    selectedItem,
    page,
    // search,
    props.addRateSheetData,
    props.updateRateSheetData,
    props.deleteRateSheetData,
  ]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    setRateSheet(props.rateSheetListData.rateSheets);
  }, [props.rateSheetListData]);

  const handleChangePagination = async (e, p) => {
    await jump(p);
    await setPage(p);
  };

  const onClickEdit = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectRateSheetId(item._id);
    setShowAddRateSheetModal(true);
  };

  const onDeleteClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectRateSheetId(item._id);
    setShowDeleteDialog(true);
  };

  const onClickYesButton = async () => {
    props.deleteRateSheet(selectRateSheetId, setShowDeleteDialog, onError);
  };

  const updateStatus = (item, ultraService, retail) => {
    const { updateRateSheetStatus } = props;
    const data = {
      ultraService: ultraService,
      retail: retail,
    };
    updateRateSheetStatus(item._id, data, (err) => onError(err));
  };

  const sortedItems = useMemo(() => {
    if (rateSheet?.length) {
      let sortableItems = [...rateSheet];
      if (orderBy !== null) {
        sortableItems.sort((a, b) => {
          let ascending = a[orderBy] < b[orderBy];
          let descending = a[orderBy] > b[orderBy];
          if (orderBy === 'effectiveOn') {
            ascending = moment(a[orderBy]) < moment(b[orderBy]);
            descending = moment(a[orderBy]) > moment(b[orderBy]);
          }
          if (ascending) return order === 'asc' ? -1 : 1;
          if (descending) return order === 'asc' ? 1 : -1;
          return 0;
        });
      }
      return sortableItems;
    } else return [];
  }, [rateSheet, order, orderBy]);

  // ===== for All ======
  const showRoleHandle = (check, role) => {
    let val;
    if (check) {
      val = ' For All';
    } else {
      val = role;
    }
    return val;
  };

  const requestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onClickTableRow = (data) => {
    setShowAddRateSheetModal(true);
    // setIsShowDetails(true);
    setSelectRateSheetId(data?._id);
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        searchPlaceholder={Strings.SEARCH_FOR_RATESHEET}
        selectedItem={selectedItem}
        selectOption={[
          { label: 'All user types', value: 'all' },
          ...userTypeList,
        ]}
        handleSelectChange={(e) => {
          setSelectedItem(e.target.value);
          setPage(1);
        }}
        searchValue={search}
        handleSearch={(e) => {
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      />
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          {props.rateSheetListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {rateSheet && rateSheet.length > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead
                      headCells={tableHeaders}
                      onSortRequest={requestSort}
                      order={order}
                      orderBy={orderBy}
                    />
                    <TableBody>
                      {rateSheet &&
                        sortedItems?.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickTableRow(row)}
                            >
                              <TableCell component='th' width='5%'>
                                <Typography variant='p_400'>
                                  {row?.ID}
                                </Typography>
                              </TableCell>
                              <TableCell component='th' width='25%'>
                                {
                                  <div className={classes.row_flex}>
                                    {row?.designer && (
                                      <Avatar
                                        sx={{ bgcolor: Colors.RAISIN_BLACK }}
                                        classes={{ root: classes.profile }}
                                        src={row?.designerProfile}
                                      >
                                        {row?.designer
                                          ?.charAt(0)
                                          ?.toUpperCase()}
                                      </Avatar>
                                    )}
                                    <Typography variant='p_700'>
                                      {row?.designer || '-'}
                                    </Typography>
                                  </div>
                                }
                              </TableCell>
                              <TableCell width='20%'>
                                <Typography variant='p_400'>
                                  {row?.forAllClient
                                    ? 'All'
                                    : row?.client || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell width='20%'>
                                <Typography variant='p_400'>
                                  {row.forAll ? 'Yes' : '-'}
                                </Typography>
                              </TableCell>
                              <TableCell
                                width='20%'
                                onClick={() => onClickTableRow(row)}
                              >
                                <Typography variant='p_400'>
                                  {moment(row?.effectiveOn).format(
                                    'MMMM DD, YYYY'
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row?.ultraService ? 'Yes' : '-'}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                <Typography variant='p_400'>
                                  {row?.retail ? 'Yes' : '-'}
                                </Typography>
                              </TableCell>
                              <TableCell width='10%'>
                                {
                                  <div
                                    className={classNames(
                                      classes.row_flex,
                                      classes.align_right,
                                      classes.padding_right
                                    )}
                                  >
                                    <img
                                      src={icons().eye_feldgrau}
                                      className={classes.icons}
                                      onClick={(e) => {
                                        onClickEdit(e, row);
                                        setIsShowDetails(true);
                                      }}
                                    />
                                    <img
                                      src={icons().edit}
                                      className={classes.icons}
                                      onClick={(e) => onClickEdit(e, row)}
                                    />
                                    <img
                                      src={icons().delete}
                                      onClick={(e) => onDeleteClick(e, row)}
                                      className={classes.icons}
                                    />
                                  </div>
                                }
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
      {showAddRateSheetModal && (
        <AddRateSheetModal
          onClose={() => {
            setShowAddRateSheetModal(false);
            setIsShowDetails(false);
          }}
          selectRateSheetId={selectRateSheetId}
          edit
          isShowDetails={isShowDetails}
        />
      )}
      {showDeleteDialog && (
        <Dialog
          title={Strings.DELETE_RATE_SHEET}
          message={Strings.ARE_YOU_SURE_RATE_SHEET}
          onClose={() => setShowDeleteDialog(false)}
          loading={props.deleteRateSheetLoading}
          onClickYesButton={onClickYesButton}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  rateSheetListData: state.rateSheetListData.data,
  rateSheetListLoading: state.rateSheetListData.loading,
  deleteRateSheetData: state.deleteRateSheetData.data,
  deleteRateSheetLoading: state.deleteRateSheetData.loading,
  updateRateSheetStatusData: state.updateRateSheetStatusData.data,
  updateRateSheetStatusLoading: state.updateRateSheetStatusData.loading,
  addRateSheetData: state.addRateSheetData.data,
  updateRateSheetData: state.updateRateSheetListData.data,
});

export default connect(mapStateToProps, {
  fetchRateSheetList,
  getRateSheet,
  deleteRateSheet,
  updateRateSheetStatus,
})(RateSheet);
