import axios from 'axios';
import { UPDATE_RATE_SHEET_STATUS } from '../../constants';

export const updateInventoryStatus =
  (id, data, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadUpdateInventoryStatus());
    }

    axios
      .put(`/inventory/updateInventoryStatus?inventoryId=${id}`, data, config)
      .then((res) => dispatch(setUpdateInventoryStatus(res)))
      .catch((err) => {
        dispatch(errorInventoryStatus(err.response));
        onError(err.response);
      });
  };

export const loadUpdateInventoryStatus = () => ({
  type: UPDATE_RATE_SHEET_STATUS.LOAD,
});

export const setUpdateInventoryStatus = (rateList) => ({
  type: UPDATE_RATE_SHEET_STATUS.SUCCESS,
  payload: rateList.data.data,
});

export const errorInventoryStatus = (error) => ({
  type: UPDATE_RATE_SHEET_STATUS.FAIL,
  payload: error,
});
