import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    justifyContent: 'flex-end',
    // transition: 'all .5s ease-in-out'
  },
  modal_container: {
    backgroundColor: Colors.WHITE,
    width: 559,
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 30px 40px 25px',
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  selection_input: {
    marginTop: '15px !important',
  },
  top_margin: {
    marginTop: '35px !important',
  },
  input: {
    marginTop: '15px !important',
    width: '100% !important',
  },
  sub_title: {
    // marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
  label_margin: {
    marginTop: `20px !important`,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  flex: {
    flex: 1,
  },
  toggle_btn: {
    // height: '24px !important',
    // width: '39px !important',
    // marginTop: '10 !important'
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important',
      '& .MuiSwitch-root': {
        marginRight: '0px !important',
      },
    },
  },
  toggle_container: {
    alignItems: 'center',
  },
  secondary_address: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 15,
    alignItems: 'center',
  },
  sub_heading: {
    marginTop: '20px !important',
    color: Colors.FELDGRAU,
  },
  options_checkbox: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '35%',
  },
  add_user_button: {
    marginTop: '20px !important',
    width: '100% !important',
  },
  display_flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  disabled_input: {
    '& .MuiInputBase-input.Mui-disabled': {
      '-webkit-text-fill-color': `${Colors.DAVYS_GREY} !important`,
    },
    '& .MuiInput-root': {
      '&::before': {
        borderBottom: `2px solid ${Colors.CHINESE_SILVER} !important`,
      },
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: `${Colors.DAVYS_GREY} !important`,
    },
  },
  textbox: {
    border: 'none',
    padding: 8,
    fontSize: 16,
    color: Colors.DAVYS_GREY,
    fontFamily: 'Lato',
    outline: 'none',
    fontWeight: 400,
    width: '100%',
    resize: 'none',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
  },
  input_label: {
    color: Colors.FELDGRAU,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Lato, sans-serif',
  },
}));
