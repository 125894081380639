import axios from 'axios';
import { UPDATE_ESTIMATION } from '../../constants';

export const updateEstimation =
  (incidentId, status, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateEstimation());

    axios
      .put(
        `/incident/updateEstimation?incidentId=${incidentId}&estimationStatus=${status}`,
        '',
        config
      )
      .then((res) => {
        dispatch(setUpdateEstimation(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorUpdateEstimation(err.response));
        onError(err.response);
      });
  };

export const loadUpdateEstimation = () => ({
  type: UPDATE_ESTIMATION.LOAD,
});

export const setUpdateEstimation = (data) => ({
  type: UPDATE_ESTIMATION.SUCCESS,
  payload: data.data,
});

export const errorUpdateEstimation = (error) => ({
  type: UPDATE_ESTIMATION.FAIL,
  payload: error,
});
