import axios from 'axios';
import { CLIENT_LIST } from '../../constants';

export const fetchClientList =
  (desId, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadClientList());
    }
    axios
      .get(
        `admin/clientList?designerId=${desId}`,
        config,
      )
      .then((res) => dispatch(setClientList(res)))
      .catch((err) => {
        dispatch(errorClientList(err.response));
        onError(err.response);
      });
  };

export const loadClientList = () => ({
  type: CLIENT_LIST.LOAD,
});

export const setClientList = (incidentList) => ({
  type: CLIENT_LIST.SUCCESS,
  payload: incidentList.data.data,
});

export const errorClientList = (error) => ({
  type: CLIENT_LIST.FAIL,
  payload: error,
});
