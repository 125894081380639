import axios from 'axios';
import { ADD_USER } from '../../constants';

export const createUser = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addUser());

  axios
    .post(`/admin/createUser`, data, config)
    .then((res) => {
      dispatch(setAddUser(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorAddUser(err.response));
      onError(err.response);
    });
};

export const addUser = () => ({
  type: ADD_USER.LOAD,
});

export const setAddUser = (data) => ({
  type: ADD_USER.SUCCESS,
  payload: data.data,
});

export const errorAddUser = (error) => ({
  type: ADD_USER.FAIL,
  payload: error,
});
