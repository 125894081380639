import React from 'react';
import { Grid, Menu, Fade } from '@material-ui/core';
import useStyles from './style';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './datePickerStyle.css';
import CustomButton from '../Button';
import classNames from "classnames";
import { TextField, Button } from '@mui/material';
import { Colors } from '../../common/colors';

// import Calendar from '../../assets/Images/calendar.svg';
// import CalendarDark from '../../assets/Images/calendar-dark.svg';

const DatePicker = (props) => {
  const classes = useStyles();
  const selectionRange = {
    startDate: props.startDate ? props.startDate : new Date(),
    endDate: props.endDate ? props.endDate : new Date(),
    key: 'selection',
  };

  return (
    <div className={classes.main_container}>
      <CustomButton
        handleClick={props.handleClick}
        selectedDate={props.selectedDate || 'Date Range'}
        containerClassName={classes.date_calendar_button}
      />

      {props.anchorEl ? (
        <Menu
          id='simple-menu'
          anchorEl={props.position}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={() => {
            if (props.handleOnClose) {
              props.handleOnClose();
            }
          }}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
          }}
          TransitionComponent={Fade}
          PaperProps={{
            style: {
              marginTop: 5,
              border: '1px solid #d3d4d5',
              boxShadow: 'none',
            },
          }}
        >
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <DateRange
              ranges={[selectionRange]}
              onChange={(ranges) => {
                if (props.handleSelect) {
                  props.handleSelect(ranges);
                }
              }}
              rangeColors={[Colors.SEA_GREEN]}
              // color={'#C1E1C5'}
              showMonthArrow={false}
            />
          </div>
          <div className={classes.btnsView}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                if (props.handleOnClose) {
                  props.handleOnClose();
                }
              }}
              // style={{ width: 80, marginRight: 5, color: 'white' }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                if (props.applyDateRange) {
                  props.applyDateRange();
                }
              }}
              disabled={!props.startDate && !props.endDate}
              // style={{ width: 80 }}
            >
              Apply
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                if (props?.clearDateRange) props.clearDateRange();
              }}
              disabled={!props?.selectedDate}
            >
              Clear
            </Button>
          </div>
        </Menu>
      ) : null}
    </div>
  );
};

export default DatePicker;
