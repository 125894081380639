import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  main_container: {
    backgroundColor: Colors.MODAL_TRANSPARENT,
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 2,
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog_container: {
    backgroundColor: Colors.WHITE,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px',
    borderRadius: 16,
  },
  close_icon_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: 'fit-content',
    alignItems: 'center',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  dialog_body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
    margin: '10px 0px',
  },
  dialog_action_container: {
    width: '100%',
    height: 35,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  action_button: {
    width: '50px !important',
    flexGrow: 'inherit !important',
    backgroundColor: `${Colors.MEDIUM_SEA_GREEN} !important`,
  },
  no_button: {
    marginRight: '5px !important',
    backgroundColor: `${Colors.FUZZY_WUZZY} !important`,
  },
}));
