import axios from 'axios';
import { GET_INVENTORY_LIST_FOR_DROPPDOWN } from '../../constants';

export const fetchInventoryListForDropdown =
  (clientId, designerId, onError, isUpdate) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (isUpdate !== 'update') {
      dispatch(loadInventoryList());
    }

    axios
      .get(
        `/inventory/getInventoryList?client=${clientId}&designer=${designerId}`,
        config
      )
      .then((res) => dispatch(setInventoryList(res)))
      .catch((err) => {
        dispatch(errorInventoryList(err.response));
        onError(err.response);
      });
  };

export const loadInventoryList = () => ({
  type: GET_INVENTORY_LIST_FOR_DROPPDOWN.LOAD,
});

export const setInventoryList = (InventoryList) => ({
  type: GET_INVENTORY_LIST_FOR_DROPPDOWN.SUCCESS,
  payload: InventoryList.data.data,
});

export const errorInventoryList = (error) => ({
  type: GET_INVENTORY_LIST_FOR_DROPPDOWN.FAIL,
  payload: error,
});
