import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import IncidentDetailsModal from '../../components/IncidentDetailsModal';
import { connect } from 'react-redux';
import { fetchIncidentList } from '../../redux/actions/incidentsActions/incidentsList';
import { fetchEmployeeList } from '../../redux/actions/incidentsActions/employeeList';
import { getIncident } from '../../redux/actions/incidentsActions/getIncident';
import { deleteIncident } from '../../redux/actions/incidentsActions/deleteIncident';
import {
  getErrorMessage,
  isAdmin,
  isDesigner,
  isVendor,
  PAGE_SIZE,
} from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader';
import Dialog from '../../components/Dialog';
import debounce from 'lodash/debounce';

const Incidents = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState('all');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showIncidentDetailsModal, setShowIncidentDetailsModal] =
    useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [incidents, setIncidents] = useState([]);
  const totalRecords = props.incidentListData?.totalRecords || 0;

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const delayedSearch = useCallback(
    debounce(
      (q) =>
        props.fetchIncidentList(
          q,
          selectedItem === 'all' ? '' : selectedItem,
          PAGE_SIZE,
          page,
          (err) => onError(err)
        ),
      600
    ),
    []
  );

  useEffect(() => {
    !isDesigner() && props.fetchEmployeeList(onError);
  }, []);

  useEffect(() => {
    props.fetchIncidentList(
      search,
      selectedItem === 'all' ? '' : selectedItem,
      PAGE_SIZE,
      page,
      (err) => onError(err)
    );
  }, [
    selectedItem,
    page,
    // search,
    props?.assignToVendorData,
    props.createEstimationData,
    props.updateEstimationData,
    props.completeIncidentData,
  ]);

  useEffect(() => {
    if (!isDesigner()) {
      const empData = props?.employeeListData?.map((el) => ({
        label: el?.name,
        value: el?._id,
      }));
      setEmployeeList(empData);
    }
  }, [props.employeeListData]);

  useEffect(() => {
    setIncidents(props?.incidentListData?.incidents || props.incidentListData);
  }, [props.incidentListData]);

  const handleChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const handleChangePagination = async (e, p) => {
    await setPage(p);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedIncident(item);
    await setShowDeleteDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteIncident(
      selectedIncident._id,
      setShowDeleteDialog,
      onError,
      () => {
        setShowIncidentDetailsModal(false);
        setIsEdit(false);
        setIncidents(incidents.filter((i) => i._id !== selectedIncident._id));
      }
    );
  };

  const onClickTableRow = async (item) => {
    await setSelectedIncident(item);
    await setShowIncidentDetailsModal(true);
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        isSearchableSelect
        isSelect={!isDesigner()}
        searchPlaceholder={Strings.SEARCH_FOR_USER_OR_BARCODE}
        selectedItem={selectedItem}
        selectOption={[
          { label: 'All Employees', value: 'all' },
          ...employeeList,
        ]}
        handleSelectChange={(e, val) => {
          if (val) {
            setSelectedItem(val);
            setPage(1);
          }
        }}
        searchValue={search}
        handleSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      />
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          {props.incidentListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords && totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableBody>
                      {incidents?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            onClick={() => onClickTableRow(row)}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell component='th' width='8%'>
                              {
                                <div className={classes.row_flex}>
                                  {row?.itemPicture?.length > 0 ? (
                                    <img
                                      src={row?.itemPicture[0]}
                                      className={classes.profile}
                                      alt='picture'
                                    />
                                  ) : (
                                    <div className={classes.profile} />
                                  )}
                                  {/* <Typography variant='p_400'>
                                    {row?.barCodeId}
                                  </Typography> */}
                                </div>
                              }
                            </TableCell>
                            <TableCell component='th' width='5%'>
                              <Typography variant='p_700'>
                                {row?.ID || `-`}
                              </Typography>
                            </TableCell>
                            <TableCell width='35%'>
                              {
                                <Typography variant='h6_700'>
                                  {`Barcode : ${row?.barCodeId || `-`}`}
                                </Typography>
                              }
                            </TableCell>
                            <TableCell width='18%'>
                              {
                                <div
                                  className={classNames(
                                    classes.incident_status,
                                    row?.status === 'Pending Estimate'
                                      ? classes.pending_estimate_tag
                                      : row?.status === 'Pending Assignment'
                                      ? classes.pending_assignment_tag
                                      : row?.status === 'Completed'
                                      ? classes.complete_tag
                                      : classes.estimate_tag
                                  )}
                                >
                                  <Typography
                                    variant='p_700'
                                    style={{ textAlign: 'center' }}
                                  >
                                    {row?.status}
                                  </Typography>
                                </div>
                              }
                            </TableCell>
                            <TableCell width='13%'>
                              {!isVendor() ? (
                                <Typography variant='p_400'>
                                  {row?.employee?.name}
                                </Typography>
                              ) : (
                                row?.estimation &&
                                row?.estimation?.estimationStatus !==
                                  'Pending' && (
                                  <div
                                    className={classNames(
                                      classes.incident_status,
                                      row?.estimation?.estimationStatus ===
                                        'Approved'
                                        ? classes.approved_status
                                        : classes.declined_status
                                    )}
                                  >
                                    <Typography
                                      variant='p_700'
                                      style={{ textAlign: 'center' }}
                                    >
                                      {row?.estimation?.estimationStatus}
                                    </Typography>
                                  </div>
                                )
                              )}
                            </TableCell>
                            <TableCell width='13%'>
                              {isVendor() && (
                                <Typography variant='p_400'>
                                  {row?.employee?.name || `-`}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell width='12%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().edit}
                                    className={classes.icons}
                                    onClick={(e) => {
                                      setIsEdit(true);
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onClickTableRow(row);
                                    }}
                                  />
                                  <img
                                    src={icons().delete}
                                    className={classes.icons}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onClickDelete(e, row);
                                    }}
                                  />
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
      {showIncidentDetailsModal && (
        <IncidentDetailsModal
          onClose={() => {
            setShowIncidentDetailsModal(false);
            setIsEdit(false);
          }}
          getIncident={props.getIncident}
          onClickDelete={() => setShowDeleteDialog(true)}
          loader={props.getIncidentDataLoading}
          selectedIncident={selectedIncident?._id}
          onError={onError}
          incidentData={props?.getIncidentData}
          addNoteData={props?.addNoteData}
          addNoteDataLoading={props?.addNoteDataLoading}
          addNote={props.addNote}
          // onClickEdit={async () => {
          //   await setShowUserDetailsModal(false);
          //   await setShowEditUserModal(true);
          // }}
          isEdit={isEdit}
          deleteNote={props?.deleteNote}
          deleteNoteData={props?.deleteNoteData}
          deleteNoteDataLoading={props?.deleteNoteDataLoading}
        />
      )}
      {showDeleteDialog && (
        <Dialog
          title={Strings.DELETE_INCIDENT}
          message={Strings.ARE_YOU_SURE_TO_DELETE_INCIDENT}
          onClose={() => setShowDeleteDialog(false)}
          loading={props.deleteIncidentDataLoading}
          onClickYesButton={onClickYesButton}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  incidentListData: state.incidentListData.data,
  incidentListLoading: state.incidentListData.loading,

  getIncidentData: state.getIncidentData.data,
  getIncidentDataLoading: state.getIncidentData.loading,

  deleteIncidentData: state.deleteIncidentData.data,
  deleteIncidentDataLoading: state.deleteIncidentData.loading,

  employeeListData: state.employeeListData.data,
  employeeListDataLoading: state.employeeListData.loading,

  assignToVendorData: state.assignToVendorData.data,
  createEstimationData: state.createEstimationData.data,
  updateEstimationData: state.updateEstimationData.data,
  completeIncidentData: state.completeIncidentData.data,
});

export default connect(mapStateToProps, {
  fetchIncidentList,
  getIncident,
  deleteIncident,
  fetchEmployeeList,
})(Incidents);
