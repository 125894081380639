import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../common/colors';
import useStyles from './styles';
import classNames from 'classnames';

const CustomRadioGroup = (props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: props.container }}
      value={props?.value}
      control={
        <Radio
          className={classes.root}
          name={props?.name}
          TouchRippleProps={{ classes: { child: 'my-class' } }}
          disableRipple
          onChange={props.onChange}
          icon={<span className={classes.icon} />}
          checkedIcon={
            <span className={classNames(classes.icon, classes.checked_icon)} />
          }
          disabled={props.disabled}
        />
      }
      label={
        <Typography
          variant='p_400'
          color={Colors.FELDGRAU}
          style={{ marginLeft: props?.label && 10 }}>
          {props?.label}
        </Typography>
      }
    />
  );
};

export default CustomRadioGroup;
