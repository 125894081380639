import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { icons } from '../../assets';
import useStyles from './styles';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomPagination from '../../components/CustomPagination';
import usePagination from '../../Hooks/usePagination';
import { Colors } from '../../common/colors';
import TableToolbox from '../../components/TableToolbox';
import TableHead from '../../components/TableHead';
import CustomCheckbox from '../../components/CustomCheckbox';
import TableMenuBar from '../../components/TableToolBar';
import { connect } from 'react-redux';
import { fetchDeliveryList } from '../../redux/actions/deliveryActions/deliveryList';
import { deleteDelivery } from '../../redux/actions/deliveryActions/deleteDelivery';
import { getDelivery } from '../../redux/actions/deliveryActions/getDelivery';
import { PAGE_SIZE, getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import { fetchDesignerList } from '../../redux/actions/rateSheetActions/designerList';
import moment from 'moment';
import Loader from '../../components/Loader';
import DeliveryDetailsModal from '../../components/DeliveryDetailsModal';
import Dialog from '../../components/Dialog';
import AddDeliveriesModal from '../../components/AddDeliveriesModal';
import debounce from 'lodash/debounce';
import ToggleButton from '../../components/ToggleButton';
import { closeDelivery } from '../../redux/actions/deliveryActions/closeDelivery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const tableHeaders = [
  // { label: '', checkbox: true },
  { label: 'ID', width: '5%' },
  { label: 'Designer', width: '20%' },
  { label: 'Client', width: '20%' },
  { label: 'Amount', width: '15%' },
  { label: 'Invoiced on', width: '15%' },
  { label: 'Delivery on', width: '20%' },
  // { label: 'Status', width: '15%' },
  { label: '', width: '20%' },
];

const Inventory = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState('all');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedIdData, setSelectedIdData] = useState([]);
  const [selectedAllRow, setSelectedAllRow] = useState(false);
  const [selectedSomeRow, setSelectedSomeRow] = useState(false);
  const [showDeliveryDetailsModal, setShowDeliveryDetailsModal] =
    useState(false);
  const [showDeleteDeliveryDialog, setShowDeleteDeliveryDialog] =
    useState(false);
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [deliveries, setDeliveries] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedTab, setSelectedTab] = useState('active');
  const totalRecords = props?.deliveryListData?.totalRecords || 0;
  const { currentData, jump, next } = usePagination(
    props?.deliveryListData?.deliveries ?? [],
    10
  );

  const fetchDeliveries = (searchStr, activeTab) => {
    const data = {
      isActive: activeTab === 'active' ? true : false,
      searchString: searchStr,
      designer: selectedItem === 'all' ? '' : selectedItem,
      page_size: PAGE_SIZE,
      page_number: page,
    };
    props.fetchDeliveryList(data, (err) => onError(err));
  };

  const delayedSearch = useCallback(
    debounce((q) => fetchDeliveries(q, selectedTab), 600),
    [selectedTab, selectedItem]
  );

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    setPage(1);
  };

  useEffect(() => {
    fetchDeliveries(search, selectedTab);
  }, [
    selectedItem,
    page,
    props.deleteDeliveryData,
    props.addDeliveryData,
    props.updateDeliveryData,
    selectedTab,
  ]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    setDeliveries(props?.deliveryListData?.deliveries);
  }, [props.deliveryListData]);

  useEffect(() => {
    props.fetchDesignerList((err) => onError(err));
  }, []);

  useEffect(() => {
    if (props.designerListData && props.designerListData.length > 0) {
      const list = props?.designerListData?.map((e) => ({
        label: e.name,
        value: e._id,
      }));
      setDesigners(list);
    }
  }, [props.designerListData]);

  useEffect(() => {
    if (selectedRow.length > 0) {
      let newData = [];
      selectedRow?.map((el) => {
        if (el?.selected?.length > 0) {
          el.selected.map((el) => {
            newData.push(el);
          });
        }
      });
      setSelectedIdData(newData);
      const isPageExist = selectedRow?.some((el) => el.page === page);
      if (isPageExist) {
        const newData = selectedRow?.filter((el) => el.page === page);
        const isAllDataSelect = newData?.some(
          (el) => el?.selected?.length === currentData()?.length
        );
        const isSomeDataSelect = newData?.some(
          (el) =>
            el?.selected?.length > 0 &&
            el?.selected?.length < currentData()?.length
        );
        if (isAllDataSelect) {
          setSelectedAllRow(true);
          setSelectedSomeRow(false);
        } else if (isSomeDataSelect) {
          setSelectedAllRow(false);
          setSelectedSomeRow(true);
        } else {
          setSelectedAllRow(false);
          setSelectedSomeRow(false);
        }
      } else {
        setSelectedAllRow(false);
        setSelectedSomeRow(false);
      }
    } else {
      setSelectedIdData([]);
      setSelectedAllRow(false);
      setSelectedSomeRow(false);
    }
  }, [page, selectedRow]);

  // const handleChange = (e) => {
  //   setSelectedItem(e.target.value);
  // };

  const handleChangePagination = async (e, p) => {
    // await jump(p);
    await setPage(p);
  };

  const onClickEdit = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedDelivery(item);
    await setShowEditDeliveryModal(true);
  };

  const onClickDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await setSelectedDelivery(item);
    await setShowDeleteDeliveryDialog(true);
  };

  const onClickYesButton = async () => {
    await props.deleteDelivery(
      selectedDelivery._id,
      setShowDeleteDeliveryDialog,
      () => {
        setDeliveries(
          deliveries.filter((d) => d?._id !== selectedDelivery?._id)
        );
      },
      onError
    );
  };

  const onClickTableRow = (item) => {
    // setIsShowDetails(true);
    setShowEditDeliveryModal(true);
    setSelectedDelivery(item);
  };

  // const updateUserDataById = (values) => {
  //   const userIndex = deliveries.findIndex(
  //     (obj) => obj._id === selectedDelivery?._id
  //   );
  //   let newUser = [...deliveries];
  //   let tempUser = { ...deliveries[userIndex] };
  //   if (values.name !== deliveries[userIndex].name) tempUser.name = values.name;
  //   if (values.email !== deliveries[userIndex].email)
  //     tempUser.email = values.email;
  //   if (values.type !== deliveries[userIndex].type) tempUser.type = values.type;
  //   newUser[userIndex] = tempUser;
  //   setDeliveries(newUser);
  // };

  // const handleCheckedAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = deliveries?.map((el) => el?._id);
  //     const data = { page: page, selected: newSelected };
  //     const isPageExist = selectedRow?.some((el) => el.page === page);
  //     if (isPageExist) {
  //       let newData = selectedRow;
  //       newData = newData.map((el) => {
  //         if (el.page === page) return { ...el, selected: newSelected };
  //         else return { ...el };
  //       });
  //       setSelectedRow(newData);
  //     } else {
  //       setSelectedRow((prevState) => [...prevState, data]);
  //     }
  //     setSelectedSomeRow(false);
  //     setSelectedAllRow(true);
  //   } else {
  //     const newSelectedRow = [...selectedRow];
  //     let fIndex = newSelectedRow?.findIndex((el) => el.page === page);
  //     newSelectedRow?.splice(fIndex, 1);
  //     setSelectedRow(newSelectedRow);
  //     setSelectedAllRow(false);
  //   }
  // };

  // const handleCheckedClick = (event, id) => {
  //   const selectedPageIndex = selectedRow?.findIndex((el) => el?.page === page);
  //   let newSelected = [];
  //   let newItem = [];

  //   if (selectedPageIndex === -1) {
  //     newSelected = newSelected.concat(selectedRow, {
  //       page: page,
  //       selected: [id],
  //     });
  //   } else if (selectedPageIndex !== -1 && selectedPageIndex >= 0) {
  //     const index = selectedRow[selectedPageIndex]?.selected?.findIndex(
  //       (el) => el === id
  //     );
  //     newSelected = selectedRow;
  //     if (index === -1) {
  //       newItem = newItem.concat(selectedRow[selectedPageIndex]?.selected, id);
  //       newSelected = newSelected?.map((el, index) => {
  //         if (index === selectedPageIndex) return { ...el, selected: newItem };
  //         else return el;
  //       });
  //     } else if (index === 0) {
  //       newItem = newItem.concat(
  //         selectedRow[selectedPageIndex]?.selected?.slice(1)
  //       );
  //       newSelected = newSelected?.map((el, index) => {
  //         if (index === selectedPageIndex) return { ...el, selected: newItem };
  //         else return el;
  //       });
  //       if (newSelected[selectedPageIndex]?.selected?.length === 0) {
  //         newSelected = [
  //           ...newSelected.slice(0, selectedPageIndex),
  //           ...newSelected.slice(selectedPageIndex + 1),
  //         ];
  //       }
  //     } else if (index > 0) {
  //       newItem = newItem.concat(
  //         selectedRow[selectedPageIndex]?.selected?.slice(0, index),
  //         selectedRow[selectedPageIndex]?.selected?.slice(index + 1)
  //       );
  //       newSelected = newSelected?.map((el, index) => {
  //         if (index === selectedPageIndex) return { ...el, selected: newItem };
  //         else return el;
  //       });
  //     }
  //   }
  //   setSelectedRow(newSelected);
  // };

  const checkValueExist = (id) => {
    if (selectedRow?.length) {
      const isExist = selectedRow?.some((el) =>
        el?.selected?.some((el) => el === id)
      );
      return isExist;
    }
  };

  const updateStatus = (item, status) => {
    props.closeDelivery(item._id, status, (err) => onError(err));
  };

  return (
    <div className={classes.main_container}>
      <TableToolbox
        isSearch
        searchPlaceholder={Strings.SEARCH_FOR_DELIVERIES}
        isSelect
        isSearchableSelect
        selectOption={[{ label: 'All designers', value: 'all' }, ...designers]}
        handleSelectChange={(e, val) => {
          if (val) {
            setSelectedItem(val);
            setPage(1);
          }
        }}
        selectedItem={selectedItem}
        handleSearch={(e) => {
          setSearch(e.target.value);
          delayedSearch(e.target.value);
        }}
      />
      <div className={classes.tableContainer}>
        {selectedIdData?.length ? (
          <TableMenuBar
            containerClassName={classes.menuBar_container}
            selectedDataCount={selectedIdData?.length}
            onDeselectClick={() => {
              setSelectedRow([]);
              setSelectedIdData([]);
              setSelectedAllRow(false);
              setSelectedSomeRow(false);
            }}
            showDeleteButton
            onDeleteClick={() => {}}
          />
        ) : null}
        <div className={classes.tab_container}>
          <Tabs
            value={selectedTab}
            style={{ minHeight: 38, height: 38 }}
            onChange={handleTabChange}
            className={classes.tabs}
            // variant='scrollable'
            scrollButtons={false}
            allowScrollButtonsMobile
            aria-label='scrollable force tabs example'
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={Strings.ACTIVE}
              value='active'
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={Strings.CLOSED}
              value='closed'
            />
          </Tabs>
        </div>
        <div className={classes.table}>
          {props.deliveryListLoading ? (
            <div className={classes.flex_view}>
              <Loader color={Colors.RAISIN_BLACK} size={40} />
            </div>
          ) : (
            <>
              {totalRecords && totalRecords > 0 ? (
                <TableContainer>
                  <Table aria-label='simple table' sx={{ minWidth: 870 }}>
                    <TableHead
                      headCells={tableHeaders}
                      // onSelectAllClick={(e) => handleCheckedAllClick(e)}
                      selectedAllRow={selectedAllRow}
                      selectedSomeRow={selectedSomeRow}
                      // totalRowCount={currentData()?.length}
                    />
                    <TableBody>
                      {deliveries?.map((row, index) => {
                        const isChecked = checkValueExist(row?._id);
                        return (
                          <TableRow
                            key={index}
                            onClick={() => onClickTableRow(row)}
                            style={{ cursor: 'pointer' }}
                          >
                            {/* <TableCell component='th'>
                              <CustomCheckbox
                                checked={isChecked || false}
                                onChange={(e) => handleCheckedClick(e, row?._id)}
                              />
                            </TableCell> */}
                            <TableCell component='th' width='5%'>
                              <Typography variant='p_400'>{row.ID}</Typography>
                            </TableCell>
                            <TableCell component='th' width='20%'>
                              <Typography variant='p_700'>
                                {row.designer}
                              </Typography>
                            </TableCell>
                            <TableCell width='20%'>
                              <Typography variant='p_400'>
                                {row.client}
                              </Typography>
                            </TableCell>
                            <TableCell width='15%'>
                              <Typography variant='p_400'>
                                {row.total}
                              </Typography>
                            </TableCell>
                            <TableCell width='15%'>
                              <Typography variant='p_400'>
                                {row.invoicedOn
                                  ? moment(row.invoicedOn).format(
                                      'MMM DD, YYYY'
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell width='15%'>
                              <Typography variant='p_400'>
                                {moment(row.deliveredOn).format(
                                  'MMM DD, YYYY - LT'
                                )}
                              </Typography>
                            </TableCell>
                            {/* <TableCell width='10%'>
                              <Typography variant='p_400'>
                                <ToggleButton
                                  checked={row.isActive}
                                  className={classes.toggle_btn}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const updateData = deliveries.map(
                                      (data, i) => {
                                        if (i === index) {
                                          return {
                                            ...data,
                                            isActive: e.target.checked,
                                          };
                                        } else {
                                          return {
                                            ...data,
                                          };
                                        }
                                      }
                                    );
                                    setDeliveries(updateData);
                                    updateStatus(row, e.target.checked);
                                  }}
                                />
                              </Typography>
                            </TableCell> */}
                            <TableCell width='10%'>
                              {
                                <div
                                  className={classNames(
                                    classes.row_flex,
                                    classes.align_right,
                                    classes.padding_right
                                  )}
                                >
                                  <img
                                    src={icons().eye_feldgrau}
                                    className={classes.icons}
                                    onClick={(e) => {
                                      onClickEdit(e, row);
                                      setIsShowDetails(true);
                                    }}
                                  />
                                  {row?.isActive && (
                                    <img
                                      src={icons().edit}
                                      className={classes.icons}
                                      onClick={(e) => {
                                        if (row?.isActive) {
                                          onClickEdit(e, row);
                                        }
                                      }}
                                    />
                                  )}
                                  {selectedTab !== 'closed' ? (
                                    <img
                                      src={icons().delete}
                                      className={classes.icons}
                                      onClick={(e) => onClickDelete(e, row)}
                                    />
                                  ) : null}
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={classes.flex_view}>
                  <Typography variant='h6_400' color={Colors.RAISIN_BLACK}>
                    {Strings.NO_DATA_FOUND}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {totalRecords > 0 && (
          <div className={classes.pagination}>
            <CustomPagination
              totalRecords={totalRecords}
              handleChange={handleChangePagination}
              pageCount={page}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
        {showDeliveryDetailsModal && (
          <DeliveryDetailsModal
            onClose={() => setShowDeliveryDetailsModal(false)}
            onClickDelete={() => setShowDeleteDeliveryDialog(true)}
            loader={props.getDeliveryDataLoading}
            getDelivery={props.getDelivery}
            // selectedUser={selectedUser?._id}
            onError={onError}
            deliveryData={props?.getDeliveryData}
            onClickEdit={async () => {
              await setShowDeliveryDetailsModal(false);
              await setShowEditDeliveryModal(true);
            }}
            deleteNote={props?.deleteNote}
            deleteNoteData={props?.deleteNoteData}
            deleteNoteDataLoading={props?.deleteNoteDataLoading}
          />
        )}
        {showDeleteDeliveryDialog && (
          <Dialog
            title={Strings.DELETE_DELIVERY}
            message={Strings.ARE_YOU_SURE_TO_DELETE_DELIVERY}
            onClose={() => setShowDeleteDeliveryDialog(false)}
            loading={props.deleteDeliveryDataLoading}
            onClickYesButton={onClickYesButton}
          />
        )}
        {showEditDeliveryModal && (
          <AddDeliveriesModal
            onClose={() => {
              setShowEditDeliveryModal(false);
              setIsShowDetails(false);
            }}
            edit={showEditDeliveryModal}
            selectedDelivery={selectedDelivery?._id}
            getDelivery={props.getDelivery}
            deliveryData={props?.getDeliveryData}
            loader={props.getDeliveryDataLoading}
            // updateDeliveryDataById={updateUserDataById}
            search={search}
            selectedItem={selectedItem === 'all' ? '' : selectedItem}
            page={page}
            onError={() => onError()}
            isShowDetails={isShowDetails}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deliveryListData: state.deliveryListData.data,
  deliveryListLoading: state.deliveryListData.loading,
  getDeliveryData: state.getDeliveryData.data,
  getDeliveryDataLoading: state.getDeliveryData.loading,
  deleteDeliveryData: state.deleteDeliveryData.data,
  deleteDeliveryDataLoading: state.deleteDeliveryData.loading,

  designerListData: state.designerListData.data,
  designerListLoading: state.designerListData.loading,
  addDeliveryData: state.addDeliveryData.data,
  updateDeliveryData: state.updateDeliveryData.data,
});

export default connect(mapStateToProps, {
  fetchDeliveryList,
  fetchDesignerList,
  getDelivery,
  deleteDelivery,
  closeDelivery,
})(Inventory);
