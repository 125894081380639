import axios from 'axios';
import { BARCODE_PDF } from '../../constants';
import { PrintCSVorPDF } from '../../../utils/appUtils';

export const barcodePdf = (isPrint, onError, callback, data) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  };
  dispatch(loadBarcodePdf());
  let queriesString = `isPrint=${isPrint}`;

  axios
    .put(`/inventory/barcodesPdf?${queriesString}`, data, config)
    .then((res) => {
      if (isPrint) {
        PrintCSVorPDF(res?.data, 'application/pdf', () => {
          dispatch(setBarcodePdf(res));
        });
      } else {
        dispatch(setBarcodePdf(res));
      }
      callback(res.data);
    })
    .catch(async (err) => {
      const isJsonBlob = (data) =>
        data instanceof Blob && data.type === 'application/json';
      const responseData = isJsonBlob(err.response?.data)
        ? await err.response?.data?.text()
        : err.response?.data || {};
      const responseJson =
        typeof responseData === 'string'
          ? JSON.parse(responseData)
          : responseData;

      dispatch(errorBarcodePdf({ data: responseJson }));
      onError({ data: responseJson });
    });
};

export const loadBarcodePdf = () => ({
  type: BARCODE_PDF.LOAD,
});

export const setBarcodePdf = (data) => ({
  type: BARCODE_PDF.SUCCESS,
  payload: data.data,
});

export const errorBarcodePdf = (error) => ({
  type: BARCODE_PDF.FAIL,
  payload: error,
});
