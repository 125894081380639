import axios from 'axios';
import { ADD_DELIVERY } from '../../constants';

export const createDelivery = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addDelivery());

  axios
    .post(`/delivery/createDelivery`, data, config)
    .then((res) => {
      dispatch(setAddDelivery(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorAddDelivery(err.response));
      onError(err.response);
    });
};

export const addDelivery = () => ({
  type: ADD_DELIVERY.LOAD,
});

export const setAddDelivery = (data) => ({
  type: ADD_DELIVERY.SUCCESS,
  payload: data.data,
});

export const errorAddDelivery = (error) => ({
  type: ADD_DELIVERY.FAIL,
  payload: error,
});
