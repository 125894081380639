import Router from './routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import { SnackbarProvider } from 'notistack';
import './App.css';
import { createContext, useState } from 'react';

export const NavbarDataContext = createContext();

function App() {
  const [feesData, setFeesData] = useState(false);

  return (
    <NavbarDataContext.Provider value={{ feesData, setFeesData }}>
      <Provider store={store}>
        <SnackbarProvider
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
        >
          <Router />
        </SnackbarProvider>
      </Provider>
    </NavbarDataContext.Provider>
  );
}

export default App;
