import axios from 'axios';
import { UPDATE_RATE_SHEET } from '../../constants';

export const updateRateSheet = (id, data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(updateSheet());

  axios
    .put(`/ratesheet/updateRatesheet?ratesheetId=${id}`, data, config)
    .then((res) => {
      dispatch(setUpdateRateSheet(res));
      onSuccess();
    })
    .catch((err) => {
      dispatch(errorUpdateRateSheet(err.response));
      onError(err.response);
    });
};

export const updateSheet = () => ({
  type: UPDATE_RATE_SHEET.LOAD,
});

export const setUpdateRateSheet = (data) => ({
  type: UPDATE_RATE_SHEET.SUCCESS,
  payload: data.data,
});

export const errorUpdateRateSheet = (error) => ({
  type: UPDATE_RATE_SHEET.FAIL,
  payload: error,
});
