import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { icons } from '../../assets';
import { Colors } from '../../common/colors';
import useStyles from './styles';
import Barcode from 'react-barcode';
import { Strings } from '../../common/strings';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Loader from '../Loader';
import classNames from 'classnames';
import { isDesigner } from '../../utils/appUtils';

export default function BarcodeModal(props) {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  const createBarcodePdf = () => {
    setLoader(true);
    const selectedArea = document.getElementById('barcode_section');
    html2canvas(selectedArea).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // const doc = new jsPDF('p', 'mm',);
      const doc = new jsPDF('l', 'mm', [80, 40]);
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, width, height);
      doc.save(`${props?.selectedBarcode?.barcode}.pdf`);
      setLoader(false);
    });
  };

  return (
    <div className={classes.main_container}>
      <div className={classes.dialog_container}>
        <div className={classes.close_icon_container}>
          <Typography variant='h6_700' color={Colors.BLACK}>
            {props.title}
          </Typography>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => props.onClose()}
          />
        </div>
        <div className={classes.dialog_body} id='barcode_section'>
          {props?.isBarcodeDetailRequired && (
            <>
              <div
                className={classNames(
                  classes.close_icon_container,
                  classes.details_one
                )}
              >
                <Typography
                  variant='p_600'
                  color={Colors.BLACK}
                  style={{ width: '60%', wordWrap: 'break-word' }}
                >
                  {props?.selectedBarcode?.manufacturer}
                </Typography>
                <Typography variant='p_600' color={Colors.BLACK}>
                  {props?.selectedBarcode?.ultraService
                    ? 'UltraService: true'
                    : ''}
                </Typography>
              </div>
              <Typography
                variant='p_600'
                color={Colors.BLACK}
                className={classes.detail_three}
              >
                {props?.selectedBarcode?.description}
              </Typography>
            </>
          )}
          <Barcode
            value={props?.selectedBarcode?.barcode}
            width={1.5}
            height={80}
            fontSize={20}
          />
          {props?.isBarcodeDetailRequired && (
            <Typography
              variant='p_400'
              color={Colors.BLACK}
              style={{
                alignSelf: 'flex-end',
                marginTop: 10,
                paddingRight: 5,
                paddingLeft: 5,
              }}
            >
              {props?.selectedBarcode?.freightLine}
            </Typography>
          )}
        </div>
        {!isDesigner() && (
          <div className={classes.print_button_container}>
            <Button
              variant='contained'
              className={classes.print_button}
              onClick={() => {
                // createBarcodePdf(props?.selectedBarcode?.barcode);
                props.onClickPrint()
              }}
            >
              {loader ? <Loader /> : Strings.PRINT.toUpperCase()}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
