import React from 'react';
import SearchInput from '../SearchInput';
import Selection from '../Selection';
import useStyles from './styles';
import DatePicker from '../DateTimeRangePicker';
import MultiSelectDropdown from '../MultiSelectDropdown';

export default function TableToolbox(props) {
  const classes = useStyles();

  return (
    <div className={classes.header_container}>
      {props.isSearch && (
        <SearchInput
          placeholder={props.searchPlaceholder}
          className={classes.search_input}
          value={props.searchValue}
          onChange={props.handleSearch}
        />
      )}
      {props.isSelect && (
        <Selection
          isSearchable={props.isSearchableSelect || false}
          value={props.selectedItem}
          containerClassName={classes.selection_input}
          handleChange={(e, value) => props.handleSelectChange(e, value)}
          options={props.selectOption || []}
          isShowDetails={props.isShowDetails}
          placeHolder={props.dropDownPlaceholder}
        />
      )}

      {props.isMultiSelection && (
        <MultiSelectDropdown
          containerClassName={
            props?.isShowDetails
              ? classes.disabled_input
              : classes.selection_input
          }
          tagsLimit={1}
          value={props.selectedItem}
          onChange={props.handleSelectChange}
          placeholder={props.dropDownPlaceholder}
          optionArray={props.selectOption}
        />
      )}

      {props.isCalendar && (
        <DatePicker
          handleSelect={props.handleDateSelect}
          handleClick={props.handleDateClick}
          handleOnClose={props.handleOnClose}
          startDate={props.startDate}
          endDate={props.endDate}
          selectedDate={props.startEndDate}
          applyDateRange={props.applyDateRange}
          anchorEl={props.anchorEl}
          position={props.position}
          clearDateRange={props.clearDateRange}
        />
      )}
    </div>
  );
}
