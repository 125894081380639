import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  dialog_mask_container: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog_box_container: {
    backgroundColor: Colors.WHITE,
    width: 600,
    padding: 25,
    height: 'fit-content',
    minHeight: 300,
    maxHeight: '70%',
    overflow: 'auto',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  dialog_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  close_icon: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  dialog_content_container: {
    width: '100%',
    flex: 1,
    display: 'flex',
    gap: 15,
    // marginTop: 20,
  },
}));
