import axios from 'axios';
import { DELETE_NOTE } from '../../constants';

export const deleteNote = (noteID,onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteNote());
  axios
    .delete(`/admin/deleteNote?noteId=${noteID}`, config)
    .then((res) => {
      dispatch(setDeleteNote(res));
      // onSuccess(noteID);
    })
    .catch((err) => {
      dispatch(errorDeleteNote(err.response));
      onError(err.response);
    });
};

export const loadDeleteNote = () => ({
  type: DELETE_NOTE.LOAD,
});

export const setDeleteNote = (data) => ({
  type: DELETE_NOTE.SUCCESS,
  payload: data,
});

export const errorDeleteNote = (error) => ({
  type: DELETE_NOTE.FAIL,
  payload: error,
});
