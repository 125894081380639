import React, { useEffect, useState } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography, TextField, Button, Stack } from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import Selection from '../Selection';
import classNames from 'classnames';
import ToggleButton from '../ToggleButton';
import CustomCheckbox from '../CustomCheckbox';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  getErrorMessage,
  preferedContactType,
  userTypeList,
} from '../../utils/appUtils';
import { AddUserSchema } from '../../utils/validationSchemas';
import { connect } from 'react-redux';
import { createUser } from '../../redux/actions/adminActions/addUser';
import { updateUser } from '../../redux/actions/adminActions/updateUser';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';
import { State, City } from 'country-state-city';
import TagsInputField from '../TagsInputField';
import { useDispatch } from 'react-redux';
import { setGetUser } from '../../redux/actions/adminActions/getUser';

const AddUserModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [cityListShipping, setCityListShipping] = useState([]);
  const [cityListBilling, setCityListBilling] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isEmployeeView, setIsEmployeeView] = useState(false);
  const COUNTRY_CODE = 'US';

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props.edit) {
      props.getUser(props.selectedUser, props.onError);
    }
    const states = State.getStatesOfCountry(COUNTRY_CODE);
    setStateList(
      states
        .map((state) => {
          const cities = City.getCitiesOfState(COUNTRY_CODE, state.isoCode);
          if (cities?.length > 0) {
            return {
              label: state?.name,
              value: state?.name,
              isoCode: state?.isoCode,
            };
          } else {
            return null;
          }
        })
        .filter((state) => state)
    );

    return () => dispatch(setGetUser([]));
  }, []);

  useEffect(() => {
    if (props.userData) {
      setFieldValue('additionalEmails', props.userData?.additionalEmails || []);
      setFieldValue('designerCode', props.userData?.designerCode || '');
      setFieldValue('type', props.userData?.type || '0');
      setFieldValue('name', props.userData?.name || '');
      setFieldValue('email', props.userData?.email || '');
      setFieldValue('note', props.userData?.note || '');
      setFieldValue('active', props.userData?.active || false);
      setFieldValue('copyToClient', props.userData?.copyToClient || false);
      setFieldValue('shipping', {
        zipcode: props.userData?.shipping?.zipcode || '',
        city: props.userData?.shipping?.city || '',
        state: props.userData?.shipping?.state || '',
        address: props.userData?.shipping?.address || '',
        business: props.userData?.shipping?.business,
        cel: props.userData?.shipping?.cel,
        fax: props.userData?.shipping?.fax,
        other: props.userData?.shipping?.other,
      });
      setSelectedState({
        shipping: stateList.find(
          (s) => s.value === props.userData?.shipping?.state
        ),
        billing: stateList.find(
          (s) => s.value === props.userData?.billing?.state
        ),
      });
      setFieldValue('billing', {
        zipcode: props.userData?.billing?.zipcode || '',
        city: props.userData?.billing?.city || '',
        state: props.userData?.billing?.state || '',
        address: props.userData?.billing?.address || '',
        business: props.userData?.billing?.business,
        cel: props.userData?.billing?.cel,
        fax: props.userData?.billing?.fax,
        other: props.userData?.billing?.other,
        sameAsShipping:
          props.userData?.sameAsShipping === false
            ? false
            : props.userData?.sameAsShipping,
        preferedContact: props.userData?.preferedContact || '0',
        billingOptions: {
          receivingFees: props.userData?.billingOptions?.receivingFees || false,
          storageFees: props.userData?.billingOptions?.storageFees || false,
          detailOnInvoice:
            props.userData?.billingOptions?.detailOnInvoice || false,
        },
      });
    }
  }, [props.userData]);

  useEffect(() => {
    if (selectedState) {
      if (selectedState?.shipping?.isoCode) {
        const cities = City.getCitiesOfState(
          COUNTRY_CODE,
          selectedState.shipping.isoCode
        );
        if (cities && cities?.length > 0) {
          setCityListShipping(
            cities.map((c) => {
              return {
                label: c?.name,
                value: c?.name,
              };
            })
          );
        }
      }
      if (selectedState?.billing?.isoCode) {
        const cities = City.getCitiesOfState(
          COUNTRY_CODE,
          selectedState.billing.isoCode
        );
        if (cities && cities?.length > 0) {
          setCityListBilling(
            cities.map((c) => {
              return {
                label: c?.name,
                value: c?.name,
              };
            })
          );
        }
      }
    }
  }, [selectedState]);

  const handleDataSubmit = (values) => {
    try {
      const data = {
        designerCode: values.designerCode,
        type: values.type,
        name: values.name?.trim(),
        email: values.email?.trim(),
        note: values.note,
        copyToClient: values?.copyToClient || false,
        shipping: {
          zipcode: values.shipping.zipcode,
          city: values.shipping.city,
          state: values.shipping.state,
          address: values.shipping.address?.trim(),
          business: values.shipping.business,
          cel: values.shipping.cel,
          fax: values.shipping.fax,
          other: values.shipping.other,
        },
        billingOptions: {
          receivingFees: values.billing.billingOptions.receivingFees,
          storageFees: values.billing.billingOptions.storageFees,
          detailOnInvoice: values.billing.billingOptions.detailOnInvoice,
        },
        sameAsShipping: values.billing.sameAsShipping,
        preferedContact: values.billing.preferedContact,
        additionalEmails: values.additionalEmails,
        active: values.active,
      };
      if (!values.billing.sameAsShipping) {
        data.billing = {};
        data.billing.zipcode = values.billing.zipcode;
        data.billing.city = values.billing.city;
        data.billing.state = values.billing.state;
        data.billing.address = values.billing.address?.trim();
        data.billing.business = values.billing.business;
        data.billing.cel = values.billing.cel;
        data.billing.fax = values.billing.fax;
        data.billing.other = values.billing.other;
      }
      if (data?.type === 'employee') {
        delete data.designerCode;
        delete data?.shipping;
        delete data?.billing;
        delete data?.billingOptions;
        delete data?.sameAsShipping;
        delete data?.preferedContact;
        delete data?.additionalEmails;
      } else {
        if (!data?.shipping?.cel) delete data?.shipping?.cel;
        if (!data?.shipping?.fax) delete data?.shipping?.fax;
        if (!data?.shipping?.other) delete data?.shipping?.other;
        if (!values?.billing?.sameAsShipping) {
          if (!data?.billing?.cel) delete data?.billing?.cel;
          if (!data?.billing?.fax) delete data?.billing?.fax;
          if (!data?.billing?.other) delete data?.billing?.other;
        }
      }
      if (props.edit) {
        props.updateUser(
          props.selectedUser,
          data,
          () => {
            // props.updateUserDataById(values);
            props.onClose();
          },
          (err) => onError(err)
        );
      } else {
        props.createUser(
          data,
          () => props.onClose(),
          (err) => onError(err)
        );
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const onChange = (key, value) => {
    setErrors({});
    setFieldValue(key, value || '');
  };

  const handleSelectedTags = (key, tags) => {
    setFieldValue(key, tags);
  };

  const formik = useFormik({
    initialValues: {
      designerCode: '',
      type: '0',
      name: '',
      email: '',
      note: '',
      additionalEmails: [],
      copyToClient: false,
      shipping: {
        zipcode: '',
        city: '0',
        state: '0',
        address: '',
        business: '',
        cel: '',
        fax: '',
        other: '',
      },
      billing: {
        zipcode: '',
        city: '0',
        state: '0',
        address: '',
        business: '',
        cel: '',
        fax: '',
        other: '',
        sameAsShipping: false,
        preferedContact: '0',
        billingOptions: {
          receivingFees: false,
          storageFees: false,
          detailOnInvoice: false,
        },
      },
      active: true,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: AddUserSchema,
    onSubmit: (values) => {
      handleDataSubmit(values);
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
  } = formik;

  useEffect(() => {
    if (values.type === 'employee') setIsEmployeeView(true);
    else setIsEmployeeView(false);
  }, [values.type]);

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        <a className={classNameRemove} onClick={(e) => onRemove(key)} />
      </span>
    );
  };

  return (
    <div className={classes.main_container}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <div className={classes.modal_container}>
            <div className={classes.close_icon_container}>
              <img
                src={icons().close}
                className={classes.close_icon}
                onClick={() => props.onClose()}
              />
            </div>
            <Typography variant='h5_700'>
              {props.edit ? Strings.EDIT_USER : Strings.NEW_USER}
            </Typography>
            {props.loader ? (
              <div className={classes.display_flex}>
                <Loader color={Colors.RAISIN_BLACK} size={40} />
              </div>
            ) : (
              <>
                <Selection
                  className={classNames(
                    classes.selection_input,
                    classes.top_margin
                  )}
                  label={Strings.TYPE}
                  handleChange={(e) => onChange('type', e.target.value)}
                  value={values.type}
                  title={Strings.SELECT}
                  options={userTypeList}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                />

                {values.type === 'designer' && (
                  <TextField
                    // required
                    id='filled-required'
                    label={Strings.DESIGNER_CODE}
                    placeholder={Strings.TYPE_HERE}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.input}
                    value={values.designerCode}
                    onChange={(e) => onChange('designerCode', e.target.value)}
                    error={Boolean(touched.designerCode && errors.designerCode)}
                    helperText={touched.designerCode && errors.designerCode}
                    disabled={props?.edit && props?.userData?.designerCode}
                  />
                )}

                <TextField
                  // required
                  id='filled-required'
                  label={Strings.NAME}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.input}
                  value={values.name}
                  onChange={(e) => onChange('name', e.target.value)}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <TextField
                  // required
                  id='filled-required'
                  label={Strings.EMAIL}
                  placeholder={Strings.TYPE_HERE}
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.email}
                  className={classes.input}
                  onChange={(e) => onChange('email', e.target.value)}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                {!isEmployeeView && (
                  <>
                    {!props.edit && (
                      <TextField
                        label={Strings.NOTE}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        onChange={(e) => onChange('note', e.target.value)}
                      />
                    )}

                    <TagsInputField
                      selectedTags={(value) =>
                        handleSelectedTags('additionalEmails', value)
                      }
                      label={Strings.ADDITIONAL_EMAILS}
                      placeholder={Strings.TYPE_HERE}
                      tags={values.additionalEmails || []}
                      renderTag={renderTags}
                      containerClassName={classes.input}
                      // disabled={false}
                    />

                    {!props?.edit && (
                      <div
                        className={classNames(
                          classes.flex_row,
                          classes.toggle_container
                        )}
                      >
                        <Typography
                          variant='p_400'
                          className={classNames(
                            classes.sub_title,
                            classes.flex
                          )}
                        >
                          {Strings.COPY_TO_CLIENT}
                        </Typography>
                        <ToggleButton
                          className={classes.toggle_btn}
                          checked={values?.copyToClient || false}
                          onChange={(e) => {
                            onChange('copyToClient', e.target.checked);
                          }}
                        />
                      </div>
                    )}

                    <Typography
                      variant='h6_700'
                      className={classNames(
                        classes.sub_title,
                        classes.label_margin
                      )}
                    >
                      {Strings.SHIPPING}
                    </Typography>

                    <div className={classes.flex_row}>
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.ZIPCODE}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        value={values.shipping.zipcode}
                        onChange={(e) =>
                          onChange('shipping', {
                            ...values.shipping,
                            zipcode: e.target.value,
                          })
                        }
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.zipcode &&
                            touched.shipping.zipcode &&
                            errors.shipping &&
                            errors.shipping.zipcode &&
                            errors.shipping.zipcode
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.zipcode &&
                          touched.shipping.zipcode &&
                          errors.shipping &&
                          errors.shipping.zipcode &&
                          errors.shipping.zipcode
                        }
                        // onWheel={(e) => e.target.blur()}
                      />
                      <Selection
                        className={classNames(
                          classes.input,
                          classes.selection_input
                        )}
                        label={Strings.STATE}
                        title={Strings.SELECT}
                        options={stateList}
                        value={values.shipping.state}
                        handleChange={(e) => {
                          onChange('shipping', {
                            ...values.shipping,
                            state: e.target.value,
                            city: '0',
                          });
                          setSelectedState({
                            ...selectedState,
                            shipping: stateList.find(
                              (s) => s.value === e.target.value
                            ),
                          });
                        }}
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.state &&
                            touched.shipping.state &&
                            errors.shipping &&
                            errors.shipping.state &&
                            errors.shipping.state
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.state &&
                          touched.shipping.state &&
                          errors.shipping &&
                          errors.shipping.state &&
                          errors.shipping.state
                        }
                      />
                      <Selection
                        className={classNames(
                          classes.input,
                          classes.selection_input
                        )}
                        label={Strings.CITY}
                        title={Strings.SELECT}
                        options={cityListShipping}
                        value={values.shipping.city}
                        handleChange={(e) =>
                          onChange('shipping', {
                            ...values.shipping,
                            city: e.target.value,
                          })
                        }
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.city &&
                            touched.shipping.city &&
                            errors.shipping &&
                            errors.shipping.city &&
                            errors.shipping.city
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.city &&
                          touched.shipping.city &&
                          errors.shipping &&
                          errors.shipping.city &&
                          errors.shipping.city
                        }
                      />
                    </div>
                    <TextField
                      // required
                      id='filled-required'
                      label={Strings.ADDRESS}
                      placeholder={Strings.TYPE_HERE}
                      variant='standard'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.input}
                      value={values.shipping.address}
                      onChange={(e) =>
                        onChange('shipping', {
                          ...values.shipping,
                          address: e.target.value,
                        })
                      }
                      error={Boolean(
                        touched.shipping &&
                          touched.shipping.address &&
                          touched.shipping.address &&
                          errors.shipping &&
                          errors.shipping.address &&
                          errors.shipping.address
                      )}
                      helperText={
                        touched.shipping &&
                        touched.shipping.address &&
                        touched.shipping.address &&
                        errors.shipping &&
                        errors.shipping.address &&
                        errors.shipping.address
                      }
                    />
                    <div className={classes.flex_row}>
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.BUSINESS}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        value={values.shipping.business}
                        onChange={(e) =>
                          onChange('shipping', {
                            ...values.shipping,
                            business: e.target.value,
                          })
                        }
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.business &&
                            touched.shipping.business &&
                            errors.shipping &&
                            errors.shipping.business &&
                            errors.shipping.business
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.business &&
                          touched.shipping.business &&
                          errors.shipping &&
                          errors.shipping.business &&
                          errors.shipping.business
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.CEL}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        value={values.shipping.cel}
                        onChange={(e) =>
                          onChange('shipping', {
                            ...values.shipping,
                            cel: e.target.value,
                          })
                        }
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.cel &&
                            touched.shipping.cel &&
                            errors.shipping &&
                            errors.shipping.cel &&
                            errors.shipping.cel
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.cel &&
                          touched.shipping.cel &&
                          errors.shipping &&
                          errors.shipping.cel &&
                          errors.shipping.cel
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className={classes.flex_row}>
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.FAX}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        value={values.shipping.fax}
                        onChange={(e) =>
                          onChange('shipping', {
                            ...values.shipping,
                            fax: e.target.value,
                          })
                        }
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.fax &&
                            touched.shipping.fax &&
                            errors.shipping &&
                            errors.shipping.fax &&
                            errors.shipping.fax
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.fax &&
                          touched.shipping.fax &&
                          errors.shipping &&
                          errors.shipping.fax &&
                          errors.shipping.fax
                        }
                        // onWheel={(e) => e.target.blur()}
                      />
                      <TextField
                        // required
                        id='filled-required'
                        label={Strings.OTHER}
                        placeholder={Strings.TYPE_HERE}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.input}
                        value={values.shipping.other}
                        onChange={(e) =>
                          onChange('shipping', {
                            ...values.shipping,
                            other: e.target.value,
                          })
                        }
                        error={Boolean(
                          touched.shipping &&
                            touched.shipping.other &&
                            touched.shipping.other &&
                            errors.shipping &&
                            errors.shipping.other &&
                            errors.shipping.other
                        )}
                        helperText={
                          touched.shipping &&
                          touched.shipping.other &&
                          touched.shipping.other &&
                          errors.shipping &&
                          errors.shipping.other &&
                          errors.shipping.other
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className={classes.billing_container}>
                      <Typography
                        variant='h6_700'
                        className={classNames(classes.sub_title, classes.flex)}
                      >
                        {Strings.BILLING}
                      </Typography>
                      <div
                        className={classNames(
                          classes.flex_row,
                          classes.toggle_container
                        )}
                      >
                        <Typography
                          variant='p_400'
                          className={classNames(
                            classes.sub_title,
                            classes.flex
                          )}
                        >
                          {Strings.SAME_AS_SHIPPING}
                        </Typography>
                        <ToggleButton
                          className={classes.toggle_btn}
                          checked={values.billing.sameAsShipping}
                          onChange={(e) =>
                            onChange('billing', {
                              ...values.billing,
                              sameAsShipping: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </div>
                    {!values.billing.sameAsShipping && (
                      <>
                        <div className={classes.flex_row}>
                          <TextField
                            // required
                            id='filled-required'
                            label={Strings.ZIPCODE}
                            placeholder={Strings.TYPE_HERE}
                            variant='standard'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.input}
                            value={values.billing.zipcode}
                            onChange={(e) =>
                              onChange('billing', {
                                ...values.billing,
                                zipcode: e.target.value,
                              })
                            }
                            error={Boolean(
                              touched.billing &&
                                touched.billing.zipcode &&
                                touched.billing.zipcode &&
                                errors.billing &&
                                errors.billing.zipcode &&
                                errors.billing.zipcode
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.zipcode &&
                              touched.billing.zipcode &&
                              errors.billing &&
                              errors.billing.zipcode &&
                              errors.billing.zipcode
                            }
                            // onWheel={(e) => e.target.blur()}
                          />
                          <Selection
                            className={classNames(
                              classes.input,
                              classes.selection_input
                            )}
                            label={Strings.STATE}
                            title={Strings.SELECT}
                            options={stateList}
                            value={values.billing.state}
                            handleChange={(e) => {
                              onChange('billing', {
                                ...values.billing,
                                state: e.target.value,
                                city: '0',
                              });
                              setSelectedState({
                                ...selectedState,
                                billing: stateList.find(
                                  (s) => s.value === e.target.value
                                ),
                              });
                            }}
                            error={Boolean(
                              touched.billing &&
                                touched.billing.state &&
                                touched.billing.state &&
                                errors.billing &&
                                errors.billing.state &&
                                errors.billing.state
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.state &&
                              touched.billing.state &&
                              errors.billing &&
                              errors.billing.state &&
                              errors.billing.state
                            }
                          />
                          <Selection
                            className={classNames(
                              classes.input,
                              classes.selection_input
                            )}
                            label={Strings.CITY}
                            title={Strings.SELECT}
                            options={cityListBilling}
                            value={values.billing.city}
                            handleChange={(e) =>
                              onChange('billing', {
                                ...values.billing,
                                city: e.target.value,
                              })
                            }
                            error={Boolean(
                              touched.billing &&
                                touched.billing.city &&
                                touched.billing.city &&
                                errors.billing &&
                                errors.billing.city &&
                                errors.billing.city
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.city &&
                              touched.billing.city &&
                              errors.billing &&
                              errors.billing.city &&
                              errors.billing.city
                            }
                          />
                        </div>
                        <TextField
                          // required
                          id='filled-required'
                          label={Strings.ADDRESS}
                          placeholder={Strings.TYPE_HERE}
                          variant='standard'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.input}
                          value={values.billing.address}
                          onChange={(e) =>
                            onChange('billing', {
                              ...values.billing,
                              address: e.target.value,
                            })
                          }
                          error={Boolean(
                            touched.billing &&
                              touched.billing.address &&
                              touched.billing.address &&
                              errors.billing &&
                              errors.billing.address &&
                              errors.billing.address
                          )}
                          helperText={
                            touched.billing &&
                            touched.billing.address &&
                            touched.billing.address &&
                            errors.billing &&
                            errors.billing.address &&
                            errors.billing.address
                          }
                        />
                        <div className={classes.flex_row}>
                          <TextField
                            // required
                            id='filled-required'
                            label={Strings.BUSINESS}
                            placeholder={Strings.TYPE_HERE}
                            variant='standard'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.input}
                            value={values.billing.business}
                            onChange={(e) =>
                              onChange('billing', {
                                ...values.billing,
                                business: e.target.value,
                              })
                            }
                            error={Boolean(
                              touched.billing &&
                                touched.billing.business &&
                                touched.billing.business &&
                                errors.billing &&
                                errors.billing.business &&
                                errors.billing.business
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.business &&
                              touched.billing.business &&
                              errors.billing &&
                              errors.billing.business &&
                              errors.billing.business
                            }
                            // onWheel={(e) => e.target.blur()}
                          />
                          <TextField
                            // required
                            id='filled-required'
                            label={Strings.CEL}
                            placeholder={Strings.TYPE_HERE}
                            variant='standard'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.input}
                            value={values.billing.cel}
                            onChange={(e) =>
                              onChange('billing', {
                                ...values.billing,
                                cel: e.target.value,
                              })
                            }
                            error={Boolean(
                              touched.billing &&
                                touched.billing.cel &&
                                touched.billing.cel &&
                                errors.billing &&
                                errors.billing.cel &&
                                errors.billing.cel
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.cel &&
                              touched.billing.cel &&
                              errors.billing &&
                              errors.billing.cel &&
                              errors.billing.cel
                            }
                            // onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        <div className={classes.flex_row}>
                          <TextField
                            // required
                            id='filled-required'
                            label={Strings.FAX}
                            placeholder={Strings.TYPE_HERE}
                            variant='standard'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.input}
                            value={values.billing.fax}
                            onChange={(e) =>
                              onChange('billing', {
                                ...values.billing,
                                fax: e.target.value,
                              })
                            }
                            error={Boolean(
                              touched.billing &&
                                touched.billing.fax &&
                                touched.billing.fax &&
                                errors.billing &&
                                errors.billing.fax &&
                                errors.billing.fax
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.fax &&
                              touched.billing.fax &&
                              errors.billing &&
                              errors.billing.fax &&
                              errors.billing.fax
                            }
                            // onWheel={(e) => e.target.blur()}
                          />
                          <TextField
                            // required
                            id='filled-required'
                            label={Strings.OTHER}
                            placeholder={Strings.TYPE_HERE}
                            variant='standard'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.input}
                            value={values.billing.other}
                            onChange={(e) =>
                              onChange('billing', {
                                ...values.billing,
                                other: e.target.value,
                              })
                            }
                            error={Boolean(
                              touched.billing &&
                                touched.billing.other &&
                                touched.billing.other &&
                                errors.billing &&
                                errors.billing.other &&
                                errors.billing.other
                            )}
                            helperText={
                              touched.billing &&
                              touched.billing.other &&
                              touched.billing.other &&
                              errors.billing &&
                              errors.billing.other &&
                              errors.billing.other
                            }
                            // onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </>
                    )}
                    <Selection
                      className={classes.selection_input}
                      label={Strings.PREFERRED_CONTACT}
                      // placeHolder={'All user types'}
                      // handleChange={handleChange}
                      title={Strings.SELECT}
                      options={preferedContactType}
                      value={values.billing.preferedContact}
                      handleChange={(e) =>
                        onChange('billing', {
                          ...values.billing,
                          preferedContact: e.target.value,
                        })
                      }
                      error={Boolean(
                        touched.billing &&
                          touched.billing.preferedContact &&
                          touched.billing.preferedContact &&
                          errors.billing &&
                          errors.billing.preferedContact &&
                          errors.billing.preferedContact
                      )}
                      helperText={
                        touched.billing &&
                        touched.billing.preferedContact &&
                        touched.billing.preferedContact &&
                        errors.billing &&
                        errors.billing.preferedContact &&
                        errors.billing.preferedContact
                      }
                    />
                  </>
                )}
                <Stack mt={3}>
                  <CustomCheckbox
                    container={classes.checkbox_container}
                    label={Strings.ACTIVE}
                    checked={values.active}
                    onChange={(e) => setFieldValue('active', e.target.checked)}
                  />
                </Stack>
                {!isEmployeeView && (
                  <>
                    <Typography variant='p_400' className={classes.sub_heading}>
                      {Strings.BILLING_OPTIONS}
                    </Typography>

                    <div className={classes.flex_row}>
                      <div className={classes.options_checkbox}>
                        <CustomCheckbox
                          container={classes.checkbox_container}
                          // indeterminate={true}
                          label={Strings.BILL_RECEIVING_FEES}
                          checked={values.billing.billingOptions.receivingFees}
                          onChange={(e) => {
                            onChange('billing', {
                              ...values.billing,
                              billingOptions: {
                                ...values.billing.billingOptions,
                                receivingFees: e.target.checked,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className={classes.options_checkbox}>
                        <CustomCheckbox
                          container={classes.checkbox_container}
                          label={Strings.BILL_STORAGE_FEES}
                          checked={values.billing.billingOptions.storageFees}
                          onChange={(e) => {
                            onChange('billing', {
                              ...values.billing,
                              billingOptions: {
                                ...values.billing.billingOptions,
                                storageFees: e.target.checked,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.options_checkbox}>
                      <CustomCheckbox
                        container={classes.checkbox_container}
                        label={Strings.SHOW_DETAIL_ON_INVOICE}
                        checked={values.billing.billingOptions.detailOnInvoice}
                        onChange={(e) => {
                          onChange('billing', {
                            ...values.billing,
                            billingOptions: {
                              ...values.billing.billingOptions,
                              detailOnInvoice: e.target.checked,
                            },
                          });
                        }}
                      />
                    </div>
                  </>
                )}

                <Button
                  type='submit'
                  className={classes.add_user_button}
                  variant='contained-radius'
                  disabled={
                    props.edit ? props.updateUserLoading : props.addUserLoading
                  }
                >
                  {(!props.edit && props.addUserLoading) ||
                  (props.edit && props.updateUserLoading) ? (
                    <Loader />
                  ) : props.edit ? (
                    Strings.UPDATE_USER.toUpperCase()
                  ) : (
                    Strings.ADD_USER.toUpperCase()
                  )}
                </Button>
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addUserData: state.addUserData.data,
  addUserLoading: state.addUserData.loading,

  updateUserData: state.updateUserData.data,
  updateUserLoading: state.updateUserData.loading,
});

export default connect(mapStateToProps, {
  createUser,
  updateUser,
})(AddUserModal);
