import axios from 'axios';
import { ASSIGN_TO_VENDOR } from '../../constants';

export const assignToVendor =
  (incidentId, vendorId, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadAssignToVendor());

    axios
      .put(
        `/incident/assignToVendor?incidentId=${incidentId}&vendorId=${vendorId}`,
        '',
        config
      )
      .then((res) => {
        dispatch(setAssignToVender(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorAssignToVender(err.response));
        onError(err.response);
      });
  };

export const loadAssignToVendor = () => ({
  type: ASSIGN_TO_VENDOR.LOAD,
});

export const setAssignToVender = (data) => ({
  type: ASSIGN_TO_VENDOR.SUCCESS,
  payload: data.data,
});

export const errorAssignToVender = (error) => ({
  type: ASSIGN_TO_VENDOR.FAIL,
  payload: error,
});
