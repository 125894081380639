import axios from 'axios';
import { BARCODE_PRINT } from '../../constants';
import { DownloadCSVorPDF } from '../../../utils/appUtils';

export const barcodePrint =
  (data, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}`},
      responseType: 'blob',
    };
    dispatch(loadBarcodePrint());

    axios
      .put(`/inventory/barcodesPrint`, data, config)
      .then((res) => {
        DownloadCSVorPDF(res?.data, `Barcode.pdf`);
        dispatch(setBarcodePrint(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorBarcodePrint(err.response));
        onError(err.response);
      });
  };

export const loadBarcodePrint = () => ({
  type: BARCODE_PRINT.LOAD,
});

export const setBarcodePrint = (data) => ({
  type: BARCODE_PRINT.SUCCESS,
  payload: data.data,
});

export const errorBarcodePrint = (error) => ({
  type: BARCODE_PRINT.FAIL,
  payload: error,
});
