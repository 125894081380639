import React, { useState, useEffect } from 'react';
import { icons } from '../../assets';
import useStyles from './styles';
import { Typography, Button, Stack } from '@mui/material';
import { Colors } from '../../common/colors';
import { Strings } from '../../common/strings';
import classNames from 'classnames';
import CustomCheckbox from '../CustomCheckbox';
import UserDetailsTabHeader from '../UserDetailsTabHeader';
import BillingTabContainer from '../BillingTabContainer';
import NotesTabContainer from '../NotesTabContainer';
import ClientsTabContainer from '../ClientsTabContainer';
import Loader from '../Loader';
import { connect } from 'react-redux';
import { addNote } from '../../redux/actions/adminActions/addNote';
import { updateNote } from '../../redux/actions/adminActions/updateNote';
import { deleteNote } from '../../redux/actions/adminActions/deleteNote';
import AddClientModal from '../AddClientModal';

const positionBgColor = {
  designer: Colors.POWDER_BLUE,
  employee: Colors.PALE_SPRING_BUD,
  admin: Colors.PALE_LAVENDER,
};

function UserDetailsModal(props) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [billingData, setBillingData] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [notesData, setNotesData] = useState({});
  const [clientsData, setClientsData] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [showClientDetail, setShowClientDetail] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  const CustomTabView = () => {
    return (
      <div className={classes.tab_main_container}>
        <UserDetailsTabHeader
          onClickHeader={setSelectedTab}
          selectedTab={selectedTab}
        />
        {selectedTab === 0 && (
          <BillingTabContainer
            shipping={shippingData}
            billing={billingData}
            isShipping={true}
          />
        )}
        {selectedTab === 1 && (
          <BillingTabContainer
            shipping={shippingData}
            billing={billingData}
            isBilling={true}
          />
        )}
        {selectedTab === 2 && (
          <NotesTabContainer
            notes={[...notesData].reverse()}
            notesData={notesData}
            setNotesData={setNotesData}
            addNoteDataLoading={props.addNoteDataLoading}
            addNoteData={props.addNoteData}
            addNote={props.addNote}
            selectedUser={props.selectedUser}
            onError={props.onError}
            deleteNote={props?.deleteNote}
            deleteNoteData={props?.deleteNoteData}
            deleteNoteDataLoading={props?.deleteNoteDataLoading}
            updateNote={props?.updateNote}
            updateNoteData={props?.updateNoteData}
            updateNoteDataLoading={props?.updateNoteDataLoading}
          />
        )}
        {selectedTab === 3 && (
          <ClientsTabContainer
            setShowClientDetail={setShowClientDetail}
            setSelectedClient={setSelectedClient}
            clients={clientsData}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    props.getUser(props.selectedUser, props.onError, true);
  }, []);

  useEffect(() => {
    setUserDetails(props?.userData);
    setBillingData(props?.userData?.billing);
    setShippingData(props?.userData?.shipping);
    setNotesData(props?.userData?.notes);
    setClientsData(props?.userData?.clients);
  }, [props.userData]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onClickDelete = () => {
    props.onClose();
    props.onClickDelete();
  };

  if (showClientDetail) {
    return (
      <AddClientModal
        onClose={() => setShowClientDetail(false)}
        selectedClient={selectedClient?._id}
        // getClientData={props?.getClientData}
        clientData={props?.getClient}
        loader={props.getClientDataLoading}
        isShowDetails
        edit
      />
    );
  }

  return (
    <div className={classes.main_container}>
      <div className={classes.modal_container}>
        <div className={classes.close_icon_container}>
          <img
            src={icons().close}
            className={classes.close_icon}
            onClick={() => props.onClose()}
          />
        </div>
        <div className={classNames(classes.flex_row, classes.align_center)}>
          <Typography variant='h5_700'>{Strings.DETAILS}</Typography>
          <img
            src={icons().edit}
            className={classes.icons}
            onClick={() => props.onClickEdit()}
          />
          <img
            src={icons().delete}
            className={classes.icons}
            onClick={onClickDelete}
          />
        </div>

        {props.loader ? (
          <div className={classes.display_flex}>
            <Loader color={Colors.RAISIN_BLACK} size={40} />
          </div>
        ) : (
          <>
            <Stack direction='row' alignItems='center'>
              <div
                className={classes.position}
                style={{
                  backgroundColor: positionBgColor[userDetails?.role],
                }}
              >
                <Typography variant='p_700'>
                  {userDetails?.role &&
                    capitalizeFirstLetter(userDetails?.role)}
                </Typography>
              </div>
              {userDetails?.role === 'designer' ? (
                <Typography variant='p_700' ml='auto'>
                  {`${Strings.BALANCE} : `}
                  <Typography variant='p_400'>
                    {userDetails?.balance || 0}
                  </Typography>
                </Typography>
              ) : null}
            </Stack>

            <div className={classes.name_view}>
              <Typography variant='h6_700'>{userDetails?.name}</Typography>
            </div>

            <div
              className={classNames(
                classes.flex_row,
                classes.align_center,
                classes.email_container
              )}
            >
              <img src={icons().email} className={classes.email_icon} />
              <Typography variant='p_400'>{userDetails?.email}</Typography>
            </div>

            {userDetails?.role !== 'employee' && (
              <>
                <CustomTabView />

                {userDetails?.additionalEmails?.length ? (
                  <Stack gap={1.25}>
                    <Typography variant='p_600' className={classes.sub_heading}>
                      {Strings.ADDITIONAL_EMAILS}
                    </Typography>
                    {userDetails?.additionalEmails?.map((el) => (
                      <Stack
                        key={el}
                        flexDirection='row'
                        alignItems='center'
                        gap={1.25}
                        color={Colors.SEA_GREEN}
                      >
                        <img
                          src={icons().email}
                          className={classes.email_icon}
                        />
                        <Typography variant='p_400'>{el}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                ) : null}

                <Typography variant='p_400' className={classes.sub_heading}>
                  {Strings.PREFERRED_CONTACT}
                </Typography>

                <Typography
                  variant='p_700'
                  className={classes.prefered_contact_value}
                >
                  {userDetails?.preferedContact}
                </Typography>

                <Stack mt={3}>
                  <CustomCheckbox
                    container={classes.checkbox_container}
                    label={Strings.ACTIVE}
                    checked={userDetails?.active || false}
                  />
                </Stack>

                <Typography variant='p_400' className={classes.sub_heading}>
                  {Strings.BILLING_OPTIONS}
                </Typography>

                <div className={classes.flex_row}>
                  <div className={classes.options_checkbox}>
                    <CustomCheckbox
                      container={classes.checkbox_container}
                      label={Strings.BILL_RECEIVING_FEES}
                      checked={
                        userDetails?.billingOptions?.receivingFees || false
                      }
                      // onChange={() => props.onSelectAllClick()}
                    />
                  </div>
                  <div className={classes.options_checkbox}>
                    <CustomCheckbox
                      container={classes.checkbox_container}
                      label={Strings.BILL_STORAGE_FEES}
                      checked={
                        userDetails?.billingOptions?.storageFees || false
                      }
                    />
                  </div>
                </div>
                <div className={classes.options_checkbox}>
                  <CustomCheckbox
                    container={classes.checkbox_container}
                    label={Strings.SHOW_DETAIL_ON_INVOICE}
                    checked={
                      userDetails?.billingOptions?.detailOnInvoice || false
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  addNoteData: state.addNoteData.data,
  addNoteDataLoading: state.addNoteData.loading,

  deleteNoteData: state.deleteNoteData.data,
  deleteNoteDataLoading: state.deleteNoteData.loading,

  updateNoteData: state.updateNoteData.data,
  updateNoteDataLoading: state.updateNoteData.loading,

  getClient: state.getClientData.data,
  getClientDataLoading: state.getClientData.loading,
});

export default connect(mapStateToProps, {
  addNote,
  updateNote,
  deleteNote,
})(UserDetailsModal);
