const LOGIN = {
  LOAD: 'LOAD_LOGIN',
  SUCCESS: 'SUCCESS_LOGIN',
  FAIL: 'FAIL_LOGIN',
};

const USER_LIST = {
  LOAD: 'LOAD_USER_LIST_DATA',
  SUCCESS: 'SUCCESS_USER_LIST_DATA',
  FAIL: 'FAIL_USER_LIST_DATA',
};

const DELETE_USER = {
  LOAD: 'LOAD_DELETE_USER',
  SUCCESS: 'SUCCESS_DELETE_USER',
  FAIL: 'FAIL_DELETE_USER',
};

const GET_USER = {
  LOAD: 'LOAD_GET_USER',
  SUCCESS: 'SUCCESS_GET_USER',
  FAIL: 'FAIL_GET_USER',
};

const ADD_NOTE = {
  LOAD: 'LOAD_ADD_NOTE',
  SUCCESS: 'SUCCESS_ADD_NOTE',
  FAIL: 'FAIL_ADD_NOTE',
};

const DELETE_NOTE = {
  LOAD: 'LOAD_DELETE_NOTE',
  SUCCESS: 'SUCCESS_DELETE_NOTE',
  FAIL: 'FAIL_DELETE_NOTE',
};

const UPDATE_NOTE = {
  LOAD: 'LOAD_UPDATE_NOTE',
  SUCCESS: 'SUCCESS_UPDATE_NOTE',
  FAIL: 'FAIL_UPDATE_NOTE',
};

const ADD_USER = {
  LOAD: 'LOAD_ADD_USER',
  SUCCESS: 'SUCCESS_ADD_USER',
  FAIL: 'FAIL_ADD_USER',
};

const UPDATE_USER = {
  LOAD: 'LOAD_UPDATE_USER',
  SUCCESS: 'SUCCESS_UPDATE_USER',
  FAIL: 'FAIL_UPDATE_USER',
};

const VENDOR_LIST = {
  LOAD: 'LOAD_VENDOR_LIST',
  SUCCESS: 'SUCCESS_VENDOR_LIST',
  FAIL: 'FAIL_VENDOR_LIST',
};

const INCIDENT_LIST = {
  LOAD: 'LOAD_INCIDENT_LIST',
  SUCCESS: 'SUCCESS_INCIDENT_LIST',
  FAIL: 'FAIL_INCIDENT_LIST',
};

const GET_INCIDENT = {
  LOAD: 'LOAD_GET_INCIDENT',
  SUCCESS: 'SUCCESS_GET_INCIDENT',
  FAIL: 'FAIL_GET_INCIDENT',
};

const DELETE_INCIDENT = {
  LOAD: 'LOAD_DELETE_INCIDENT',
  SUCCESS: 'SUCCESS_DELETE_INCIDENT',
  FAIL: 'FAIL_DELETE_INCIDENT',
};

const ADD_VENDOR = {
  LOAD: 'LOAD_ADD_VENDOR',
  SUCCESS: 'SUCCESS_ADD_VENDOR',
  FAIL: 'FAIL_ADD_VENDOR',
};

const DELETE_VENDOR = {
  LOAD: 'LOAD_DELETE_VENDOR',
  SUCCESS: 'SUCCESS_DELETE_VENDOR',
  FAIL: 'FAIL_DELETE_VENDOR',
};

const UPDATE_VENDOR = {
  LOAD: 'LOAD_UPDATE_VENDOR',
  SUCCESS: 'SUCCESS_UPDATE_VENDOR',
  FAIL: 'FAIL_UPDATE_VENDOR',
};

const ADD_CLIENT = {
  LOAD: 'LOAD_ADD_CLIENT',
  SUCCESS: 'SUCCESS_ADD_CLIENT',
  FAIL: 'FAIL_ADD_CLIENT',
};

const UPDATE_CLIENT = {
  LOAD: 'LOAD_UPDATE_CLIENT',
  SUCCESS: 'SUCCESS_UPDATE_CLIENT',
  FAIL: 'FAIL_UPDATE_CLIENT',
};

const DELETE_CLIENT = {
  LOAD: 'LOAD_DELETE_CLIENT',
  SUCCESS: 'SUCCESS_DELETE_CLIENT',
  FAIL: 'FAIL_DELETE_CLIENT',
};

const GET_CLIENT = {
  LOAD: 'LOAD_GET_CLIENT',
  SUCCESS: 'SUCCESS_GET_CLIENT',
  FAIL: 'FAIL_GET_CLIENT',
};

const CLIENT_LIST = {
  LOAD: 'LOAD_CLIENT_LIST_DATA',
  SUCCESS: 'SUCCESS_CLIENT_LIST_DATA',
  FAIL: 'FAIL_CLIENT_LIST_DATA',
};

const GET_VENDOR = {
  LOAD: 'LOAD_GET_VENDOR',
  SUCCESS: 'SUCCESS_GET_VENDOR',
  FAIL: 'FAIL_GET_VENDOR',
};

const DESIGNER_LIST = {
  LOAD: 'LOAD_DESIGNER_LIST',
  SUCCESS: 'SUCCESS_DESIGNER_LIST',
  FAIL: 'FAIL_DESIGNER_LIST',
};

const EMPLOYEE_LIST = {
  LOAD: 'LOAD_EMPLOYEE_LIST',
  SUCCESS: 'SUCCESS_EMPLOYEE_LIST',
  FAIL: 'FAIL_EMPLOYEE_LIST',
};

const LIST_CLIENT = {
  LOAD: 'LOAD_CLIENT_LIST',
  SUCCESS: 'SUCCESS_CLIENT_LIST',
  FAIL: 'FAIL_CLIENT_LIST',
};
const ADD_RATE_SHEET = {
  LOAD: 'LOAD_ADD_RATE_SHEET',
  SUCCESS: 'SUCCESS_ADD_RATE_SHEET',
  FAIL: 'FAIL_ADD_RATE_SHEET',
};

const RATE_SHEET_LIST = {
  LOAD: 'LOAD_ADD_RATE_SHEET_LIST',
  SUCCESS: 'SUCCESS_ADD_RATE_SHEET_LIST',
  FAIL: 'FAIL_ADD_RATE_SHEET_LIST',
};

const GET_RATE_SHEET = {
  LOAD: 'LOAD_GET_RATE_SHEET',
  SUCCESS: 'SUCCESS_GET_RATE_SHEET',
  FAIL: 'FAIL_GET_RATE_SHEET',
};

const UPDATE_RATE_SHEET = {
  LOAD: 'LOAD_UPDATE_RATE_SHEET',
  SUCCESS: 'SUCCESS_UPDATE_RATE_SHEET',
  FAIL: 'FAIL_UPDATE_RATE_SHEET',
};

const DELETE_RATE_SHEET = {
  LOAD: 'LOAD_DELETE_RATE_SHEET',
  SUCCESS: 'SUCCESS_DELETE_RATE_SHEET',
  FAIL: 'FAIL_DELETE_RATE_SHEET',
};

const UPDATE_RATE_SHEET_STATUS = {
  LOAD: 'LOAD_UPDATE_RATE_SHEET_STATUS',
  SUCCESS: 'SUCCESS_UPDATE_RATE_SHEET_STATUS',
  FAIL: 'FAIL_UPDATE_RATE_SHEET_STATUS',
};

const ADD_FEE = {
  LOAD: 'LOAD_ADD_FEE',
  SUCCESS: 'SUCCESS_ADD_FEE',
  FAIL: 'FAIL_ADD_FEE',
};

const UPDATE_FEE = {
  LOAD: 'LOAD_UPDATE_FEE',
  SUCCESS: 'SUCCESS_UPDATE_FEE',
  FAIL: 'FAIL_UPDATE_FEE',
};

const UPDATE_FEE_STATUS = {
  LOAD: 'LOAD_UPDATE_FEE_STATUS',
  SUCCESS: 'SUCCESS_UPDATE_FEE_STATUS',
  FAIL: 'FAIL_UPDATE_FEE_STATUS',
};

const DELIVERY_LIST = {
  LOAD: 'LOAD_DELIVERY_LIST',
  SUCCESS: 'SUCCESS_DELIVERY_LIST',
  FAIL: 'FAIL_DELIVERY_LIST',
};

const GET_DELIVERY = {
  LOAD: 'LOAD_DELIVERY_DETAILS',
  SUCCESS: 'SUCCESS_DELIVERY_DETAILS',
  FAIL: 'FAIL_DELIVERY_DETAILS',
};

const ADD_DELIVERY = {
  LOAD: 'LOAD_ADD_DELIVERY',
  SUCCESS: 'SUCCESS_ADD_DELIVERY',
  FAIL: 'FAIL_ADD_DELIVERY',
};

const UPDATE_DELIVERY = {
  LOAD: 'LOAD_UPDATE_DELIVERY',
  SUCCESS: 'SUCCESS_UPDATE_DELIVERY',
  FAIL: 'FAIL_UPDATE_DELIVERY',
};

const DELETE_DELIVERY = {
  LOAD: 'LOAD_DELETE_DELIVERY',
  SUCCESS: 'SUCCESS_DELETE_DELIVERY',
  FAIL: 'FAIL_DELETE_DELIVERY',
};

const CHANGE_PASSWORD = {
  LOAD: 'LOAD_CHANGE_PASSWORD',
  SUCCESS: 'SUCCESS_CHANGE_PASSWORD',
  FAIL: 'FAIL_CHANGE_PASSWORD',
};

const GET_USER_BY_ID = {
  LOAD: 'LOAD_GET_USER_BY_ID',
  SUCCESS: 'SUCCESS_GET_USER_BY_ID',
  FAIL: 'FAIL_GET_USER_BY_ID',
};

const UPLOAD_FILE = {
  LOAD: 'LOAD_UPLOAD_FILE',
  SUCCESS: 'SUCCESS_UPLOAD_FILE',
  FAIL: 'FAIL_UPLOAD_FILE',
};

const DELETE_FILE = {
  LOAD: 'LOAD_DELETE_FILE',
  SUCCESS: 'SUCCESS_DELETE_FILE',
  FAIL: 'FAIL_DELETE_FILE',
};

const UPDATE_USER_PROFILE = {
  LOAD: 'LOAD_UPDATE_USER_PROFILE',
  SUCCESS: 'SUCCESS_UPDATE_USER_PROFILE',
  FAIL: 'FAIL_UPDATE_USER_PROFILE',
};

const INVENTORY_LIST = {
  LOAD: 'LOAD_INVENTORY_LIST',
  SUCCESS: 'SUCCESS_INVENTORY_LIST',
  FAIL: 'FAIL_INVENTORY_LIST',
};

const INVENTORY_LIST_FOR_NEXT_PREV = {
  LOAD: 'LOAD_INVENTORY_LIST_FOR_NEXT_PREV',
  SUCCESS: 'SUCCESS_INVENTORY_LIST_FOR_NEXT_PREV',
  FAIL: 'FAIL_INVENTORY_LIST_FOR_NEXT_PREV',
};

const GET_INVENTORY_LIST_FOR_DROPPDOWN = {
  LOAD: 'LOAD_INVENTORY_LIST',
  SUCCESS: 'SUCCESS_INVENTORY_LIST',
  FAIL: 'FAIL_INVENTORY_LIST',
};

const ADD_INVENTORY = {
  LOAD: 'LOAD_ADD_INVENTORY',
  SUCCESS: 'SUCCESS_ADD_INVENTORY',
  FAIL: 'FAIL_ADD_INVENTORY',
};

const GET_INVENTORY = {
  LOAD: 'LOAD_GET_INVENTORY',
  SUCCESS: 'SUCCESS_GET_INVENTORY',
  FAIL: 'FAIL_GET_INVENTORY',
};

const UPDATE_INVENTORY = {
  LOAD: 'LOAD_UPDATE_INVENTORY',
  SUCCESS: 'SUCCESS_UPDATE_INVENTORY',
  FAIL: 'FAIL_UPDATE_INVENTORY',
};

const DELETE_INVENTORY = {
  LOAD: 'LOAD_DELETE_INVENTORY',
  SUCCESS: 'SUCCESS_DELETE_INVENTORY',
  FAIL: 'FAIL_DELETE_INVENTORY',
};

const BARCODE_PRINT = {
  LOAD: 'LOAD_BARCODE_PRINT',
  SUCCESS: 'SUCCESS_BARCODE_PRINT',
  FAIL: 'FAIL_BARCODE_PRINT',
};

const BARCODE_PDF = {
  LOAD: 'LOAD_BARCODE_PDF',
  SUCCESS: 'SUCCESS_BARCODE_PDF',
  FAIL: 'FAIL_BARCODE_PDF',
};

const INVOICE_LIST = {
  LOAD: 'LOAD_INVOICE_LIST',
  SUCCESS: 'SUCCESS_INVOICE_LIST',
  FAIL: 'FAIL_INVOICE_LIST',
};

const DELETE_INVOICE = {
  LOAD: 'LOAD_DELETE_INVOICE',
  SUCCESS: 'SUCCESS_DELETE_INVOICE',
  FAIL: 'FAIL_DELETE_INVOICE',
};

const EXPORT_INVOICE = {
  LOAD: 'LOAD_EXPORT_INVOICE',
  SUCCESS: 'SUCCESS_EXPORT_INVOICE',
  FAIL: 'FAIL_EXPORT_INVOICE',
};

const FORGOT_PASSWORD = {
  LOAD: 'LOAD_FORGOT_PASSWORD',
  SUCCESS: 'SUCCESS_FORGOT_PASSWORD',
  FAIL: 'FAIL_FORGOT_PASSWORD',
};

const UPDATE_INVENTORY_STATUS = {
  LOAD: 'LOAD_UPDATE_INVENTORY_STATUS',
  SUCCESS: 'SUCCESS_UPDATE_INVENTORY_STATUS',
  FAIL: 'FAIL_UPDATE_INVENTORY_STATUS',
};

const GET_VENDOR_USER_LIST = {
  LOAD: 'LOAD_GET_VENDOR_USER_LIST',
  SUCCESS: 'SUCCESS_GET_VENDOR_USER_LIST',
  FAIL: 'FAIL_GET_VENDOR_USER_LIST',
};

const ASSIGN_TO_DESIGNER = {
  LOAD: 'LOAD_ASSIGN_TO_DESIGNER',
  SUCCESS: 'SUCCESS_ASSIGN_TO_DESIGNER',
  FAIL: 'FAIL_ASSIGN_TO_DESIGNER',
};

const ASSIGN_TO_VENDOR = {
  LOAD: 'LOAD_ASSIGN_TO_VENDOR',
  SUCCESS: 'SUCCESS_ASSIGN_TO_VENDOR',
  FAIL: 'FAIL_ASSIGN_TO_VENDOR',
};

const GENERATE_REPORT = {
  LOAD: 'LOAD_GENERATE_REPORT',
  SUCCESS: 'SUCCESS_GENERATE_REPORT',
  FAIL: 'FAIL_GENERATE_REPORT',
};

const UPDATE_ESTIMATION = {
  LOAD: 'LOAD_UPDATE_ESTIMATION',
  SUCCESS: 'SUCCESS_UPDATE_ESTIMATION',
  FAIL: 'FAIL_UPDATE_ESTIMATION',
};
const CREATE_ESTIMATION = {
  LOAD: 'LOAD_CREATE_ESTIMATION',
  SUCCESS: 'SUCCESS_CREATE_ESTIMATION',
  FAIL: 'FAIL_CREATE_ESTIMATION',
};

const GENERATE_PULL_LIST = {
  LOAD: 'LOAD_GENERATE_PULL_LIST',
  SUCCESS: 'SUCCESS_GENERATE_PULL_LIST',
  FAIL: 'FAIL_GENERATE_PULL_LIST',
};

const GET_INVOICE = {
  LOAD: 'LOAD_GET_INVOICE',
  SUCCESS: 'SUCCESS_GET_INVOICE',
  FAIL: 'FAIL_GET_INVOICE',
};

const GENERATE_REPORT_PDF = {
  LOAD: 'LOAD_GENERATE_REPORT_PDF',
  SUCCESS: 'SUCCESS_GENERATE_REPORT_PDF',
  FAIL: 'FAIL_GENERATE_REPORT_PDF',
};

const RUN_REPORT = {
  LOAD: 'LOAD_RUN_REPORT',
  SUCCESS: 'SUCCESS_RUN_REPORT',
  FAIL: 'FAIL_RUN_REPORT',
};

const ADD_LOCATION = {
  LOAD: 'LOAD_ADD_LOCATION',
  SUCCESS: 'SUCCESS_ADD_LOCATION',
  FAIL: 'FAIL_ADD_LOCATION',
};

const EDIT_LOCATION = {
  LOAD: 'LOAD_EDIT_LOCATION',
  SUCCESS: 'SUCCESS_EDIT_LOCATION',
  FAIL: 'FAIL_EDIT_LOCATION',
};

const DELETE_LOCATION = {
  LOAD: 'LOAD_DELETE_LOCATION',
  SUCCESS: 'SUCCESS_DELETE_LOCATION',
  FAIL: 'FAIL_DELETE_LOCATION',
};

const LIST_LOCATION = {
  LOAD: 'LOAD_LIST_LOCATION',
  SUCCESS: 'SUCCESS_LIST_LOCATION',
  FAIL: 'FAIL_LIST_LOCATION',
};

const FEE_LIST = {
  LOAD: 'LOAD_FEE_LIST',
  SUCCESS: 'SUCCESS_FEE_LIST',
  FAIL: 'FAIL_FEE_LIST',
};

const SEND_EMAIL = {
  LOAD: 'LOAD_SEND_EMAIL',
  SUCCESS: 'SUCCESS_SEND_EMAIL',
  FAIL: 'FAIL_SEND_EMAIL',
};

const CLOSE_DELIVERY = {
  LOAD: 'LOAD_CLOSE_DELIVERY',
  SUCCESS: 'SUCCESS_CLOSE_DELIVERY',
  FAIL: 'FAIL_CLOSE_DELIVERY',
};

const CALCULATE_AMOUNT = {
  LOAD: 'LOAD_CALCULATE_AMOUNT',
  SUCCESS: 'SUCCESS_CALCULATE_AMOUNT',
  FAIL: 'FAIL_CALCULATE_AMOUNT',
};

const COMPLETE_INCIDENT = {
  LOAD: 'LOAD_COMPLETE_INCIDENT',
  SUCCESS: 'SUCCESS_COMPLETE_INCIDENT',
  FAIL: 'FAIL_COMPLETE_INCIDENT',
};

export {
  LOGIN,
  USER_LIST,
  DELETE_USER,
  GET_USER,
  ADD_NOTE,
  ADD_USER,
  UPDATE_USER,
  DELETE_NOTE,
  UPDATE_NOTE,
  VENDOR_LIST,
  INCIDENT_LIST,
  ADD_VENDOR,
  DELETE_VENDOR,
  UPDATE_VENDOR,
  GET_INCIDENT,
  DELETE_INCIDENT,
  GET_VENDOR,
  DESIGNER_LIST,
  EMPLOYEE_LIST,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT,
  CLIENT_LIST,
  LIST_CLIENT,
  ADD_RATE_SHEET,
  RATE_SHEET_LIST,
  GET_RATE_SHEET,
  UPDATE_RATE_SHEET,
  DELETE_RATE_SHEET,
  UPDATE_RATE_SHEET_STATUS,
  ADD_FEE,
  DELIVERY_LIST,
  ADD_DELIVERY,
  UPDATE_DELIVERY,
  DELETE_DELIVERY,
  GET_DELIVERY,
  CHANGE_PASSWORD,
  GET_USER_BY_ID,
  UPLOAD_FILE,
  DELETE_FILE,
  UPDATE_USER_PROFILE,
  INVENTORY_LIST,
  ADD_INVENTORY,
  GET_INVENTORY,
  UPDATE_INVENTORY,
  DELETE_INVENTORY,
  INVOICE_LIST,
  DELETE_INVOICE,
  EXPORT_INVOICE,
  FORGOT_PASSWORD,
  GET_INVENTORY_LIST_FOR_DROPPDOWN,
  UPDATE_INVENTORY_STATUS,
  GET_VENDOR_USER_LIST,
  ASSIGN_TO_DESIGNER,
  ASSIGN_TO_VENDOR,
  GENERATE_REPORT,
  UPDATE_ESTIMATION,
  CREATE_ESTIMATION,
  GENERATE_PULL_LIST,
  GET_INVOICE,
  GENERATE_REPORT_PDF,
  RUN_REPORT,
  ADD_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
  LIST_LOCATION,
  FEE_LIST,
  UPDATE_FEE,
  UPDATE_FEE_STATUS,
  BARCODE_PRINT,
  BARCODE_PDF,
  SEND_EMAIL,
  CLOSE_DELIVERY,
  CALCULATE_AMOUNT,
  COMPLETE_INCIDENT,
  INVENTORY_LIST_FOR_NEXT_PREV,
};
