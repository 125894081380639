import axios from 'axios';
import { CREATE_ESTIMATION } from '../../constants';

export const createEstimation =
  (incidentId, data, onSuccess, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadUpdateEstimation());

    axios
      .put(`/incident/createEstimation?incidentId=${incidentId}`, data, config)
      .then((res) => {
        dispatch(setUpdateEstimation(res));
        onSuccess();
      })
      .catch((err) => {
        dispatch(errorUpdateEstimation(err.response));
        onError(err.response);
      });
  };

export const loadUpdateEstimation = () => ({
  type: CREATE_ESTIMATION.LOAD,
});

export const setUpdateEstimation = (data) => ({
  type: CREATE_ESTIMATION.SUCCESS,
  payload: data.data,
});

export const errorUpdateEstimation = (error) => ({
  type: CREATE_ESTIMATION.FAIL,
  payload: error,
});
