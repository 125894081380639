import { EDIT_LOCATION } from '../../constants';
import axios from 'axios';

export const editLocation =
  (locId, data, onSuccess, onError, onDialogClose) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadEditLocation());
    axios
      .put(`/location/updateLocation?locationId=${locId}`, data, config)
      .then((res) => {
        dispatch(setEditLocation(res.data));
        onSuccess('Location has been updated successfully.');
        onDialogClose();
      })
      .catch((err) => {
        dispatch(failEditLocation(err.response));
        onError(err.response);
      });
  };

export const loadEditLocation = () => ({
  type: EDIT_LOCATION.LOAD,
});

export const setEditLocation = (data) => ({
  type: EDIT_LOCATION.SUCCESS,
  payload: data,
});

export const failEditLocation = (err) => ({
  type: EDIT_LOCATION.FAIL,
  payload: err,
});
