import React from 'react';
import useStyles from './styles';
import { icons } from '../../assets';

const ImageViewer = (props) => {
  const { image, onClose } = props;
  const classes = useStyles();

  return (
    <div className={classes.modal_mask_container}>
      <img
        src={icons().wrong_icon_white}
        className={classes.close_icon}
        onClick={() => onClose && onClose()}
      />
      <div className={classes.image_container}>
        <img src={image} alt='' className={classes.image} />
      </div>
    </div>
  );
};

export default ImageViewer;
