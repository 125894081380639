import { makeStyles } from '@mui/styles';
import { Colors } from '../../common/colors';

export default makeStyles((theme) => ({
  button_container: {
    display: 'flex',
    width: '100%',
  },
  button: {
    width: '300px',
    cursor: 'pointer',
    fontSize:16,
    padding: '7.5px 15px',
    border:'none',
    borderBottom: `2px solid ${Colors.CHINESE_SILVER}`,
    display: 'flex',
    alignItems: 'center',
    color: Colors.DAVYS_GREY,
    background: 'transparent',
    fontWeight:400,
    fontFamily:'Lato, sans-serif !important',
    position:'relative',
  },

  rightIcon: {
    marginLeft: 'auto',
  },
  cal_icon: {
    width:'100%',
    display: 'flex',
    
  },
}));
