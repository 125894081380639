import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
// import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  menuList,
  venderMenuList,
  designerMenuList,
} from '../../common/sidebarMenu';
import { icons } from '../../assets';
import Avatar from '@mui/material/Avatar';
import { Colors } from '../../common/colors';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Strings } from '../../common/strings';
import Dialog from '../../components/Dialog';
import { isDesigner, isVendor } from '../../utils/appUtils';
import { connect } from 'react-redux';

const Sidebar = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const userName = localStorage.getItem('userName');
  const [anchorEl, setAnchorEl] = useState(null);
  const [userProfile, setUserProfile] = useState(localStorage?.profilePicture);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const displayMenuList = isVendor()
    ? venderMenuList()
    : isDesigner()
    ? designerMenuList()
    : menuList();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect(() => {
  //   const userData = props?.getUserByIdData;
  //   console.log("userData :", userData);
  //   if (Object.keys(userData).length !== 0) {
  //     console.log("calllll12")
  //     setUserProfile(userData?.data?.profilePicture);
  //   } else setUserProfile(localStorage?.profilePicture);
  // }, [props.getUserByIdData]);

  useEffect(() => {
    const profile = localStorage.getItem('profilePicture');
    setUserProfile(profile);
  }, [localStorage.getItem('profilePicture')]);

  const logout = () => {
    if (localStorage?.rememberMe) {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('profilePicture');
      localStorage.removeItem('userName');
      navigate('/login');
    } else {
      localStorage.clear();
      navigate('/login');
    }
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <img src={icons().logo_white} className={classes.logo} />
          </div>

          <div className={classes.listContainer}>
            {displayMenuList.map((item, index) => {
              return (
                <NavLink
                  key={'menu' + index}
                  className={classes.iconContainer}
                  to={item.path}
                >
                  <div className={classes.navItem}>
                    <img src={item.icon} className={classes.icons} />
                    {item.path === pathname && (
                      <div className={classes.divider} />
                    )}
                  </div>
                  <Typography
                    variant='caption_400'
                    style={{ color: Colors.POWDER_BLUE, whiteSpace: 'nowrap' }}
                  >
                    {item?.title_sentence_case}
                  </Typography>
                </NavLink>
              );
            })}
          </div>

          <div className={classes.bottomContainer}>
            <NavLink
              key={'menu' + '00'}
              className={classes.iconContainer}
              to={'/app/settings'}
            >
              <div className={classes.navItem}>
                <img src={icons(theme).settings} className={classes.icons} />
                {pathname === '/app/settings' && (
                  <div className={classes.divider} />
                )}
              </div>
              <Typography
                variant='caption_400'
                style={{ color: Colors.POWDER_BLUE, whiteSpace: 'nowrap' }}
              >
                {'Settings'}
              </Typography>
            </NavLink>
            <Avatar
              sx={{ bgcolor: Colors.RAISIN_BLACK, cursor: 'pointer' }}
              classes={{ root: classes.profile }}
              src={userProfile}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {userName?.charAt(0)?.toUpperCase()}
            </Avatar>

            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              classes={{ paper: classes.menu_paper }}
            >
              <MenuItem
                classes={{ root: classes.menu_item }}
                onClick={() => {
                  setShowLogoutDialog(true);
                  setAnchorEl(null);
                }}
              >
                {Strings.LOGOUT}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {showLogoutDialog && (
        <Dialog
          title={Strings.LOGOUT}
          message={Strings.ARE_YOU_SURE_LOGOUT}
          onClose={() => setShowLogoutDialog(false)}
          onClickYesButton={() => logout()}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getUserByIdData: state.getUserByIdData.data,
});

export default connect(mapStateToProps)(Sidebar);
