import axios from 'axios';
import { ADD_NOTE } from '../../constants';

export const addNote = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadAddNote());
  axios
    .post(`/admin/addNote`, data, config)
    .then((res) => {
      dispatch(setAddNote(res));
      onSuccess(res.data);
    })
    .catch((err) => {
      dispatch(errorAddNote(err.response));
      onError(err.response);
    });
};

export const loadAddNote = () => ({
  type: ADD_NOTE.LOAD,
});

export const setAddNote = (data) => ({
  type: ADD_NOTE.SUCCESS,
  payload: data,
});

export const errorAddNote = (error) => ({
  type: ADD_NOTE.FAIL,
  payload: error,
});
